@import 'colors';

// CSS correcties t.b.v. blueriq applicaties met name voor border-top die nu standaard is
// hier zitten ook adviseur correcties in waarbij een geneste fieldset structuur is en een no-bottom-border die ook een border-top krijgt


// only for BlueriqElements
div[data-component="BlueriqElement"] {
	fieldset {
		width: 100%;
		min-width: 0;
		padding: 0 0 10px 0;
		border-bottom: none;
		padding-top: 20px;
		margin: 0;
		&.form-fieldset {
			border-top: solid 1px $nn_extra_light_grey;
			h2 + hr {
				display:none;
			}
		}
		&.no-bottom-border {
			border-top: solid 1px $nn_extra_light_grey;
		}
		&.form-fieldset--header {
			border:0;
			padding:0;

			& + .form-fieldset {
				border-top:0;
				padding-top:0;
			}
		}
	}

	.block,
	.dialog--slidepanel {
		fieldset.form-fieldset:first-child {
			border-top:0;
			padding-top:0;
		}
	}
	// remove first fieldset border inside tab
	.tab fieldset.form-fieldset:first-of-type {
		border-top: none;
	}

	//remove border after bleuriq header wich contains a hr
	div[data-blueriq-component-name="ContainerDefaultTypeHeader"]  + .form-fieldset {
			border-top:0;
	}

}