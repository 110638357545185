@use 'sass:math';

@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../global/css/colors';

/* COMPONENT: quote
=============================================================================*/
.quotee {
  @include box-sizing-border-box;
  background-color: $nn_ultra_light_grey;
  position: relative;
  margin-bottom: 30px;
  padding: 30px 30px 20px 30px;
  font-style: normal;
}

.quotee-title {
  font-weight: bold;
  color: $nn_extra_dark_grey;
}

.quotee-quote,
.quotee-quote p {
  @include font-size(24, 41);
  @media (max-width: $mobile_break_point) {
    @include font-size(18, 24);
  }
  color: $nn_extra_dark_grey;
  position: relative;
  margin: 60px 0 30px;
  font-style: normal;
  @include font-bold;
  &:before {
    content: '“';
    font-family:
      Times New Roman,
      Arial,
      Helvetica,
      Sans-serif;
    color: $nn_medium_orange;
    @include font-size(140);
    position: absolute;
    top: -70px;
    left: 0;
    line-height: 1;
  }
}
.quotee-author {
  color: $nn_medium_grey;
  font-style: italic;
}
.quotee--light {
  background-color: $white;
  border-left: 1px solid $nn_extra_light_grey;
  .quotee-quote {
    color: $nn_medium_grey;
  }
}

/* PART: Deprecated parts
=============================================================================*/
.quotee-cite,
.quotee-avatar,
.quotee-name {
  display: none; /* Old parts. */
}

/* THEME: quote
=============================================================================*/

.quotee--border {
  @include border-radius(5px);
  border: 1px solid $nn_light_grey;
  margin: 20px 0 20px 120px;

  p {
    font-size: math.div(16, 22) + 0em;
    line-height: 1.4em;
    font-weight: normal;
    font-family: verdana, arial;
    margin-bottom: 20px;
  }

  .quotee-cite,
  .quotee-avatar,
  .quotee-name {
    display: inline-block;
  }

  p.h2:before,
  p.h2:after {
    display: none;
  }
  .quotee-avatar {
    margin: -15px 0 0 -135px;
  }
  .quotee-cite {
    .quotee-avatar {
      width: 100px;
      height: 100px;
      position: absolute;
    }
    .quotee-name {
      color: $nn_medium_orange;
      text-align: left;
      font-style: normal;
      font-weight: bold;
    }
    position: relative;
    display: block;
    left: auto;
  }
}

.quotee--border:last-child {
  margin-bottom: 30px;
}

.quotee--border:before {
  content: '';
  background: url('./teasers/img/pointers.png') no-repeat;
  height: 16px;
  position: absolute;
  width: 11px;
  top: 20px;
  left: -10px;
}

.quotee--border.quotee--avatar-right {
  margin-left: 0;
  margin-right: 120px;
  .quotee-avatar {
    margin: -15px -135px 0;
    right: 0;
  }
}
.quotee--border.quotee--avatar-right:before {
  display: none;
}

.quotee--border.quotee--avatar-right:after {
  content: '';
  background: url('./teasers/img/pointers.png') -11px 0 no-repeat;
  height: 16px;
  position: absolute;
  width: 11px;
  right: -10px;
  top: 20px;
}

/* THEME: text image left
=============================================================================*/

.teaser--text-image-left {
  overflow: hidden;
}

.teaser-img {
  float: left;
  width: 85px;
}

.teaser-text {
  @include box-sizing-border-box;
  float: left;
  padding-left: 15px;
  padding-top: 60px;
  vertical-align: middle;
  width: 365px;

  .h1 {
    line-height: 1em;
  }
}

/* THEME: Flippable component
=============================================================================*/

.flippable-component {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.flippable-component .h1 {
  line-height: 1.1em;
}
.flippable-component .component-back-side {
  left: 0;
  position: absolute;
  top: 15px;
  visibility: hidden;
}

.cover {
  background: none repeat scroll 0 0 #ffffff;
  opacity: 0;
  position: absolute;
  width: 300px;
  z-index: 10;
}

.flip-panel,
.left-flip-panel,
.right-flip-panel {
  overflow: hidden;
  position: absolute;
  width: 300px;
  z-index: 20;
}
.flip-panel .front {
  border: 0 solid rgba(0, 0, 0, 0);
  left: 6px;
  position: absolute;
  top: 0;
  z-index: 10;
}
.flip-panel.left-flip-panel .front {
  border-left-color: #d7d7d7;
  left: 6px;
}
.flip-panel.right-flip-panel .front {
  border-right-color: #f7f7f7;
}
.flip-panel .side {
  height: 100%;
  position: absolute;
  top: 0;
  width: 6px;
  z-index: 20;
}
.flip-panel.left-flip-panel .side {
  background: none repeat scroll 0 0 $nn_light_grey;
  left: 0;
}
.flip-panel.right-flip-panel .side {
  background: none repeat scroll 0 0 #eeeeee;
  right: 0;
}

/*

@TODO
FIX ME HACK sprint #7

*/

@media (min-width: $mobile_break_point) and (max-width: $tabmob_break_point) {
  .is-responsive {
    .masthead-image {
      width: 45%;
    }
    /*.masthead-content {
			width: 55%;

			ul li:last-child {
				display: none;
			}
		}
*/
    .grid--block-wau {
      > .grid-unit {
        width: 33%;
      }

      > .grid-unit:last-child {
        width: 100%;

        .image--wau {
          width: 29%;
          float: left;
          margin: -5px 30px 0 0;
        }
      }
    }

    .grid--block-kappa {
      > .grid-unit {
        width: 100%;
      }

      > .grid-unit + .grid-unit {
        margin-top: 10px;
      }
    }

    .grid--block-nu {
      > .grid-unit {
        width: 100%;
        float: none;
      }
    }
  }
}

/* THEME: 2023 Matter style
=============================================================================*/

.quotee--border-orange-bold-left {
  background-color: #fff;
  border-left: 4px solid $nn_medium_orange;
  border-radius: 2px;
  padding: 16px 0;
  margin: 56px 0;
}

.quote-content {
  color: $nn_medium_orange;
  position: relative;
  font-style: normal;
  @include font-medium;
  margin-bottom: 16px;
}
.quote-medium {
  @include font-size(28, 30.8);
  @media (max-width: $mobile_break_point) {
    @include font-size(24, 26.4);
  }
}
.quote-large {
  @include font-size(36, 39.6);
  @media (max-width: $mobile_break_point) {
    @include font-size(28, 30.8);
  }
}
.quote-cite {
  margin-left: 32px;
  .quote-author {
    @include font-bold;
    @include font-size(14, 21);
    font-style: normal;
  }
  .quote-author-role {
    color: $nn_medium_grey;
    font-style: normal;
  }
}

cite a {
  text-decoration: none;
}
