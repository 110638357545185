.o-media {
    display: flex;
    align-items: flex-start;

    &__figure {
        margin-right: 1em;
        //figure on the right side
        &--reversed {
            margin: 0 0 0 1em;
            order: 1;
        }
    }
    &__body {
        flex: 1;
    }
    &--center {
        align-items: center;
    }
}

@media (max-width: $phone) {
    .o-media {}
}
