@import "../_helpers/vars";

@keyframes expand {
  from {
    max-height: 0;
  }
  99% {
    max-height: 1000px;
  }
  to {
    max-height: none;
  }
}

@keyframes collapse {
  from {
    max-height: 1000px;
  }
  to {
    max-height: 0;
  }
}

.c-focus-element {
  display: block;
  max-height: 0;
  overflow: hidden;
  z-index: $focusElement;
  position: relative;
  animation: collapse 0.5s ease-out;

  &--on {
    max-height: none;
    animation: expand 0.5s ease-out;
  }
}

.c-focus-element-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $focusElementOverlay;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.5s ease-out;
}
