@import '../_helpers/sizes';
@import '../_helpers/mixins';
@import '../_helpers/color';

.masthead-productpage {
  @include flexbox;
  flex-direction: row;
  width: 100%;

  @media(max-width: $size_l) {
    flex-direction: column-reverse;
    min-width: $size_xxs;
  }

  .masthead-productpage__intro {
    @include flex(1);
    box-sizing: border-box;
    margin-right: 32px;
    padding: 24px 64px 24px 0;
    min-width: 470px;

    @media (max-width: $size_l) {
      margin-right: none;
      padding: 24px 0;
      min-width: $size_xxs;
  }

    .intro__text {
      max-width: 520px;
    }

    .intro__list {
      margin-bottom: 32px;

      .c-list-icon__item {
         margin: 4px 0;
      }
      .list-icon--green {
        color: $nn_green_700;
        margin-right: 8px;
      }
    }

    .intro_cta-container {
        @include flexbox;
        flex-direction: row;
        align-items: stretch;
        gap: 16px;

        @media (max-width: $size_s) {
          flex-direction: column;
        }

        a {
          text-decoration: none;
        }

        .c-button__icon {
          color: $nn_medium_orange_600;
          margin-right: 8px;
        }

        .cta--main {
          width: 100%;
        }

        .cta--secondary {
          width: 100%;
          @include flexbox;
          align-items: center;
          justify-content: center;
        }
    }

    .intro__disclamer {
      color: $nn_grey_800;
      margin-top: 16px;
      max-width: 520px;
    }
  }

  .masthead-productpage__sticker--wrapper {
    position: relative;
    z-index: 1;

    .masthead-productpage__sticker {
      min-width: 75px;
      right: -45px;
      top: 40px;
      position: absolute;

      @media (max-width: $size_l) {
        right: 32px;
        top: -60px;
      }
    }
  }

  .masthead-productpage__image--wrapper {
    position: relative;
    height: 408px;
    width: 545px;
    overflow: hidden;

    @media (min-width: $size_xs) and (max-width: $size_l) {
      height: 396px;
      width: 100%;
    }

    @media (max-width: $size_xs) {
      height: 201px;
      width: 100%;
    }
    
    .masthead-productpage__image {
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }
  }
}