@import "../_helpers/mixins";
@import "../_helpers/color";
@import "../_helpers/component-design-token-maps";

.c-button {
  @include font-medium;
  cursor: pointer;
  @include color($primaryButton_elements_Button_styles_color);
  display: inline-block;
  text-decoration: none;
  text-align: center;
  padding: 9px 16px 11px 16px;
  line-height: 1;
  box-sizing: border-box;
  @include background-color($primaryButton_elements_Button_styles_backgroundColor);
  border: 0;
  @include border-radius(4px);

  .opt-out-nitti-grotesk-font & {
    padding: 10px;
  }

  &:focus-visible {
    outline-offset: 0;
    outline-style: solid;
    outline-width: 2px;
    @include outline-color($primaryButton_elements_Button_interactionStates_FocusVisible_styles_outlineColor);
  }

  // Give a little space for adjacent .c-buttons
  // (note that templating engines that leave whitespace between buttons will also result in extra space)
  + .c-button {
    margin-left: 5px;
  }

  // Dagny display renders slighty higher on ie
  .ie10 &,
  .ie11 & {
    padding-top: 12px;
  }

  .ie10 &--large,
  .ie11 &--large {
    padding-top: 16px;
  }

  &__icon {
    // Take icon out of flow, so its size does not attribute to the height of the button
    line-height: 0;
    vertical-align: middle;
    margin-top: -1px;
  }

	&:hover {
		text-decoration:none;
    @include color($primaryButton_elements_Button_styles_color);
    @include box-shadow($primaryButton_elements_Button_interactionStates_Hover_styles_boxShadow);
    @include background-color($primaryButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
	}

  &--next,
  &--back {
    min-width: 38px;
    min-height: 38px;
    position: relative;
    display: inline-block;

    &__icon {
      font-size: 18px;
      font-weight: 900;
      display: inline-block;
      pointer-events: none;
    }
  }

  &--next {
    padding-right: 28px;

    &__icon {
      position: absolute;
      top: 10px;
      right: 8px;
    }
  }

  &--back {
    @include color($plainButton_elements_Button_styles_color);
    @include background-color($plainButton_elements_Button_styles_backgroundColor);
    padding-left: 3em;
    position: relative;
    display: inline-block;

    &:hover {
      text-decoration: none;
      color: inherit;
      @include color($plainButton_elements_Button_styles_color);
      @include background-color($plainButton_elements_Button_styles_backgroundColor);
    }

    &:hover::before {
      color: inherit;
      @include border-color($secondaryButton_elements_Button_interactionStates_Hover_styles_borderColor);
      @include background-color($secondaryButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
      @include box-shadow($secondaryButton_elements_Button_interactionStates_Hover_styles_boxShadow);
    }

    &__icon {
      @include color($secondaryButton_elements_Icon_styles_color);
      position: absolute;
      top: 10px;
      left: 10px;

      &:hover {
        @include color($secondaryButton_elements_Icon_interactionStates_Hover_styles_color);
      }
    }

    &:before {
      box-sizing: border-box;
      content: " ";
      width: 38px;
      height: 38px;
      position: absolute;
      border-width: 1px;
      border-style: solid;
      @include border-color($secondaryButton_elements_Button_styles_borderColor);
      border-radius: 4px;
      top: 0;
      left: 0;
    }
  }

  &--secondary {
    @include background-color($secondaryButton_elements_Button_styles_backgroundColor);
    @include color($secondaryButton_elements_Button_styles_color);
    border-width: 1px;
    border-style: solid;
    @include border-color($secondaryButton_elements_Button_styles_borderColor);
    padding: 8px 16px 10px 16px;

    .opt-out-nitti-grotesk-font & {
      padding: 9px;
    }

    &:hover {
      @include color($secondaryButton_elements_Button_styles_color);
      @include border-color($secondaryButton_elements_Button_interactionStates_Hover_styles_borderColor);
      @include background-color($secondaryButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
    }
  }

  &--succes {
    background-color: $nn_medium_green;

    &:hover {
      background-color: darken($nn_medium_green, 5%);
    }
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--disabled,
  &--disabled:hover {
    background-color: $nn_extra_light_grey;
    color: $nn_medium_grey;
    cursor: default;
    box-shadow: none;
  }

  &--dashed {
    font-weight: normal;
    @include background-color($addButton_elements_Button_styles_backgroundColor);
    @include color($addButton_elements_Button_styles_color);
    border-width: 1px;
    border-style: dashed;
    @include border-color($addButton_elements_Button_styles_borderColor);

    &.u-textalign-left {
      text-align: center;
    }

    &:hover {
      color: inherit;
      @include background-color($addButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
      @include border-color($addButton_elements_Button_interactionStates_Hover_styles_borderColor);
      @include box-shadow($addButton_elements_Button_interactionStates_Hover_styles_boxShadow);
    }

    .c-button__icon {
      @include color($addButton_elements_Icon_styles_color);
      margin: 0 8px 0 0;
    }
  }

  &--large {
    @include font-size(20);
    padding: 11px 16px 15px 16px;

    .opt-out-nitti-grotesk-font & {
      padding: 13px 16px;
    }
  }
}
