@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../global/css/colors';

/* COMPONENT: dialog
=============================================================================*/
.dialog {
  @include border-radius(5px);
  @include drop-shadow(0px, 2px, 13px, rgba(0, 0, 0, 0.3));

  position: absolute;
  z-index: 1100;
  padding: 20px 20px 0;
  background: #fff;
  border: 1px solid $nn_light_grey;
  max-width: 570px;
  width: auto;
  top: 100px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .dialog {
      bottom: 0;
      left: 0;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;
      width: 100%;

      > .content {
        display: inline-block;
      }

      > .close-button {
        position: fixed;
        top: 35px;
      }
    }
  }
}

.dialog .content {
  padding-top: 2px; /* match visual design */
  overflow: hidden;

  h3 {
    padding-right: 25px; /* Prevent text over close icon */
  }

  .p--dialog-content {
    display: inline-block;
    margin-bottom: 15px;
  }
}

.dialog--expandable-close {
  float: right;
  width: 20px;
  height: 20px;
  background: url('./dialogs/img/close-button.png') no-repeat 5px 5px;
  margin: 0 0 5px 0;
  cursor: pointer;
  text-indent: -9999px;
}

.dialog-sprite {
  background-image: url('dialogs/img/dialogs.png');
  background-repeat: no-repeat;
}

/* PART: tooltip arrow
=============================================================================*/
.tooltip-arrow {
  @extend .dialog-sprite;
  position: absolute;
  right: 13px;
  top: -11px;
  background-position: -10px 0;
  width: 20px;
  height: 11px;
}

.close-button {
  @extend .dialog-sprite;
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 15px;
  background-position: 0 0;
  width: 10px;
  height: 10px;
  z-index: 1200;
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .dialog {
      left: 10px;
      margin-right: 20px;
      width: auto;
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .dialog {
      left: 0;
      margin: 0 3%;
      min-width: 94%;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}

/* COMPONENT: Information dialog
=============================================================================*/
.dialog--information,
.dialog--expandable {
  padding: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;

  width: 24px;
  height: 24px;
  overflow: visible;
  position: relative;

  color: transparent;
  font-family: 'nn-iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  text-align: center;

  &:before {
    color: $nn_medium_grey;
    content: '\e916';
    user-select: none;
  }

  &:hover:before {
    color: $nn_dark_grey;
  }

  > * {
    visibility: hidden;
  }
}

// Ugly hack to ensure this legacy info icon gets space when used behind a new type of inline element
.c-form-field__element--inline + .dialog--information {
  margin-left: 10px;
}

.dialog--expandable {
  position: absolute;
  margin-left: 15px;
  margin-top: 10px;
}

.field--checkbox,
.field--radiobutton {
  > .dialog--information {
    position: absolute;
    right: -22px;
    top: 5px;
  }

  .dialog--expandable {
    margin-top: 0;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive .field--checkbox > .dialog--information {
    right: -28px;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .dialog--expandable {
      right: 0;
      top: 0;
    }
    .monospace {
      .dialog--expandable {
        margin-top: 18px;
      }
    }
    .dialog--expandable-content {
      padding: 14px;
    }
  }
}

.dialog--expandable-content {
  @include border-radius(5px);

  padding: 11px;
  border: 1px solid $nn_light_grey;
  background: $nn_ultra_light_grey;
  margin: 0 0 10px;
  z-index: 100;
}

.premium-discount {
  .dialog--expandable-content {
    margin-top: 15px;
  }
}

.touch-fields {
  .dialog--information,
  .dialog--expandable {
    width: 24px;
    height: 24px;
    @include border-radius(16px);
  }
}

/* COMPONENT: Youtube dialog
=============================================================================*/
.youtube {
  min-width: 560px;
}

/* CONTEXT: Grid Field Row
=============================================================================*/
.grid--field-row .grid-unit {
  .dialog--information {
    position: absolute;
    right: 10px;
    top: 0;
    /*top: -3px;*/
  }
}

/* CONTEXT: Nested Grid Field Row
=============================================================================*/
@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--field-row .grid--field-row .grid-unit .dialog--information {
      right: 25px;
    }
  }
}

/* CONTEXT: Grid Field Row header
=============================================================================*/
.grid--field-row .grid-unit .field-row-header .dialog--information {
  top: 10px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--field-row .grid-unit .field-row-header .dialog--information {
      top: 0;
    }
  }
}

/* CONTEXT: Grid Field Row
=============================================================================*/
.grid--block-delta .grid-unit,
.grid--block-beta .grid-unit {
  > .dialog--information {
    position: absolute;
    right: -40px;
    top: 12px;
  }

  .date-picker-icon ~ .dialog--information,
  .date-picker-icon ~ .dialog--expandable {
    right: -47px;
  }

  div[class*='span'].text-field-wrapper + .date-picker-icon,
  input[class*='span'] + .date-picker-icon,
  input[class*='span'] + .dialog--information,
  input[class*='span'] + .date-picker-icon + .dialog--information {
    position: relative;
    right: auto;
    left: 8px;
    display: inline-block;
  }

  input[class*='span'] + .dialog--information,
  input[class*='span'] + .date-picker-icon + .dialog--information {
    top: 0;
  }
}

.grid--block-filter .grid-unit {
  > .dialog--information {
    position: absolute;
    right: -26px;
    top: 10px;
  }
}
.touch-fields {
  .grid--block-delta,
  .grid--block-beta {
    .grid-unit {
      .dialog--information {
        top: 9px;
      }

      .field--radiobutton,
      .field--checkbox {
        .dialog--information {
          top: 0;
        }
      }
    }
  }
}

.grid--block-iota .grid-unit,
.grid--block-masthead .grid-unit {
  .custom-select-box + .dialog--information {
    margin-top: 6px;
  }

  input[class*='span'] + .date-picker-icon {
    position: relative;
    right: auto;
    left: 8px;
    display: inline-block;
  }
}

/*Form in column grid */
@media (min-width: $mobile_break_point) {
  .is-responsive .col-one-half,
  .is-responsive .col-t-one-half,
  .is-responsive .col-d-one-half {
    .grid--block-delta .grid-unit > .dialog--information {
      right: 0;
      top: 9px;
    }
  }
}

@media (min-width: $tabmob_break_point) and (max-width: $tablet_break_point) {
  .is-responsive .col-one-half,
  .is-responsive .col-t-one-half,
  .is-responsive .col-d-one-half {
    .grid--block-delta .grid-unit > .dialog--information {
      right: 0;
      top: 9px;
      position: relative;
    }
  }
}

/* //End Form in column grid */

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-delta .grid-unit > .dialog--information,
    .grid--block-beta .grid-unit > .dialog--information,
    .grid--block-filter .grid-unit > .dialog--information {
      right: 0;
      top: 9px;
    }
  }
}

/* CONTEXT: Field label
=============================================================================*/
.field-label {
  position: relative;
}
.field-label .dialog--information {
  position: absolute;
  right: 10px;
  top: 5px;
}

/* THEME: dialog--ajax
=============================================================================*/
.dialog--ajax {
  max-width: 690px;
}

@media (min-width: $desktop_break_point) {
  .dialog {
    width: 100%;
  }
}
/*width: auto;*/

/* THEME: dialog--iframe
=============================================================================*/
.dialog--iframe {
  width: 660px;
  /*max-width: 690px;*/
  max-width: 660px;
}

/* THEME: dialog--warning
=============================================================================*/
.dialog--warning {
  @include border-radius(4px);
  background-color: #f9f8c8;
  border: 1px solid $nn_medium_orange;
  max-width: 690px;
  padding: 20px 25px 15px 10px;

  h2 {
    background: url('../control/alertbox/img/alertbox-icons-sprite.png')
      no-repeat;
    background-position: -581px 5px;
    background-repeat: no-repeat;
    padding: 0 0 0 28px;
    margin: 0 0 10px 0;
    position: relative;
  }

  p {
    padding: 0 0 0 28px;
    margin: 0 0 15px 0;
    position: relative;
  }

  .close-button {
    z-index: 1;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive .dialog--warning h2 {
    background-position: -281px 2px;
  }
}

/* COMPONENT: dialog--navigation
=============================================================================*/
.expanded-main-navigation a.active {
  color: #000;
}

/* PART: .tab
=============================================================================*/
.dialog--navigation {
  max-width: 960px;

  > a {
    *text-decoration: none;
  }

  .tab,
  .tab span {
    background: url('./dialogs/img/main-navigation-tab.png') no-repeat 100% 100%;
  }

  .tab {
    display: inherit;
    position: absolute;
    left: 4px;
    top: -40px;
    padding-right: 25px;
    line-height: 1.5em;
    height: 40px;

    span {
      display: block;
      padding-top: 14px;
      padding-left: 25px;
      color: $nn_medium_orange;
      background-position: 0 0;
      height: 27px;
    }
  }

  /* Reset for the inner span */
  .tab span span {
    background: none;
    padding: 0;
    margin: 0;
    border: none;
  }

  .tab span span.item-mijnnn {
    padding-left: 14px;
    background-position: left 5px;
  }

  .tab.meer span span {
    padding-right: 12px;
  }

  div.meer::after {
    content: '';
    position: absolute;
    top: 22px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid $nn_medium_orange;
    pointer-events: none;
    z-index: 9999;
  }

  a.active {
    color: #000;
  }

  .content {
    padding-bottom: 15px;
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .dialog--navigation {
      width: auto;
    }
  }
}

/* PART: dialog navigation categories
=============================================================================*/
.dialog--navigation .categories {
  margin: 0;
  padding: 0;
  white-space: normal;

  .category {
    vertical-align: top;
    display: inline-block;
    margin: 0 15px 0 0;
    width: 160px;
    width: auto;

    p.more {
      border-top: 1px solid $nn_light_grey;
      padding: 5px 0 0 0;
      background: none;
    }

    a.more {
      display: inline-block;
    }
  }

  img {
    max-width: 160px;
    height: auto;
  }

  .caption em {
    font-style: normal;
  }
}

/* PART: dialog self service company SSC
	Overriding the SSC stylesheet
=============================================================================*/
@media (max-width: $tablet_break_point) {
  .is-responsive {
    .etdialog .etdialogclosebutton {
      background-position: 10px 0;
      width: 20px;
      height: 20px;
    }
  }
}

.ie .dialog--spinner {
  min-width: 300px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .etdialog {
      left: 10px !important;
      margin-right: 10px;
      width: auto !important;
      max-width: 100% !important;
      min-width: 80%;
    }

    .etdialogcontent > div {
      max-width: 100% !important;
      width: auto !important;
    }
  }
}

/* PART: dialog ajax loader
=============================================================================*/
.ajax-loader {
  position: fixed;
  z-index: 2050;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2) url('./dialogs/img/ajax-loader.gif') no-repeat
    center center;
  opacity: 0;
}

.ajax-loader--v2 {
  background: transparent url('./dialogs/img/ajax-loader-v2.gif') no-repeat 0 0;
  width: 32px;
  height: 32px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .dialog--tooltip {
      position: absolute;
      bottom: auto;
      overflow: visible;

      > .close-button {
        position: absolute;
        top: 15px;
      }
    }
  }
}

/* PART: dialog slide-panel (sticky footer)
=============================================================================*/
@media (min-width: $mobile_break_point) {
  .dialog--slidepanel {
    box-shadow: none;
    border-radius: 0;
    border: 0;
    border-top: 3px solid $nn_medium_orange;
    margin-right: 0 !important;
    background-color: $nn_ultra_light_grey;
  }

  .dialog--slidepanel + .block--bordered-top {
    border-color: $nn_light_grey;
  }
}
