$fonticon-content--icon-search: "\e94b";
$fonticon-content--icon-user: "\e94c";
$fonticon-content--icon-user-active: "\e94d";
$fonticon-content--pict-calculator: "\e94a";
$fonticon-content--angle-left: "\3c";
$fonticon-content--angle-right: "\3e";
$fonticon-content--pict-download: "\e600";
$fonticon-content--file: "\e601";
$fonticon-content--check: "\e602";
$fonticon-content--close: "\e603";
$fonticon-content--delete: "\e604";
$fonticon-content--image: "\e605";
$fonticon-content--chevron-left: "\e606";
$fonticon-content--chevron-right: "\e607";
$fonticon-content--plus: "\e608";
$fonticon-content--minus: "\e609";
$fonticon-content--twitter: "\e60a";
$fonticon-content--facebook: "\e60b";
$fonticon-content--menu: "\e60c";
$fonticon-content--google-plus: "\e60d";
$fonticon-content--linkedin: "\e60e";
$fonticon-content--switch-off: "\e60f";
$fonticon-content--switch-on: "\e610";
$fonticon-content--lock: "\e900";
$fonticon-content--lock-open: "\e901";
$fonticon-content--arrow-bottom: "\e902";
$fonticon-content--arrow-top: "\e903";
$fonticon-content--pict-search: "\e904";
$fonticon-content--arrow-right: "\e905";
$fonticon-content--arrow-left: "\e906";
$fonticon-content--user: "\e907";
$fonticon-content--app: "\e908";
$fonticon-content--close--thin: "\e909";
$fonticon-content--mortgage: "\e90a";
$fonticon-content--whattodo: "\e90b";
$fonticon-content--laptop: "\e90c";
$fonticon-content--gross-net: "\e90d";
$fonticon-content--search: "\e90e";
$fonticon-content--savings-account: "\e90f";
$fonticon-content--remaining-debt-home: "\e910";
$fonticon-content--creditcard: "\e911";
$fonticon-content--moving-consideration: "\e912";
$fonticon-content--accident: "\e913";
$fonticon-content--calculate: "\e914";
$fonticon-content--pict-error: "\e915";
$fonticon-content--pict-info: "\e916";
$fonticon-content--pict-success: "\e917";
$fonticon-content--pict-warning: "\e918";
$fonticon-content--pict-dwelling: "\e919";
$fonticon-content--pict-feedback: "\e91a";
$fonticon-content--pict-meeting: "\e91b";
$fonticon-content--pict-premium: "\e91c";
$fonticon-content--pict-upload: "\e91d";
$fonticon-content--percent: "\e91e";
$fonticon-content--pict-twitter: "\e91f";
$fonticon-content--pict-advisor: "\e920";
$fonticon-content--pict-callnow: "\e921";
$fonticon-content--pict-call: "\e922";
$fonticon-content--pict-client: "\e923";
$fonticon-content--pict-mail: "\e924";
$fonticon-content--pict-print: "\e925";
$fonticon-content--pict-star: "\e926";
$fonticon-content--pict-website: "\e927";
$fonticon-content--pict-clarity: "\e928";
$fonticon-content--pict-percentage: "\e929";
$fonticon-content--pict-authorisation: "\e92a";
$fonticon-content--pict-extra-attention: "\e92b";
$fonticon-content--pict-arrow-right: "\e92c";
$fonticon-content--pict-hourglass: "\e92d";
$fonticon-content--pict-arrow-left: "\e92e";
$fonticon-content--pict-plus: "\e92f";
$fonticon-content--pict-minus: "\e930";
$fonticon-content--house: "\e931";
$fonticon-content--nn-logo: "\e932";
$fonticon-content--umbrella: "\e933";
$fonticon-content--pict-star-pos: "\e934";
$fonticon-content--personal-savings: "\e935";
$fonticon-content--debt: "\e936";
$fonticon-content--tree: "\e937";
$fonticon-content--folder: "\e938";
$fonticon-content--boat: "\e939";
$fonticon-content--edit: "\e93a";
$fonticon-content--cross-thin: "\e93b";
$fonticon-content--calculator: "\e93c";
$fonticon-content--bullet: "\e93d";
$fonticon-content--zorg: "\e93e";
$fonticon-content--particulier-beleggen: "\e93f";
$fonticon-content--particulier-hypotheken: "\e940";
$fonticon-content--particulier-lenen: "\e941";
$fonticon-content--particulier-levensverzekeringen: "\e942";
$fonticon-content--particulier-schadeverzekeringen: "\e943";
$fonticon-content--particulier-sparen: "\e944";
$fonticon-content--particulier-zorgverzekering: "\e945";
$fonticon-content--zakelijk-collectief-pensioen: "\e946";
$fonticon-content--zakelijk-individueel-pensioen: "\e947";
$fonticon-content--zakelijk-inkomensverzekeringen: "\e948";
$fonticon-content--zakelijk-schadeverzekeringen: "\e949";
$fonticon-content--chevron-up: "\f053";
$fonticon-content--eye: "\f205";
$fonticon-content--chat-question: "\e94e";
$fonticon-content--qr-code: "\e94f";
$fonticon-content--advisor-no-bg: "\e950";
$fonticon-content--toegang-en-beveiliging: "\e951";
$fonticon-content--overzicht: "\e952";
$fonticon-content--my-documents: "\e953";
$fonticon-content--mijn-betalingsoverzicht: "\e954";
$fonticon-content--lopende-zaken: "\e955";
$fonticon-content--gegevens-en-instellingen: "\e956";
$fonticon-content--key-lock: "\e957";
$fonticon-content--add: "\e958";
$fonticon-content--trashcan: "\e959";
$fonticon-content--envelope: "\e95a";
$fonticon-content--archive: "\e95b";
$fonticon-content--mail: "\e95c";
$fonticon-content--questionmark: "\e95d";
$fonticon-content--magnifying-glass: "\e95e";
$fonticon-content--qr-code-line: "\e95f";
$fonticon-content--pencil: "\e960";
$fonticon-content--download: "\e961";
$fonticon-content--information: "\e962";
$fonticon-content--youtube: "\e963";
$fonticon-content--x: "\e964";
$fonticon-content--instagram: "\e965";
$fonticon-content--whatsapp: "\e966";
$fonticon-content--paper-clip: "\e967";