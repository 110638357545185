@import '../_helpers/mixins';
@import '../_helpers/color';

.c-comparison-table {
  width: 100%;
  @extend .u-color-background--ultra-light-grey;
  @include border-radius(4px);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 10px;
  margin: 0;

  &__option {
    flex: 1 1; //percentage(1/3);
    //max-width: 33%; //percentage(1/3);
    box-sizing: border-box;
    padding: 10px;
    //android fix
    display: inline-block;
    vertical-align: top;

    &__container {
      background-color: $white;
      @include border-radius(4px);
      padding: 20px;
      box-sizing: border-box;
      height: 100%;
      //keep footer v-align at bottom
      display: flex;
      flex-direction: column;
      &--trivial {
        background-color: transparent;
      }
    }

    &__title {
      min-height: 2.2em;
      padding: 5px 0 15px;
      @include font-bold;
      font-size: 20px;
      line-height: 1.125;
      border-bottom: 2px solid $nn_medium_orange;
      //vertical align header text to the bottom
      display: flex;
      align-items: flex-end;
      overflow: hidden;
    }

    &__total {
      font-size: 20px;
      line-height: 20px;
      @include font-bold;
      color: $nn_medium_orange;
      white-space: nowrap;
    }

    &__label {
      margin-right: 5px;
    }

    &__value {
      white-space: nowrap;
    }

    &.is-disabled {
      // Disabled option styles
      .c-comparison-table__option__title,
      .c-comparison-table__option__total,
      .c-comparison-table__option__label,
      .c-comparison-table__option__value,
      .c-comparison-table__option__collapse__title,
      .c-comparison-table__option__collapse__value,
      .c-comparison-table__option__collapse__target {
        color: $nn_medium_grey;
      }

      .c-comparison-table__option__title {
        border-color: $nn_medium_grey;
      }
    }

    &__footer {
      //make sure footer is always on bottom of the box
      margin-top: auto;
    }

    &__collapse {
      padding: 6px 0;
      color: $nn_extra_dark_grey;

      &:hover {
        cursor: pointer;
        color: $nn_medium_orange;
      }

      &__title {
        margin-right: 5px;

        &:before {
          font-family: 'nn-iconfont';
          content: $fonticon-content--plus;
          font-size: 16px;
          margin-right: 12px;
          .collapse & {
            content: $fonticon-content--minus;
          }
        }
      }

      &__value {
        white-space: nowrap;
      }
    }
  }

  &__footer {
    background-color: $white;
    @include border-radius(4px);
    padding: 15px 20px;
    margin: 10px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    text-align: center;
    width: calc(100% - 20px);
  }
}

.is-responsive {
  @media (max-width: $size_l) {
    .c-comparison-table__option {
      flex: 0 0 100%;
      max-width: 100%;
      //android 4.4 fix
      width: 100%;
    }
  }
}

//TODO new collapser icon setup
._c-comparison-table__option__collapse {
  .collapser-icon:after,
  .collapser-icon:before {
    background: $nn_extra_dark_grey;
  }
  .is-disabled & {
    .collapser-icon:after,
    .collapser-icon:before {
      background: $nn_medium_grey;
    }
  }

  &:hover {
    .collapser-icon:after,
    .collapser-icon:before {
      background: $nn_medium_orange;
    }
  }
}
