@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../../_helpers/sizes';

.share-socials-component-container {
    border-top: solid 1px $nn_sand_200;
    padding: 32px 0;

    .share-options-container {
        display: flex;
        flex-wrap: wrap;

        .share--item {
            width: calc(33.33% - 10px); /* Adjust the width as needed */
            padding: 12px 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            &:hover{
                cursor: pointer;

                .share-item--title {
                    text-decoration: underline;
                }

                .hover--linkedin {
                    color: #0077B5;
                }

                .hover--facebook {
                    color: #1877F2;
                }

                .hover--x {
                    color: #000000;
                }

                .hover--whatsapp {
                    color: #25D366;
                }

                .hover--copy {
                    color: $nn_medium_orange_600;
                }

                .hover--mail {
                    color: $nn_medium_orange_600;
                }
            }

            @media screen and (max-width: $mobile_break_point) {
                width: calc(50% - 10px);
 
            }
            
            .c-icon-size-social-share {
                font-size: 18px;
                margin-right: 16px;
                color: $nn_grey_800;
                text-decoration: none;
            }

        }
    }

}