@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';

/* COMPONENT: Carrousel / Slideshow
=============================================================================*/
.slideshow-component {
  @include border-radius-sep(0, 5px, 5px, 0);

  margin-left: -30px;
  margin-bottom: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 0;
  width: auto;
  height: 300px;
  overflow: hidden;
}

/* PART: Slide
=============================================================================*/
.slide {
  @include border-radius-sep(0, 5px, 5px, 0);

  position: absolute;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-position: left top;
  width: 100%;
  height: 300px;
  top: 0;
  left: 0;
}

/* PART: Slidecontent
=============================================================================*/
.slidecontent {
  @include border-radius-sep(0, 5px, 5px, 0);

  margin: 0;
  padding: 15px 20px 18px 20px;
  position: absolute;
  left: 0;
  bottom: 50px;
  background: #fff;
  width: 370px;

  .footnote {
    margin-top: -8px;
    margin-bottom: 0;
  }
}

/* PART: navigation
=============================================================================*/
.bgnav {
  @include border-radius(5px);

  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 998;
  height: 20px;
  background-color: #fff;
  opacity: 0.8;
  margin: 0 10px 0 0;
  width: 700px;
}

.nav {
  position: absolute;
  z-index: 999;
  left: 10px;
  bottom: 10px;
  list-style: none outside none;
  padding: 0;
  margin: 0 10px 0 0;
  width: 100%;
}

.nav li {
  list-style: none;
  display: inline;
  float: left;
  height: 20px;
  padding: 0;
  margin: 0;
  background: none;
  position: relative;
  overflow: hidden;
  &:before {
    display: none;
  }
}

.nav li a {
  float: left;
  border-left: solid 1px #c0c1c2;
  @include font-size(12);
  text-align: center;
  text-decoration: none;
  width: 99%;
  height: 20px;
}

.nav li:first-child a {
  border-left: 0;
}

.nav li.cycle-pager-active a {
  color: $nn_medium_orange;
}

/* CONTEXT: tablet
=============================================================================*/
@media (max-width: $desktop_break_point) {
  .is-responsive {
    .bgnav,
    .nav {
      width: 96%;
    }
  }
}

/* CONTEXT: tablet
=============================================================================*/
@media (max-width: $tablet_break_point) {
  .is-responsive {
    .slide img {
      width: $tablet_break_point;
    }
  }
}

@media (max-width: 720px) {
  .is-responsive {
    .slide img {
      width: auto;
    }
  }
}

/* CONTEXT: mobile
=============================================================================*/
@media (max-width: $mobile_break_point) {
  .is-responsive {
    .slideshow-component {
      @include border-radius(0px);
      margin-left: -15px;
      margin-right: -15px;
      height: auto;
      overflow: visible;
    }

    .slide {
      @include border-radius(0px);
      position: static;
      height: auto;

      img {
        max-width: 100%;
      }
    }

    .slide-title {
      display: none;
    }

    .slidecontent {
      @include border-radius(0px);
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box; /* Opera/IE 8+ */

      position: static;
      width: 100%;
    }

    .bgnav,
    .nav {
      display: none;
    }
  }
}

/* THEME: slideshow--banner
=============================================================================*/
.slideshow-component {
  &.slideshow--option-pagerbottom {
    overflow: visible;
    margin-bottom: 40px !important;
    .nav,
    .bgnav {
      bottom: -30px;
    }
  }
  &.slideshow--banner,
  &.slideshow--size-400 {
    @include border-radius(0px);
    width: 210px;
    height: 300px;
    margin: 0;

    .slidecontent {
      position: static;
      width: auto;
      padding: 0;

      img {
        @include border-radius(3px);
        margin-bottom: 15px;
      }
    }
  }
  &.slideshow--size-400 {
    width: 400px;
    height: 400px;
    .slide {
      height: 400px;
    }

    @media (max-width: $mobile_break_point) {
      width: 100%;
      height: auto;
      .slide {
        height: auto;
      }
      .nav {
        display: block;
      }
    }
    .slidecontent {
      img {
        width: 400px;
        height: 400px;
        @media (max-width: $mobile_break_point) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
