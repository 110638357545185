@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../../../../nn-psdl-modules/src/icon/c-icon.variables';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: Collapser
=============================================================================*/

.expand {
  position: relative;
  padding-left: 32px;
  text-decoration: none;

  &:before {
    @include color($collapsibleSectionTrigger_elements_Icon_styles_color);
    content: $fonticon-content--arrow-bottom;
    font-family: 'nn-iconfont';
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.25rem;
    line-height: 1.5rem;

    .opt-out-nitti-grotesk-font & {
      top: -3px;
      @include font-size(20, 20);
    }
  }

  .open,
  .close {
    @include color($listItem_elements_Text_styles_color);
    @include font-medium;

    &:hover {
      @include color($listItem_elements_Text_styles_color);
      text-decoration: underline;
      text-underline-offset: 4px;
      @include text-decoration-color($link_elements_Text_variants_Primary_interactionStates_Hover_styles_textDecorationColor);
      text-decoration-thickness: 2px;
    }
  }

  .close {
    display: none;
  }

  /*collapser trigger with a 'more' styling*/
  &.more {
    padding-right: 30px;
    padding-left: 0;

    &:before {
      display: none;
    }

    &:after {
      display: inline-block;
      background-color: $nn_medium_orange;
      width: 14px;
      height: 14px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      content: '';
      text-align: center;
      color: #fff;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: -2px;
      line-height: 12px;
      background: url('../control/icons/img/arrow-small-down.svg') no-repeat;
      background-position: 0 0;
    }

    &.collapse {
      &:after {
        background: url('../control/icons/img/arrow-small-up.svg') no-repeat;
      }
    }
  }
}

.expand.collapse {
  &:before {
    content: $fonticon-content--arrow-top;
  }

  .close {
    display: inherit;
  }

  .open {
    display: none;
  }
}

.collapsible-block {
  margin: 0 0 15px 0;

  .more {
    display: inline-block;
    margin: 10px 0 0 0;
  }
}

/* CONTEXT: grid--field-row
=============================================================================*/
.grid--field-row.collapsible-block {
  position: relative;
  padding: 0 0 0 0;
  margin: 0;
}

/* THEME: Expand link button
=============================================================================*/
.expand--no-label {
  width: 12px;
  padding-left: 12px;
  overflow: hidden;

  .close,
  .open {
    display: none;
  }
}

.expand--no-label.collapse {
  .close,
  .open {
    display: none;
  }
}

/* CONTEXT: Expand link button
=============================================================================*/
.grid--field-row.collapsible-block .expand--no-label {
  position: absolute;
  width: 12px;
  top: 5px;
  right: 8px;
  height: 15px;
  z-index: 2;
  padding: 0;
}

/* CONTEXT: collapser icon
=============================================================================*/
.collapser-icon {
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  
  &:before {
    content: $fonticon-content--arrow-bottom;
    font-family: 'nn-iconfont';
    position: absolute;
    @include color($collapsibleSectionTrigger_elements_Icon_styles_color);
  }
}

.collapse {
  .collapser-icon {
    &:before {
      content: $fonticon-content--arrow-top;
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .collapser-icon {
    }
  }
}
