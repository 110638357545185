.c-search {
  position: relative;

  &__input {
    padding-right: 30px;
  }

  &__submit,
  &__reset {
    background: transparent;
    color: $nn_medium_orange;
    font-size: 20px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 !important;

    &:hover {
      background: transparent;
      color: $nn_dark_orange;
    }

    &:before {
      content: $fonticon-content--icon-search;
    }
  }

  &__submit-icon {
    &:before {
      content: $fonticon-content--icon-search;
    }
  }

  &__reset-icon {
    &:before {
      content: $fonticon-content--cross-thin;
    }
  }
}
