@import 'colors';
@import 'breakpoints';
@import 'sass-functions';

.max-width-page {
	max-width:940px;
	margin:0 auto;
}
.nopadding {
	padding:0 !important;
}
.nomargin {
	margin:0 !important;
}
.noborder {
	border:0 !important;
}

.pull-left {
	float: left !important;
}

.pull-right {
	float: right  !important;
}

@media (max-width: $tablet_break_point) {
	.m-pull-right {
		float:right;
	}
}

.absolute-right {
	position: absolute;
	right:0;
	top:0;
}

.align-right {
	text-align: right !important;
}

@media (max-width: $tablet_break_point) {
	.m-margin-top-0 {
		margin-top:0;
	}
	.m-margin-bottom-0 {
		margin-bottom:0;
	}
}

@media (min-width: $tablet_break_point) {
	.t-align-right {
		text-align: right !important;
	}
	.t-align-left {
		text-align: left !important;
	}
	.t-noborder {
		border:0 !important;
	}
	.t-margin-top-10 {
		margin-top:10px;
	}
	.t-margin-bottom-10 {
		margin-bottom:10px;
	}
}


.align-left {
	text-align: left !important;
}


.img-left {
	float:left;
	margin:0 1.5em 1em 0;
}
.img-right {
	float:right;
	margin:0 0 1em 1.5em;
}
.img-left,
.img-right {
	width:100%;
	@media (min-width: $tablet_break_point){
		width:auto;
	}
}

.inlineblock {
	display: inline-block !important;
}
.inline {
	display: inline !important;
}
.p--inline {
	font-size: 1em;
	display: inline;
}
@media (min-width: $tablet_break_point){
	.t-displayblock {
		display:block;
	}
}

.no-border {
	border: 0 !important;
}

.no-discount {
	color: $nn_light_grey;
	font-weight: normal;
	text-decoration: line-through;
}

.bold {
	font-weight: bold;
}

.absolute-top-right {
	position: absolute;
	top: 0;
	right: 0;
}

.relative {
	position: relative;
}

body.noscroll {

    overflow: hidden;
    width: 100%;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.bg--transparent {
	background:transparent;
	&:hover {
		background:transparent;
	}
}

// Text decoration help classes

.line-through {
	text-decoration: line-through;
}
.no-underline {
	text-decoration: none;
}

.no-decoration {
	text-decoration: none;
}

// Color help classes

.color--mid-gray {
	color: $nn_dark_grey;
}

.font_middle_gray {
	color: $nn_dark_grey !important;
}
.color--orange {
	color:$nn_medium_orange;
}

.color--medium-orange {
	color: $nn_medium_orange;
}

.color--dark-grey {
	color: $nn_dark_grey;
}

.color--medium-grey {
	color: $nn_medium_grey;
}

.color--extra-dark-grey {
	color: $nn_extra_dark_grey;
}

.color--red {
	color:$nn_medium_red;
}

// Background  color helper classeslight grey
.bg_light_light_gray,
.bg-light-light-gray {
	background-color: $nn_extra_light_grey;
}

.bg-ultra-light-gray,
.bg-extreme-light-gray {
	background-color: $nn_ultra_light_grey;
}

.alternategreybg > :nth-child(odd) {
	background-color: $nn_ultra_light_grey;
}

// Font size helper classes
.font-size_xs {
	font-size: 75% !important;
}

.font-size_s {
	font-size: 90% !important;
}

.font-size_m {
	font-size: 100% !important;
}
.font-size_l {
	font-size: 120%;
}

.font-size_xl {
	font-size: 160%;
}

.font-size_xxl {
	font-size: 200%;
}

.font-size_xxxl {
	font-size: 250% !important;
	line-height: 120%;
}

@media (max-width: $tablet_break_point) {
	.is-responsive {
		.font-size_xxxl {
			font-size: 180% !important;
		}
	}
}

.clearfix {
	@include clearfix();
}

.clearboth {
	clear:both;
}

.box-sizing-border-box {
	@include box-sizing-border-box();
}

.truncate {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin ellipsis {
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// PART: borders

.border--top-orange {
	border-top: 2px solid $nn_medium_orange !important;
}


// PART: widths

.width-5 {
	&.colspan-2 {
		width: 10%;
	}
	&.colspan-3 {
		width: 15%;
	}
}
.width-10 {
	&.colspan-2 {
		width: 20%;
	}
	&.colspan-3 {
		width: 30%;
	}
}
.width-15 {
	&.colspan-2 {
		width: 30%;
	}
	&.colspan-3 {
		width: 45%;
	}
}
.width-20 {
	&.colspan-2 {
		width: 40%;
	}
	&.colspan-3 {
		width: 60%;
	}
}
.width-25 {
	&.colspan-2 {
		width: 50%;
	}
	&.colspan-3 {
		width: 75%;
	}
}
.width-30 {
	&.colspan-2 {
		width: 60%;
	}
	&.colspan-3 {
		width: 90%;
	}
}
.width-35 {
	&.colspan-2 {
		width: 70%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-40 {
	&.colspan-2 {
		width: 80%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-45 {
	&.colspan-2 {
		width: 90%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-50 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-55 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-60 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-65 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-70 {
	&.colspan-2 {
		width: 100%;
	}
}
.width-75 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-80 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-85 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-90 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-95 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}
}
.width-100 {
	&.colspan-2 {
		width: 100%;
	}
	&.colspan-3 {
		width: 100%;
	}

}

// PART: Exact percentage widths
// FIXME: aaahh, depricate asap
@for $i from 1 through 100 {
	.width-#{$i} {
		width: $i * 1%;
	}
}

.width-100pc {
	width: 100%;
}

// PART: padding

.no-padding {
    padding: 0 !important;
}

.nomargin {
	margin:0;
}

// PART: padding bottom

.padding-bottom-5 {
	padding-bottom:5px !important;
}

// PART: padding left

.padding-left-5px {
	padding-left: 5px;
}

.padding-left-10px {
	padding-left: 5px;
}
.padding-left-20px {
	padding-left: 20px;
}

.padding-left-10p {
	padding-left: 10px;
}

.padding-left-5 {
	padding-left: 5%;
}
.padding-left-10 {
	padding-left: 10%;
}
.padding-left-15 {
	padding-left: 15%;
}
.padding-left-20 {
	padding-left: 20%;
}
.padding-left-25 {
	padding-left: 25%;
}
.padding-left-30 {
	padding-left: 30%;
}
.padding-left-35 {
	padding-left: 35%;
}
.padding-left-40 {
	padding-left: 40%;
}
.padding-left-45 {
	padding-left: 45%;
}
.padding-left-50 {
	padding-left: 50%;
}
.padding-left-55 {
	padding-left: 55%;
}
.padding-left-60 {
	padding-left: 60%;
}
.padding-left-65 {
	padding-left: 65%;
}
.padding-left-70 {
	padding-left: 70%;
}
.padding-left-75 {
	padding-left: 75%;
}
.padding-left-80 {
	padding-left: 80%;
}
.padding-left-85 {
	padding-left: 85%;
}
.padding-left-90 {
	padding-left: 90%;
}
.padding-left-95 {
	padding-left: 95%;
}
.padding-left-100 {
	padding-left: 100%;
}

// PART: padding right

.padding-right-5 {
	padding-right: 5%;
}
.padding-right-10 {
	padding-right: 10%;
}
.padding-right-15 {
	padding-right: 15%;
}
.padding-right-20 {
	padding-right: 20%;
}
.padding-right-25 {
	padding-right: 25%;
}
.padding-right-30 {
	padding-right: 30%;
}
.padding-right-35 {
	padding-right: 35%;
}
.padding-right-40 {
	padding-right: 40%;
}
.padding-right-45 {
	padding-right: 45%;
}
.padding-right-50 {
	padding-right: 50%;
}
.padding-right-55 {
	padding-right: 55%;
}
.padding-right-60 {
	padding-right: 60%;
}
.padding-right-65 {
	padding-right: 65%;
}
.padding-right-70 {
	padding-right: 70%;
}
.padding-right-75 {
	padding-right: 75%;
}
.padding-right-80 {
	padding-right: 80%;
}
.padding-right-85 {
	padding-right: 85%;
}
.padding-right-90 {
	padding-right: 90%;
}
.padding-right-95 {
	padding-right: 95%;
}
.padding-right-100 {
	padding-right: 100%;
}

// PART: padding top

.padding-top-5 {
	padding-top: 5px;
}
.padding-top-10 {
	padding-top: 10px;
}
.padding-top-15 {
	padding-top: 15px;
}
.padding-top-20 {
	padding-top: 20px;
}

// PART: margin

.nomargin {
	margin:0 !important;
}

// PART: padding bottom

.padding-bottom-0 {
	padding-bottom: 0 !important;
}

.padding-bottom-10 {
	padding-bottom: 10px;
}

// PART: margin top

.margin-top-0 {
	margin-top:0;
}

.margin-top-3 {
	margin-top: 3px;
}
.margin-top-5 {
	margin-top: 5px;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-15 {
	margin-top: 15px;
}
.margin-top-20 {
	margin-top: 20px;
}
.margin-top-30 {
	margin-top: 30px;
}
.margin-top-50 {
	margin-top: 50px;
}
.margin-top--50 {
	margin-top:-50px;
}

// PART: margin left

.margin-left-5 {
	margin-left: 5px;
}
.margin-left-10 {
	margin-left: 10px;
}
.margin-left-15 {
	margin-left: 15px;
}
.margin-left-20 {
	margin-left: 20px;
}

// PART: margin right

.margin-right-5 {
	margin-right:5px;
}

.margin-right-10 {
	margin-right: 10px;
}
.margin-right-20 {
	margin-right: 20px;
}

// PART: margin bottom

.margin-bottom-0 {
	margin-bottom:0;
}
.margin-bottom-5 {
	margin-bottom: 5px;
}
.margin-bottom-10 {
	margin-bottom: 10px;
}
.margin-bottom-15 {
	margin-bottom: 15px;
}
.margin-bottom-20 {
	margin-bottom: 20px;
}
.margin-bottom-25 {
	margin-bottom: 25px;
}
.margin-bottom-30 {
	margin-bottom: 30px;
}
.margin-bottom-40 {
	margin-bottom: 40px;
}
.margin-bottom-60 {
	margin-bottom: 60px;
}

//@media (max-width: $desktop_break_point) {
// These classes are only used for the third grid-unit in grid block delta
// no need for media query cause it won't fit in 3 cols anymore
    .till-d-margin-top-5 {
        margin-top:10px;
    }
    .till-d-margin-bottom-15 {
        margin-bottom:10px;
    }
//}

// PART: responsive cells

@media (max-width: $mobile_break_point) {

	.is-responsive {

		.resp-align-left {
			text-align:left !important;
		}

		.font-size_xl {
			font-size: 150% !important;
		}

		.resp-width-5 {
			width: 5%;
		}
		.resp-width-10 {
			width: 10%;
		}
		.resp-width-15 {
			width: 15%;
		}
		.resp-width-20 {
			width: 20%;
		}
		.resp-width-25 {
			width: 25%;
		}
		.resp-width-30 {
			width: 30%;
		}
		.resp-width-35 {
			width: 35%;
		}
		.resp-width-40 {
			width: 40%;
		}
		.resp-width-45 {
			width: 45%;
		}
		.resp-width-50 {
			width: 50%;
		}
		.resp-width-55 {
			width: 55%;
		}
		.resp-width-60 {
			width: 60%;
		}
		.resp-width-65 {
			width: 65%;
		}
		.resp-width-70 {
			width: 70%;
		}
		.resp-width-75 {
			width: 75%;
		}
		.resp-width-80 {
			width: 80%;
		}
		.resp-width-85 {
			width: 85%;
		}
		.resp-width-90 {
			width: 90%;
		}
		.resp-width-95 {
			width: 95%;
		}
		.resp-width-100 {
			width: 100%;
		}


		.resp-padding-left-0 {
			padding-left: 0;
		}
		.resp-padding-left-5 {
			padding-left: 5%;
		}
		.resp-padding-left-10 {
			padding-left: 10%;
		}
		.resp-padding-left-15 {
			padding-left: 15%;
		}
		.resp-padding-left-20 {
			padding-left: 20%;
		}
		.resp-padding-left-25 {
			padding-left: 25%;
		}
		.resp-padding-left-30 {
			padding-left: 30%;
		}
		.resp-padding-left-35 {
			padding-left: 35%;
		}
		.resp-padding-left-40 {
			padding-left: 40%;
		}
		.resp-padding-left-45 {
			padding-left: 45%;
		}
		.resp-padding-left-50 {
			padding-left: 50%;
		}
		.resp-padding-left-55 {
			padding-left: 55%;
		}
		.resp-padding-left-60 {
			padding-left: 60%;
		}
		.resp-padding-left-65 {
			padding-left: 65%;
		}
		.resp-padding-left-70 {
			padding-left: 70%;
		}
		.resp-padding-left-75 {
			padding-left: 75%;
		}
		.resp-padding-left-80 {
			padding-left: 80%;
		}
		.resp-padding-left-85 {
			padding-left: 85%;
		}
		.resp-padding-left-90 {
			padding-left: 90%;
		}
		.resp-padding-left-95 {
			padding-left: 95%;
		}
		.resp-padding-left-100 {
			padding-left: 100%;
		}

		.resp-padding-right-5 {
			padding-right: 5%;
		}
		.resp-padding-right-10 {
			padding-right: 10%;
		}
		.resp-padding-right-15 {
			padding-right: 15%;
		}
		.resp-padding-right-20 {
			padding-right: 20%;
		}
		.resp-padding-right-25 {
			padding-right: 25%;
		}
		.resp-padding-right-30 {
			padding-right: 30%;
		}
		.resp-padding-right-35 {
			padding-right: 35%;
		}
		.resp-padding-right-40 {
			padding-right: 40%;
		}
		.resp-padding-right-45 {
			padding-right: 45%;
		}
		.resp-padding-right-50 {
			padding-right: 50%;
		}
		.resp-padding-right-55 {
			padding-right: 55%;
		}
		.resp-padding-right-60 {
			padding-right: 60%;
		}
		.resp-padding-right-65 {
			padding-right: 65%;
		}
		.resp-padding-right-70 {
			padding-right: 70%;
		}
		.resp-padding-right-75 {
			padding-right: 75%;
		}
		.resp-padding-right-80 {
			padding-right: 80%;
		}
		.resp-padding-right-85 {
			padding-right: 85%;
		}
		.resp-padding-right-90 {
			padding-right: 90%;
		}
		.resp-padding-right-95 {
			padding-right: 95%;
		}
		.resp-padding-right-100 {
			padding-right: 100%;
		}

		/* IN PX */


		.resp-padding-right-10px {
			padding-right: 10px;
		}
		.resp-padding-right-25px {
			padding-right: 25px;
		}
		.resp-padding-right-30px {
			padding-right: 30px;
		}

		.resp-padding-left-10px {
			padding-left: 10px;
		}

		.resp-margin-right-10px {
			margin-right: 10px;
		}
		.resp-margin-right-25px {
			margin-right: 25px;
		}

	}

}

@media only screen and (min-width: $mobile_break_point) and (max-width: $tabmob_break_point)  {
	.is-responsive {
		.font-size_xl {
			font-size: 180% !important;
		}
	}
}


// PART: Enhancer classes

.relative {
	position: relative;
}

.add-astrix {
  position: relative;
}

.add-astrix:after {
  content: '*';
  position: absolute;
  top: 4px;
}

.nowrap {
	white-space: nowrap;
}

.pre {
	white-space: pre;
}

.inline-block {
	display: inline-block;
}

@media (max-width: $mobile_break_point) {

	.is-responsive {

		.minus,
		.plus {
			font-weight: bold;
		}
		.minus:before {
			content: '- ';
			display: inline;
		}
		.plus:before {
			content: '+ ';
			display: inline;
		}

	}

}
