// Left for legacy
@import '../../../global/css/colors';

/* COMPONENT: Checkbox
=============================================================================*/
.checkbox--custom {
  overflow: hidden;
  position: absolute;
  left: -3px;
  top: 2px;
  width: 20px;
  height: 20px;
  background: url('../img/sprites/checkbox.svg') no-repeat 0 0;

  input {
    cursor: pointer;
    opacity: 0;
    width: 20px;
    height: 17px;
    outline: 0;
  }
}

.checkbox--custom.checked {
  background-position: 0 -200px;
}

.checkbox--custom.disabled {
  background-position: 0 -500px;
}

.checkbox--custom.disabled.checked {
  background-position: 0 -400px;
}

.checkbox--custom.disabled + label {
  color: $nn_medium_grey;
}

/* CONTEXT: Poll
=============================================================================*/
.poll-options {
  .checkbox--custom {
    margin-left: -3px;
    width: 24px;
    height: 24px;
  }
}

.field--checkbox {
  margin-top: 10px;

  .c-form-checkbox + label {
    margin-bottom: 8px;
  }
}
