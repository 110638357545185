$nn_ultra_light_grey: #f1edeb;
$white: #fff;

.u-shimmer {
  background-color: $nn_ultra_light_grey;
  background-image:  linear-gradient(90deg, transparent, $white, transparent);
  background-repeat: no-repeat;
  background-size: 800px 800px;
  position: relative;

  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: shimmering;
  -webkit-animation-name: shimmering;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;

  &__article {
    &--small {
      height: 15vh;
      max-height: 150px;
    }
    &--medium {
      height: 30vh;
      max-height: 300px
    }
    &--large {
      height: 60vh;
      max-height: 400px;
    }
  }

  &__aside {
    &--small {
      height: 15vh;
    }
    &--medium {
      height: 30vh;
    }
    &--large {
      height: 60vh;
    }
  }

  &__circle {
    border-radius: 50%;

    &--small {
      width: 33px;
      height: 33px;
    }
    &--medium {
      width: 66px;
      height: 66px;
    }
    &--large {
      width: 99px;
      height: 99px;
    }
  }

  &__button {
    width: 85px;
    height: 36px;
    border-radius: 4px;
  }

  &__text {
    &--small {
      height: 24px;
      width: 7rem;
    }
    &--medium {
      height: 38px;
      width: 17.5rem;
    }
    &--large {
      height: 52px;
      width: 25rem;
    }
  }
}

@keyframes shimmering {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}
