/*
	Component: IBAN converter
*/

#iban_copy_target {

	.more {
		margin-top: 5px;
		display: inline-block;
	}
}