.c-header-dropdown {
    position: absolute;
    z-index: 500;
    left: 0;
    right: 0;
    display: block;
    transition: all 250ms;
    padding: 0;
    height: auto;
    max-width: $desktop-max;
    margin: -1px auto 0 auto;
    background-color: $nn_ultra_light_grey;
    border-top: 1px solid $nn_extra_light_grey;

    font-size: 16px; // FIXME: STATIC sets basic font to 12px, so remove after STATIC is removed
	font-size: 1rem; // FIXME: STATIC sets basic font to 12px, so remove after STATIC is removed

    &--user.c-header-dropdown--open {
        padding-top: 48px;
    }

    &--open {
      height: auto;
      padding: 12px 0;
    }


    &__row {
        @extend .u-clear;
    	box-sizing: border-box;
        max-width: $desktop-min;

        .is-wide & {
            max-width: $desktop-max;
        }

        margin: 0 auto;
        padding-bottom: 24px;

        &:last-child,
        &--closed {
            padding-bottom: 0
        }

        &--indent {
            padding-right: 30px;
            padding-left: 30px;
        }
    }


    &__col {
        box-sizing: border-box;
        float: left;
        padding-left: 12px;

        &:first-child { padding-left: 0; }

        &--6-12 {
            width: 50%;
        }

        &--4-12 {
            width: 33.333%;
        }

    }

}


@media (max-width: 820px) {

    .c-header-dropdown {

        &__row {
            &--indent {
                padding-right: 12px;
                padding-left: 12px;
            }
        }

    }

    .is-responsive .c-header-dropdown {

        &__col {
            // TODO: find size for tablet
            &\@size-m {
                width: 100%;
                float: none;
                padding-bottom: 12px;
                padding-left: 0;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

    }


}



@media print {

	.c-header-dropdown {
        display: none !important;
	}

}
