@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../../_helpers/component-design-token-maps';

.c-form-repeater {
	@include background-color($card_variants_Default_styles_backgroundColor);
	border-width: 1px;
	border-style: solid;
	@include border-color($card_variants_Default_styles_borderColor);
  border-radius: 4px;

	&__icon {
		display: inline;
		font-size: 23px;
		padding: 4px;
		margin-top: -5px;
		margin-bottom: -5px;
		background-color: transparent;
    color: inherit;
		position: relative;
		border: 1px dotted transparent;

		// TODO remove when ie11 support is gone
		.ie11 & {
			padding-top: 4px;
		}

		&:hover {
			background-color: transparent;
			color: $nn_medium_orange;
		}
		
		&:active,
		&:focus {
			border: 1px dotted $nn_extra_dark_grey;
			border-radius: 0;
		}
	}
}
