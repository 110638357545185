@import '../../../global/css/sass-functions';
@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* VAR: Text indent
=============================================================================*/
$select-text-indent: 0.01px;
$string-empty: '';

/* COMPONENT: Select
=============================================================================*/
.select {
  display: inline-block;
  padding: 0;
  position: relative;
  @include background-color($select_elements_Box_styles_backgroundColor);
  @include box-sizing-border-box();
  height: 48px;
}

/* STATE: No pointer events
=============================================================================*/
.select--nopointerevents {
  border-width: 1px;
  border-style: solid;
  @include border-color($select_elements_Box_styles_borderColor);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  @include box-sizing-border-box();

  overflow: hidden;
  vertical-align: top;
}

/* PART: The actual select box
=============================================================================*/
.select > select {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  outline: 0;

  border-style: solid;
  border-width: 1px;
  border-color: $nn_light_grey;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: $select-text-indent;
  /*text-overflow: $string-empty;*/

  position: relative;
  background: transparent;

  @include font-size(16);

  margin: 0;
  padding: 7px 52px 7px 16px;
  max-width: 290px;
  height: 48px;
}

@media (min-width: $mobile_break_point) {
  .select > select {
    max-width: 330px;
  }
}

@media (min-width: $tablet_break_point) {
  select {
    width: 330px;
  }
}

/* PART: No pointer events mode
=============================================================================*/
.select--nopointerevents > select {
  position: relative;
  z-index: 10;
  visibility: visible;
  opacity: 0;
}
.select--nopointerevents > select[disabled] {
  opacity: 0;
}

/* WORKAROUND: Text indent is needed in Firefox to push the native arrow out of sight
=============================================================================*/
@-moz-document url-prefix() {
  .select > select {
    text-indent: 1px;
  }
}

/* PART: Arrow block
=============================================================================*/
.select:after,
.select-arrow {
  content: ' ';
  position: absolute;
  display: inline-block;
  width: 24px;
  height: 24px;
  z-index: 2;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  @include background-color($select_elements_Box_styles_backgroundColor);

  @include font-size(16);

  background-image: url('../../../../nn-psdl-modules/src/form/c-form-select/img/c-form-select.svg');
  background-position: center;
  background-repeat: no-repeat;
}

/* STATE: Disabled state
=============================================================================*/
.select > select[disabled],
.select--disabled,
.select--disabled:after,
.select--disabled:before,
.select--disabled .select-arrow,
.select--disabled .select-textoverlay {
  background-color: #ebebe4;
}

.select--disabled:after,
.select--disabled .select-arrow {
  opacity: 0.3;
}

/* PART: Text overlay when pointer events are missing
=============================================================================*/
.select-textoverlay {
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  .data-table-cell & {
    font-size: 1rem;
  }
}

/* PART: Custom sizes (for older implementation)
=============================================================================*/
.size2-select-field select,
.size2-select-field .select--nopointerevents {
  width: 90px;
}
.size3-select-field select,
.size3-select-field .select--nopointerevents {
  width: 120px;
}
.size4-select-field select,
.size4-select-field .select--nopointerevents {
  width: 150px;
}
.size5-select-field select,
.size5-select-field .select--nopointerevents {
  width: 180px;
}
.size6-select-field select,
.size6-select-field .select--nopointerevents {
  width: 210px;
}
.size7-select-field select,
.size7-select-field .select--nopointerevents {
  width: 240px;
}
.size8-select-field select,
.size8-select-field .select--nopointerevents {
  width: 270px;
}
.size9-select-field select,
.size9-select-field .select--nopointerevents {
  width: 100%;
}
@media (min-width: $tablet_break_point) {
  .size9-select-field select,
  .size9-select-field .select--nopointerevents {
    width: 300px;
  }
}

.size-full-select-field select,
.size-full-select-field .select--nopointerevents {
  width: 100%;
}

.filterbox-three-col-field-group select,
.filterbox-three-col-field-group .select--nopointerevents {
  width: 210px;
}
.filterbox-one-col-field-group select,
.filterbox-one-col-field-group .select--nopointerevents {
  width: 180px;
}

/* WORKAROUND: Fixes for Firefox messing with <select> styling
=============================================================================*/
@-moz-document url-prefix() {
  .select:after,
  .select-arrow {
    top: 1px;
    bottom: 1px;
    right: 0;
    border-radius: 0 2px 2px 0;
  }

  .select--disabled:after,
  .select--disabled .select-arrow {
    opacity: 1;
  }
}
