.c-spreadsheet {
	&__container {
		max-width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		position: relative;

		// Firefox cuts off 6px off thebottom of the spreadsheet
		@-moz-document url-prefix() {
			& {
				padding-bottom: 6px;
			}
		}
	}
}

.handsontable {

	.htInvalid td {
		background-color: #fceeee;
	}

	td.htInvalid {
		background-color: #f2bdc3; /*gives priority over td.area selection background*/
		border-color: #952d24!important;
	}
}