@import '../_helpers/color';

.c-sticky-footer:not(.is-sticky) .c-sticky-footer__sticky-hide{
	display: none;
}

.c-sticky-footer__sticky-hide:focus {
	outline: none;
}

.c-sticky-footer__inner {
	background-color: $white;
	padding-top: 20px;
}

.c-sticky-footer__fontsize-dynamic { 
	font-size: calc( 2.125vw + 5px ); 
}

@media only screen and (max-width: $size_l) {
	.c-sticky-footer__fontsize-dynamic { 
		font-size: calc( 4.125vw + 10px ); 
	}
}

@media only screen and (min-width: 960px) {
	.c-sticky-footer__fontsize-dynamic { 
		font-size: calc( 1.125vw + 5px ); 
	}
}

// Sticky state
.c-sticky-footer.is-sticky{
	.c-sticky-footer__inner {
		box-sizing: border-box;
		box-shadow: 0px -3px 6px -1px $nn_light_grey;
		position: fixed;
		top: auto;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		width: 100%;
	}
}

html.is-responsive {
	.c-sticky-footer--padded.is-sticky .c-sticky-footer__inner{
		padding-left: 30px;
		padding-right: 30px;

		@media (max-width: $size-xs) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}