@import '../_helpers/mixins';

.c-review-digit {
  @include font-size(36);

  @media (min-width: $size-s) {
    @include font-size(92);
    margin-right: 30px;
  }
}
