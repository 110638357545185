@import '../_helpers/color';
@import '../_helpers/mixins';
@import '../_helpers/sizes';
@import '../_helpers/component-design-token-maps';

.c-sidepanel {
  $sidepanel-width-partial: 570px;
  $sidepanel-width-full: 100vw;

  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);

  &__mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }

  &__top {
    @include font-medium();
    @include font-size(16, 16 * 1.1);
    align-items: center;
    padding: 21px 16px 8px 16px;
    min-height: 64px;
    box-sizing: border-box;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @include border-color($divider_elements_Divider_styles_borderColor);

    .opt-out-nitti-grotesk-font & {
      padding: 17px 54px 17px 21px+12px;
    }
  }

  &__close_btn {
    position: absolute;
    top: 12px;
    right: 10px;
    padding: 6px;
    text-decoration: none;
    cursor: pointer;
    @include font-size(24);
    border-radius: 50%;
    @include color($iconButton_elements_Icon_styles_color);

		&:hover {
      @include color($iconButton_elements_Icon_styles_color);
			@include background-color($iconButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
		}
  }

  &__wrapper {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    transition: right 0.5s ease;
    right: -$sidepanel-width-full;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: $sidepanel-width-full;
    @include background-color($sidePanel_elements_ContentContainer_styles_backgroundColor);
    overflow: hidden;
    box-sizing: border-box;
  }

  &__content {
    flex: 1 0 0;
    overflow: auto;
    padding: 24px 16px;
  }

  &__is-open &__wrapper {
    overflow: auto;
    right: 0;
  }

  @media (min-width: $size-m) {
    &__wrapper {
      right: -$sidepanel-width-partial;
      width: $sidepanel-width-partial;
      padding-left: 1px;
      border-width: 1px;
      border-style: solid;
      @include border-color($card_variants_Default_styles_borderColor);
    }
  }
}