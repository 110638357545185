@import '../_helpers/color';
@import '../_helpers/mixins';
@import '../icon/c-icon.variables';
@import "../_helpers/component-design-token-maps";

@mixin c-link {
  display: inline-block;
  position: relative;
  padding: 0 0 0 1.5em;
  @include color($link_elements_Text_variants_Secondary_styles_color);
  text-decoration: none;
  @include font-medium;

  &:hover {
    @include color($link_elements_Text_variants_Secondary_styles_color);
    text-decoration: underline;
    text-underline-offset: 4px;
    @include text-decoration-color($link_elements_Text_variants_Secondary_interactionStates_Active_styles_textDecorationColor);
    text-decoration-thickness: 2px;
  }

  &:before {
    content: $fonticon-content--arrow-right;
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 0.8125rem;
    line-height: 1.5rem;
    font-family: 'nn-iconfont';
    @include color($link_elements_Icon_variants_Primary_styles_color);

    .opt-out-nitti-grotesk-font & {
      top: -2px;
      left: -8px;
    }
  }
}