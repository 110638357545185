@import "../_helpers/mixins";
@import "../_helpers/color";
@import '../_helpers/sizes';

.c-contact {
  border: 0;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 30px;
  padding: 0;
  position: relative;
  width: 100%;

  &__column,
  &__column--half {
    float: left;
    width: 100%;
    min-width: 170px;
  }
  @media (min-width: $phone) {
    &__column--half {
      width: 50%;
    }
  }
  &__contact-list {
    list-style: none;
  }
  &__item {
    padding: 0;
    background: none;
    margin-bottom: 0.5em;

    &:before {
      display: none;
    }

    a {
      text-decoration: none;
      display: inline-block;
      height: auto;
      min-height: 2em;
      vertical-align: middle;
      @include font-medium;
    }
  }
  @media (min-width: $phone) {
    &__column &__item {
      width: 50%;
      float: left;
      min-width: 170px;
    }
  }
  &__icon {
    display: inline-block;
    width: 31px;
    height: 31px;
    margin-right: 15px;
    vertical-align: middle;
    background-repeat: no-repeat;
    &:before {
      font-size: 2rem;
      color: $nn_medium_orange;
    }
    @extend .c-icon;
  }

  &__item--phone-number &__icon {
    @extend .c-icon--pict-call;
  }
  &__item--send-email &__icon {
    @extend .c-icon--pict-mail;
  }
  &__item--call-me-now &__icon {
    @extend .c-icon--pict-callnow;
  }
  &__item--download &__icon {
    @extend .c-icon--pict-download;
  }
  &__item--customer-service &__icon {
    @extend .c-icon--pict-website;
  }
  &__item--print &__icon {
    @extend .c-icon--pict-print;
  }
  &__item--reviews-ratings &__icon {
    @extend .c-icon--pict-star;
  }
  &__item--more-info &__icon {
    @extend .c-icon--pict-advisor;
  }
}



// Contact blocks MATTER style 2023
// ====================================================
.contact-blocks__component {

  .contact-blocks__wrapper {
    @include flexbox;
    flex-wrap: wrap;
    gap: 24px;

    .contact-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      min-width: 262px;
      max-width: 262px;
      box-sizing: border-box;
      padding: 24px;
      border: 1px solid $nn_sand_200;
      border-radius: 4px;

      @media (min-width: $size_s) and (max-width: $size_l) {
        flex: 2;
        min-width: calc(50vw - 24px);
        max-width: none;
      }

      @media (max-width: $size_s) {
        min-width: 100%;
      }

        .contact-block__name {
          @include flexbox;
          justify-content: space-between;
          align-items: baseline;

          .contact-block__name--image {
            color: $nn_medium_orange_600;
            
            .contact-card__icon {
              font-size: 2rem;
            }
          }
        }

      .contact-block__description {
        color: $nn_grey_800;
        margin-bottom: 16px;
      }

      .contact-block__link {
        text-decoration: none;
        
        .link-arrow--right {
          width: 21px;
          margin-left: 14px;
          transition: all 0.2s ease-out;
          transform: rotate(90deg);
          font-size: 1.125rem;
          color: $nn_medium_orange_600;
        }
        &:hover {
            .link-arrow--right {
              margin-left: 18px;
            }
        }
      }
    }
  }
}

