@import '../_helpers/color';
@import '../_helpers/sizes';
@import '../layout/o-layout-variables';
@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';

.c-card {
  flex: 1 0 100%;
  box-sizing: border-box;
  margin-bottom: $grid-gutter-width;

  border-width: 1px;
  border-style: solid;
  @include border-color($card_variants_Default_styles_borderColor);
  border-radius: 4px;
  @include background-color($card_variants_Default_styles_backgroundColor);

  &__img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: calc(100% + 2px);
    position: relative;
    top: -1px;
    left: -1px;

    &--border {
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  &__content {
    padding: 20px;
  }
}



