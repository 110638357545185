// Left for legacy

.field--radiobutton {
  &:first-child {
    margin: 10px 0 0;
  }

  &.radiobutton--inline {
    display: inline-block;
    margin: 10px 10px 0 0;
    position: relative;
  }

  .c-radio-button+label {
    margin-bottom: 8px;
  }
}

/* COMPONENT: Gender selection
=============================================================================*/
.field--gender-selection {
  .field--radiobutton {
    display: inline-block;
    margin-bottom: 0;
  }
}
