@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../../../../nn-psdl-modules/src/_helpers/_mixins.scss';

/* custom message styling
=============================================================================*/

.message--default {
  @include border-radius(4px);
  @extend .block--alert-image;
  margin: 0 0 15px;
  padding: 8px 12px 8px 42px;
  min-height: auto;
}

.message--info {
  @extend .message--default;
  border: 1px solid #a7ceec;
  background-position: -492px -46px;
}

.message--error {
  @extend .message--default;
  background-position: -418px -102px;
  border: 1px solid #ff9595;
}

.message--warning {
  @extend .message--default;
  background-position: -340px -158px;
  border: 1px solid #ffea95;
}

.message--success {
  @extend .message--default;
  background-position: -184px -270px;
  border: 1px solid #98da6b;
}

/* next best actions
=============================================================================*/

.message--nba {
  margin-bottom: 4px;
}

.message--nba-header {
  @include font-bold;
  background: none;
  cursor: pointer;
  padding-left: 0;
  position: relative;
  &:before {
    display: none;
  }
}

.message--nba-header:after {
  background: url('../global/img/sprite-icon-plus-minus.png') no-repeat;
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  right: 0;
  top: 6px;
}

.expanded .message--nba-header:after {
  background-position: right 0;
}

.message--nba-title {
  padding: 0;
  display: inline-block;
}

.message--nba-body,
.message--nba-button {
  @include box-sizing-border-box;
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  clear: both;
}

.message--nba-icon {
  background: url('./message/img/nba.png') 0 0 no-repeat;
  display: inline-block;
  height: 20px;
  margin-left: -25px;
  width: 20px;
  vertical-align: middle;
}

.message--nba-button {
  width: 39%;
  text-align: right;
  padding-right: 15px;
}

.message--nba-row {
  background: none;
  border-top: 1px solid $nn_light_grey;
  padding: 15px 0 15px 25px;
  &:before {
    display: none;
  }
}
.message--nba-count {
  font-family: verdana, geneva, tahoma, sans-serif;
  background-color: $nn_medium_orange;
  border-radius: 15px;
  color: #fff;
  position: absolute;
  margin-left: 5px;
  display: none;
  width: 20px;
  height: 20px;
  text-align: center;
  @include font-size(10);
  line-height: 1.5em;
  top: 2px;
}

.touch {
  .message--nba-header:after {
    background: url('../global/img/sprite-icon-mobile-plus-minus.png') no-repeat;
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    right: -5px;
    top: -2px;
  }
  .expanded .message--nba-header:after {
    background-position: right 0;
  }
}

@media (max-width: $tabmob_break_point) {
  .is-responsive {
    .message--nba-body {
      margin-bottom: 10px;
      width: 100%;
    }
    .message--nba-button {
      width: 100%;
      text-align: left;
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .message--nba-count {
      display: inline-block;
    }
    .message--nba-title {
      font-size: 1.2em;
      padding: 0;
    }
  }
}

/* COMPONENT: error message
=============================================================================*/
.error-message {
  @extend .block--alert-image;
  background-position: -431px -109px;
  background-repeat: no-repeat;
  color: $nn_medium_red;
  padding-left: 22px;
  margin-top: 6px;

  ul li {
    @include list-style-none;
    position: inherit;

    &::before {
      content: none;
    }
  }
}

/*	COMPONENT: error message
	CONTEXT:
=============================================================================*/
.grid--block-iota .error-message {
  clear: both;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 5px;
  background-position: -431px -104px;
}

.grid--block-masthead .error-message,
.grid--block-masthead-v2 .error-message {
  position: relative;
  left: -80%;
  width: 165%;
  margin-bottom: 0;
}

.grid--block-masthead-v2 .error-message {
  left: -165%;
  width: 340%;
}

@media (min-width: $mobile_break_point) and (max-width: $tabmob_break_point) {
  .is-responsive .grid--block-masthead-v2 .error-message {
    left: -157%;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-masthead .error-message,
    .grid--block-masthead-v2 .error-message {
      left: 0;
      width: auto;
    }

    .grid--block-masthead-v2 .error-message {
      min-width: 220px;
    }
  }
}

/* COMPONENT: warning message
=============================================================================*/

.warning-message {
  @extend .block--alert-image;
  background-position: -126px -332px;
  color: #f6a138;
  padding-left: 22px;
  margin-top: 6px;
}

/* COMPONENT: success message
=============================================================================*/

.success-message {
  @extend .block--alert-image;
  background-position: -199px -280px;
  color: #76ce3a;
  padding-left: 22px;
  margin-top: 6px;
}

.icon-ffwd {
  height: 25px;
  width: 25px;
}

.success-message-ffwd {
  @extend .block--alert-image;
  background-position: -199px -280px;
}

.loading-message-ffwd {
  background: url('./message/img/ffwd-loading.gif') no-repeat left top
    transparent;
  display: block;
  margin: 0;
  padding: 0;
}

.grid--block-masthead,
.grid--block-masthead-v2 {
  .icon-ffwd {
    top: 6px;
    position: absolute;
    right: -30px;
    padding: 0;
    margin: 0;
  }
}

.touch-fields .grid--block-masthead .icon-ffwd,
.touch-fields .grid--block-masthead-v2 .icon-ffwd {
  top: 11px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive.touch-fields {
    .grid--block-masthead,
    .grid--block-masthead-v2 {
      .icon-ffwd {
        top: 40px;
        right: -32px;
      }
    }
  }
}

.warning-message,
.success-message,
.error-message {
  &.span4 {
    width: 128px;
  }
}
