@import '../_helpers/color';

.c-premium-comparison{
	$cell_spacing: 10px;
	margin: 0 -$cell_spacing;

	table{
		table-layout: fixed;
		width: 100%;
		border-collapse: separate;
		border-spacing: $cell_spacing 0px;

		&.is-multi{
			@media (max-width: $size_m) {
				display: none !important;
			}
		}

		&.is-single{
			@media (min-width: $size_m) {
				display: none !important;
			}
		}
	}

	td,
	th{
		padding: $cell_spacing 0;
		font-weight: normal;
		text-align: center;

		&:first-child{
			width: auto;
		}
	}

	thead{
		th,
		td{
			vertical-align: bottom;
		}
	}

	tbody{
		th{
			text-align: left;
		}

		td{
			background-color: $nn_ultra_light_grey;
			border: 0px solid $nn_ultra_light_grey;
			border-left-width: 2px;
			border-right-width: 2px;

			&.is-selected{
				border-color: $nn_medium_orange;
			}
		}

		th,
		td{
			vertical-align: middle;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				left: -12px;
				right: -12px;
				border-bottom: 1px solid $nn_extra_light_grey;
			}

			&:first-child:after{
				left: -2px;
			}

			&:last-child:after{
				right: -2px;
			}

			&.is-selected:after{
				left: 0px;
				right: 0px;
			}
		}

		tr{
			&:first-child > td{
				border-top-width: 2px;
				padding-top: $cell_spacing - 2px;
			}

			&:last-child > td{
				border-bottom-width: 2px;
				padding-top: $cell_spacing - 2px;
			}

			&:last-child > th:after,
			&:last-child > td:after{
				display: none !important;
			}
		}
	}

	tfoot{
		th,
		td{
			padding-top: 14px;
			vertical-align: top;
		}

		td.is-selected .c-premium-comparison__button-unselected,
		td:not(.is-selected) .c-premium-comparison__button-selected,
		input{
			display: none !important;
		}
	}

}

.c-premium-comparison-fixed{	
	td,
	th{
		width: 85px;
	}
}
