.c-figure {
  position: relative;
  margin: 0;
}
.c-figure__caption {
  position: absolute;
  background-color: $nn_medium_orange;
  color: white;
  padding: 10px;
  &--topleft {
    top: 0;
    left: 0;
  }
  &--topright {
    top: 0;
    right: 0;
  }
  &--bottomleft {
    bottom: 0;
    left: 0;
  }
  &--bottomright {
    bottom: 0;
    right: 0;
  }
}