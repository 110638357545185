@import '../_helpers/sizes';
@import './c-dialog.variables';

.c-modal--context {
	position: fixed;
	overflow: visible;

	&__dialog {
		position: absolute;
		transition: transform $o-glass-transition-duration ease-out, left 0.1s ease-out, top 0.1s ease-out;
	}

	// Fix for blueriq dialog moving to edge of screen when positioned dialog is opened from a (relative) blueriq dialog.
	&.c-modal--blueriq {
		overflow: hidden;

		.c-modal__dialog {
			position: relative;
		}
	}
}

.c-modal {
	&--context &__dialog {
		@media (min-width: $size_s) {
			max-width: 500px;
		}
	}
	&--light.c-modal--context {
		background-color: transparent;
	}
}
