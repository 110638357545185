.c-list {
	list-style: none;
	margin-bottom: 2rem;

	&__item {
		@extend ._li-reset;
		margin: 16px 0;
		padding: 0;

		a.c-link {
			@include font-normal;
		}
	}

	&--inline &__item{
		display: inline-block;
		margin-right: 30px;
	}
}