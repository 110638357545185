/* TODO set up or add flexbox method after ie9 support drops */

@use "sass:math";

@import '../_helpers/sizes';
@import '../_helpers/mixins';
@import 'o-layout-variables';

.o-layout {
    &__row {
        @include u-clear;
        box-sizing: border-box;

        margin-left: -($grid-gutter-width*0.5);
        margin-right: -($grid-gutter-width*0.5);

        &--nogutter {
            @include u-clear;
            margin-left: 0;
            margin-right: 0;
            width:100%;

            [class*=o-layout__col-] {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    @for $i from 1 through $grid-no-columns {
        &__col-xl-#{$i} {
            width: percentage(math.div($i, $grid-no-columns));
        }
    }
}

    [class*=o-layout__col-] {
        position: relative;
        float: left;
        box-sizing: border-box;
        min-height: 1px;
        padding-left: ($grid-gutter-width*0.5);
        padding-right: ($grid-gutter-width*0.5);
    }

html.is-responsive {
    [class*=o-layout__col-] {
        width: 100%;
        padding-left: ($grid-gutter-width*0.5);
        padding-right: ($grid-gutter-width*0.5);
    }

	.o-layout {
		@for $i from 1 through $grid-no-columns {
			&__col-xxs-#{$i} {
				width: percentage(math.div($i, $grid-no-columns));
			}
		}
		&__row {
			margin-left: -($grid-gutter-width*0.5);
			margin-right: -($grid-gutter-width*0.5);
			width: auto;

			&--nogutter {
				margin-left: 0;
				margin-right: 0;
				width:100%;

				[class*=o-layout__col-] {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		@for $i from 1 through $grid-no-columns {
				&__col-xxs-#{$i} {
					width: percentage(math.div($i, $grid-no-columns));
				}
		}


		@for $i from 1 through $grid-no-columns {
			&__offset-xxs-#{$i} {
				margin-left: percentage(math.div($i, $grid-no-columns))
			}
		}

		/* add breakpoints if/when needed small to large */
		@media (min-width: $size-xxs) {
			@for $i from 1 through $grid-no-columns {
				&__col-xs-#{$i} {
					width: percentage(math.div($i, $grid-no-columns));
				}
			}

			@for $i from 1 through $grid-no-columns {
				&__offset-xs-#{$i} {
					margin-left: percentage(math.div($i, $grid-no-columns))
					/*width: percentage($i / $grid-no-columns);*/
				}
			}
		}
		 @media (min-width: $size-xs) {
			@for $i from 1 through $grid-no-columns {
				&__col-s-#{$i} {
					width: percentage(math.div($i, $grid-no-columns));
				}
			}

			 @for $i from 1 through $grid-no-columns {
				 &__offset-s-#{$i} {
					 margin-left: percentage(math.div($i, $grid-no-columns))
					 /*width: percentage($i / $grid-no-columns);*/
				 }
			 }
		}

		@media (min-width: $size-s) {
			@for $i from 1 through $grid-no-columns {
				&__col-m-#{$i} {
					width: percentage(math.div($i, $grid-no-columns));
				}
			}

			@for $i from 1 through $grid-no-columns {
				&__offset-m-#{$i} {
					margin-left: percentage(math.div($i, $grid-no-columns))
					/*width: percentage($i / $grid-no-columns);*/
				}
			}
		}


		@media (min-width: $size_m) {
			@for $i from 1 through $grid-no-columns {
				&__col-l-#{$i} {
					width: percentage(math.div($i, $grid-no-columns));
				}
			}

			@for $i from 1 through $grid-no-columns {
				&__offset-l-#{$i} {
					margin-left: percentage(math.div($i, $grid-no-columns))
					/*width: percentage($i / $grid-no-columns);*/
				}
			}
		}

		@media (min-width: $size_l) {
			@for $i from 1 through $grid-no-columns {
				&__col-xl-#{$i} {
					width: percentage(math.div($i, $grid-no-columns));
				}
			}

			@for $i from 1 through $grid-no-columns {
				&__offset-xl-#{$i} {
					margin-left: percentage(math.div($i, $grid-no-columns))
					/*width: percentage($i / $grid-no-columns);*/
				}
			}
		}
		@media (min-width: $size_xl) {
			@for $i from 1 through $grid-no-columns {
				&__col-xxl-#{$i} {
					width: percentage(math.div($i, $grid-no-columns));
				}
			}

			@for $i from 1 through $grid-no-columns {
				&__offset-xxl-#{$i} {
					margin-left: percentage(math.div($i, $grid-no-columns))
					/*width: percentage($i / $grid-no-columns);*/
				}
			}
		}
	}
}

// PRINT VERSION
@media print {
    .ut-grid {
        &__row {
            width: 100%!important;
        }
        &__indent {
            padding: 0!important;
        }
    }
}
