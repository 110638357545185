@import '../../../global/css/breakpoints';
@import '../../../global/css/colors';

/* COMPONENT: Select Field
=============================================================================*/
$select_field_height: 25px;
$select_field_height: 37px;

$select_field_height_touch: 37px;

.select-hidden {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.custom-select-box {
  cursor: pointer;
  vertical-align: top;
  display: inline-block;
  width: 330px;
  &.disabled {
    opacity: 0.6;
    filter: alpha(opacity=60);
    background-color: #fff !important;
  }

  .current-value,
  .new-current-value {
    background: #fff url('../img/select-box.png') no-repeat right 5px;

    overflow: hidden;
    padding: 0 39px 0 10px;

    border: 1px solid $nn_light_grey;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: $select_field_height;
    line-height: 36px;
  }
}

.focused-custom-select-box {
  .current-value,
  .new-current-value {
    border-color: #e97122;
    -webkit-box-shadow: rgba(0, 0, 0, 0.23) 0 2px 5px;
    -moz-box-shadow: rgba(0, 0, 0, 0.23) 0 2px 5px;
    box-shadow: rgba(0, 0, 0, 0.23) 0 2px 5px;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .custom-select-box {
      width: 100%;
      max-width: 100%;
    }

    .current-value {
      height: 37px;
      height: $select_field_height_touch;
      line-height: 37px;
      background-position: right 5px;
      box-sizing: border-box;
      white-space: inherit;
    }
  }
}

.custom-select-box-option-div {
  position: absolute;
  z-index: 20000;
  left: 0;
  top: 0;
  background: #fff;
  border: 1px solid $nn_medium_orange;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 2px 13px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 2px 13px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 13px;
  width: 318px;
}

.custom-select-box-option-div.scrolling {
  padding: 4px 0;
}

.custom-select-box-option-div.scrolling .custom-select-box-options {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.custom-select-box-option-div.scrolling .custom-select-box-options li {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

/* Options */

.custom-select-box-options {
  margin: 0;
}

.custom-select-box-options li {
  cursor: pointer;
  padding: 8px 10px 9px;
  line-height: 18px;
  background: #fff;
}
.custom-select-box-options li:before {
  display: none;
}

.custom-select-box-options li.disabled {
  color: #a5a5a5;
}

.custom-select-box-options li.first-child,
.custom-select-box-options li:first-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}

.custom-select-box-options li.last-child,
.custom-select-box-options li:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;

  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}

/* Option hover states */

.custom-select-box-options li.hover {
  background: #f9d0b6;
}

.custom-select-box.span1 {
  width: 60px;
}
.custom-select-box.span2 {
  width: 90px;
}
.custom-select-box.span3 {
  width: 120px;
}
.custom-select-box.span4 {
  width: 150px;
}
.custom-select-box.span5 {
  width: 180px;
}
.custom-select-box.span6 {
  width: 210px;
}
.custom-select-box.span7 {
  width: 240px;
}
.custom-select-box.span8 {
  width: 270px;
}
.custom-select-box.span9 {
  width: 300px;
}

.grid--block-masthead {
  .select-field,
  .custom-select-box {
    width: 100% !important;
  }
}

.option-parts-length-1 {
  .option-part {
    display: inline-block;
    width: 100%;
  }
}

.option-parts-length-2 {
  .option-part {
    display: inline-block;
    width: 50%;
  }
}

.option-parts-length-3 {
  .option-part {
    display: inline-block;
    width: 30%;
  }
}

.option-parts-length-4 {
  .option-part {
    display: inline-block;
    width: 25%;
  }
}

.current-value .option-part {
  width: auto;
  margin-right: 1em;
}

.option-part {
  display: inline-block;
  width: 20%;
}

.custom-select-box-options {
  .option-select-header {
    background: $nn_ultra_light_grey;
    cursor: default;
  }
}

/* CONTEXT: Responsive Block Delta inside column
/*Inside column grid ugly patch*/
.is-responsive .col-t-one-half,
.is-responsive .col-d-one-half,
.is-responsive .col-one-half {
  .custom-select-box {
    width: 100%;
    .current-value {
      white-space: normal;
    }
  }
}

@media (min-width: $tabmob_break_point) and (max-width: $tablet_break_point) {
  .is-responsive .col-t-one-half,
  .is-responsive .col-d-one-half,
  .is-responsive .col-one-half {
    .custom-select-box {
      width: 330px;
    }
  }
}
