@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';

/* COMPONENT: poll
=============================================================================*/

.poll {
  .button--submit {
    margin-top: 10px;
  }
}
@media (min-width: $mobile_break_point) {
  .poll {
    .field--radiobutton {
      margin: 0;
    }
    .field--radiobutton .field-label,
    .field-label--radiobutton {
      margin-bottom: 0;
    }
    .button--submit {
      margin-top: 20px;
    }
  }
}

/* PART: options
=============================================================================*/
.poll-options {
  li {
    padding-left: 0;
    *margin-bottom: 5px;
    &:before {
      display: none;
    }
  }
}

/* PART: poll-results
=============================================================================*/
.poll-results {
  margin-bottom: 1em;
  > li {
    padding: 0;
    &:before {
      display: none;
    }
  }
}

/* PART: poll-result
=============================================================================*/

.poll-result {
  margin: 1em 0;

  > dt {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  > dd {
    margin: 0;
  }
}

.poll-results-option {
  display: block;
}

.poll-results-votes {
  display: block;
  @include font-size(14);
  color: $nn_medium_grey;
}

.poll-results-percentage {
  margin-top: -1px;
  @include font-size(14);
}

.poll-results-percentage-bar {
  position: relative;
  margin: -14px 0 0 34px;
  height: 10px;
  border: 1px solid $nn_extra_light_grey;
  background: $white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.poll-results-percentage-bar-indicator {
  margin: 0;
  height: 10px;
  border: 1px solid $white;
  background: $nn_medium_orange;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
