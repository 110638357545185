.c-tabular-selector {
	border: 1px solid $nn_light_grey;
	border-radius: 4px;
	position: relative;
	display: inline-block;

	&:after {
		content: " ";
		position: absolute;
		display: inline-block;
		width: 2em;
		right: 0;
		top: .3em;
		bottom: .3em;
		border-left: 1px solid $nn_light_grey;
		pointer-events: none;
		background-color: #fff;
		background-image: url('tabularselector/img/triangle.svg');
		background-position: center;
		background-repeat: no-repeat;
	}

	&:focus,
	&.is-open {
		border-color: $nn_medium_orange;
	}

	&__row {
		&--input {
			&:not(.is-selected) {
				&,
				& td {
					cursor: pointer;
				}
				&:hover,
				&:hover td {
					background-color: $nn_ultra_light_grey;
				}
			}
			&.is-selected,
			&.is-selected td {
				background-color: $nn_medium_orange;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}

	&__cell {
		&--input {
			vertical-align: middle;
			text-align: center;
		}
	}

	&__label {
		display: inline-block;
		width: 100%;
		height: 100%;
		cursor: pointer;
		padding: 7px 5px 8px;
	}

	.c-table__body__cell {
		padding: 0;
	}



	.is-selected &__label {
		color: $white;
	}

	&__dialog {
		background-color: $white;
		border: 1px solid $nn_light_grey;
		border-radius: 4px;
		position: absolute;
		// right: 0;
		top: calc(100% + 4px);
		display: none;
		padding: 10px 10px 0 10px;
		z-index: 500;
	}

	.is-open &__dialog {
		display: block;
	}

	&__table {
		margin-bottom: 0;

	}

	&__cell--input {
		display: none;
	}

	&__value {
		display: inline-block;
		padding: 8px;
		vertical-align: middle;
		box-sizing: border-box;
		width: 330px; //default use in old grid unit
		cursor: pointer;
		position: relative;
		z-index: 1;

		&-table {
			width: 100%;
			td {
				padding: 0 5px 0 8px;
			}
		}
	}
}

// THIS WRAPPER IS NEEDED AS LONG AS WE SUPPORT THIS is-responsive setup...
.is-responsive {

	@media (max-width: $size_xs) {
		.c-tabular-selector,
		.c-tabular-selector__value {
			width: 100%;
			max-width: 100%;
		}
	}
}