/* CONTROL: Spinner
=============================================================================*/
.spinner {
	margin: 0 0 20px 0;
	padding: 9px 9px 9px 82px;
	display: inline-block;

	min-height: 60px;

	background-color: #fff;
	background-position: 6px center;
	background-repeat: no-repeat;

	background-image: url('../img/spinner.gif');

}

.spinner-content {
	margin: 0;
	display: inline-block;
}

.spinner--overlay {
	position: fixed;
	z-index: 1050;
	left: 50%;
	top: 50%;
	min-width: 400px;
	-webkit-transform: translate(-50%,-50%);
	-moz-transform:    translate(-50%,-50%);
	-ms-transform:     translate(-50%,-50%);
	-o-transform:      translate(-50%,-50%);
	transform:         translate(-50%,-50%);
}