// TODO: do we define break and tweakpoints, or are most breakpoints simply device breakpoints
$mobile_min_width: 320px;
$mobile_break_point: 479px;
$tabmob_break_point: 610px;
$tablet_break_point: 820px;
$desktop_break_point: 1184px;

$phone: 480px;
$phablet: 767px;
$tablet: 768px;
$landscape: 1024px;

$desktop-min: 1184px;
$desktop-max: 1280px;


// Suggestion for new sizing setup
// get rid of naming things like mobile
$size_xxs: 320px;
$size_xs: 479px;
$size_s: 610px;
$size_m: 768px;
$size_l: 820px;
$size_xl: 1184px;
$size_xxl: 1280px;