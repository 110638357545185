@import '../_helpers/color';
@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';

.c-datepicker {
  // Assume input of 48px
	&__icon {
		position: absolute;
    transform: translateY(calc((48px - 16px) / 2)) translateX(calc(0px - 16px - 16px));
		width: 16px;
		height: 16px;
		background: url('c-datepicker__icon.svg') no-repeat center;
	}

	&__dialog {
		min-height: 240px;
	
		// FIXME General Advisor styling messes with the table here
		table {
			border-bottom: none;
			margin-bottom: 0;

			td {
				padding: 0;
				border-top: none;
			}
		}

		&__header {
			padding-bottom: 0.5rem;

			// We have to use .select, since the <select> element will wrap itself
			> .select {
				margin-right: 0.5rem;
			}
		}
	}
	&__select-month {
		width: 8em;
	}
	&__select-year {
		width: 6em;
	}
	&__month {
		width: 100%;
		border: none;
	}
	&__weekday,
	&__date {
		box-sizing: border-box;
		padding: 0.3rem 0;
		margin: 2px;
		text-align: center;
	}

	&__weekday {
		@include color($datepickerDate_elements_Date_styles_color);
		font-size: 0.875rem;
	}

	&__date {
    display: inline-flex;
    align-items: center;
    justify-content: center;
		border-radius: 50%;
    height: 40px;
    width: 40px;
		border-width: 2px;
		border-style: solid;
		@include border-color($datepickerDate_elements_Date_styles_borderColor);

		&[data-date] {
			cursor: pointer;
		}

		&--inactive {
			@include color($datepickerDate_elements_Date_dataStates_Unavailable_styles_color);
		}

		&--today {
			border-width: 2px;
			border-style: solid;
			@include border-color($datepickerDate_elements_Date_dataStates_IsToday_styles_borderColor);
		}
		
		&--chosen {
			font-weight: bold;
			border-width: 2px;
			border-style: solid;
			@include border-color($datepickerDate_elements_Date_dataStates_Selected_styles_borderColor);

      &:hover {
        cursor: default;
      }
		}

		&:hover[data-date]:not(.c-datepicker__date--chosen) {
			color: inherit;
			@include background-color($datepickerDate_elements_Date_interactionStates_Hover_styles_backgroundColor);
		}
	}
}
