/*
 * Exerience Central Front-end fixes
 * XC Produces extra div elements in the Front-end
 * corrections needed can be placed here 
=============================================================================*/

.adviseur .site-title {
    top: 64px!important;
}

.uniqueid {
    display: none;
}

.xc-layout-wrapper,
.xc-mediaitem-wrapper,
.xc-page-wrapper,
.xc-pagepart-wrapper
 {
    margin: 0;
    padding: 0;
}