@import '../c-inline-message/c-inline-message.mixin';
@import "../../_helpers/mixins";
@import "../../_helpers/component-design-token-maps";

.c-form-label {
  padding-bottom: 4px;
  display: block;
  @include font-medium;
  @include color($label_elements_Label_styles_color);

  + .o-flex {
    .o-flex__col--icon {
      .c-icon--pict-warning {
        display: none;
      }
    }
  }

  ~ .u-color--medium-orange.u-font-bold {
    @include c-inline-message;
  }
}
