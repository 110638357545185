@import '../../_helpers/sizes';
@import '../../_helpers/color';

.c-form-radio-images {
  @media (min-width: $size_m) {
    flex-direction: row;
  }

  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-direction: column;

  &__item {
    @media (min-width: $size_m) {
      min-width: 20%;
      flex-basis: 25%;
      display: block;
      border-bottom: none;

    }

    min-width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $nn_light_grey;

    &__label {
      @media (min-width: $size_m) {
        width: 100%;
      }
      @media (min-width: $size_xs) {
        min-width: 25%;
      }

      min-width: 50%;
    }
  }

  &__item:last-child {
      border-bottom: none;
  }
}
