@import "../../_helpers/mixins";
@import "../../_helpers/component-design-token-maps";

.c-list-icon {
	list-style: none;
	margin-bottom: 2rem;

	&__item {
		@extend ._li-reset;
		margin: 1rem 0;
		padding: 0;
	}

	&__link, &__text {
		display: inline-block;
		position: relative;
		padding: 0 0 0 1.5rem;
		@include color($listItem_elements_Text_styles_color);
		text-decoration: none;

		&:visited {
			@include color($listItem_elements_Text_styles_color);
		}
	}

	&__link:hover {
		@include color($link_elements_Icon_variants_Primary_interactionStates_Hover_styles_color);
	}

	&__icon {
		@include color($iconButton_elements_Icon_styles_color);
		display: inline-block;
		margin-left: -1.5rem;
		width: 1.5rem;
		font-size: 1rem;
		text-decoration: none;
		vertical-align: middle;
    position: relative;
    top: -2px;
    // Do not interfere with the flow of text
    line-height: 0;

    .opt-out-nitti-grotesk-font & {
      position: static;
      top: auto;
    }

		&:visited {
			@include color($iconButton_elements_Icon_styles_color);
		}
	}

	&--medium {
		.c-list-icon__link,
		.c-list-icon__text {
			padding-left: 2rem;
		}

		.c-list-icon__icon {
			margin-left: -2rem;
			width: 2rem;
			font-size: 1.5rem;
		}
	}

	&--large {
		.c-list-icon__link,
		.c-list-icon__text {
			padding-left: 2.5rem;
		}

		.c-list-icon__icon {
			margin-left: -2.5rem;
			width: 2.5rem;
			font-size: 2rem;
		}
	}
}