@import '../_helpers/mixins';

.c-pagination {
	line-height: 38px;

	&__list {
		@include list-style-none;
		text-align: center;
		line-height: 38px;
	}


	&__item {
		@extend ._li-reset;
		color: $nn_extra_dark_grey;
		cursor: pointer;
		display:inline-block;
		margin: 0;
	}

	&__link {
		text-decoration: none;
		color: $nn_medium_grey;
		padding: 5px;

		&.is-active,
		&:active {
			color: $nn_medium_orange;
			text-decoration: none;
		}
	}

	&__button-next {
		margin-left: 0px;
		float: right;
	}

	&__button-back {
		margin-right: 0px;
		float: left;
	}

}

@media (max-width: $size-s) {
	.c-pagination {

		&__list {
			width: 100%;
		}

		&__button-next {
			float: right;
			margin: 0;
		}
		&__button-back {
			float: left;
			margin: 0;
		}
	}
}