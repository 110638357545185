@use 'sass:math';

@import 'colors';
@import '../../global/css/mixins';
@import '../../global/css/component-design-token-maps';

@mixin firefox-only() {
  @-moz-document url-prefix() {
    @content;
  }
}

$font-size-base: 16;
@mixin font-size($sizeValue: 14, $lineHeight: null) {
  font-size: $sizeValue + px;
  font-size: math.div($sizeValue, $font-size-base) + rem;
  @if $lineHeight {
    line-height: math.div($lineHeight, $sizeValue);
  }
}

$tablet: 768px;
$desktop-small: 900px;
$desktop-medium: 1160px;
$desktop-large: 1430px;

@mixin breakpoint($point) {
  @if $point == desktop-large {
    @media only screen and (min-width: $desktop-large) {
      @content;
    }
  } @else if $point == desktop-medium {
    @media only screen and (min-width: $desktop-medium) {
      @content;
    }
  } @else if $point == desktop-small {
    @media only screen and (min-width: $desktop-small) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  }
}

@mixin grid-wrapper($gutter-width) {
  .grid-wrapper--#{$gutter-width} {
    @content;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-sep($radiusTL, $radiusTR, $radiusBR, $radiusBL) {
  -webkit-border-radius: $radiusTL $radiusTR $radiusBR $radiusBL;
  -moz-border-radius: $radiusTL $radiusTR $radiusBR $radiusBL;
  border-radius: $radiusTL $radiusTR $radiusBR $radiusBL;
}

@mixin transition($type, $duration, $timing) {
  -webkit-transition: $type $duration $timing;
  -moz-transition: $type $duration $timing;
  -o-transition: $type $duration $timing;
  -ms-transition: $type $duration $timing;
  transition: $type $duration $timing;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin drop-shadow($horOffset, $verOffset, $blur, $color) {
  -webkit-box-shadow: $horOffset $verOffset $blur $color;
  -moz-box-shadow: $horOffset $verOffset $blur $color;
  box-shadow: $horOffset $verOffset $blur $color;
}

@mixin bg-gradient-vertical($from, $to) {
  background: $from; /* Old browsers */
  background: -moz-linear-gradient(top, $from 0%, $to 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $from),
    color-stop(100%, $to)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $from 0%,
    $to 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $from 0%, $to 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $from 0%, $to 100%); /* IE10+ */
  background: linear-gradient(to bottom, $from 0%, $to 100%); /* W3C */
}

@mixin bg-gradient-vertical-plus-image($image, $offset, $repeat, $from, $to) {
  background: url($image) $from $offset $repeat; /* Old browsers */
  background:
    url($image) $offset $repeat,
    -moz-linear-gradient(top, $from 0%, $to 100%); /* FF3.6+ */
  background:
    url($image) $offset $repeat,
    -webkit-gradient(linear, left top, left bottom, color-stop(0%, $from), color-stop(100%, $to)); /* Chrome,Safari4+ */
  background:
    url($image) $offset $repeat,
    -webkit-linear-gradient(top, $from 0%, $to 100%); /* Chrome10+,Safari5.1+ */
  background:
    url($image) $offset $repeat,
    -o-linear-gradient(top, $from 0%, $to 100%); /* Opera 11.10+ */
  background:
    url($image) $offset $repeat,
    -ms-linear-gradient(top, $from 0%, $to 100%); /* IE10+ */
  background:
    url($image) $offset $repeat,
    linear-gradient(to bottom, $from 0%, $to 100%); /* W3C */
}

/**
 * $image : The path to the image to be used as a border
 * $offset : The inward offsets of the image-border
 * $width : The widths of the image-border
 * $outset : The amount by which the border image area extends beyond the border box
 * $repeat : Whether the image-border should be repeated, rounded or stretched
 *
 */
@mixin bg-border-image($image, $offset, $repeat) {
  border-image: $image $offset $repeat;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

// Edit mode selector mixin
@mixin preview-mode-selector {
  body.xc-incontext {
    @content;
  }
}

@mixin edit-mode-selector {
  body.wm_not_selectable {
    @content;
  }
}

// ***** Extensions ******
@mixin clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin box-sizing-border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin font-normal {
  font-family: 'Nitti Grotesk NN Text', Arial, Helvetica, Sans-serif;
  font-weight: normal;

  .opt-out-nitti-grotesk-font & {
    font-family: 'NN Dagny', Arial, Helvetica, Sans-serif;
  }
}

@mixin font-bold {
  font-family: 'Nitti Grotesk NN Text', Arial, Helvetica, Sans-serif;
  font-weight: bold;

  .opt-out-nitti-grotesk-font & {
    font-family: 'NN Dagny', Arial, Helvetica, Sans-serif;
  }
}

@mixin font-medium {
  font-family: 'Nitti Grotesk NN Text', Arial, Helvetica, Sans-serif;
  font-weight: 500;

  .opt-out-nitti-grotesk-font & {
    font-family: 'NN Dagny', Arial, Helvetica, Sans-serif;
    font-weight: bold;
  }
}

/* EXTEND: Gray circle
=============================================================================*/
@mixin icon-list-circle {
  content: '';
  position: absolute;
  left: 0;
  top: calc((1.5rem / 2) - 3px);
  width: 6px;
  height: 6px;
  @include border-radius(100%);
  @include background-color($circle_elements_Circle_variants_XS_styles_backgroundColor);
}

/* EXTEND: Orange arrow
=============================================================================*/
@mixin icon-link-list-image {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent $nn_medium_orange;
}

/* EXTEND: Small grey block
=============================================================================*/
@mixin icon-link-list-small-grey {
  content: '';
  left: 8px;
  top: 5px;
  width: 0;
  height: 0;
  @include border-radius(0);
  background: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent $nn_light_grey;
}
