@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: Sticker
=============================================================================*/
.sticker {
  @include background-color($circle_elements_Circle_variants_SmallAttentional_styles_backgroundColor);
  padding: 0;
}

/* COMPONENT: Sticker-clean
=============================================================================*/
.sticker-clean {
  @include background-color($hero_elements_ContentContainer_styles_backgroundColor);
  border-width: 2px;
  border-style: solid;
  @include border-color($circle_elements_Circle_variants_SmallAttentional_styles_borderColor);
  padding: 4px;
  @include box-shadow($card_variants_Attentional_styles_boxShadow);
  @include border-radius(50%);
  width: 75px;
  height: 75px;
}

/* PART: text
=============================================================================*/
.sticker-clean-text {
  display: table-cell;
  margin: 0;
  width: 75px;
  height: 75px;
  @include color($circle_elements_Text_variants_SmallAttentional_styles_color);
  @include font-bold;
  @include font-size(14);
  line-height: 1.1875rem;
  padding: 3px 0 0 0;
  text-align: center;
  vertical-align: middle;
}

.sticker-clean-highlighted {
  display: block;
  font-size: 1.125rem;
}

/* CONTEXT: carrousel/slide-component
=============================================================================*/
.slidecontent .sticker-clean {
  position: absolute;
  top: -20px;
  right: 20px;
}

/* COMPONENT: Sticker Tiny
=============================================================================*/
.sticker-clean--tiny {
  @include border-radius(50%);

  @include background-color($circle_elements_Circle_variants_SmallAttentional_styles_backgroundColor);
  border-width: 2px;
  border-style: solid;
  @include border-color($circle_elements_Circle_variants_SmallAttentional_styles_borderColor);
  padding: 0;
  box-shadow: none;
  width: 1.5rem;
  height: 1.5rem;
  @include color($circle_elements_Text_variants_SmallAttentional_styles_color);
  @include font-bold;
  text-align: center;
}

.sticker-clean--tiny + h3 {
  margin-top: 5px;
}

.sticker-clean--rebate {
  @include border-radius(40px);
  background-color: $nn_medium_green;
  width: 80px;
  height: 80px;

  .sticker-clean-text {
    @include font-bold;
    display: block;
    padding-top: 10px;
    line-height: 1.3125rem;
    font-size: 0.75rem;
    width: 80px;
    height: 80px;
  }

  .sticker-clean-highlighted {
    display: inline-block;
    padding-top: 3px;
    font-size: 1.8125rem;
  }
}
