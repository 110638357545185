.c-page-section {
	// We need to undo the basic padding applied by the page
	$page-padding: 30px;

	margin: 3rem (-$page-padding);
	padding: 3rem $page-padding;

	body:not(.is-wide) & {
		// Scrollbars are not always taken into account in the media query/vw
		// unit calculation.
		$scrollbar-safety-margin: 20px;

		@media (min-width: $size_xl) {
			margin-left: calc((100vw - #{$size_xl}) / -2 - #{$scrollbar-safety-margin});
			margin-right: calc((100vw - #{$size_xl}) / -2 - #{$scrollbar-safety-margin});
			padding-left: calc((100vw - #{$size_xl}) / 2 + #{$scrollbar-safety-margin});
			padding-right: calc((100vw - #{$size_xl}) / 2 + #{$scrollbar-safety-margin});
		}

		@media (min-width: $size_xxl + 30px) {
			margin-left: calc((#{$size_xxl} - #{$size_xl}) / -2 - #{$page-padding});
			margin-right: calc((#{$size_xxl} - #{$size_xl}) / -2 - #{$page-padding});
			padding-left: calc((#{$size_xxl} - #{$size_xl}) / 2 + #{$page-padding});
			padding-right: calc((#{$size_xxl} - #{$size_xl}) / 2 + #{$page-padding});
		}
	}
}
