@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';

.force-section-visibility {

	.force-section-visibility-container {
		@include box-sizing-border-box;
		@include transition(height, 0.35s,linear);

		height: 100px;
		overflow-y: scroll;
		padding: 14px;
	}

}

@media (max-width: $mobile_break_point) {
	.is-responsive {
		.force-section-visibility-container {
			font-size: 0.75em;
		}
	}

}