@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';

$c-expander-transition-duration: 0.5s;

.c-expander-list {

	.c-expander-list__item--collapse_extra {
		.c-expander-list__item__toggle__extra {
			transition: opacity $c-expander-transition-duration;
		}

		&.is-expanding,
		&.is-expanded {
			.c-expander-list__item__toggle__extra {
				opacity: 0;
			}
		}

		&.is-expanded {
			.c-expander-list__item__toggle__extra {
				display: none;
			}
		}
	}

	border-bottom-width: 1px;
	border-bottom-style: solid;
	@include border-bottom-color($collapsibleSection_elements_ContentContainer_styles_borderColor);

	margin-bottom: 15px;

	&__item {
		transition: border-top-color $c-expander-transition-duration;
		border-top-width: 1px;
		border-top-style: solid;
		@include border-top-color($collapsible_elements_CollapsibleSection_styles_borderColor);

		&__toggle {
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			padding: 11px 32px 11px 0;
			position: relative;

			&:hover {
				@include background-color($collapsible_elements_CollapsibleSectionTrigger_interactionStates_Hover_styles_backgroundColor);
			}
			
			&__icon {
				@extend .c-icon;
				@extend .c-icon--angle-right;

				position: absolute;
				right: 0;
				top: 18px;
				@include color($collapsibleSectionTrigger_elements_Icon_styles_color);

				&::before {
					display: inline-block;
					transition: transform $c-expander-transition-duration ease-out;
					transform-origin: 50% 50%;
					transform: rotate(90deg);
					font-size: 22px;
				}
			}

			&__title {
				transition: color $c-expander-transition-duration;
				flex: 2 1 auto;
				@include color($label_elements_Label_styles_color);
        padding: 5px 10px 5px 0;
				white-space: wrap;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 24px;
				font-weight: bold;

        .opt-out-nitti-grotesk-font & {
          padding: 7px 10px 3px 0;
        }
			}

			&__extra {
				flex: 0 1 auto;
				white-space: nowrap;
				padding: 0;
				max-width: 100%;
				line-height: 38px;
			}
		}

		&__content {
			&-outer {
				box-sizing: border-box;
				margin: 0 10px 0 32px;
				max-height: 0;
				overflow: hidden;
				padding: 0;
			}

			&-inner {
				margin: 0;
				padding: 0;
				widows: 100%;

				/* clearfix */
				&::before,
				&::after{
					content: " ";
					display: table;
				}
				&::after{
					clear: both;
				}
			}
		}

		&:first-child &__toggle,
		&.is-expanded &__toggle,
		&.is-expanding &__toggle{
			padding-top: 10px;
		}

		.is-expanded &__content {
			&-outer {
				max-height: none;
			}
		}

		.is-expanded &__toggle,
		.is-expanding &__toggle {
			&__icon {
				&::before {
					transform: rotate(-90deg);
				}
			}
		}

		&.is-expanded,
		&.is-expanding {
			border-top-width: 2px;
		}
		
		.is-expanding &__content,
		.is-contracting &__content {
			&-outer {
				transition: max-height $c-expander-transition-duration;
			}
		}
	}
}