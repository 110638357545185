@import "c-icon.variables";

.c-icon--icon-search:before {
  content: $fonticon-content--icon-search;
}
.c-icon--icon-user:before {
  content: $fonticon-content--icon-user;
}
.c-icon--icon-user-active:before {
  content: $fonticon-content--icon-user-active;
}
.c-icon--pict-calculator:before {
  content: $fonticon-content--pict-calculator;
}
.c-icon--angle-left:before {
  content: $fonticon-content--angle-left;
}
.c-icon--angle-right:before {
  content: $fonticon-content--angle-right;
}
.c-icon--pict-download:before {
  content: $fonticon-content--pict-download;
}
.c-icon--file:before {
  content: $fonticon-content--file;
}
.c-icon--check:before {
  content: $fonticon-content--check;
}
.c-icon--close:before {
  content: $fonticon-content--close;
}
.c-icon--delete:before {
  content: $fonticon-content--delete;
}
.c-icon--image:before {
  content: $fonticon-content--image;
}
.c-icon--chevron-left:before {
  content: $fonticon-content--chevron-left;
}
.c-icon--chevron-right:before {
  content: $fonticon-content--chevron-right;
}
.c-icon--plus:before {
  content: $fonticon-content--plus;
}
.c-icon--minus:before {
  content: $fonticon-content--minus;
}
.c-icon--twitter:before {
  content: $fonticon-content--twitter;
}
.c-icon--facebook:before {
  content: $fonticon-content--facebook;
}
.c-icon--menu:before {
  content: $fonticon-content--menu;
}
.c-icon--google-plus:before {
  content: $fonticon-content--google-plus;
}
.c-icon--linkedin:before {
  content: $fonticon-content--linkedin;
}
.c-icon--switch-off:before {
  content: $fonticon-content--switch-off;
}
.c-icon--switch-on:before {
  content: $fonticon-content--switch-on;
}
.c-icon--lock:before {
  content: $fonticon-content--lock;
}
.c-icon--lock-open:before {
  content: $fonticon-content--lock-open;
}
.c-icon--arrow-bottom:before {
  content: $fonticon-content--arrow-bottom;
}
.c-icon--arrow-top:before {
  content: $fonticon-content--arrow-top;
}
.c-icon--pict-search:before {
  content: $fonticon-content--pict-search;
}
.c-icon--arrow-right:before {
  content: $fonticon-content--arrow-right;
}
.c-icon--arrow-left:before {
  content: $fonticon-content--arrow-left;
}
.c-icon--user:before {
  content: $fonticon-content--user;
}
.c-icon--app:before {
  content: $fonticon-content--app;
}
.c-icon--close--thin:before {
  content: $fonticon-content--close--thin;
}
.c-icon--mortgage:before {
  content: $fonticon-content--mortgage;
}
.c-icon--whattodo:before {
  content: $fonticon-content--whattodo;
}
.c-icon--laptop:before {
  content: $fonticon-content--laptop;
}
.c-icon--gross-net:before {
  content: $fonticon-content--gross-net;
}
.c-icon--search:before {
  content: $fonticon-content--search;
}
.c-icon--savings-account:before {
  content: $fonticon-content--savings-account;
}
.c-icon--remaining-debt-home:before {
  content: $fonticon-content--remaining-debt-home;
}
.c-icon--creditcard:before {
  content: $fonticon-content--creditcard;
}
.c-icon--moving-consideration:before {
  content: $fonticon-content--moving-consideration;
}
.c-icon--accident:before {
  content: $fonticon-content--accident;
}
.c-icon--calculate:before {
  content: $fonticon-content--calculate;
}
.c-icon--pict-error:before {
  content: $fonticon-content--pict-error;
}
.c-icon--pict-info:before {
  content: $fonticon-content--pict-info;
}
.c-icon--pict-success:before {
  content: $fonticon-content--pict-success;
}
.c-icon--pict-warning:before {
  content: $fonticon-content--pict-warning;
}
.c-icon--pict-dwelling:before {
  content: $fonticon-content--pict-dwelling;
}
.c-icon--pict-feedback:before {
  content: $fonticon-content--pict-feedback;
}
.c-icon--pict-meeting:before {
  content: $fonticon-content--pict-meeting;
}
.c-icon--pict-premium:before {
  content: $fonticon-content--pict-premium;
}
.c-icon--pict-upload:before {
  content: $fonticon-content--pict-upload;
}
.c-icon--percent:before {
  content: $fonticon-content--percent;
}
.c-icon--pict-twitter:before {
  content: $fonticon-content--pict-twitter;
}
.c-icon--pict-advisor:before {
  content: $fonticon-content--pict-advisor;
}
.c-icon--pict-callnow:before {
  content: $fonticon-content--pict-callnow;
}
.c-icon--pict-call:before {
  content: $fonticon-content--pict-call;
}
.c-icon--pict-client:before {
  content: $fonticon-content--pict-client;
}
.c-icon--pict-mail:before {
  content: $fonticon-content--pict-mail;
}
.c-icon--pict-print:before {
  content: $fonticon-content--pict-print;
}
.c-icon--pict-star:before {
  content: $fonticon-content--pict-star;
}
.c-icon--pict-website:before {
  content: $fonticon-content--pict-website;
}
.c-icon--pict-clarity:before {
  content: $fonticon-content--pict-clarity;
}
.c-icon--pict-percentage:before {
  content: $fonticon-content--pict-percentage;
}
.c-icon--pict-authorisation:before {
  content: $fonticon-content--pict-authorisation;
}
.c-icon--pict-extra-attention:before {
  content: $fonticon-content--pict-extra-attention;
}
.c-icon--pict-arrow-right:before {
  content: $fonticon-content--pict-arrow-right;
}
.c-icon--pict-hourglass:before {
  content: $fonticon-content--pict-hourglass;
}
.c-icon--pict-arrow-left:before {
  content: $fonticon-content--pict-arrow-left;
}
.c-icon--pict-plus:before {
  content: $fonticon-content--pict-plus;
}
.c-icon--pict-minus:before {
  content: $fonticon-content--pict-minus;
}
.c-icon--house:before {
  content: $fonticon-content--house;
}
.c-icon--nn-logo:before {
  content: $fonticon-content--nn-logo;
}
.c-icon--umbrella:before {
  content: $fonticon-content--umbrella;
}
.c-icon--pict-star-pos:before {
  content: $fonticon-content--pict-star-pos;
}
.c-icon--personal-savings:before {
  content: $fonticon-content--personal-savings;
}
.c-icon--debt:before {
  content: $fonticon-content--debt;
}
.c-icon--tree:before {
  content: $fonticon-content--tree;
}
.c-icon--folder:before {
  content: $fonticon-content--folder;
}
.c-icon--boat:before {
  content: $fonticon-content--boat;
}
.c-icon--edit:before {
  content: $fonticon-content--edit;
}
.c-icon--cross-thin:before {
  content: $fonticon-content--cross-thin;
}
.c-icon--calculator:before {
  content: $fonticon-content--calculator;
}
.c-icon--bullet:before {
  content: $fonticon-content--bullet;
}
.c-icon--zorg:before {
  content: $fonticon-content--zorg;
}
.c-icon--particulier-beleggen:before {
  content: $fonticon-content--particulier-beleggen;
}
.c-icon--particulier-hypotheken:before {
  content: $fonticon-content--particulier-hypotheken;
}
.c-icon--particulier-lenen:before {
  content: $fonticon-content--particulier-lenen;
}
.c-icon--particulier-levensverzekeringen:before {
  content: $fonticon-content--particulier-levensverzekeringen;
}
.c-icon--particulier-schadeverzekeringen:before {
  content: $fonticon-content--particulier-schadeverzekeringen;
}
.c-icon--particulier-sparen:before {
  content: $fonticon-content--particulier-sparen;
}
.c-icon--particulier-zorgverzekering:before {
  content: $fonticon-content--particulier-zorgverzekering;
}
.c-icon--zakelijk-collectief-pensioen:before {
  content: $fonticon-content--zakelijk-collectief-pensioen;
}
.c-icon--zakelijk-individueel-pensioen:before {
  content: $fonticon-content--zakelijk-individueel-pensioen;
}
.c-icon--zakelijk-inkomensverzekeringen:before {
  content: $fonticon-content--zakelijk-inkomensverzekeringen;
}
.c-icon--zakelijk-schadeverzekeringen:before {
  content: $fonticon-content--zakelijk-schadeverzekeringen;
}
.c-icon--chevron-up:before {
  content: $fonticon-content--chevron-up;
}
.c-icon--eye:before {
  content: $fonticon-content--eye;
}
.c-icon--chat-question:before {
  content: $fonticon-content--chat-question;
}
.c-icon--qr-code:before {
  content: $fonticon-content--qr-code;
}
.c-icon--advisor-no-bg:before {
  content: $fonticon-content--advisor-no-bg;
}
.c-icon--toegang-en-beveiliging:before {
  content: $fonticon-content--toegang-en-beveiliging;
}
.c-icon--overzicht:before {
  content: $fonticon-content--overzicht;
}
.c-icon--my-documents:before {
  content: $fonticon-content--my-documents;
}
.c-icon--mijn-betalingsoverzicht:before {
  content: $fonticon-content--mijn-betalingsoverzicht;
}
.c-icon--lopende-zaken:before {
  content: $fonticon-content--lopende-zaken;
}
.c-icon--gegevens-en-instellingen:before {
  content: $fonticon-content--gegevens-en-instellingen;
}
.c-icon--key-lock:before {
  content: $fonticon-content--key-lock;
}
.c-icon--add:before {
  content: $fonticon-content--add;
}
.c-icon--trashcan:before {
  content: $fonticon-content--trashcan;
}
.c-icon--envelope:before {
  content: $fonticon-content--envelope;
}
.c-icon--archive:before {
  content: $fonticon-content--archive;
}
.c-icon--mail:before {
  content: $fonticon-content--mail;
}
.c-icon--questionmark:before {
  content: $fonticon-content--questionmark;
}
.c-icon--magnifying-glass:before {
  content: $fonticon-content--magnifying-glass;
}
.c-icon--qr-code-line:before {
  content: $fonticon-content--qr-code-line;
}
.c-icon--pencil:before {
  content: $fonticon-content--pencil;
}
.c-icon--download:before {
  content: $fonticon-content--download;
}
.c-icon--information:before {
  content: $fonticon-content--information;
}
.c-icon--youtube:before {
  content: $fonticon-content--youtube;
}
.c-icon--x:before {
  content: $fonticon-content--x;
}
.c-icon--instagram:before {
  content: $fonticon-content--instagram;
}
.c-icon--whatsapp:before {
  content: $fonticon-content--whatsapp;
}
.c-icon--paper-clip:before {
  content: $fonticon-content--paper-clip;
}