@import '../_helpers/sizes';

@import "c-form-label/c-form-label";
@import "c-form-text/c-form-text";
@import "c-form-textarea/c-form-textarea";
@import "c-form-radio/c-form-radio";
@import "c-form-radio-panel/c-form-radio-panel";
@import "c-form-radio-images/c-form-radio-images";
@import "c-form-checkbox/c-form-checkbox";
@import "c-form-select/c-form-select";
@import "c-form-button/c-form-button";
@import "c-form-license-plate/c-form-license-plate";
@import "c-form-password/c-form-password";
@import "c-form-input-group/c-form-input-group";
@import "c-form-field/c-form-field";
@import "c-form-fieldset/c-form-fieldset";
@import "c-form-repeater/c-form-repeater";

.c-form {
	// TODO what about our naming conventions ??
	&-container {
		margin-bottom:16px;

		.c-form-label {
			line-height: 2.375rem;
		}

	}
	&__label {
		display: block;
		float: left;
		width:33%;
		cursor: default;
	}
	&__fields {
		margin-left:38%;
		&--inline .c-form__field {
			width:auto;
			display: inline-block;
			margin-right:20px;
		}
	}
	&__field {

	}

}


@media (max-width: $phone) {
	.c-form {
		&__label {
			width: 100%;
		}
		&__fields {
			margin-left: 0;
		}
	}
}
