@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: Text Field */
$text_field_height: 25px;
$text_field_height_touch: 38px;
$text_field_height_license: 38px;

input[readonly] {
  background-color: #ebebe4;

  &.like-not-readonly {
    background-color: #fff;
  }
}

input[disabled].text-field {
  background-color: #ebebe4;
  color: $nn_extra_dark_grey;
  border: none;
  padding-left: 10px;
  -webkit-text-fill-color: $nn_extra_dark_grey; /* WEBKIT IOS FIX */
  opacity: 1; /* WEBKIT IOS FIX */
}

.text-field-prefix + input[disabled].text-field {
  padding-left: 10px;
}

.field-value {
  padding: 10px 0 0 0;
  display: inline-block;
}

input[type='date'] {
  @include box-sizing-border-box();
  width: 80%;
  background-color: #fff;
}

.date-field,
.text-field {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  @include border-color($input_elements_Input_styles_borderColor);
  border-radius: 4px;
  padding: 8px 16px;
  width: 100%;
  height: 48px;
  -webkit-appearance: none;
}

.text-field--100pc {
  width: 100%;
}

.text-field--stretched {
  box-sizing: border-box;
  width: 100%;
}

.text-field--icon-client {
  background-image: url('../img/sprites/icons.svg');
  background-repeat: no-repeat;
  background-position: -412px -586px;
  padding-left: 30px;
}

.date-field {
  background: url('../img/select-box.png') no-repeat right 5px;
}

@mixin placeholder-styling {
  color: $nn_medium_grey;
}

/* IE */
.text-field-placeholder {
  @include placeholder-styling;
}

/* WEBKIT */
.text-field::-webkit-input-placeholder {
  @include placeholder-styling;
}

/* MOZILLA */
.text-field::-moz-placeholder {
  @include placeholder-styling;
}

/* MOZILLA */
.text-field:-moz-placeholder {
  @include placeholder-styling;
}

.text-field:-ms-input-placeholder {
  @include placeholder-styling;
}

.text-field-prefix,
.text-field-suffix,
.text-field-suffix-value {
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  .data-table-cell & {
    font-size: 1rem;
  }
}

.text-field-prefix {
  @include placeholder-styling;
  font-style: normal;
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 24px;
}

.text-field-suffix {
  @include placeholder-styling;
  font-style: normal;
  position: absolute;
  z-index: 100;
  left: 20px;
}

.touch-fields {
  .text-field-prefix,
  .text-field-suffix {
    top: 50%;
    transform: translateY(-50%);
  }
}

.text-field-suffix-value {
  visibility: hidden;
  position: absolute;
}

/* PART: Text field sizes DO NOT USE DEPRICATED!!!
=============================================================================*/

.span1 {
  width: 80px;
}
.span2 {
  width: 110px;
}
.span3 {
  width: 130px;
}
.span4 {
  width: 170px;
}
.span5 {
  width: 200px;
}
.span6 {
  width: 230px;
}
.span7 {
  width: 260px;
}
.span8 {
  width: 290px;
}
.span9 {
  width: 320px;
}
.span100 {
  width: 100%;
}

/* THEME: Masthead span styling
=============================================================================*/

.grid--block-masthead {
  .text-field {
    width: 100%;
  }

  .span3 {
    width: 85%;
  }
}

.grid--block-masthead-v2 {
  .span1 {
    width: 100%;
  }

  .span2 {
    width: 100%;
  }
}

/* THEME: TextField size responsive
=============================================================================*/
@media (max-width: $mobile_break_point) {
  .is-responsive {
    .date-field,
    .text-field {
      width: 100%; /* the default */
    }

    .span1,
    .span2 {
      width: 50%;
    }
    .span3 {
      width: auto;
    }
    .span8 {
      width: 100%;
    }
    .span9 {
      width: 100%;
    }

    .grid--block-masthead-v2 {
      .span2 {
        width: 100%;
      }
    }
  }
}

/* LANDSCAPE line-height fix iOS */
@media (min-width: $mobile_break_point) and (max-width: $tablet_break_point) {
  .is-responsive .date-field {
    line-height: $text_field_height_touch;
  }
}

/* CONTEXT: Header search form
=============================================================================*/
.header-search-form {
  .text-field--search,
  .text-field-wrapper {
    width: 100%;
  }
}

/* THEME: Lisence Plate
=============================================================================*/

.text-field--licenseplate {
  background: url('text-field/img/sprite-license-plate-mobile.png') -3px -40px
    no-repeat;
  border-radius: 0;
  font-weight: bold;
  font-size: 13px;
  width: 132px;
  border: 0;
  text-transform: uppercase;
  height: 38px;
  line-height: 38px;
  margin-right: 10px;
  padding: 0;

  &,
  &.text-field[disabled] {
    padding-left: 38px;
  }
}

.text-field--licenseplate.focus {
  background-position: -3px 0;
}

input[disabled].text-field--licenseplate.text-field {
  background-position: -3px -80px;
  background-color: transparent;
  color: $nn_medium_grey;
}

.touch-fields input[disabled].text-field--licenseplate.text-field {
  background-position: -3px -80px;
}

@mixin placeholder-styling-licensplate {
  color: $nn_extra_dark_grey;
}

.text-field--licenseplate::-webkit-input-placeholder {
  @include placeholder-styling-licensplate;
}

/* Disabled state */
.text-field--licenseplate[disabled]::-webkit-input-placeholder {
  color: transparent;
}

.text-field--licenseplate:-moz-placeholder {
  /* Firefox 18- */
  @include placeholder-styling-licensplate;
}

.text-field--licenseplate[disabled]:-moz-placeholder {
  color: transparent;
}

.text-field--licenseplate::-moz-placeholder {
  /* Firefox 19+ */
  @include placeholder-styling-licensplate;
}

.text-field--licenseplate[disabled]::-moz-placeholder {
  color: transparent;
}

.text-field--licenseplate:-ms-input-placeholder {
  @include placeholder-styling-licensplate;
}

.text-field--licenseplate:disabled:-ms-input-placeholder {
  color: transparent;
}

.text-field--licenseplate:disabled.text-field-placeholder {
  color: transparent;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .text-field--licenseplate {
      background: url('text-field/img/sprite-license-plate-mobile.png') -3px -40px
        no-repeat;
      height: $text_field_height_touch;
      width: 132px;
    }
    .text-field--licenseplate:focus {
      background-position: -3px 0;
    }
    .text-field--licenseplate:disabled {
      background-position: -3px -80px;
    }

    .grid--block-field {
      display: inline;
      width: auto;
    }

    .grid--block-iota {
      .grid-unit {
        .custom-select-box {
          & + .dialog--information {
            margin: 10px 0 0 20px;
          }
        }
      }
    }
  }
}

/*
	COMPONENT: TEXT AREA
*/
.text-field--textarea {
  padding-top: 9px;
  padding-bottom: 8px;
  height: auto;

  &[disabled] {
    color: $nn_extra_dark_grey;
    padding: 12px 0 10px 0;
    border: none;
    background-color: #fff;
    resize: none;

    -webkit-text-fill-color: $nn_extra_dark_grey; /* WEBKIT IOS FIX */
    opacity: 1; /* WEBKIT IOS FIX */
  }

  &[readonly] {
    background-color: #ebebe4;
  }
}

.touch-fields {
  .text-field--textarea {
    height: auto;
  }
}

.etcustomtextfield {
  position: relative;
  width: 100%;
  float: left;
}
.ethint {
  position: absolute;
  left: 9px;
  top: 10px;
  color: $nn_medium_grey;
  @include font-bold;
}

.ettextfield {
  .ethint {
    display: block;
  }
  .etfocus {
    .ethint {
      display: none !important;
    }
  }
  .etvalue {
    .ethint {
      display: none;
    }
  }
}

/* Context: Fixed sizes within scrollable table
=============================================================================*/
.data-table--scrollable-wrapper .data-table--scrollable {
  .span1 {
    width: 60px;
  }
  .span2 {
    width: 90px;
  }
  .span3 {
    width: 120px;
  }
  .span4 {
    width: 150px;
  }
  .span5 {
    width: 180px;
  }
  .span6 {
    width: 210px;
  }
  .span7 {
    width: 240px;
  }
  .span8 {
    width: 270px;
  }
  .span9 {
    width: 300px;
  }
  .span100 {
    width: 100%;
  }
}
