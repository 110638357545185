@import 'c-dialog.variables';

.c-modal {

	&--datepicker {
		background-color: transparent;
		position: fixed;
		overflow: visible;
		transition-duration: 0s;
	}

	&--datepicker &__dialog {
		width: 300 + $c-dialog-padding-size * 2;
		transform: none;
		transition-duration: 1ms;
	}

	&--datepicker[data-arrow-position] &__close-btn {
		padding-left: 0;
		margin-left: 0;
	}
}
