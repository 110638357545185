$base-line-height: 32px;
$spin-duration: 1.5s;

.c-spinner, .c-spinner--small {

	border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: 0.15rem solid $nn_ultra_light_grey;
    border-top-color: $nn_medium_orange;
    animation: rotate $spin-duration infinite linear;

	// hide texst
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;

}

.c-spinner--small {
	width: 16px;
	height: 16px;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
