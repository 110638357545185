@import '../_helpers/color';
@import '../_helpers/sizes';
@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';

#tags-container {
    margin-top:32px
}

#tags-container #tag-component-container {
    display:flex;
    flex-flow:row wrap;
    margin:16px 0
}

#tags-container #tag-component-container .tag-item {
    box-sizing:border-box;
    display:flex;
    flex-direction:row;
    align-items:center;
    box-sizing:border-box;
    padding:4px 12px;
    border-radius:4px;
    background-color:#d5e2f0;
    font-weight:500;
    color:#2e5783;
    margin:0 15px 15px 0
}

#tags-container #tag-component-container .tag-item:hover {
    background-color:#e0eaf4
}

#tags-container #tag-component-container .tag-item--active {
    background-color:#2e5783;
    color:#fff
}

#tags-container #tag-component-container .tag-item--active:hover {
    background-color:#234263
}

#tags-container a,#tags-container a:hover,#tags-container a:focus,#tags-container a:active {
    text-decoration:none;
    color:inherit
}

#tags-container .inVertical {
    justify-content:center
}