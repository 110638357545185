@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../control/icons/css/icons.mixins';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: bullet-list
=============================================================================*/
ul {
  @include list-style-none;
  margin: 0 0 16px 0;

  li {
    position: relative;
    padding: 0 0 0 16px;

    &:before {
      @include icon-list-circle;
    }
  }

  ul {
    margin: 0 0 0 0;
  }

  /* FIXME XC cannot output correct HTML :( */
  > ul {
    margin-left: 16px;
  }

  @media (min-width: $tablet_break_point) {
    &.list--two-column {
      li {
        /*float:left;*/
        display: inline-block;
        width: 45%;
      }
    }
  }
}

ol {
  margin: 0 0 15px 0;
  padding: 0 0 0 15px;
  li {
    padding-bottom: 0;
    &:before {
      padding-right: 0.7em;
    }
  }
  ol {
    margin: 8px 0 0 0;
  }
  .field & li:before {
    display: none;
  }
}

ul {
  ol {
    padding: 0 0 0 20px;

    li {
      background: none;
      padding: 0;
    }
  }
}

.none {
  li + li {
    margin-top: 8px;
  }
}

/* COMPONENT: Ordered list removal
=============================================================================*/
.list--removal {
  list-style-type: decimal;
  li {
    display: list-item;
    list-style-type: decimal;
    margin-left: 20px;
    padding-right: 20px;
    position: relative;
    &:before {
      display: none;
    }
    .icon--delete {
      position: absolute;
      right: 0;
      top: 3px;
    }
  }
}

/* COMPONENT: Nested list
=============================================================================*/

.list--nobullet {
  > li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}

/* COMPONENT: Nested list
=============================================================================*/

.nested-list > li > ul {
  margin: 0;

  > li {
    padding: 0 0 0 20px;
    margin-bottom: 0;
    position: relative;
    &:before {
      @include icon-link-list-small-grey;
    }
  }
}

/* COMPONENT: List -- checked
=============================================================================*/
.list--checked {
  margin-bottom: 15px;
  li {
    padding-left: 32px;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjQzNzQgMi4zOTA5QzIxLjA5OTUgMi4xNDg3IDIwLjYzMjcgMi4yMjY4IDIwLjM5MDUgMi41NjMzTDcuOTkyNjEgMTkuODQ1TDMuNTMwMjEgMTUuMzgzMUMzLjIzNzIxIDE1LjA5MDEgMi43NjI2MSAxNS4wOTAxIDIuNDY5NzEgMTUuMzgzMUMyLjE3NjgxIDE1LjY3NjEgMi4xNzY3MSAxNi4xNTA3IDIuNDY5NzEgMTYuNDQzNkw3LjU1NjYxIDIxLjUzMDVDNy42OTc3MSAyMS42NzIxIDcuODg4NjEgMjEuNzUwMiA4LjA4NjkxIDIxLjc1MDJDOC4xMDc0MSAyMS43NTAyIDguMTI3NDEgMjEuNzQ5MiA4LjE0NzkxIDIxLjc0NzNDOC4zNjc2MSAyMS43Mjk3IDguNTY3ODEgMjEuNjE2NCA4LjY5NjIxIDIxLjQzNzdMMjEuNjA5MyAzLjQzNzNDMjEuODUwNSAzLjEwMDkgMjEuNzczMyAyLjYzMjEgMjEuNDM3NCAyLjM5MDlaIiBmaWxsPSIjZWE2NTBkIi8+Cjwvc3ZnPgo=');
    &:before {
      display: none;
    }
  }
}

/* COMPONENT: link-list
=============================================================================*/

%link-list,
.link-list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  form .data-table & {
    margin: 0;
  }
  > li {
    padding: 0;
    background: none;
    &:before {
      display: none;
    }
    p:first-child,
    > a {
      display: inline-block;
      position: relative;
      padding: 0 0 0 15px;
      background-image: none;
      text-decoration: none;
      &:before {
        @include icon-link-list-image;
      }
    }
    &.etactive {
      a {
        border-width: 5px 5px 0 5px;
        border-color: $nn_medium_orange transparent transparent transparent;
        color: $nn_medium_orange;
      }
      p {
        padding-left: 15px;
      }
    }
    ul {
      margin: 0 0 0 20px;
    }
    > a.orange {
      padding: 0;
      color: $nn_medium_orange;
      &:before {
        display: none;
      }
    }
  }
  .more {
    margin-top: 5px;
    padding-left: 30px;
  }
}

/* THEME: link-list--lined
=============================================================================*/

.link-list--lined {
  > li {
    border-top: 1px solid $nn_extra_light_grey;
    padding-top: 10px;
    padding-bottom: 10px;
    &:first-child {
      border-top: 0;
    }
    > a {
      @include font-size(16);
      @include font-bold;
      padding-left: 15px;
      &:before {
        top: 5px;
      }
    }
    .block--ultra-light-gray & {
      margin: 0 -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.link-list--collapsable {
  > li {
    &.active {
      background: $nn_ultra_light_grey;
    }
    > a {
      padding-left: 15px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        background: $nn_medium_grey;
        top: 8px;
        left: 0;
        border: 0;
      }
      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 10px;
        background: $nn_medium_grey;
        top: 4px;
        left: 4px;
      }
      &.collapse {
        &:after {
          display: none;
        }
      }
      &.empty {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 5px;
          width: 0;
          height: 0;
          background: transparent;
          border-style: solid;
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent $nn_medium_orange;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

/* THEME: list--dates
=============================================================================*/

.list--dates {
  margin-bottom: 20px; /* rvv: for advisor, not suitable in corrections*/
  li {
    padding: 0;
    &:before {
      display: none;
    }
    a {
      padding: 4px 0;
      text-decoration: none;
      display: table;

      &:hover {
        @include color($link_elements_Text_variants_Primary_styles_color);
      }

      .date {
        margin-right: 10px;
        @include color($link_elements_Text_variants_Primary_interactionStates_Hover_styles_color);
        display: table-cell;
      }

      &:first-child {
        a {
          padding-top: 0;
        }
      }

      &:last-child {
        a {
          padding-bottom: 0;
        }
      }
    }
  }
}

/* THEME: link-list--ordered
=============================================================================*/

.link-list--described {
  li {
    border-bottom: 1px solid $nn_light_grey;
    margin-bottom: 20px;
    padding: 0;
    a {
      padding-left: 0;
      color: $nn_medium_orange;
      &:before {
        display: none;
      }
    }
    p {
      margin: 0 0 20px 0;
    }
  }
}

/* THEME: link-list--secundarynav
=============================================================================*/

.link-list--secundarynav {
  margin: 0;
  li {
    padding: 0;
    margin-bottom: 10px;
    a {
      padding-left: 0;
      @include font-bold;
      @include font-size(14);
      color: $nn_medium_grey;
      &:before {
        display: none;
      }
      &:not(.is-active):hover {
        text-decoration: underline;
      }
      &.is-active {
        color: $nn_default_text_color;
        cursor: default;
      }
    }
  }
}

/* THEME: list--dates
=============================================================================*/

.list--dates {
  margin-bottom: 20px; /* rvv: for advisor, not suitable in corrections*/
  li {
    padding: 0;
    &:before {
      display: none;
    }
    a {
      padding: 4px 0;
      text-decoration: none;
      display: table;
      width: 100%;

      &:hover {
        color: $nn_subtle_text_color;
      }

      .date {
        padding-right: 15px;
        color: $nn_subtle_text_color;
        display: table-cell;
        width: 25%;
      }
    }

    &:first-child {
      a {
        padding-top: 0;
      }
    }

    &:last-child {
      a {
        padding-bottom: 0;
      }
    }
  }
}

/* THEME: link-list--ordered
=============================================================================*/

.link-list--sticker {
  @extend %link-list;
  margin-top: 15px;

  li {
    min-height: 40px;
    padding: 7px 0 10px 40px;
    h2 {
      @include font-size(16);
      color: $nn_extra_dark_grey;
      margin: 0;
      padding: 0;
    }
  }

  .sticker-clean--tiny {
    top: 0;
    left: 0;
    position: absolute;
  }
}

/* THEME: link-list--orange
=============================================================================*/

.link-list--orange {
  @extend %link-list;

  li {
    padding: 0 0 7px 0;
    background: none;
    &:before {
      display: none;
    }
    a {
      background-position: 0 5px;
      display: inline-block;
      padding-left: 15px;
      color: $nn_medium_orange;
      font-weight: bold;
      text-decoration: none;

      &:before {
        @include icon-link-list-image;
      }
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

/* THEME: link-list--inline
=============================================================================*/

.link-list-group--inline {
  position: relative;
  overflow: visible;
  margin: 0;
  padding: 12px 0 0;
}

.link-list--inline {
  @include clearfix;
  display: inline-block;
  margin: 0;
  padding: 0;

  li {
    background: none;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    padding: 0 0 14px 0;
    &:before {
      display: none;
    }
    > p {
      margin-bottom: 0;
      padding: 0 5px 0 0;
      color: $nn_dark_grey;
    }

    & + li > a {
      border-left: 1px solid $nn_extra_light_grey;
      padding: 0 5px 0 10px;
    }

    &:first-child > a {
      padding: 0 5px 0 0;
    }

    a {
      text-decoration: none;
      @include color($link_elements_Text_variants_Primary_styles_color);

      &:hover {
        @include color($link_elements_Text_variants_Secondary_interactionStates_Hover_styles_color);
      }
    }
  }
}

/* THEME: link-list--icons
=============================================================================*/

.link-list--icons {
  margin: 16px 15px 0;
  li {
    background: none;
    display: inline-block;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
}

/* THEME: link-list--overview
=============================================================================*/

.link-list--overview {
  @extend %link-list;
  margin-bottom: 0;

  li {
    width: 100%;
    padding: 15px 0;
    background: none;
    border-top: 1px solid $nn_light_grey;

    &:before {
      display: none;
    }
    a {
      background: none;
      display: block;
      text-decoration: none;
      &:before {
        display: none;
      }
    }
  }

  h4 {
    margin: 0;
    font-size: 1em;
    max-width: 78.209%;
  }
  h4.more {
    top: 0;
  }

  span {
    background-position: 0 5px;

    padding-left: 15px;

    display: block;
    margin-left: 78.209%; /* 525 / 660 */
  }
}

.is-responsive .link-list--overview li a {
  background-position: -10px -10px; /* there is a background image on the anchor. Just hide it */
}

/* THEME: Responsive link-list--overview Responsive
=============================================================================*/

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .link-list--overview {
      li {
        padding: 10px;

        a {
          background-position: 100% 50%;
        }
      }

      h4 {
        float: none;
        font-weight: normal;
        background: none;
        padding: 0 0 0 30px;
      }
    }
  }
}

/* THEME: link-list--download
=============================================================================*/

.link-list--download {
  li {
    margin-bottom: 16px;

    &.pdf {
      background: none;
      a {
        background: none;
        position: relative;
        padding-left: 25px;
        @include color($link_elements_Text_variants_Primary_styles_color);

        &:hover {
          @include color($link_elements_Text_variants_Primary_interactionStates_Hover_styles_color);
          text-decoration: underline;
          text-underline-offset: 4px;
          @include text-decoration-color($link_elements_Text_variants_Primary_interactionStates_Hover_styles_textDecorationColor);
          text-decoration-thickness: 2px;
        }

        &:before {
          @include icon;
          @include icon--file-20;
          content: '';
          width: 20px;
          height: 30px;
          position: absolute;
          top: -1px;
          border: 0;
        }
      }
      &:before {
        display: none;
      }
    }
  }
}

/* CONTEXT: Responsive link-list--download
=============================================================================*/
@media (max-width: $mobile_break_point) {
  .is-responsive .block--border .link-list--download {
    .pdf {
      text-decoration: none;
    }
    .pdf a {
      background-position: 11px 12px;
      /*padding-left: 40px;*/
    }
  }
}

/* THEME: link-list--responsive
=============================================================================*/
.link-list--responsive {
  @include clearfix;
}

/* THEME: link-list--submenu
=============================================================================*/
.link-list--submenu {
  @extend %link-list;

  li {
    padding: 4px 0;
    position: relative;
    &:before {
      display: none;
    }
    a {
      display: inline-block;
      padding-left: 15px;

      text-decoration: none;
      color: $nn_extra_dark_grey;
      @include font-bold;
      @include font-size(14);
      line-height: 20px;
      position: static;

      &:before {
        @include icon-link-list-image;
        top: 9px;
      }
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .link-list--submenu {
      margin: 0;

      li {
        margin: 0;
        padding: 0;

        a {
          padding: 15px 30px 15px 30px;
          display: block;
          font-size: 14px;
          background: none;
          color: $nn_medium_grey;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

/* CONTEXT: responsive mobile
=============================================================================*/

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .link-list--responsive {
      margin: 0 -15px;
      &#etfaqbox {
        margin: 0 -15px;
      }
      li {
        border-top: 1px solid $nn_light_grey;
        padding: 0;

        @include bg-gradient-vertical(#fefefe, #e7e7e7);

        .link-list--responsive-row-title,
        a {
          display: block;
          margin: 0 10px 0 0;
          padding: 11px 10px; /* 10 / 300 */
        }

        a {
          background-position: 100% 50%; /* 290 / 300 */
          &:before {
            display: none;
          }
        }

        .pdf {
          padding-left: 25px;
          text-decoration: underline;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            border-left: 0;
            left: 0;
            top: -5px;
            width: 20px;
            height: 25px;
            @include icon;
            @include icon--download-20;
          }
        }
      }
    }

    .contact .link-list--responsive li a {
      padding: 0 10px 11px;
    }

    .grid--block-xi .link-list--responsive li {
      background: none;
      filter: none;

      a {
        color: $nn_medium_orange;
        font-weight: bold;
      }
    }
  }
}

/* THEME: list--compare
=============================================================================*/
.list--compare {
  margin: 0 0 15px 0;

  li {
    background-image: none;
    margin: 0;
    padding: 5px 10px;

    > span,
    > strong {
      float: left;
    }
    &:before {
      display: none;
    }
  }

  li.odd,
  li:nth-child(odd) {
    background-color: $nn_ultra_light_grey;
  }

  p {
    font-size: 1em;
    margin: 0 0 0 65px;
    padding: 0;
  }
}

/* CONTROL: list-footer
*/

.list-footer {
  margin: 0 0 15px 0;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .list-footer {
      border-top: 1px solid $nn_light_grey;
      margin: 0 -10px 14px;
      padding: 10px 10px 0 10px;
    }
  }
}

/* THEME: form actions login
=============================================================================*/

.form-actions--login {
  .link-list li {
    height: 38px;

    a {
      margin: 2px 0 0 0; /* layout exception visual design loginpage */
    }
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive .form-actions--login .link-list li {
    padding: 0 0 7px 0;
    height: auto;
  }
}

@media (max-width: $tabmob_break_point) {
  .is-responsive .form-actions--login .link-list li {
    padding: 0 0 7px 0;

    a {
      margin: 2px 0 0 0; /* layout exception visual design loginpage */
    }
  }
}

/* THEME: nested-list--non-semantic
=============================================================================*/

.nested-list--non-semantic {
  .nested-list-level--beta {
    padding: 0 0 0 20px;
    margin-left: 16px;
    position: relative;
    &:before {
      @include icon-link-list-small-grey;
    }
  }
}
