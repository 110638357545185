@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';

/* CONTROL: Range
=============================================================================*/

/* POLYFILL: Range slider
=============================================================================*/
.rangeslider {
  height: 25px;
  @include box-sizing-border-box;

  &:before {
    background-color: $white;
    border-color: $nn_extra_light_grey;
    width: 100%;
  }
}

/* PART: Slider bar
=============================================================================*/
.rangeslider:before,
.rangeslider-fill {
  content: '';
  position: absolute;
  top: 12px;
  height: 10px;
  border-style: solid;
  border-width: 1px;
  @include box-sizing-border-box;
  @include border-radius(4px);
}

.rangeslider-fill {
  background-color: $nn_medium_orange;
  border-color: $white;
}

.rangeslider {
  position: relative;
}

/* STATUS: Disabled
=============================================================================*/
.rangeslider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

/* PART: Handle
=============================================================================*/
.rangeslider-handle {
  display: block;
  width: 25px;
  height: 25px;
  background-color: $nn_medium_orange;
  border: solid 2px $white;
  position: absolute;
  top: 5px;
  @include border-radius(50%);
  @include box-sizing-border-box;
}

input[type='range']:focus + .rangeslider .rangeslider-handle {
  background-color: $nn_dark_orange;
}

/* PART: Wrapper
=============================================================================*/
.range-wrapper {
  position: relative;
  margin-bottom: 20px;
}

/* PART: Min & max displays
=============================================================================*/
.range-min,
.range-max {
  @include font-size(14);
  color: $nn_medium_grey;
  position: absolute;
  top: 30px;
}

.range-max {
  right: 0;
}
.range-min {
  left: 0;
}

/* STATUS: Display current value
=============================================================================*/
.range-wrapper--valueshown {
  .rangeslider {
    margin-right: 127px;
  }

  .range-max {
    right: 127px;
  }

  .range-valuedisplay {
    background-color: $white;
    width: 105px;
  }

  .range-valuedisplay-prefix-input,
  .text-field.range-valuedisplay-prefix-input:disabled {
    padding-left: 20px;
  }

  .range-valuedisplay-prefix,
  .range-valuedisplay-suffix {
    position: relative;
  }
}

.range-valuedisplay-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;

  .text-field:disabled {
    border: 1px solid $nn_light_grey;
    background-color: #f1f1f1;
    padding-left: 10px;
  }

  .range-valuedisplay-prefix,
  .range-valuedisplay-suffix {
    position: absolute;
    display: inline-block;
    top: 7.5px;
    color: $nn_medium_grey;
  }

  .range-valuedisplay-prefix {
    left: 8px;
  }

  .range-valuedisplay-suffix {
    right: 8px;
  }
}
