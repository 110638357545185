@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';

.service {
  border-bottom: 1px solid $nn_ultra_light_grey;
  margin-bottom: 20px;
}

.service--minimal {
  margin-bottom: 13px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .service--minimal {
      margin-bottom: 5px;
    }
  }
}

@media (max-width: $desktop_break_point) and (min-width: $tablet_break_point) {
  .is-responsive {
    .service {
      #et_vatrigger.etsvccomp {
        .ettextfield input {
          width: 77px;
        }

        .ettextfield label {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .ettextfield .ethint {
          width: 83px;
        }

        input.etsubmit {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

.contact-list {
  margin-bottom: 20px;
  li {
    padding: 0;
    background: none;
    &:before {
      display: none;
    }

    a {
      position: relative;
      display: inline-block;
      margin-bottom: 18px;
      padding: 0 0 0 40px;
      background-position: 5px 5px;
      text-decoration: none;
      min-height: 18px;
      height: auto;
      vertical-align: middle;
      @include font-bold;
      @include font-size(16);
    }
  }

  .icon {
    position: absolute;
    left: 0;
    top: -7px;
    display: inline-block;
    width: 31px;
    height: 31px;
    margin-right: 15px;
    vertical-align: middle;
    @include icon;
  }
}

.contact-list.contact-list--twocol li {
  @media (min-width: $tablet_break_point) {
    float: left;
    width: 50%;
  }
}

.phone-number .icon {
  @include icon--call;
}

.call-me-now .icon {
  @include icon--callback;
}

.send-email .icon {
  @include icon--mail;
}

.download .icon {
  @include icon--download;
}

.customer-service .icon {
  @include icon--mouse;
}

.print .icon {
  @include icon--print;
}

.reviews-ratings .icon {
  @include icon--star;
}

.more-info .icon {
  @include icon--advisor;
}

.next .icon {
  @include icon--arrowright;
}

.contact--small {
  li {
    a {
      padding-left: 24px;
    }
  }
  .icon {
    top: -2px;
    display: inline-block;
    width: 21px;
    height: 21px;
    margin-right: 8px;
  }

  .phone-number .icon {
    @include icon--call-20;
  }
  .send-email .icon {
    @include icon--mail-20;
  }
  .next .icon {
    @include icon--arrowright-20;
  }
}

@media (max-width: $tablet_break_point) and (min-width: $mobile_break_point) {
  .is-responsive {
    .service,
    .service--minimal,
    .contact > p,
    .contact-list {
      width: 50%;
    }

    .service,
    .service--minimal,
    .contact > p {
      float: right;
    }

    .contact-list {
      float: left;
    }

    .contact {
      position: relative;

      > p {
        position: absolute;
        left: 50%;
        top: 0;
      }
    }

    .service,
    .service--minimal {
      & + .contact p {
        top: 60px;
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .contact {
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }

    .contact-list {
      margin-top: 20px;
    }

    .contact-list.link-list--responsive {
      li {
        position: relative;
        background: none;
        filter: none;

        a {
          margin-bottom: 0;
        }
      }
      .icon {
        vertical-align: baseline;
        position: relative;
        top: 8px;
      }
    }
  }
}
