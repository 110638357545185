@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import "../../../global/css/mixins";
@import "../../../global/css/component-design-token-maps";

/* COMPONENT Masthead
=============================================================================*/
$masthead_height: 240px;

.masthead {
  @include clearfix;

  position: relative;
  width: auto;
  margin-left: -30px;
  margin-bottom: 30px;

}

.masthead-image {
  @include border-radius-sep(0, 5px, 5px, 0);
  @include box-sizing-border-box;

  float: left;
  width: 45%;
  height: $masthead_height;
  overflow: hidden;
}

.masthead-content {
  @include box-sizing-border-box;
  float: left;
  width: 55%;
  max-height: 90%;
  padding-left: 30px;
  @media (min-width: $mobile_break_point) {
    .block {
      margin: 0;
    }
  }
}

/* Responsive Masthead
=============================================================================*/

.masthead--responsive {
  position: initial;
  display: table;
  margin-bottom: 30px;
  height: auto;

  .masthead-image {
    float: none;
    display: table-cell;
    height: initial;

    position: relative;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    img {
      display: none;
    }
  }

  .masthead-content {
    float: none;
    display: table-cell;
    height: initial;

    .block {
      margin-bottom: 0;
      height: auto;
    }
  }
}

/* Breakpoints
=============================================================================*/

@media (min-width: $mobile_break_point) and (max-width: $tabmob_break_point) {
  .is-responsive {
    .masthead {
      margin: 0px -30px;
      display: block;

      .masthead-image,
      .masthead-content {
        @include border-radius(0px);

        display: block;
        width: 100%;
        height: auto;
        padding: 0;

        .block {
          height: auto;
        }
      }

      .masthead-image img {
        width: 100%;
      }

      .masthead-content {
        padding: 0 30px;
        margin: 20px 0;
      }
    }
    .masthead--responsive .masthead-image {
      height: 300px;
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .masthead {
      display: block;
      margin: 0 -15px;

      .masthead-image img {
        width: 100%;
      }

      .masthead-image,
      .masthead-content {
        @include border-radius(0px);

        display: block;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
      }
    }

    .masthead-content {
      .block {
        position: static;
        margin-bottom: 15px;
        height: auto;
      }

      .block--border {
        border: 0;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .masthead--responsive .masthead-image {
      height: 300px;
    }
  }
}

/* COMPONENT: Masthead // NEW VERSION
=============================================================================*/
.masthead--big {
  margin: -31px -30px 30px -32px;
  padding: 0;
  position: relative;
  width: 100%;
  min-width: 280px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  @include border-bottom-color($divider_elements_Divider_styles_borderColor);
  height: 440px;

  .is-loggedin & {
    margin-top: -61px;
  }

  .masthead-wrapper--image {
    position: absolute;
    width: calc(100vw - 15px);
    max-width: 1280px;
    min-width: 280px;
    height: 440px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    display: none;
  }

  .masthead-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: 960px;
    height: 100%;
  }

  .masthead-image,
  .masthead-image img {
    width: 100%;
  }

  .masthead-content {
    @include background-color($hero_elements_ContentContainer_styles_backgroundColor);
    display: block;
    position: absolute;
    top: 50%;
    left: 30px;
    padding: 30px 30px 15px 30px;
    width: auto;
    min-width: 250px;
    @include transform(translateY(-50%));
    @include border-radius(4px);
    @media (min-width: $tablet_break_point) {
      max-width: 520px;
    }
    h1,
    h2 {
      margin-bottom: 10px;
    }
  }
  &.has-sticker h1,
  &.has-sticker h2 {
    padding-right: 90px;
  }
  @media (max-width: $mobile_break_point) {
    &.has-sticker h1.hyphenate,
    &.has-sticker h2.hyphenate {
      padding-right: 90px;
    }
  }
  @media (min-width: $tablet_break_point) {
    &.has-sticker h1,
    &.has-sticker h2 {
      padding-right: 90px;
    }
  }

  .sticker-clean--rebate {
    position: absolute;
    bottom: 30px;
    right: -20px;
  }

  .block .sticker-clean--rebate {
    bottom: -20px;
    right: -40px;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive .masthead--big {
    margin: -15px -15px 15px -15px;
  }
}

@media (min-width: $mobile_break_point) and (max-width: $tablet_break_point) {
  .is-responsive .masthead--big {
    margin: -30px -32px 15px;
  }
  .has-sticker:not(:has(.image-tablet)) {
    margin-top: 10px;
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive .masthead--big {
    width: auto;
    height: auto;
    .masthead-wrapper--image {
      width: 100%;
      height: 56.25vw;
      min-height: 180px;
      position: static;
    }
  }
  .masthead-wrapper--content {
    position: relative;
    .masthead-content {
      position: static;
      padding: 15px 15px 10px 15px;
      height: auto;
      width: 100%;
      float: none;
      @include transform(none);
      .block {
        margin-bottom: 0;
      }
    }
  }
  .sticker-clean--rebate {
    top: 160px;
    right: 20px;
    bottom: auto;
  }
}


/* COMPONENT: Sticker 
=============================================================================*/

.block .sticker-clean {
  position: absolute;
  top: -55px;
  right: -50px;

    @media (min-width: $mobile_break_point) and (max-width: $tablet_break_point) {
      top: -50px;
      right: 22px;
    }

    @media (max-width: $mobile_break_point) {
      top: -40px;
      right: 20px;
    }
}
