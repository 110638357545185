@use "sass:math";

@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../../icon/_c-icon.variables.scss';
@import '../../_helpers/component-design-token-maps';

.tabbed-component {
	margin-bottom: 30px;

	&.accordion {
		.tab-button {
			display: none;
		}
	}
}

.tab-header {
	width: 100%;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	@include border-color($tabs_elements_Container_styles_borderColor);
	margin-bottom: 20px;

	.tabbed-component.accordion:not(.tabbed-component--display-tab-header) & {
		display: none;
	}

	.tabbed-component--display-tab-header & {
		opacity: 0;
		position: absolute;
	}
}

.tab-list {

	display: table;
	margin: 0;

	/* PART: Tab links -> opens the corresponding tab panel */
	.tab {
		padding: 0 0 0 24px;
		display: table-cell;
		box-sizing: border-box;
		margin-bottom:0;
		&:first-child {
			padding-left: 0;
		}

		&:before {
			display:none;
		}

		&.active {
			a {
				border-bottom-width: 2px;
				border-bottom-style: solid;
				@include border-color($tab_elements_TabIndicator_dataState_Selected_styles_backgroundColor);
				@include color($tab_elements_Label_dataState_Selected_styles_color);
			}
		}

		a {
			@include font-bold;
			@include font-size(16);
      padding: 12px 0;
			text-decoration: none;
			display: inline-block;
			outline: 0;
			@include color($tab_elements_Label_styles_color); 

      .opt-out-nitti-grotesk-font & {
        @include font-size(20);
        padding: 13px 0 17px;
        margin-bottom: -3px;
      }
		}
	}
}

/* PART: Tab panels -> has the content */
.tab {
	&.active {
		display: block !important;
	}

	.tabbed-title {
		display: none;
	}
}

.tabbed-component--items-2 {
	.tab-list {
		.tab {
			width: (100*0.5)+0%;
		}
	}
}

.tabbed-component--items-3 {
	.tab-list {
		.tab {
			width: math.div(100, 3)+0%;
		}
	}
}

.tabbed-component--items-4 {
	.tab-list {
		.tab {
			width: (100*0.25)+0%;
		}
	}
}

.tabbed-component--items-5 {
	.tab-list {
		.tab {
			width: (100*0.2)+0%;
		}
	}
}

.tabbed-component--items-6 {
	.tab-list {
		.tab {
			width: math.div(100, 6)+0%;
		}
	}
}


/*
	PART : Tab in accordion mode */

.tabbed-component.accordion {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	@include border-color($tabs_elements_Container_styles_borderColor);
}

/*
	PART : Tab Button accordion */
.tab-button {

	display: none;

	a {
		@include font-bold;
		display: flex;
    justify-content: space-between;
		line-height: 1.5em;
		text-decoration: none;
		padding: 12px 0;
		border-top-width: 1px;
		border-top-style: solid;
		@include border-color($tabs_elements_Container_styles_borderColor);
		position: relative;
		
		&:after {
			content: $fonticon-content--arrow-bottom;
      font-family: 'nn-iconfont';
      @include color($iconButton_elements_Icon_styles_color);
		}
	}

	.tabbed-component.accordion & {
		display: block;
	}

	&.active {

		border-color: transparent;

		a {
			border-top-width: 2px;

			&:after {
        content: $fonticon-content--arrow-top;
			}
		}
	}
}

.wmeditview {
	.tabbed-component > div.tab {
		display:none !important;
	}
	.tabbed-component > div.tab.active {
		display:block !important;
	}
}

/* THEME: Advisor
=============================================================================*/
.tabbed-component--advisor {
	.tab-header {
		border-bottom-width:2px;
	}
	.tab-list {


		.tab {
			padding-left: 0;
			overflow:hidden;

      a {
				padding-right: 15px;
				padding-left: 15px;
				border-style: solid;
				border-width: 2px 2px 0 2px;
				border-color: transparent;
				@include background-color($card_variants_Default_styles_backgroundColor);
				border-radius: 8px 8px 0 0;
				margin-right:2px;
				margin-bottom:-2px;
				@include color($tab_elements_Label_styles_color); 
			}

			&.active {
				overflow:visible;
				a {
					@include background-color($card_variants_Tinted_styles_backgroundColor);
					@include border-color($tabs_elements_Container_styles_borderColor);
					border-right-width:2px;
					border-left-width:2px;
					border-radius: 8px 8px 0 0;
					@include color($tab_elements_Label_dataState_Selected_styles_color);
				}
			}
		}
	}

	.tab.active {
		display: block;
	}
}