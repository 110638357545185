@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../../_helpers/component-design-token-maps';

.c-form-checkbox:not(.c-form-checkbox--switch) {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;

  + label,
		+ .c-form-label /* left for legacy */ {
    background: url("./c-form-checkbox/c-form-checkbox.svg") no-repeat left top;
    background-size: 24px;
    padding: 0 0 2px 40px;
    margin-bottom: 11px;
    display: inline-block;
    cursor: pointer;
  }

  &:focus-visible + label,
		&:focus-visible + .c-form-label /* left for legacy */ {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:checked +label,
		&:checked + .c-form-label /* left for legacy */ {
    background: url("./c-form-checkbox/c-form-checkbox-checked.svg") no-repeat
      left top;
    background-size: 24px;
  }

  &:disabled:checked +label,
		&:disabled:checked + .c-form-label /* left for legacy */ {
    background: url("./c-form-checkbox/c-form-checkbox-disabled-checked.svg")
      no-repeat left top;
    background-size: 24px;
  }

  &:disabled +label,
		&:disabled + .c-form-label /* left for legacy */ {
    background: url("./c-form-checkbox/c-form-checkbox-disabled.svg") no-repeat
      left top;
    background-size: 24px;
    cursor: default;
  }
}

// icon variant

.c-form-checkbox--icon + .c-form-label {
  background: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  padding: 0;
  margin-bottom: 11px;
  user-select: none;
}

.c-form-checkbox--icon:checked + .c-form-label,
.c-form-checkbox--icon:disabled + .c-form-label,
.c-form-checkbox--icon:disabled:checked + .c-form-label {
  background: none;
}

.c-form-checkbox--icon + .c-form-label .c-icon {
  margin-right: 4px;
  @include color($checkbox_elements_Box_styles_accentColor);
  transition: color 0.3s, text-shadow 0.3s;
  font-size: 24px;
}

.c-form-checkbox--icon:checked + .c-form-label .c-icon {
  @include color($checkbox_dataStates_Checked_elements_Box_styles_accentColor);

}

.c-form-checkbox--icon[disabled] + .c-form-label {
  cursor: default;
  opacity: 0.5;
}

// switch variant

.c-form-checkbox--switch {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;

  + .c-form-label {
    background: none;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    margin-bottom: 11px;
    user-select: none;
  }
}

.c-form-label__switch {
  display: inline-block;
  vertical-align: middle;
  width: 46px;
  height: 25px;
  border-radius: 12px;
  box-sizing: border-box;
  @include background-color($switch_elements_Container_styles_backgroundColor);
  overflow: hidden;
}

.c-form-label__switch:after {
  content: "";
  float: left;
  margin: 2px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  @include background-color($switch_elements_Knob_styles_backgroundColor);
}

.c-form-checkbox--switch:checked + .c-form-label .c-form-label__switch {
  @include background-color($switch_dataStates_On_elements_Container_styles_backgroundColor);
}

.c-form-checkbox--switch:checked + .c-form-label .c-form-label__switch:after {
  float: right;
}

.c-form-checkbox--switch[disabled] + .c-form-label .c-form-label__switch {
  opacity: 0.5;
  cursor: default;
  @include background-color($switch_elements_Container_styles_backgroundColor);
}

.c-form-checkbox--switch[disabled] + .c-form-label .c-form-label__switch:after {
  box-shadow: none;
}
