@import '../_helpers/color';
@import '../_helpers/sizes';
@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';

.cards-container--homepage {
  @media (min-width: $size_l) {
    margin-top: -70px;
    position: relative;
  }
}

.card-components--container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  padding: 0 0 32px 0;

  a {
    @include color($link_elements_Text_variants_Secondary_styles_color);
    text-decoration: none; /* no underline */
  }

  .card-component--item-wraper {
    @include background-color($card_variants_Attentional_styles_backgroundColor);
    margin: 2px 0;
    flex: 1 1 0;
    min-width: 238px;
    flex-basis: 31%;
    height: 75px;
    border-radius: 4px;
    @include box-shadow($card_variants_Attentional_styles_boxShadow);
    border-width: 1px;
    border-style: solid;
    @include border-color($card_variants_Attentional_styles_borderColor);
    display: flex;

    .card-component--item {
      width: 100%;
      display: flex;
      align-items: center;
      .card-icon {
        margin: 0 14px 0 19px;
      }

      .icon-fallback {
        width: 32px;
        height: 32px;
        margin: 0 14px 0 19px;
      }
      
      span.card-arrow--right {
        width: 21px;
        margin-left: 14px;
        transition: all 0.2s ease-out;
        transform: rotate(90deg);
        font-size: 1.125rem;
        @include color($iconButton_elements_Icon_styles_color);
      }

      &:hover {
        .card-arrow--right {
          margin-left: 18px;
        }
      }
    }
  }
}
