.c-chart-bar--hor {
	height: 24px;

	&:before {
		content: '';
		display: block;
		height: 24px;
		background: repeating-linear-gradient(
			90deg,
			transparent,
			transparent 24px,
			$white 24px,
			$white 25px
		  );
	}
}