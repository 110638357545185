$sizeToValueMap: (
  '5': 8px,
  '10': 12px,
  '15': 16px,
  '20': 20px,
  '25': 24px,
  '30': 32px,
  '40': 40px,
  '80': 80px,
);
$directions: 'top', 'right', 'bottom', 'left';


.u-padding {
  padding: 16px;
}

@each $size, $value in $sizeToValueMap {
  .u-padding--#{$size} {
    padding: #{$value};
  }
}

@each $direction in $directions {
  .u-padding-#{$direction} {
    padding-#{$direction}: 16px;
  }
  @each $size, $value in $sizeToValueMap {
    .u-padding-#{$direction}--#{$size} {
      padding-#{$direction}: #{$value};
    }
  }
}

.u-padding--none {
  padding: 0 !important;
}
@each $direction in $directions {
  .u-padding-#{$direction}--none {
    padding-#{$direction}: 0 !important;
  }
}