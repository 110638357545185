/* PART: content-section
=============================================================================*/
@import '../../../global/css/breakpoints';

.content-section {
	position: relative;
	padding-top: 30px;
	padding-bottom: 30px;
	.is-loggedin & {
		padding-top:60px;
	}
}

.content-section-bt {
	margin-top: 16px;
}

@media (max-width: $mobile_break_point) {
	.is-responsive {
		.content-section {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}