@import '../../../global/css/breakpoints';

.image--responsive {
  max-width: 100%;
  margin-bottom: 15px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive .image--responsive {
    margin-bottom: 10px;
  }
}

.image--submenu {
  max-width: 100%;
  margin: 0;
}

.image--wau {
  @extend .image--responsive;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .image--wau {
      width: auto;
      height: 35px;
      float: left;
      margin: -5px 5px 0 0;
    }
  }
}
