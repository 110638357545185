/* COMPONENT: Upload field
=============================================================================*/
.upload-field-wrapper {
	position: relative;
	display: inline-block;
}

/* PART: File input
=============================================================================*/
.upload-field-input {
	position: absolute;
	opacity: 0;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.upload-field-button-select {
	position: relative;
	z-index: 2;
}

/* STATUS: Default
=============================================================================*/
.upload-field-filename-text,
.upload-field-button-delete,
.upload-field-loading,
.upload-field-error,
.upload-field-warning {
	display: none;
}

/* STATUS: Loading
=============================================================================*/
.upload-field--loading {
	.upload-field-input,
	.upload-field-filename-field,
	.upload-field-filename-text,
	.upload-field-button-select {
		display: none;
	}
	.upload-field-loading {
		display: block;
	}
}

/* STATUS: Complete
=============================================================================*/
.upload-field--complete {
	.upload-field-input,
	.upload-field-filename-field,
	.upload-field-button-select,
	.upload-field-loading {
		display: none;
	}
	.upload-field-filename-text,
	.upload-field-button-delete {
		display: inline-block;
	}
}

/* STATUS: Error
=============================================================================*/
.upload-field--error {
	.upload-field-error {
		margin-top: 1em;
		display: block;

		p {
			font-size: 1em;
		}
	}
}

/* STATUS: Warning
=============================================================================*/
.upload-field--warning {
	.upload-field-warning {
		margin-top: 1em;
		display: block;

		p {
			font-size: 1em;
		}
	}
}



/* PART: Loading container
=============================================================================*/
.upload-field-loading {
	background: transparent url('./uploadfield/img/upload-field-loader.gif') top left no-repeat;
	padding-top: 14px;
}

/* PART: Loading message
=============================================================================*/
.upload-field-loading-message {
	width: 288px;
}

/* PART: Filename textual representation
=============================================================================*/
.upload-field-filename-text {
	width: 272px;
	padding-top: 10px;
}

/* PART: Delete button
=============================================================================*/
.upload-field-button-delete {
	background: transparent url('./uploadfield/img/close-button.png') 0 4px no-repeat;
	width: 16px;
	height: 16px;
	margin: 0;
	overflow: hidden;
	text-indent: 100%;
	border: 0;
	&:hover {
		background-color:transparent;
	}
}

/* PART: Upload percentage
=============================================================================*/
.upload-field-loading-percentage {
	display: inline-block;
	position: absolute;
	top: 14px;
	right: 0;
}