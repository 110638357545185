@import 'o-flex-variables';

.o-flex {
  display: flex;
  align-items: center;
  margin-left: -($flex-gutter-width*0.5);
  margin-right: -($flex-gutter-width*0.5);

  &__col {
    flex: 1 1 auto;
    padding-left: ($flex-gutter-width*0.5);
    padding-right: ($flex-gutter-width*0.5);

    &--auto {
      flex: 0 0 auto;
    }
  }

  &__col--icon {
      padding-left: 0;
  }

  &___col--icon-radio {
    padding-left: 0;
    align-self: baseline;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--no-margin {
    margin: 0;
  }
}
