//class for detecting touchstart and touchend
$window-touched : "touchevents";

// z-index layers
$modalLayer: 1100;
$focusElement: 1010;
$focusElementOverlay: 1009;
$mainNavigationLayer: 1000;
$mainNavigationFixedBanner: 1001;
$elementOverlay: 50;
