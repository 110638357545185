@import '../../../global/css/breakpoints';
@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: Breadcrumb
=============================================================================*/

.breadcrumb-list {
	@include clearfix;
	list-style: none;
	padding: 0 0 15px 0;
	margin: 0;
	li {
		padding: 0;
		display: inline-block;
    @include color($breadcrumbs_elements_Link_styles_color);
		&:before {
			display: none;
		}
		a {
			text-decoration: none;
			display: inline-block;
			padding-right:5px;
      @include color($link_elements_Text_variants_SecondaryStandAlone_styles_color);
			&:after {
				content:"/";
				margin-left:5px;
				@include color($breadcrumbs_elements_Container_styles_color);

        // To not take over text decoration underline on hover
        display: inline-block;
			}
			&.no-trailing-slash:after {
				content: "";
			}
			&:hover {
        text-decoration: underline;
			}
		}
		&:last-child {
			a {
				&:after {
					content:"";
				}
			}
		}
	}
}


/* PART: link-list--responsive
=============================================================================*/


@media (max-width: $mobile_break_point) {

	.is-responsive {

		.breadcrumb-list {
			margin:10px 0 20px;
			li {
				display: inline-block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}

