@import '../_helpers/mixins';
@import '../_helpers/color';
@import '../main-navigation/mixins';
@import '../_helpers/component-design-token-maps';

.c-table {
  border-spacing: 0;
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 20px;
	//TODO remove when advisor legacy table border is removed
	border: 0;
}

.c-table__header__cell {
	border: 0;
	padding: 7px 5px 8px;
	@include font-bold;
	vertical-align: bottom;
  text-align: left;
  box-sizing: border-box;
}

.c-table__body {
	border-bottom: 1px solid $nn_light_grey;
}

.c-table__body__cell,
.c-table__body__hcell {
	padding: 7px 5px 8px;
	@include font-normal;
	vertical-align: top;
	text-align: left;
	border: 0;//TODO remove when advisor legacy is removed
	border-top: 1px solid $nn_light_grey;
  transition: border-color 0.5s;
  box-sizing: border-box;
}

.c-table__body__row--hover {
	transition: background-color 0.2s ease;
	cursor: pointer;
}

//hover effect
.c-table__body__row--hover:hover,
.c-table__body__row--hover:focus{
	@include background-color($table_elements_TableHeaderCell_interactionStates_Hover_styles_backgroundColor);
}

// FIXME: If possible, remove deprecated styling for .active;

// Subrows, also used for expander tables.
.c-table__body__subrow .c-table__body__cell{
	border-top: 0;
}

.c-table__body__subrow,
.c-table__body__row.active,
.c-table__body__row.is-expanded,
.c-table__body__row.is-expanding {
	.c-table__body__cell,
	.c-table__body__hcell {
		@include background-color($table_elements_TableHeaderCell_interactionStates_Hover_styles_backgroundColor);
	}
}

.c-table__body__row {
	&:first-child,
	&.active,
	&.is-expanded,
	&.is-expanding {
		.c-table__body__cell,
		.c-table__body__hcell {
			border-top-width: 2px;
			border-top-style: solid;
			@include border-color($table_elements_TableHeader_styles_borderColor);
		}
	}
}

//when we want a complete cell to be clickable us this on de <td>
.c-table__body__cell__linkblock {
	position: relative;
}

//when we want a complete cell to be clickable us this on de a href
.c-table__body__cell__link {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	background-color: transparent;
	color: inherit;
	text-decoration: none;
}

// Variant: grid table
.c-table--grid {
	table-layout: fixed;
}

// Variant: nowrap
.c-table--nowrap {
	tr {
		white-space: nowrap;
	}
}

// Variant: borders on all cells
.c-table--bordered {
	.c-table__header__cell,
	.c-table__body__cell {
		border-width: 0 1px 1px 0;
		border-style: solid;
		@include border-color($table_elements_TableCell_styles_borderColor);
	}
	.c-table__body__cell:first-child {
		border-left-width: 1px;
	}
	.c-table__header__cell {
		border-top-width: 1px;
	}
}

// Variant: extra padding
.c-table--medium {
	.c-table__header__cell,
	.c-table__body__cell,
	.c-table__body__hcell {
		padding: 12px 10px;

		&:first-child {
			padding-left: 5px;
		}
	}
}

.c-table--large {
	.c-table__header__cell,
	.c-table__body__cell,
	.c-table__body__hcell {
		padding: 20px 15px;
	}
}

// Variant: expander table
.c-table--expander {
	.c-table__body__row--hover {
		cursor: pointer;
	}

	.c-table__body__subrow {
		display: none;

		&.is-expanded,
		&.is-expanding {
			display: table-row;
		}
	}

	.c-table__body__cell--toggle {
		width: 15px;

		@extend .c-icon;
		@extend .c-icon--angle-right;
		@include color($iconButton_elements_Icon_styles_color);
		display: table-cell;

		&::before {
			display: inline-block;
			transition: transform 0.5s ease-out;
			transform-origin: 50% calc(50% - 1px);
			transform: rotate(90deg);
			font-size: 22px;
		}
	}

	.c-table__body__row {
		&.is-expanded,
		&.is-expanding {
			.c-table__body__cell--toggle {
				&::before {
					transform: rotate(-90deg);
				}
			}
		}
	}
}

// Variant: status overview
.c-table--status_overview {
	.c-table__body__cell--text {
		width: 70%;
	}
	.c-table__body__cell--icon {
		width: 28px;
		max-width: 28px;
	}

	// NOTE: THIS WRAPPER IS NEEDED AS LONG AS WE SUPPORT THIS is-responsive setup...
	@media (max-width: $size-s) {
		.is-responsive & .c-table__body__cell--text {
			width: 90%;
		}
		.is-responsive & .c-table__body__cell--icon {
			width: auto;
			max-width: none;
		}
	}
}

//sizing & text-aligining a table cell width an icon-s
.c-table__body__cell--icon-s {
 	width: 28px;
 	max-width: 28px;
}

.c-table__body__cell--icon-s__icon {
	margin-top: -5px;
}

// Variant: no borders
.c-table--no-border {
  .c-table__body {
      border: 0;
  }

  .c-table__body__row:first-child {
      .c-table__body__cell
      {
          border: 0;
      }
  }

  .c-table__body__row:first-child {
      .c-table__body__hcell
      {
        border: 0;
      }
  }

  .c-table__body__cell,
  .c-table__body__hcell {
      border: 0;
  }
}

.c-table--stickyheader {
	-webkit-overflow-scrolling: touch;
  overflow-y: auto;

  .c-table__header {
		position: relative;
		overflow: visible;
  }
}

.c-table-shadowHeader {
  position: fixed;
  background: white;
  border-bottom: 2px solid $nn_medium_orange;
  z-index: 2;
  overflow: hidden;
  @include menu-slide(top);

  .c-table__header__row {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .c-table__header__cell {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
  }
}

.c-table--stickycolumn {
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
  border-collapse: initial;

	.sticky-first-cell {
		position:absolute;
		left:0;
		top:auto;
		@include background-color($table_elements_TableCell_styles_backgroundColor);
    z-index: 1;
    box-sizing: border-box;
  }
}

.c-scrollablecontainer::-webkit-scrollbar,
.c-scrollablewrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 3px;
}
.c-scrollablecontainer::-webkit-scrollbar-thumb,
.c-scrollablewrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
