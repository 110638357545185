//equal columns
.c-flexblock {
    //TODO global border-box
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
	justify-content: space-around;
	justify-content: center;
    flex-wrap: wrap;
    align-content: space-between;
    padding: 0;

    &--grey {
        @extend .u-color-background--ultra-light-grey;
        @extend .u-border-radius;
        padding: 10px 0;
    }
    &__content {
        flex: 1 0 25%;
        align-self: auto;
        padding: 0 10px 0 0;
       	box-sizing: border-box;
        flex-wrap: wrap;

		&--padding {
			padding-left: 20px;
		}
		&--bordered {
            border-left: 1px solid $nn_extra_light_grey;
            padding-left: 20px;
        }
        &--bordered--white {
            border-left: 1px solid $white;
            padding-left: 20px;
        }
    }

}

// THIS WRAPPER IS NEEDED AS LONG AS WE SUPPORT THIS is-responsive setup...
.is-responsive {
    @media (max-width: $size_l) {
        .c-flexblock {
            flex-direction: column;
            flex-wrap: wrap;
            -ms-flex-flow: row wrap;
            padding: 0;

            &__content {
                flex: 0 1 auto;
                padding: 10px 10px 10px 0;
                //ie10 fix for overflowing text
                display: inline-block;
               	width: 100%;

                &--padding {
                    padding-left: 10px;
                }

                &--bordered {
                    border-left: 0;
                    border-top: 1px solid $nn_extra_light_grey;
                    padding-left: 0;
                }

                &--bordered--white {
                    border-left: 0;
                    border-top: 1px solid $white;
                    padding-left: 10px;
                }
            }
        }
    }
}

//just some basic support for ie9
.ie9 .c-flexblock__content {
    min-width: 30%;
    max-width: 50%;
    float: left;
}
