@import '../_helpers/color';
@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';

$notification-border-thin: 1px;

.c-notification {
  box-sizing: border-box;
  position: relative;
  z-index: 0; // This is not superfluous, so don't remove it!
  padding: 12px 16px 12px 16px;
  margin-top: 0.625rem;
  background-color: transparent;

  [data-component="ColoredText"] {
    margin-top: -5px;

    .light,
    .medium,
    .dark {
      font-size: 1rem;
      @include color($paragraph_elements_Paragraph_styles_color);
    }
  }

  [data-component="ColoredText"],
  .c-title-h3,
  p {
    margin-bottom: 0.25rem;
  }

  &__close-btn {
    position: absolute;
    z-index: 10;
    right: 17px;
    top: 21px;
    font-size: .8rem;
    padding: 6px;
    text-decoration: none;
    @include color($icon_elements_Icon_styles_color);

		&:hover {
      @include color($iconButton_elements_Icon_styles_color);
		}
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    @include background-color($card_variants_Default_styles_backgroundColor);
    @include color($attentionBlock_elements_Container_styles_color);
  }

  .c-modal__content & {
    border-width: 0;

    &:first-child {
      margin-top: 1.4rem;
    }

  }

  &,
  &--info,
  &--information,// FIME: Why are there two variants for this?!?......
  &--success,
  &--error,
  &--warning {
    padding-left: 42px;

    &:before {
      font-family: 'nn-iconfont';
      font-size: 24px;
      line-height: 24px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding-left: 8px;
      padding-top: 12px;
      border-left-style: solid;
      border-left-width: 4px;
    }
  }

  &:before {
    content: $fonticon-content--pict-info;
    @include border-color($attentionBlock_variants_Informative_elements_Bar_styles_backgroundColor);
    @include color($attentionBlock_variants_Informative_elements_Icon_styles_color);
  }

  &:after {
    @include background-color($attentionBlock_variants_Informative_elements_Container_styles_backgroundColor);
  }

  &--info {  
    &:before {  
      content: $fonticon-content--pict-info;  
      @include color($attentionBlock_variants_Informative_elements_Icon_styles_color);  
      @include border-color($attentionBlock_variants_Informative_elements_Bar_styles_backgroundColor);  
    }  
    &:after {  
      @include background-color($attentionBlock_variants_Informative_elements_Container_styles_backgroundColor);  
    }  
  }  
    
  &--information {  
    &:before {  
      content: $fonticon-content--pict-info;  
      @include color($attentionBlock_variants_Informative_elements_Icon_styles_color);  
      @include border-color($attentionBlock_variants_Informative_elements_Bar_styles_backgroundColor);  
    }  
    &:after {  
      @include background-color($attentionBlock_variants_Informative_elements_Container_styles_backgroundColor);  
    }  
  }  
    
  &--success {  
    &:before {  
      content: $fonticon-content--pict-success;  
      @include color($attentionBlock_variants_Success_elements_Icon_styles_color);  
      @include border-color($attentionBlock_variants_Success_elements_Bar_styles_backgroundColor);  
    }  
    &:after {  
      @include background-color($attentionBlock_variants_Success_elements_Container_styles_backgroundColor);  
    }  
  }  
    
  &--warning {  
    &:before {  
      content: $fonticon-content--pict-warning;  
      @include color($attentionBlock_variants_Warning_elements_Icon_styles_color);  
      @include border-color($attentionBlock_variants_Warning_elements_Bar_styles_backgroundColor);  
    }  

    &:after {  
      @include background-color($attentionBlock_variants_Warning_elements_Container_styles_backgroundColor);  
    }  
  }  
    
  &--error {  
    &:before {  
      content: $fonticon-content--pict-warning;  
      @include color($attentionBlock_variants_Error_elements_Icon_styles_color);  
      @include border-color($attentionBlock_variants_Error_elements_Bar_styles_backgroundColor);  
    }  
    &:after {  
      @include background-color($attentionBlock_variants_Error_elements_Container_styles_backgroundColor);  
    }  
  }  

  &--closable {
    padding-right: 46px;
  }

}

.c-notification-list {
  .c-notification {
    transition: all 0.5s;
  }
  .c-notification--hide {
    opacity: 0;
  }
}
