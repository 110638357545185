$mobile_break_point: 479px;
$mobile_min_width: 320px;
$tabmob_break_point: 610px;
$tablet_break_point: 820px;
$desktop_break_point: 1184px;

//screen sizes
$tablet: 768px;
$desktop-small: 900px;
$desktop-medium: 1160px;
$desktop-large: 1430px;