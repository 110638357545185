.c-cmscontent {
    padding: 30px;

    &--multimedia {
        max-width: 520px;
        background-color: $nn_ultra_light_grey;
    }

    &__poster{
        display: block;
        position: relative;
        text-align: center;
        margin-bottom: 10px;

        &__play {
            display: block;
            position: absolute;
            top: calc(50% - (70px / 2));
            left: calc(50% - (70px / 2));
            width: 70px;
            height: 70px;
            color: $nn-medium-orange;
        }

        &__still {
            display: block;
            width: 100%;
        }

        &:hover &__play,
        &:active &__play,
        &:focus &__play{
            color: $nn_dark_orange;
        }
    }

    &__dialog {
        /* If no title is given, the close button overlaps the embed. */
        .u-embed-container:first-child{
            margin-top: 30px;
        }
    }
}
