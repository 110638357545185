@import '../_helpers/mixins';

.u-font {
	@include font-normal;
}

.u-font-bold {
	@include font-bold;
}

// FONT SIZES
.u-font-size-xs {@include font-size(12);}
.u-font-size-s {@include font-size(14);}
.u-font-size-m {@include font-size(16);}
.u-font-size-l {@include font-size(18);}
.u-font-size-xl {@include font-size(20);}
.u-font-size-xxl {
	@media  (max-width: $size_xs) {
		@include font-size(18);
	}
	@include font-size(24);
}

.u-font-size-xxxl {
	@media(max-width: $size_xs) {
		@include font-size(20);
	}
	@include font-size(35);}

.u-font-size-xxxxl {
	@media (max-width: $size_xs){
		@include font-size(24);
	}
	@include font-size(52);
}


// TEXT DECORATION
.u-font {
	&-linethrough {
		text-decoration: line-through;
	}

	&-underline {
		text-decoration: underline;
	}

	&-decoration-none {
		text-decoration: none;
	}
}