@import '../_helpers/color';
@import '../_helpers/mixins';
@import '../_helpers/sizes';


.tasks-menu-cards--container {
    margin: 32px 0;
    @include flexbox();
    flex-wrap: wrap;
    gap: 32px;
}

.menu-card--item {
    flex: 1;
    min-width: 352px;
    box-sizing: border-box;
    padding: 32px 32px 24px;
    border-radius: 4px;
    box-shadow: 0px 2px 16px 0px rgba(114, 114, 114, 0.08), 0px 1px 6px 0px rgba(128, 128, 128, 0.1), 0px 1px 2px 0px rgba(102, 102, 102, 0.1);
    
    .self-service-card--single & {
        width: 352px;
        @media (max-width: $tabmob_break_point) {
            width: 100%;
        }
    }
    .tasks-card-title--container {
        @include flexbox();
        align-items: center;
        margin-bottom: 16px;

        .card-title-icon {
            width: 40px;
            height: 40px; 
            overflow: hidden;
            margin-right: 16px;

            img {
                width: 100%;
                height: auto; 
                display: block;
            }
        }

        .card-title {
            @include font-bold();
            @include font-size(20, 22)
        }
    }

    .tasks-card-items--container {
        .task-item--container {
            height: 48px;
            width: 100%;
            position: relative;
            display: inline-block;

            .item-title {
                display: inline-block;
                line-height: 48px
            }

            .c-icon--arrow-right {
                position: absolute;
                right: 4px;
                line-height: 48px;
                color: $nn_medium_orange_600;
                transition: all 0.2s ease-out;
                margin-right: 4px;
            }
            &:hover {
                .c-icon--arrow-right {
                  margin-right: 0px;
                }
            }
        }
    }

}

.self-service-card--single {
    @include flexbox();
    flex-direction: row;
    margin: 32px 0;
    
    @media (max-width: $tabmob_break_point) {
        flex-direction: column;
    }

    .self-service-card--single__intro {
        margin: 32px 32px 32px 0;
    }

}