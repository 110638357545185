@import '../_helpers/color';
// it's a shame you had to write this class, not a hack but a shame

// prevent zooming on landscape tablet iOs
@media only screen and (max-width: $landscape) {
	body {
		-webkit-text-size-adjust: none;
	}
}


// SUBMENU CONTENT is still old html in STATIC and css, we need to get rid of the li:before
.c-header-dropdown {
	.grit-unit {
		padding-left: 0;

		&:before {
			display: none
		}
	}

}
// SUBMENU fix
.c-list-default {

  &--sub {
    padding: 0;
  }

  &__item {
    padding-left: 0;

    &::before {
      display: none;
    }

    & .c-icon {
      color: $nn_medium_orange;
    }
  }

  &__item &__icon-link {
    text-decoration: none;
  }
}

// FIXME: very bad and ugly fix for a link disappearing behind text in an implementation for advisor
.data-table-wrapper {
  // Please remove me when it has been fixed properly
  a {
    z-index: 1;
  }
}


// TODO: old pages used #page with tabindex
#page:focus { outline: 0; }



// FIXME: STATIC sets basic font to 12px, so remove after STATIC is removed
.c-header {
	&__switch {
		&-link  {
			font-size: 16px;
			font-size: 1rem;
		}
	}

	&__menu {
		&-link {
			font-size: 16px;
			font-size: 1rem;
		}
	}

	&__login {
		&-link {
			font-size: 16px;
			font-size: 1rem;
		}
	}

	&__no-link {
		font-size: 16px;
		font-size: 1rem;

	}

}

// Text fields in tables in scrollablecontainers are misbehaving with old benaviour
.is-responsive .c-scrollablecontainer .date-field,
.is-responsive .c-scrollablecontainer .text-field {
	width: 100%;
	min-width: 60px;
}

//todo: remove when advisor legacy table styling is removed;
.c-tabular-selector__value-table {
	margin: 0;
	border: 0;
	td {
		border: 0;
	}
}
