//top part kan be removed maybe prototype?


.c-chat {

	&__messagebox {
		background: $nn_ultra_light_grey;
		height: 400px;
		margin-bottom: 20px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	&__message {
		margin: 10px 30px;
		max-width: 22em;
		float: left;
	}

	&__message--right {
		float:right;
		text-align: right;
	}

	&__balloon {
		color: $white;
		display: inline-block;
		text-decoration: none;
		text-align: left;
		padding: 11px;
		position: relative;
		box-sizing: border-box;
		background-color: $nn-medium-orange;
		@extend .u-border-radius;

		&--left:after {
			content: ' ';
			position: absolute;
			width: 0;
			height: 0;
		  	left: -24px;
			right: auto;
		  	top: 8px;
			bottom: auto;
			border: 14px solid;
			border-color: transparent $nn-medium-orange transparent transparent;
		}

		&--right {
			color: $nn_extra_dark_grey;
			background: $white;

			&:after {
				content: ' ';
				position: absolute;
				width: 0;
				height: 0;
			  	right: -24px;
				left: auto;
			  	top: 8px;
				bottom: auto;
				border: 14px solid;
				border-color: transparent transparent transparent $white;
			}
		}


	}

	&__messageform {
		background: $nn_ultra_light_grey;
		padding: 20px;
		position: relative;
	}

	&__input {
		padding-right: 90px;
		&__box {
			resize: none;
		}
	}

	&__submit {
		position: absolute;
		right: 20px;
		top: 20px;
	}

}

@mixin chat-dialog-small {
		width: 50px;
		height: 150px;
		bottom: 45%;
		bottom: calc(50% - 75px);
		right: -1px;
		padding: 5px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;

		.c-chat__dialog__title,
		.c-chat__dialog__body,
		.c-chat__dialog__close-icon,
		.c-chat__dialog__button__icon {
			display: none;
		}

		.c-chat__dialog__button,
		.c-chat__dialog__button:hover {
			font-size: 20px;
			color: $nn-medium-orange;
			background: $white;
			writing-mode: vertical-lr;
			transform: rotate(180deg);
			padding: 8px 8px;
			height: 110px;
		}

		.c-chat__dialog__icon {
			font-size: 24px;
		}
}

.c-chat {
	&__dialog {
		box-sizing: border-box;
		background-color: $white;
		@extend .u-border-radius;
		box-shadow: 0px 2px 13px rgba(0, 0, 0, .3);
		padding: 10px 12px;
		border: 1px solid $nn_light_grey;
		max-width: 270px;
		width: auto;
		position: fixed;
    	bottom: 30px;
    	right: 30px;
    	z-index: 1010;
    	transition: all 400ms ease-out;

			&__close-icon {
				color: $nn_medium_grey;
				font-size: 14px;
				display: inline-block;
				width: 12px;
				height: 12px;
				position: absolute;
				cursor: pointer;
				top: 24px;
				right: 15px;
				&:before {
					font-family: 'nn-iconfont';
                    content: $fonticon-content--close--thin;
				}

			}

			&__icon {
				color: $nn-medium-orange;
				font-size: 48px;
				display: inline-block;
				width: 68px;
			}

			&__title {
				font-size: 20px;
				display: inline-block;
				width: 140px;
				margin-left: 10px;
			}

			&__body {
				margin-bottom: 10px;
			}

		&.is-small {
			@include chat-dialog-small;
		}

		@media (max-width: $size_l) {
			.is-responsive & {
				@include chat-dialog-small;
			}
		}
	}
}