@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../global/css/colors';

/* PART: icon
=============================================================================*/
.date-picker-sprite {
  background-image: url('../img/sprites/date-picker.png');
}
.date-picker-sprite-mobile {
  background-image: url('../img/sprites/date-picker-mobile.png');
}

.date-picker-icon {
  /*@extend .date-picker-sprite;*/
  background-image: url('./datefield/img/date-picker-icon.png');
  background-repeat: no-repeat;
  background-position: 0 0;

  position: absolute;
  top: 4px;
  right: -25px;
  width: 17px;
  height: 18px;
  cursor: pointer;
}

.touch-fields .grid--block-filter input[class*='span'] + .date-picker-icon,
.touch-fields .date-picker-icon {
  top: 10px;
}

.touch-fields input[class*='span'] + .date-picker-icon {
  top: 5px;
}

.dialog.dialog--date-picker {
  @extend .date-picker-sprite;
  background-repeat: no-repeat;
  background-position: left top;
  background-color: transparent;
  padding: 0;
  border: 0;
  width: 344px;
  height: 85px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  .day-box,
  .month-box,
  .year-box,
  select {
    @include font-size(13);
  }

  .content {
    padding-top: 0;
  }

  .day-select {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
    cursor: pointer;
    position: absolute;
    z-index: 9000;
    left: 25px;
    top: 29px;
    width: 65px;
    height: 27px;

    option[disabled],
    option:first-child {
      color: $nn_dark_grey;
    }
  }

  .day-box {
    cursor: pointer;
    position: absolute;
    left: 25px;
    top: 29px;
    padding-left: 12px;
    color: #000;
    line-height: 27px;
    width: 53px;
    height: 27px;
  }

  &.date-of-birth .day-box {
    margin-left: -3px;
  }

  .month-select {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
    cursor: pointer;
    position: absolute;
    z-index: 9000;
    left: 100px;
    top: 29px;
    width: 115px;
    height: 27px;

    option:first-child,
    option[disabled] {
      color: $nn_dark_grey;
    }
  }

  .month-box {
    cursor: pointer;
    position: absolute;
    left: 100px;
    top: 29px;
    padding-left: 12px;
    color: #000;
    line-height: 27px;
    width: 103px;
    height: 27px;
  }

  &.date-of-birth .month-box {
    margin-left: -3px;
  }

  .year-select {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
    position: absolute;
    z-index: 9000;
    left: 225px;
    top: 29px;
    width: 80px;
    height: 27px;
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;

    option:first-child,
    option[disabled] {
      color: $nn_dark_grey;
    }
  }

  .year-box {
    cursor: pointer;
    position: absolute;
    left: 225px;
    top: 29px;
    padding-left: 12px;
    color: #000;
    line-height: 27px;
    width: 68px;
    height: 27px;
  }

  &.date-of-birth .year-box {
    margin-left: -3px;
  }

  /* PART: popup calendar
	=============================================================================*/
  &.calendar {
    padding-top: 56px;
    background-position: left -112px;
  }

  /*PART: popup foward-back-box
	=============================================================================*/

  .forward-back-box {
    @extend .date-picker-sprite;
    background-repeat: no-repeat;
    background-position: -17px -85px;

    position: absolute;
    left: 25px;
    top: 29px;
    /*background: url('../img/date-picker.png') no-repeat -17px -85px;*/
    width: 65px;
    height: 27px;
  }

  .forward-back-box .back {
    @extend .date-picker-sprite;
    background-repeat: no-repeat;
    background-position: -18px -86px;

    cursor: pointer;
    position: absolute;
    left: 1px;
    top: 1px;
    /*background: url('../img/date-picker.png') no-repeat -18px -86px;*/
    width: 31px;
    height: 25px;
  }

  .forward-back-box .back.disabled {
    background-position: -83px -86px;
  }

  .forward-back-box .forward {
    @extend .date-picker-sprite;
    background-repeat: no-repeat;
    background-position: -50px -86px;
    cursor: pointer;
    position: absolute;
    left: 33px;
    top: 1px;
    /*background: url('../img/date-picker.png') no-repeat -50px -86px;*/
    width: 31px;
    height: 25px;
  }

  .forward-back-box .forward.disabled {
    background-position: -115px -86px;
  }

  .calendar {
    @extend .date-picker-sprite;
    background-repeat: no-repeat;
    background-position: left bottom;
    padding: 0 39px 29px 25px;
    width: 280px;

    .day-of-the-week {
      display: inline-block;
      position: relative;
      left: -1px;
      margin-bottom: 10px;
      border-bottom: 1px solid #000;
      font-size: 13px;
      line-height: 30px;
      text-align: center;
      width: 40px;
      height: 30px;
    }

    .day-of-the-week:first-child {
      left: 0;
    }

    .day {
      cursor: pointer;
      display: inline-block;
      margin-right: 1px;
      margin-bottom: 1px;
      line-height: 32px;
      text-align: center;
      width: 39px;
      height: 32px;
      font-size: 13em/16em;
    }

    .day.selected,
    .day:hover {
      background: #f9d0b2;
    }

    .day.invisible,
    .day.invisible:hover {
      cursor: default;
      color: #fff;
      background: #fff;
    }

    .day.disabled,
    .day.disabled:hover {
      cursor: default;
      color: $nn_light_grey;
      background: #fff;
    }
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive .dialog.dialog--date-picker {
    width: 344px;
  }
}

/* iOS Native datefield */
.touch .date-field {
  line-height: 34px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .dialog.dialog--date-picker {
      background-image: url('../img/sprites/date-picker-mobile.png');
      margin-left: -6px;
    }
    .date-picker-icon {
      right: 0;
    }
    .grid-unit--info .date-picker-icon {
      right: -28px;
    }

    .field--date-mobile .field:nth-child(2) .custom-select-box {
      width: 90px;
    }

    .grid--block-delta > .grid-unit + .grid-unit--datefield {
      padding-right: 2px;
    }

    .grid--block-delta
      > .grid-unit
      + .grid-unit--datefield.grid-unit--datefield-picker {
      padding-right: 28px;
    }

    /* CONTEXT: Datepicker field with info dialog
=============================================================================*/
    .grid .grid-unit--datefield-picker.grid-unit--info {
      padding-right: 56px;
    }
  }
}
