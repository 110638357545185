@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../c-inline-message/c-inline-message.mixin';
@import "../../_helpers/component-design-token-maps";
@import "../../_helpers/alias-design-token-maps";

.c-form-text {
	box-sizing: border-box;
	border-width: 1px;
  border-style: solid;
  @include border-color($input_elements_Input_styles_borderColor);
  @include border-radius(4px);
	padding: 8px 16px;
	width: 100%;
	height: 48px;
	-webkit-appearance: none;
  @include background-color($input_elements_Input_styles_backgroundColor);
  @include color($input_elements_Input_styles_color);

  &:focus-visible {
    outline-offset: 0;
    outline-style: solid;
    outline-width: 2px;
    @include outline-color($input_elements_Input_interactionStates_FocusVisible_styles_outlineColor);
  }

	&--error {
		@media (min-width: $size_xs) {
			width: 100%;
		}
    border-width: 2px;
    @include border-color($input_elements_Input_dataStates_Invalid_styles_borderColor);
	}

	/* PLACEHOLDER */
	&::-webkit-input-placeholder {
    @include color($color_text_subtle);
    font-style: italic;
	}
	&:-ms-input-placeholder {
    @include color($color_text_subtle);
    font-style: italic;
	}
	&::-moz-placeholder {
    @include color($color_text_subtle);
    font-style: italic;
		opacity: 1;
	}
	&:-moz-placeholder {
    @include color($color_text_subtle);
    font-style: italic;
		opacity: 1;
	}

  /* Number Input Spinners */
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    /* Chrome, Safari, Edge, IE11 */
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    /* Firefox */
    -moz-appearance:textfield;
	}
	&[disabled] {
		background-color: transparent;
	}

  ~ .u-color--medium-red {
    @include c-inline-message;
  }
}
