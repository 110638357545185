.u-display {

	&-transition-03 {
		transition: all 0.3s linear;
	}

	&-hide--vertical {
		overflow: hidden;
		min-height: 0;
		max-height: 0;
		opacity: 0;
		padding-top: 0;
		padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-width: 0;
	}
}
