@import '../../../global/css/colors';

.review-rating-background {
	background: url('./reviews/img/star.png') left -9999px repeat-x;
}

.reviews {
	margin-bottom: 20px;

	p {
		margin-bottom: 0;
	}

	li.review {
		background: none;
		padding: 0 0 20px 0;
		&:before {
			display:none;
		}
		p {
			font-size: 100%;
		}
		.review-rating .review-rating--numeral {
			font-size: 100%;
		}
		.review-category,
		.review-date {
			font-size: 0.8em;
		}
	}

	.review-category {
		color: $nn_medium_orange;
		font-size: 0.6em;
		font-weight: bold;
		line-height: 1.8em;
		margin: 0;
		padding: 0;
	}
	.review-date {
		font-size: 0.6em;
	}

	.review-rating {
		@extend .review-rating-background;

		background-position: left top;
		height: 16px;
		list-style: none;
		margin: 0 0 10px 0;
		padding: 0;
		position: relative;
		width: 105px;

		.review-rating--current {
			@extend .review-rating-background;
			background-position: left bottom;
			height: 16px;
			line-height: 16px;
			left: 0;
			position: absolute;
			text-indent: -9999px;
			top: 0;
			z-index: 1;
		}

		.review-rating--numeral {
			color: $nn_medium_orange;
			font-size: 0.75em;
			font-weight: bold;
			left: 108px;
			position: absolute;
		}
		.review-rating--total {
			color: $nn_dark_grey;
			font-size: 0.75em;
			position: absolute;
			left: 135px;
		}
	}
}

.reviews--multiple {
	.review {
		border-bottom: 1px solid $nn_medium_orange;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}
}
.reviews--single {
	p {
		margin-bottom: 5px;
	}
}

