@media only screen and (max-width: $size_m) {
  .c-scroll-to-top {
    display: inline-block !important;
  }
}
span.c-scroll-to-top {
  line-height: 35px;
  vertical-align: middle;
}
.c-scroll-to-top {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 25px;
  transition: all 0.3s ease, transform 0.5s 0.3s ease;
  padding-bottom: 30px;
  opacity: 0;
  visibility: hidden;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
  background: #414141;
  &-visible {
    opacity: 0.25;
    visibility: visible;
  }
  &-fadeIn {
    opacity: 0.50;
    color: #FFFFFF;
  }
  &:hover {
    color: #FFFFFF;
  }
  &:before {
    position: absolute;
    width: 35px;
    height: 35px;
    right: 100%;
    top: 0;
    background: #414141;
    border-radius: 5px 5px 5px 5px;
  }
}
