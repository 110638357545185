@import '../../../global/css/colors';

/* COMPONENT: icon-bar
=============================================================================*/

.grid--icon-bar{
    margin: 15px 0 20px;

    a {
        color: $nn_dark_grey;
        text-decoration:none;
    }
    a:hover {
        color: $nn_medium_orange;
    }

    .grid-unit {
        color: $nn_dark_grey;
        font-size:11px;
        text-align: center;
        width: 20%;
    }

    .icon {
        display:block;
        height: 40px;
        margin: 0 auto;
		overflow: hidden;
        position:inherit;
        width: 60px;

		img {
			margin-top: -40px;
		}
    }

	.grid-unit:hover {
		.icon {
			img {
				margin-top: 0;
			}
		}
	}
}

/* COMPONENT: icon bar social
=============================================================================*/

.icons--social {

	li {
		background: none;
		float:left;
		margin-left: 1px;
		padding: 0;
	}

	.icon {
		display: inline-block;
		height: 20px;
		width: 20px;
		overflow: hidden;
	}

	.icon:hover img {
		margin-top: -20px;
	}
}

.header-section {
	.icons--social {
		position:absolute;
		right: 30px;
		bottom: 41px;
	}
}

.icons--social-v2 {
	li {
		float:left;
		padding: 0;
		&:before{
			display:none;
		}
	}

	li + li {
		margin-left: 6px;
	}
}

.icons--trust-builder {
	li {
		background: none;
		float:left;
		padding: 0;
	}

	li + li {
		margin-left: 16px;
	}
}