.c-notification-list {
	// Shame: There is styling on ul and ul > li elements. Override here
	ul {
		& > li {
			&::before {
				display: none;
			}
		}
	}

	min-width: $mobile_min_width;

	&__title {
		border-bottom: solid 2px $nn_medium_orange;
		margin-bottom: 1em;
	}

	&__list {
		list-style: none !important;
		padding: 0;
	}

	&__item {

		padding: 0;
		margin-bottom: 1em;
		border-bottom: solid 1px $nn_extra_light_grey;

		&__heading {
			display: flex;
			align-items: stretch;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 1em;

			&__icon {
				font-size: 32px;
				flex: 0 0 32px;
				align-self: stretch;
				color: $nn_medium_blue;

				&__text {
					@extend .c-icon;
					@extend .c-icon--pict-info;
				}
			}

			&__title {
				flex: 2 0 auto;
				font-weight: bold;
				padding: 0 0.5em;
				min-width: calc(304px - 32px); // min viewport = 320, so min viewport - icon

				@media (max-width: $mobile_break_point) {
					& {
						max-width: calc(100% - 48px);
					}
				}
			}

			&__icon,
			&__title {
				display: flex;
				justify-content: center;
				flex-direction: column;
			}

			&__actions {
				flex: 1 0 auto;
				padding: 0;
				margin-left: 32px;

				display: flex;
				align-items: stretch;
				justify-content: center;

				@media (max-width: $mobile_break_point) {
					& {
						flex-direction: column-reverse;
					}
				}

				//todo watch naming conventions
				&__content-toggle {
				}

				&__content-toggle,
				&__link {
					display: flex;
					justify-content: center;
					flex-direction: column;
					flex: 1 0 auto;

					padding: 0 0.5em;
					text-decoration: none;

					&__text {
						display: flex;
						flex-direction: row;
						text-align: left;
					}
				}

				&__content-toggle {
					&__text {
						min-width: 150px;
					}
					.collapser-icon {
						display: inline-block;
						margin-right: 1em;
						margin-top: calc( ( 1.25em - 14px ) / 2 );
					}
				}

				&__link {
					&__text {
						width: 200px;
						border: none;
						@extend .c-link;
					}
				}

			}

		}

		&--warning &__heading &__heading__icon {
			color: $nn_medium_orange;
			&__text {
				@extend .c-icon--pict-warning;
			}
		}

		&--error &__heading &__heading__icon {
			color: $nn_medium_red;
			&__text {
				@extend .c-icon--pict-warning;
			}
		}

		&__content {
			margin-top: 0.5em;
			padding: 1em 2.5em 0.5em 2.5em;
			background-color: $nn_ultra_light_grey;

			&__status {
				@extend .u-color--medium-orange;

				&__icon {
					@extend .c-icon;
					@extend .c-icon--eye;
				}
			}
		}
	}

}
