@import '../_helpers/sizes';
@import '../_helpers/mixins';
@import '../_helpers/color';

@mixin full-width {

  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

$masthead-height: 369px;
.c-masthead {
  @media(min-width: $size_s) {
    height: $masthead-height;
    display: flex;
    align-items: center;
    margin-top: -31px;
    margin-bottom: 30px;
  }

  &--image--wrapper {
    @media(min-width: $size_s) {
      display: block;
    }

    display: none;
    @include full-width;
  }

  &--image {
    height: $masthead-height;
    max-width: 1280px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }

  &--content {
    @media(min-width: $size_s) {
      @include border-radius(4px);
      padding: 30px;
      max-width: 460px;
      min-width: 250px;
      width: auto;

      h1 {
        @include font-size(45, 47);
      }
    }

    @media(max-width: $size_s) {
      @include full-width;
      border-bottom: 1px solid $nn_extra_light_grey;
    }

    padding: 15px 15px 15px;
    background-color: $white;
    z-index: 10;
    box-sizing: border-box;

    p {
      @include font-size(24, 31);
      @include font-bold;
    }

    button, .c-button {
      @media(min-width: $size_xs) {
        @include font-size(20);
        padding: 11px 16px 15px 16px;
        margin-right: 10px;

        .opt-out-nitti-grotesk-font & {
          padding: 13px 16px;
        }
      }

      margin-bottom: 10px;
    }
  }
}
