@import '../../_helpers/color';

.c-form-password {
	position: relative;
	width: 100%;

	.c-form-password__input{
		position: relative;
		padding-right: 64px;

		&::-ms-clear {
			display: none;
		}

		&[type='text'] + .c-form-password__toggle{
			background-color: $nn_dark_grey;
		}

		&.is-nonempty:focus + .c-form-password__toggle{
			opacity: 1;
			visibility: visible;
		}
	}

	.c-form-password__toggle{
		opacity: 0;
		visibility: hidden;

		display: block;
		position: absolute;
		top: 8px;
		bottom: 8px;
		right: 8px;
		width: 48px;
		user-select: none;
		cursor: pointer;
		transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
		color: #ffffff;
		font-size: 13px;
		line-height: 20px;
		border-radius: 2px;
		background-color: $nn_light_grey;

		&:active{
			background-color: $nn_dark_grey;
		}

		&:after{
			content: 'Tonen';
			display: block;
			text-align: center;
		}
	}
}
