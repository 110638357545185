$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);

// DARK ORANGE
$nn_dark_orange_100: rgb(253, 237, 233); // #fdede9
$nn_dark_orange_700: rgb(231, 67, 21); // #e64415
$nn_dark_orange_800: rgb(200, 58, 18); // #c83a12

// ORANGE
$nn_dark_orange: rgb(230, 68, 21); // #e64415
$nn_medium_orange_600: rgb(234, 101, 13); // #ea650d
$nn_medium_orange: $nn_medium_orange_600;
$nn_light_orange: rgb(238, 127, 0); // #ee7f00
$nn_fifty_light_orange: rgb(234, 201, 142); // #eac98e
$nn_ultra_light_orange: rgb(255, 247, 238); //#fff7ee

// LIGHT ORANGE
$nn_light_orange_100: rgb(255, 237, 217); // #ffedd9
$nn_light_orange_500: rgb(238, 127, 0); // #ee7f00
$nn_light_orange_700: rgb(193, 103, 0); // #c16700

// GREY
$nn_extra_dark_grey: rgb(65, 65, 65); // #414141
$nn_dark_grey: rgb(102, 102, 102); // #666666
$nn_medium_grey: rgb(153, 153, 153); // #999999
$nn_light_grey: rgb(202, 199, 199); // #cac7c7
$nn_extra_light_grey: rgb(229, 222, 217); // #e5ded9
$nn_ultra_light_grey: rgb(241, 237, 235); // #f1edeb
$nn_grey_600: rgb(141, 141, 141); // #8d8d8d
$nn_grey_800: rgb(109, 109, 109); // #6d6d6d
$nn_grey_1000: rgb(64, 64, 64); // #404040
$nn_grey_1100: rgb(43, 43, 43); // #2B2B2B
$nn_grey_1200: rgb(26, 26, 26); // #1a1a1a

// BLUE
$nn_dark_blue: rgb(83, 137, 194); // #5389c2
$nn_medium_blue: rgb(124, 170, 214); // #7caad6
$nn_light_blue: rgb(164, 204, 234); // #a4ccea
$nn_blue_100: rgb(234, 241, 248); // #eaf1f8
$nn_blue_700: rgb(72, 129, 190); // #4881be
$nn_blue_800: rgb(60, 112, 168); // #3c70a8

// GREEN
$nn_dark_green: rgb(91, 152, 83); // #5b9853
$nn_medium_green: rgb(150, 180, 57); // #96b439
$nn_light_green: rgb(200, 205, 46); // #c8cd2e
$nn_green_100: rgb(233, 242, 231); // #e9f2e7
$nn_green_600: rgb(93, 156, 85); // #5d9c55
$nn_green_700: rgb(83, 139, 76); // #538b4c

// PURPLE
$nn_dark_purple: rgb(112, 108, 176); // #706cb0
$nn_medium_purple: rgb(156, 145, 198); // #9c91c6
$nn_light_purple: rgb(198, 191, 224); // #c6bfe0

// SAND
$nn_sand_50: rgb(250, 249, 248); // #faf9f8
$nn_sand_100: rgb(243, 239, 237); // #f3efed
$nn_sand_200: rgb(230, 223, 218); // #e6dfda

// Additional colors
$nn_medium_red: rgb(215, 0, 0); // #D70000

// Typography
$nn_attentional_text_color: $nn_grey_1200;
$nn_default_text_color: $nn_grey_1000;
$nn_subtle_text_color: $nn_grey_800;
$nn_heading_text_color: $nn_attentional_text_color;
$nn_large_number_text_color: $nn_attentional_text_color;
$nn_inline_link_text_color: $nn_attentional_text_color;
$nn_body_text_color: $nn_default_text_color;
$nn_inactive_menu_item_text_color: $nn_subtle_text_color;
$nn_placeholder_text_color: $nn_subtle_text_color;
