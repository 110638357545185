@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../../_helpers/component-design-token-maps';

.c-form-select {
	margin: 0;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.25;
	width: 100%;
	max-width: 100%;
  height: 48px;
	border-width: 1px;
	border-style: solid;
	@include border-color($select_elements_Box_styles_borderColor);
  @include border-radius(4px);
	@include background-color($select_elements_Box_styles_backgroundColor);
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: url('c-form-select/img/c-form-select.svg');
	background-repeat: no-repeat;
  background-size: 18px;
	background-position: right 18px center;
	background-origin: border-box;
	padding: 7px 52px 7px 16px;

	&--no-border {
		border: 1px solid transparent;
		padding: 10px 6px;
	}

	&--stretch {
		width: 100%;
	}

	&--inline {
		width: auto;
	}

	&--error {
    border-width: 2px;
		@include border-color($attentionBlock_variants_Error_elements_Bar_styles_backgroundColor);
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
		background-image: url('c-form-select/img/c-form-select--disabled.svg');
	}
}


//Hide native select arrow for IE 10/11
.c-form-select::-ms-expand {
    display: none;
}
