@import '../../../global/css/sass-functions';

/* THEME: Container: Aligh right
=============================================================================*/
.container--alignright {
	@include clearfix();

	.container-content {
		float: right;
	}
}