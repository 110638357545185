@import '../../../global/css/sass-functions';
@import '../../../global/css/colors';
@import '../../../../../../nn-psdl-modules/src/icon/c-icon.variables';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: Link more
=============================================================================*/
.more {
  position: relative;
  text-decoration: none;
  display: inline-block;
  padding-right: 32px;
  position: relative;
  @include font-medium;
  @include font-size(16);

  &:after {
    content: $fonticon-content--arrow-right;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    font-family: 'nn-iconfont';
    @include color($link_elements_Icon_variants_Primary_styles_color);
  }
}

/* COMPONENT: Link arrowed
=============================================================================*/

.arrowed {
  text-decoration: none;
  display: inline-block;
  padding-left: 10px;
  position: relative;

  &:before {
    @include icon-link-list-image;
  }
}

/* COMPONENT: Link more
=============================================================================*/
.less {
  text-decoration: none;
  @include font-size(12);

  display: inline-block;
  padding-left: 30px;
  position: relative;

  &:before {
    display: inline-block;
    background-color: $nn_extra_light_grey;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    content: ' ';
    text-align: center;
    color: #fff;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: -2px;
    line-height: 12px;
    /*@extend .icon-arrow-small-left;*/
    @extend .icon-arrow-small-left-dark;
    background-position: 0 0;
  }
}

/* CONTROL: Link button
=============================================================================*/
.link-button {
  @include font-size(12);
  color: $nn_medium_orange;
  text-decoration: none;
}

/* THEME: pdf link
=============================================================================*/
.pdf-sprite {
  background-image: url('./links/img/pdf-link-list-item.png');
}

.pdf {
  padding-left: 25px;
  text-decoration: underline;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    border-left: 0;
    left: 0;
    top: -5px;
    width: 20px;
    height: 25px;
    @include icon;
    @include icon--download-20;
  }
  .nowrap {
    white-space: nowrap;
  }
}
