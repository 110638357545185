@import '../_helpers/sizes';
@import '../_helpers/mixins';
@import '../_helpers/color';

.z-layout-component__wrapper {
  flex-direction: row;

  @media (max-width: $size_s) { 
    flex-direction: column;
  }
}

.z-layout-component__wrapper--reversed {
  flex-direction: row-reverse;

  @media (max-width: $size_s) { 
    flex-direction: column-reverse;
  }
}

.z-layout-component__wrapper--reversed,
.z-layout-component__wrapper {
  @include flexbox;
  align-items: center;
  width: 100%;
  gap: 96px;

  @media (max-width: $size_l) { 
    gap: 32px;
  }

  .z-layout-component__content {
    @include flex(1);

    .z-layout-content__cta {
      text-decoration: none;

      .link-arrow--right {
        width: 21px;
        margin-left: 14px;
        transition: all 0.2s ease-out;
        transform: rotate(90deg);
        font-size: 1.125rem;
        color: $nn_medium_orange_600;
      }

      &:hover {
        .link-arrow--right {
          margin-left: 18px;
        }    
      }
    }
  }

  .z-layout-componen__image--wrapper {
    @include flex(1);
    position: relative;
    height: 306px;
    width: 100%;
    overflow: hidden;

    @media (max-width: $size_l) { 
      height: 420px;
      width: 100%;
    }

    @media (max-width: $size_s) { 
      flex: auto;
    }

    .z-layout-componen__image {
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }
  }
}
