@import "../../icon/_c-icon.variables.scss";
@import "../../../../nn-psdl-utility/src/colors";
@import "../../_helpers/mixins";
@import "../../_helpers/component-design-token-maps";

@mixin c-inline-message {
  display: flex;
  border-radius: 4px;
  padding: 8px;
  @include background-color($inlineMessage_elements_Container_variants_Error_styles_backgroundColor);
  font-weight: normal;
  @include color($paragraph_elements_Paragraph_styles_color, $isImportant: true);

  &::before {
    content: $fonticon-content--pict-warning;
    font-family: 'nn-iconfont';
    font-size: 24px;
    line-height: 24px;
    @include color($inlineMessage_elements_Icon_variants_Error_styles_color);
    margin-right: 8px;
  }
}