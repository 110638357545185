@import '../_helpers/mixins';
@import "../_helpers/component-design-token-maps";
@import "../_helpers/alias-design-token-maps";

//TODO remove u-no-border after fieldset border-top is removed
//only use u-border--none
//@deprecated: only use u-border--none
.u-no-border {
	border: none!important;
}

.u-border {

	//Default radius voor rounded boxes
	&-radius {
		@include border-radius(4px);
	}
	&-radius-2 {
		border-radius: 2px;
	}

	&--none{
		border: none!important;
	}

	&--m-orange {
		border: 1px solid $nn_medium_orange;
	}
	&--l-orange {
		border: 1px solid $nn_light_orange;
	}
	&--lgrey {
		border: 1px solid $nn_light_grey;
	}
	&--elgrey {
		border: 1px solid $nn_extra_light_grey;
	}
	&--ulgrey {
		border: 1px solid $nn_ultra_light_grey;
	}

	&--mediumorange-2 {
		border: 2px solid $nn_medium_orange;
	}

	// bottom
	&--bt-lgrey {
		border-bottom: 1px solid $nn_light_grey;
	}

	&--bt-ulgrey {
		border-bottom: 1px solid $nn_ultra_light_grey;
  }

  &--bt-mediumorange {
		border-bottom-width: 2px;
		border-bottom-style: solid;
		@include border-bottom-color($color_text_subtle);
	}

	// bottom
	&--bt-lgrey-2 {
		border-bottom: 2px solid $nn_light_grey;
	}

	// top
	&--tp-white-2 {
		border-top: 2px solid #fff;
	}

	// top
	&--tp-lgrey {
    border-top-width: 1px;
    border-top-style: solid;
    @include border-top-color($divider_elements_Divider_styles_borderColor);
	}
	&--tp-ulgrey {
		border-top: 1px solid $nn_ultra_light_grey;
	}

	// left
	&--l-lgrey {
		border-left: 1px solid $nn_light_grey;
	}
	&--l-elgrey {
		border-left: 1px solid $nn_extra_light_grey;
	}
	&--l-ulgrey {
		border-left: 1px solid $nn_ultra_light_grey;
	}

	&-2 {
		border-width: 2px;
	}
}
