$sizeToValueMap: (
  '5': 8px,
  '10': 12px,
  '15': 16px,
  '20': 20px,
  '25': 24px,
  '30': 32px,
  '40': 40px,
  '80': 80px,
);
$directions: 'top', 'right', 'bottom', 'left';

.u-margin {
    margin: 16px;
}

@each $size, $value in $sizeToValueMap {
  .u-margin--#{$size} {
    margin: #{$value};
  }
}

.u-margin-auto {
    margin-left: auto;
    margin-right: auto;
}

@each $direction in $directions {
  .u-margin-#{$direction} {
    margin-#{$direction}: 16px;
  }
  @each $size, $value in $sizeToValueMap {
    .u-margin-#{$direction}--#{$size} {
      margin-#{$direction}: #{$value};
    }
  }
}

.u-margin--none {
    margin: 0 !important;
}

@each $direction in $directions {
  .u-margin-#{$direction}--none {
    margin-#{$direction}: 0 !important;
  }
}
