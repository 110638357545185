@use 'sass:math';
@use "sass:map";
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin header-link {
  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    color: inherit;
  }
}

@mixin font-normal {
  font-weight: normal;
}

@mixin font-bold {
  font-weight: bold;
}

@mixin color($value, $isImportant: false) {
  @include dark-mode('color', $value, $isImportant);
}

@mixin text-decoration-color($value, $isImportant: false) {
  @include dark-mode('text-decoration-color', $value, $isImportant);
}

@mixin background-color($value, $isImportant: false) {
  @include dark-mode('background-color', $value, $isImportant);
}

@mixin outline-color($value, $isImportant: false) {
  @include dark-mode('outline-color', $value, $isImportant);
}

@mixin border-top-color($value, $isImportant: false) {
  @include dark-mode('border-top-color', $value, $isImportant);
}

@mixin border-bottom-color($value, $isImportant: false) {
  @include dark-mode('border-bottom-color', $value, $isImportant);
}

@mixin border-color($value, $isImportant: false) {
  @include dark-mode('border-color', $value, $isImportant);
}

@mixin border-left-color($value, $isImportant: false) {
  @include dark-mode('border-left-color', $value, $isImportant);
}

@mixin box-shadow($value, $isImportant: false) {
  @include dark-mode('box-shadow', $value, $isImportant);
}

@mixin dark-mode($property, $value, $isImportant: false) {
  @if ($isImportant) {
    #{$property}: map.get($value, "light") !important;
  }
  @else {
    #{$property}: map.get($value, "light");
  }

  @media (prefers-color-scheme: dark) {
    :where(.opt-in-dark-mode) & {
      @if ($isImportant) {
        #{$property}: map.get($value, "dark") !important;
      }
      @else {
        #{$property}: map.get($value, "dark");
      }
    }
  }
}

@mixin font-medium {
  font-weight: 500;

  .opt-out-nitti-grotesk-font & {
    font-weight: bold;
  }
}

@function max-decimal-digits($value, $maxDigits: 5) {
  $factor: math.pow(10, $maxDigits);
  @return math.div(math.round($value * $factor), $factor);
}

$font-size-base: 16;
@mixin font-size($sizeValue: 14, $lineHeight: null) {
  font-size: $sizeValue + px;
  font-size: math.div($sizeValue, $font-size-base) + rem;
  @if $lineHeight {
    line-height: max-decimal-digits(math.div($lineHeight, $sizeValue));
  }
}

@mixin u-clear {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin list-style-none {
  list-style: none;
  padding: 0;
}
