@import '../../../global/css/colors';

@import 'icons.mixins';

// NOTE: paths to images need to be relative to root folder where the styles are gathered

/* EXTEND: SVG icons
=============================================================================*/
.icon-arrow-small-up {
  background-image: url('./icons/img/arrow-small-up.svg');
  background-repeat: no-repeat;
}
.icon-arrow-small-right {
  background-image: url('./icons/img/arrow-small-right.svg');
  background-repeat: no-repeat;
}
.icon-arrow-small-down {
  background-image: url('./icons/img/arrow-small-down.svg');
  background-repeat: no-repeat;
}
.icon-arrow-small-left {
  background-image: url('./icons/img/arrow-small-left.svg');
  background-repeat: no-repeat;
}
.icon-arrow-small-left-dark {
  background-image: url('./icons/img/arrow-small-left-dark-grey.svg');
  background-repeat: no-repeat;
}
.icon--delete {
  background-image: url('./icons/img/delete.png');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  &:hover {
    opacity: 0.5;
  }
}

/* CONTROL: Icons
=============================================================================*/

.icons-sprite {
  background: url('./icons/img/icons-sprite.png');
}

.icon {
  display: block;
}

.icon--img {
  display: inline-block;
  vertical-align: middle;
}

.icon--check {
  @extend .icons-sprite;
  vertical-align: middle;
  display: inline-block;
  height: 16px;
  width: 21px;
}

.icon--cross {
  @extend .icons-sprite;
  vertical-align: middle;
  display: inline-block;
  background-position: 0 -24px;
  height: 21px;
  width: 21px;
}

td {
  .icon--check {
    margin: 0 auto;
  }
}

/* CONTROL: Social icons
=============================================================================*/

.icons--social {
  li {
    float: left;
    margin-left: 1px;
    padding: 0;
    &:before {
      display: none;
    }
  }

  .icon {
    display: inline-block;
    height: 20px;
    width: 20px;
    overflow: hidden;
  }

  .icon:hover img {
    margin-top: -20px;
  }
}

.header-section {
  .icons--social {
    position: absolute;
    right: 30px;
    bottom: 45px;
  }
}

/* CONTROL: Stock value icons
=============================================================================*/
.icon--stock-value-up,
.icon--stock-value-down {
  min-width: 50px;
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: 100% 0;
  padding-right: 16px;
  display: inline-block;
}

.icon--stock-value-up {
  background-image: url('./icons/img/arrow-up-green.svg');
}

.icon--stock-value-down {
  background-image: url('./icons/img/arrow-down-red.svg');
}

.icon-payment-sprite {
  background-image: url('../../../../nn-psdl-modules/src/img/c-form-radio-ideal.png');
}

.icon--phone-20 {
  @include icon;
  @include icon--phone-20;
  @include icon--standalone-20;
}

.icon--mail-20 {
  @include icon;
  @include icon--mail-20;
  @include icon--standalone-20;
}

.icon--phone-20-white {
  @include icon;
  @include icon--phone-20-white;
  @include icon--standalone-20;
}

.icon-item {
  position: relative;
  display: inline-block;
  padding: 5px 0 5px 25px;
  text-decoration: none;
  min-height: 9px;
  &:before {
    @include icon;
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 1px;
  }
  &.icon-download:before {
    background-position: -60px -650px;
  }
  &.icon-download-arrowed:before {
    background-position: -60px -300px;
  }
  &.icon-vink-20:before {
    background-position: -60px -772px;
  }
  &.icon-exclamation-20:before {
    background-position: -60px -713px;
  }
  &.icon-client:before {
    background-position: -60px -590px;
  }
  &.icon-client-black:before {
    background-position: -300px -590px;
  }
  &.icon-client-grey:before {
    background-position: -420px -590px;
  }
  &.icon-item--size-20 {
    &:before {
      width: 20px;
      height: 20px;
      top: 2px;
    }
  }
}

/* PART: Vertical arrow icons
=============================================================================*/
.icon--arrow-updown-grey,
.icon--arrow-up,
.icon--arrow-down {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 1em;

  &:before,
  &:after {
    position: absolute;
    border-style: solid;
  }

  &:before {
    top: 0;
    border-width: 0 5px 5px 5px;
  }
  &:after {
    bottom: 0;
    border-width: 5px 5px 0 5px;
  }
}

.icon--arrow-updown-grey {
  &:before {
    content: '';
    border-color: transparent transparent $nn_medium_grey transparent;
  }
  &:after {
    content: '';
    border-color: $nn_medium_grey transparent transparent transparent;
  }
}

.icon--arrow-up {
  &:before {
    content: '';
    border-color: transparent transparent $nn_medium_orange transparent;
  }
}
.icon--arrow-down {
  &:after {
    content: '';
    border-color: $nn_medium_orange transparent transparent transparent;
  }
}
