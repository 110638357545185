@import '../icon/c-icon.variables';
@import '../link/c-link.mixin';
@import '../_helpers/color';
@import '../_helpers/sizes';
@import '../_helpers/mixins';
@import '../_helpers/vars';
@import './mixins';

$breakpoint: $size_l;
$breakpoint_mobile: $size_xs;

$portalMenuHeight: 47;
$mainMenuHeight: 85;
$portalMenuHeightMobile: 60;
$mainMenuHeightMobile: 48;
$mainNavigationHeight: $portalMenuHeight + $mainMenuHeight;
$mainNavigationHeightMobile: $portalMenuHeightMobile + $mainMenuHeightMobile;

.c-main-navigation {
  min-height: $mainNavigationHeight + px;

  @media (max-width: $breakpoint) {
    min-height: $mainNavigationHeightMobile + px;
  }

  &.c-main-navigation--custom-height {
    min-height: $mainMenuHeight + px;

    @media (max-width: $breakpoint) {
      min-height: $mainNavigationHeightMobile + px;
    }
  }

  ///////////////////////////
  // GENERAL
  ///////////////////////////

  &__banner {
    &.c-main-navigation__banner--credit-warning {
      position: fixed;
      top: 0;
      z-index: $mainNavigationFixedBanner;
      @include background-color(
        $pageHeader_elements_Container_styles_backgroundColor
      );
      width: 100%;
      height: 10vh;
      border: solid 2px #000;
      box-sizing: border-box;
      -ms-interpolation-mode: bicubic;
      background-image: url('./banner-img/afm_crediet_waarschuwing.md.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 50%;
    }

    &.c-main-navigation__banner--empty-placeholder {
      width: 100%;
      height: 10vh;
    }

    @media (max-width: $breakpoint) {
      &.c-main-navigation__banner--credit-warning {
        background-size: 80% auto;
      }
    }

    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
      &.c-main-navigation__banner--credit-warning {
        background-image: url('./banner-img/afm_crediet_waarschuwing.hd.jpg');
      }
    }
  }

  header {
    position: relative;
    width: 100%;
    z-index: $mainNavigationLayer;
  }

  ul {
    max-width: 1184px; // 1120 (content) + 32 (padding left) + 32 (padding right)
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 32px;
    box-sizing: border-box;

    .is-wide & {
      max-width: $desktop-max;
    }

    li {
      padding: 0 0 0 15px;

      &:first-child {
        padding: 0;
      }

      &:before {
        display: none;
      }

      &:not(.c-main-navigation__search) {
        position: static;
      }
    }

    a {
      text-decoration: none;
      &.c-main-navigation__active {
        font-weight: 500;
        cursor: default;
      }
    }

    button {
      &.c-main-navigation__active {
        @include color(
          $link_elements_Icon_variants_Primary_interactionStates_Active_styles_color
        );
      }
    }

    @media (max-width: $breakpoint) {
      padding: 0 15px;
    }
  }

  &__skip {
    max-width: $desktop-min;
    margin: 0 auto;

    .is-wide & {
      max-width: $desktop-max;
    }
  }

  &__main &__menu &__toggler,
  &__portal &__portal__dropdown button {
    &:after {
      position: absolute;
      top: 50%;
      right: 3px;
      font-family: 'nn-iconfont';
      @include color(
        $link_elements_Icon_variants_Primary_interactionStates_Active_styles_color
      );
      content: '>';
      transition: all 0.2s linear;
      transform: translateY(-50%) rotate(90deg);
      font-size: 0.8125rem;

      .opt-out-nitti-grotesk-font & {
        top: auto;
      }
    }
  }

  &__main &__menu &__expanded &__toggler,
  &__portal &__expanded &__toggler {
    &:after {
      -moz-transform: translateY(-50%) rotate(270deg);
      -webkit-transform: translateY(-50%) rotate(270deg);
      transform: translateY(-50%) rotate(270deg);
    }
  }

  &__main &__menu &__toggler,
  &__portal &__toggler {
    padding: 0 21px 0 4px;

    .opt-out-nitti-grotesk-font & {
      padding: 4px 21px 4px 4px;
    }
  }

  &__menu {
    ul {
      width: 100%;

      li,
      li:first-child {
        padding: 0 15px 0 0;

        &:last-child {
          padding: 0;
        }
      }
    }
  }

  &__text-addition {
    &--notification {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 1.2;
      height: 1.2em;
      min-width: 1.2em;
      @include background-color(
        $indicatorBadge_variants_Error_elements_Container_styles_backgroundColor
      );
      border-radius: 25px;
      @include color(
        $indicatorBadge_variants_WithCount_elements_TotalCount_styles_color
      );
      margin-left: 2px;
      border-width: 2px;
      border-style: solid;
      @include border-color(
        $indicatorBadge_variants_Error_elements_Container_styles_backgroundColor
      );
    }
  }

  ///////////////////////////
  // PORTAL
  ///////////////////////////
  &__portal {
    position: relative;
    @include background-color(
      $pageHeader_elements_ContentContainer_styles_backgroundColor
    );

    ul {
      height: $portalMenuHeight + px;
    }

    li {
      text-align: center;
      &.c-main-navigation {
        &__logo,
        &__login,
        &__profile,
        &__links,
        &__portal__dropdown,
        &__co-branding {
          display: none;
        }
      }
    }

    .c-main-navigation__logo {
      img {
        height: 44px;
      }
    }

    &.c-main-navigation__portal--custom-height {
      ul {
        height: 0;
      }
    }

    &--user-logo {
      justify-content: space-between;
    }

    @media (max-width: $breakpoint) {
      @include background-color(
        $pageHeader_elements_Container_styles_backgroundColor
      );

      ul {
        height: $portalMenuHeightMobile + px;
      }

      li {
        display: none;
        padding: 0 0 0 15px;
        &.c-main-navigation {
          &__logo,
          &__login,
          &__profile,
          &__links,
          &__portal__dropdown,
          &__co-branding {
            display: block;
          }

          &__co-branding {
            margin-left: auto;
          }
        }
      }

      &.c-main-navigation__portal--custom-height {
        ul {
          height: $portalMenuHeightMobile + px;
        }
      }
    }
  }

  &__portal &__portal-menu {
    display: -webkit-flex;
    display: flex;
    ul {
      padding: 0;

      li {
        padding: 0 30px 0 0;
      }
    }

    a {
      padding: 13px 0;
      @include font-size(14);
      @include font-normal;
      @include color($pageHeader_elements_Link_styles_color);

      &:not(.c-main-navigation__active):hover {
        text-decoration: underline;
      }
    }
    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &__portal &__links {
    margin-left: auto;
    @media (max-width: $breakpoint) {
      display: block;
      ul {
        height: $portalMenuHeightMobile + px;
        li {
          display: block;
        }
      }
    }
  }

  &__portal &__portal__dropdown {
    display: none;
    margin-left: auto;
    position: static;

    button {
      position: relative;
      background-color: transparent;
      @include color($secondaryButton_elements_Button_styles_color);
      @include font-normal;
      @include font-size(14);
    }

    @media (max-width: $breakpoint) {
      display: block;
    }
  }

  &__portal &__profile {
    @media (max-width: $breakpoint) {
      position: static;
    }
  }

  &__portal &__profile &__submenu {
    @media (max-width: $breakpoint) {
      padding: 16px 0 0 30px;
      margin: 0;
    }
  }

  &__portal &__expanded &__submenu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    // opacity: 1;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    z-index: $mainNavigationLayer;
  }

  &__portal ul.c-main-navigation__submenu,
  &__portal &__submenu {
    @media (max-width: $breakpoint) {
      display: none;
      white-space: nowrap;
      position: absolute;
      @include background-color(
        $pageHeader_elements_Container_styles_backgroundColor
      );
      border-top-width: 1px;
      border-top-style: solid;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      @include border-color($divider_elements_Divider_styles_borderColor);
      margin: 19px 0 0 0;
      padding: 16px 0 10px 25px;
      left: 0;
      right: 0;
      width: auto;
      box-shadow: none;

      a,
      li a {
        display: inline-block;
        position: relative;
        padding: 0 0 0 1.5em;
        text-decoration: none;
        @include font-bold;
        @include font-normal;
        margin: 0 0 15px 0;

        @include color($link_elements_Text_variants_Primary_styles_color);

        &:hover {
          @include color(
            $link_elements_Icon_variants_Primary_interactionStates_Hover_styles_color
          );
        }

        &:before {
          position: absolute;
          top: -0.1em;
          left: -8px;
          font-size: 1.5em;
          line-height: 1;
          font-family: 'nn-iconfont';
          @include color($secondaryButton_elements_Icon_styles_color);
          @include font-normal;
        }

        &:not(.nav-icon) {
          &:before {
            content: $fonticon-content--angle-right;
          }
        }

        @include font-normal;
        margin: 0 0 15px 0;
      }

      h3 {
        @include color($heading_elements_Heading_styles_color);
        margin-bottom: 15px;
      }
    }
  }

  ///////////////////////////
  // MAIN
  ///////////////////////////
  &__main {
    @include background-color(
      $pageHeader_elements_Container_styles_backgroundColor
    );

    ul {
      height: $mainMenuHeight + px;
    }

    a {
      @include font-medium;
    }

    @media (min-width: $breakpoint) {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      @include border-color($divider_elements_Divider_styles_borderColor);
    }

    @media (max-width: $breakpoint) {
      @include background-color(
        $pageHeader_elements_ContentContainer_styles_backgroundColor
      );

      ul {
        height: $mainMenuHeightMobile + px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (max-width: $breakpoint_mobile) {
      min-width: 320px;
    }

    &.c-main-navigation__sticky {
      position: fixed;
      width: 100%;
      top: 0;

      @media (max-width: $breakpoint) {
        position: relative;

        &--mobile {
          position: fixed;
          transform: translateY(-100%);
        }

        &--slide-up {
          transform: translateY(-100%);
          @include menu-slide;
        }

        &--slide-down {
          transform: translateY(0);
          @include menu-slide;
        }
      }
    }
  }

  &__main &__logo {
    img {
      height: 55px;
      min-width: 187px;
    }
    @media (max-width: $breakpoint) {
      display: none;
      height: 44px;
      min-width: 143px;
    }
  }

  &__main &__co-branding {
    margin-left: auto;

    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &__main &__menu {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;

    ul {
      display: -webkit-inline-flex;
      display: inline-flex;
      padding: 0 15px;
    }

    a {
      padding: 13px 4px 13px 4px;
      @include color($link_elements_Text_variants_Primary_styles_color);

      &:hover {
        @include color(
          $link_elements_Icon_variants_Primary_interactionStates_Hover_styles_color
        );
      }

      .opt-out-nitti-grotesk-font & {
        padding: 4px;
      }
    }

    button {
      background: none;
      @include color($link_elements_Text_variants_Primary_styles_color);

      &:hover {
        background: none;
        @include color(
          $link_elements_Icon_variants_Primary_interactionStates_Hover_styles_color
        );
      }
    }

    @media (max-width: $breakpoint) {
      order: 2;
      ul {
        padding: 0;
      }

      li:last-child {
        padding-right: 15px;
      }

      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media (max-width: $breakpoint_mobile) {
      background-image: linear-gradient(
          to right,
          $nn_ultra_light_grey,
          $nn_ultra_light_grey
        ),
        linear-gradient(to right, $nn_ultra_light_grey, $nn_ultra_light_grey),
        linear-gradient(
          to right,
          rgba(153, 153, 153, 0.5),
          rgba(250, 249, 248, 0)
        ),
        linear-gradient(
          to left,
          rgba(153, 153, 153, 0.5),
          rgba(250, 249, 248, 0)
        );

      background-position:
        left center,
        right center,
        left center,
        right center;
      background-size:
        20px 100%,
        0px 100%,
        20px 100%,
        40px 100%;
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
    }
  }

  &__main &__menu &__toggler {
    position: relative;
    height: 44px;
  }

  &__main &__menu &__expanded &__toggler {
    @include color($link_elements_Text_variants_Primary_styles_color);
  }

  //remove the bottom/2nd class completely when CMS implements new HTML structure
  &__main ul.c-main-navigation__submenu,
  &__main div.c-main-navigation__submenu {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    white-space: nowrap;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-width: 1px;
    border-style: solid;
    @include border-color($divider_elements_Divider_styles_borderColor);
    border-top: none;
    @include background-color(
      $pageHeader_elements_Container_styles_backgroundColor
    );
    width: auto;
    height: auto;
    position: absolute;
    margin: 21px -28px 0;
    padding: 15px 35px 15px 25px;
    visibility: hidden;
    opacity: 0;

    li + li,
    a + a {
      margin-top: 15px;
    }

    a {
      display: inline-block;
      position: relative;
      padding: 0 0 0 1.5em;
      @include color($link_elements_Text_variants_Primary_styles_color);
      text-decoration: none;
      @include font-normal;

      &:hover {
        color: inherit;
        text-decoration: underline;
        text-underline-offset: 4px;
        @include text-decoration-color(
          $link_elements_Text_variants_Primary_interactionStates_Hover_styles_textDecorationColor
        );
        text-decoration-thickness: 2px;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.8125rem;
        line-height: 1.5rem;
        font-family: 'nn-iconfont';
        @include color($icon_elements_Icon_styles_color);

        .opt-out-nitti-grotesk-font & {
          top: -0.1em;
        }
      }

      &:not(.nav-icon) {
        &:before {
          content: $fonticon-content--angle-right;
        }
      }
    }

    h3 {
      margin-bottom: 0;
    }

    @media (max-width: $breakpoint) {
      border: none;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      @include border-color($divider_elements_Divider_styles_borderColor);
      border-radius: 0;
      margin: 0;
      left: 0;
      box-shadow: none;
      position: absolute;
      right: 0;
    }
  }

  &__main &__expanded &__submenu {
    opacity: 1;
    visibility: visible;
    z-index: $mainNavigationLayer;
  }

  &__links {
    ul {
      padding: 0;
    }

    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &__login {
    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &__logout {
    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &__search {
    white-space: nowrap;
    padding-left: 3px;

    .c-icon--icon-search {
      margin-left: 7px;
    }

    &-form {
      position: relative;

      input[type='text'].c-form-text {
        height: 36px;
        border-radius: 3px;
        width: 0;
        padding: 0;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;

        &.has-width {
          width: 400px;
          padding: 8px 50px 8px 8px;

          @media (max-width: $breakpoint) {
            width: 93vw;
          }
        }

        &.has-width ~ .c-icon.c-search__submit {
          right: 34px;
          @include font-normal;
        }

        &.has-width ~ .c-icon.c-search__reset {
          @include font-normal;
        }
      }

      button[data-role='enable-nav-search'] {
        display: none;
        &.active {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }
      }

      @media (max-width: $breakpoint) {
        button[data-role='enable-nav-search'] {
          @include font-size(0);
        }
      }
    }

    @media (max-width: $breakpoint) {
      z-index: $mainNavigationLayer - 1;
      position: absolute;
      right: 15px;

      button[data-module='chatbotTrigger'],
      a {
        @include font-size(0);
      }

      .c-icon--icon-search {
        @include font-size(16);
        margin-left: 0;
      }
    }
    @media (min-width: $breakpoint_mobile) and (max-width: $breakpoint) {
      padding: 0 0 0 5px;
      position: absolute;
      right: 15px;
    }
  }

  .c-main-navigation &__profile {
    position: relative;
    button {
      background-color: transparent;
      @include color(
        $link_elements_Icon_variants_Primary_interactionStates_Active_styles_color
      );
      padding: 2px !important; //used !important as buttons in IE specific default padding from psdl styling

      &:hover {
        background-color: transparent;
      }
      @media (max-width: $breakpoint) {
        padding: 0;
      }
    }

    @media (max-width: $breakpoint) {
      position: static;
      display: none;
    }

    div.c-main-navigation__submenu {
      .nav-icon,
      .logout {
        line-height: 1.5rem;
        padding: 0 0 0 25px;
        margin-left: 16px;
        margin-bottom: 25px;
      }
      .nav-icon:first-child {
        margin-top: 12px;
      }

      .before-logout {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #f1edeb;
        padding-left: 34px;
        left: -10px;
        width: 85%;
        text-align: left;

        &::before {
          padding-left: 10px;
        }
      }

      .logout {
        margin-bottom: 20px;
        @media (min-width: 768px) {
          margin-left: 8px;
        }

        &::before {
          left: -5px;
        }
      }
    }
  }

  &__profile div.c-main-navigation__submenu {
    margin-top: 25px;
    left: auto;
    margin-right: 0;

    // at this specific width 1560px drop down should start sticking to the right side
    @media (max-width: calc($size_xxl + 280px)) {
      right: 0;
    }

    @media (max-width: $breakpoint) {
      left: 0;
    }
  }

  &__user-icon {
    @include font-size(33);

    &.c-icon:before {
      content: $fonticon-content--icon-user;
    }

    &.c-icon:hover:before {
      content: $fonticon-content--icon-user-active;
    }
  }

  &.overlay,
  &.submenu-overlay {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    @include background-color(
      $pageHeader_elements_Backdrop_styles_backgroundColor
    );
    transition: opacity 0.5s linear;
    z-index: $mainNavigationLayer - 1;

    &.is-active {
      opacity: 0.25;
    }
  }
}

@media (max-width: $breakpoint_mobile) {
  .c-main-navigation--floating-search .c-main-navigation__search {
    order: 1;
    position: relative;
    margin-left: 15px;
    a {
      @include background-color(
        $pageHeader_elements_ContentContainer_styles_backgroundColor
      );
      border: none;
      padding: 12px 12px 12px 3px;

      &:hover {
        @include background-color(
          $pageHeader_elements_ContentContainer_styles_backgroundColor
        );
        border: none;
        box-shadow: none;
      }
    }
    .c-icon--icon-search {
      @include font-size(24);
    }
  }
  .c-main-navigation--floating-search .c-main-navigation__menu {
    order: 2;
    margin-left: -25px;
  }
}
