//Created 5 segments for different segment sizes in svg which we include
//Segment size 1

$segment1-2: -172deg;

$segment2-1: -154deg;

$segment2-3: -138deg;
$segment3-1: -129deg;
$segment3-2: -120deg;
$segment3-3: -112deg;
$segment4-1: -103deg;
$segment4-2: -95deg;
$segment4-3: -85deg;

$segment5-2: -68deg;


.c-riskmeter-segment-1 {
    background: transparent url('img/c-riskmetersegment-1.svg') center no-repeat;
}
//Segment size 1.3
.c-riskmeter-segment-2 {
    background: transparent url('img/c-riskmetersegment-2.svg') center no-repeat;
}
//Segment size 1.6
.c-riskmeter-segment-3 {
    background: transparent url('img/c-riskmetersegment-3.svg') center no-repeat;
}
//Segment size 2
.c-riskmeter-segment-4 {
    background: transparent url('img/c-riskmetersegment-4.svg') center no-repeat;
}
//Segment size 2.3
.c-riskmeter-segment-5 {
    background: transparent url('img/c-riskmetersegment-5.svg') center no-repeat;
}
//Segment size 0.6
.c-riskmeter-segment-6 {
    background: transparent url('img/c-riskmetersegment-6.svg') center no-repeat;
}

.c-riskmeter {
    width: 184px;
    height: 189px;
    position: relative;
    display: block;
    background: transparent url('riskmeter/img/c-riskmeter.svg') center no-repeat;

//Put an overlay over our background image, choose width and rotate the segment
    &:before {
        content: "";
        width: 184px;
        height: 184px;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
    }
    &[data-risk="1"] {
        &:before {
            transform: rotate($segment1-2);
            @extend .c-riskmeter-segment-1;
        }
    }
    &[data-risk="2"] {
        &:before {
            transform: rotate($segment2-1);
            @extend .c-riskmeter-segment-2;
        }
    }
    &[data-risk="3"] {
        &:before {
            transform: rotate($segment2-3);
            @extend .c-riskmeter-segment-2;
        }
    }
    &[data-risk="4"] {
        &:before {
            transform: rotate($segment3-1);
            @extend .c-riskmeter-segment-2;
        }
    }
    &[data-risk="5"] {
        &:before {
            transform: rotate($segment3-2);
            @extend .c-riskmeter-segment-2;
        }
    }
    &[data-risk="6"] {
        &:before {
            transform: rotate($segment3-2);
            @extend .c-riskmeter-segment-2;
        }
    }
    &[data-risk="7"] {
        &:before {
            transform: rotate($segment3-2);
            @extend .c-riskmeter-segment-3;
        }
    }
    &[data-risk="8"] {
        &:before {
            transform: rotate($segment3-2);
            @extend .c-riskmeter-segment-3;
        }
    }
    &[data-risk="9"] {
        &:before {
            transform: rotate($segment3-3);
            @extend .c-riskmeter-segment-3;
        }
    }
    &[data-risk="10"] {
        &:before {
            transform: rotate($segment3-3);
            @extend .c-riskmeter-segment-3;
        }
    }
    &[data-risk="11"] {
        &:before {
            transform: rotate($segment3-3);
            @extend .c-riskmeter-segment-3;
        }
    }
    &[data-risk="12"] {
        &:before {
            transform: rotate($segment4-1);
            @extend .c-riskmeter-segment-3;
        }
    }
    &[data-risk="13"] {
        &:before {
            transform: rotate($segment4-1);
            @extend .c-riskmeter-segment-3;
        }
    }
    &[data-risk="14"] {
        &:before {
            transform: rotate($segment4-1);
            @extend .c-riskmeter-segment-4;
        }
    }
    &[data-risk="15"] {
        &:before {
            transform: rotate($segment4-1);
            @extend .c-riskmeter-segment-4;
        }
    }
    &[data-risk="16"] {
        &:before {
            transform: rotate($segment4-1);
            @extend .c-riskmeter-segment-5;
        }
    }
    &[data-risk="17"] {
        &:before {
            transform: rotate($segment4-2);
            @extend .c-riskmeter-segment-4;
        }
    }
}
