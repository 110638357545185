@use "sass:math";

@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';

$datepicker-list-height: 270px;
$datepicker-month-height: 270px;

.datepicker-dialog {
	width: 100%;
}

.datepicker-list {
	border-top: 1px dotted $nn_medium_orange;
	height: $datepicker-list-height;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.datepicker-header {
	padding-right: 30px;
	overflow: auto;
}

.datepicker-header-title {
	float: left;
	width: 140px;
}
.datepicker-header-select {
	select {
		width:auto;
	}
}

.datepicker-weekdays {
	width: 100%;
}

.datepicker-month-wrapper {
	height: $datepicker-month-height;
}

.datepicker-month {
	width: 100%;
	border-collapse: collapse;

	td {
		text-align: center;
		@include box-sizing-border-box;
		width: percentage(math.div(1, 7));
		padding: 0.5em;

		&:not(.datepicker-date--inactive) {
			cursor: pointer;

			&:hover {
				background-color: $nn_extra_light_grey;
			}
		}

		.datepicker-date--chosen:hover {
			background-color: $nn_light_orange;
		}
	}

	.datepicker-date--inactive {
		color: $nn_medium_grey;
	}
}

.datepicker-month-title {
	border-bottom: 1px solid $nn_medium_orange;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.datepicker-weekday {
	@include box-sizing-border-box;
	width: percentage(math.div(1, 7));
	padding: 0.5em;
}

.datepicker-weekday {
	text-align: center;
	@include font-size(14);
	color: $nn_medium_grey;
}

.datepicker-date--today {
	color: $nn_medium_orange;
}

.datepicker-date--chosen {
	color: $white;
	background-color: $nn_medium_orange;
}


.dialog.dialog--datepicker {
	width: 300px;
	padding: 15px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;

	@include border-radius(3px);
	@include drop-shadow(0px, 2px, 13px, rgba(0, 0, 0, .1));

	.content {
		padding: 0;
	}

	.grid-wrapper--0:first-child {
		margin-right: 50px;
		margin-bottom: 0;
	}

	.datepicker-currentmonth-title {
		line-height: 42px;
	}

	.datepicker-arrow {
		position: absolute;
		top: 27px;
		right: 100%;
		width: 20px; height: 20px;

		&:before, &:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			border: 10px solid transparent;
			border-right: 10px solid white;
			width: 0;
			height: 0;
			z-index: 1
		}

		&:after {
			border: 12px solid transparent;
			border-right: 12px solid $nn_light_grey;
			z-index: 0;
			top: -2px;
		}
	}

	> .close-button {
		top: 30px;
		right: 20px;
	}

	&.dialog--datepicker-pos-bottom {

		.datepicker-arrow {
			top: -20px;
			left: 20px;

			&:before, &:after {
				border: 10px solid transparent;
				border-bottom: 10px solid white;
				top: auto;
				bottom: 0;
			}

			&:after {
				border-bottom: 10px solid $nn_light_grey;
				top: auto;
				bottom: 1px;
			}
		}

		> .close-button {
			position: absolute;
		}

	}
}

@media (max-width: $tablet_break_point) {
	.is-responsive .dialog.dialog--datepicker {
		width: 300px;
		min-width: 0;
		margin: 0;
		overflow-y: inherit;

		> .content {
			width: 100%;
		}
	}
}

@media (max-width: $mobile_break_point) {
	.is-responsive .dialog.dialog--datepicker {
		width: 300px;
	}
}

.datepickerfield-wrapper {
	display: inline-block;
	position: relative;
	max-width: 100%;

	& input {
		max-width: 100%;
	}
}

.datepickerfield-icon {
	position: absolute;
	top: 12px;
	right: 10px;
	width: 16px;
	height: 16px;
	background-image: url('./datepicker/img/datepicker-icon.svg');
	background-position: 0 0;
	background-repeat: no-repeat;
}

/* PATTERN: Start/end construct
=============================================================================*/
.field--datepicker-start-end {
	max-width: 480px;
}
.field--datepicker-start-end label {
	display: inline-block;
	width: 100%;
	position: relative;
	padding-right: 18px;
	box-sizing: border-box;
}
.field--datepicker-start-end label .information-dialog {
	position: absolute;
	top: 0;
	right: 0;
}