/* PART: SVG icon sheet
=============================================================================*/
@mixin icon {
  background: url('../img/sprites/icons.svg') no-repeat;
}

@mixin icon--call {
  background-position: 0 0;
}
@mixin icon--callback {
  background-position: 0 -60px;
}
@mixin icon--mail {
  background-position: 0 -120px;
}
@mixin icon--mouse {
  background-position: 0 -180px;
}
@mixin icon--advisor {
  background-position: 0 -240px;
}
@mixin icon--download {
  background-position: 0 -300px;
}
@mixin icon--print {
  background-position: 0 -360px;
}
@mixin icon--star {
  background-position: 0 -420px;
}
@mixin icon--magnify {
  background-position: 0 -480px;
}
@mixin icon--arrowright {
  background-position: 0 -540px;
}
@mixin icon--client {
  background-position: 0 -590px;
}
@mixin icon--file {
  background-position: 0 -650px;
}
@mixin icon--call-20 {
  background-position: -60px -60px;
}
@mixin icon--mail-20 {
  background-position: -60px -120px;
}
@mixin icon--arrowright-20 {
  background-position: -60px -180px;
}
@mixin icon--magnify-20 {
  background-position: -60px -480px;
}
@mixin icon--file-20 {
  background-position: -60px -650px;
}
@mixin icon--phone-20 {
  background-position: -60px 0;
}
@mixin icon--phone-20-white {
  background-position: -120px 0;
}
@mixin icon--download-20 {
  background-position: -60px -650px;
}

@mixin icon--standalone-20 {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 8px 0 0;
  top: 2px;
  position: relative;
}
