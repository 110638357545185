@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../../_helpers/component-design-token-maps';

.c-form-radio-panel {
  display: flex;
  flex-wrap: wrap;

  &__item {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  &__label:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &__label:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__item:focus + &__label {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &__item + &__label:hover {
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    @include border-color($toggleButton_elements_Button_interactionStates_Hover_styles_borderColor);
  }

  &__item:checked + &__label {
    border-width: 2px;
    border-style: solid;
    @include border-color($toggleButton_elements_Button_dataStates_Selected_styles_borderColor);
    padding: 6px 14px 8px;

    .opt-out-nitti-grotesk-font & {
      padding: 8px 14px 6px;
    }
  }

  &__item:checked + &__label {
    font-weight: bold;
  }

  &__item:disabled:checked + &__label,
  &__item:disabled:checked + &__label:hover {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
    padding: 7px 14px 9px;
    border-width: 1px;

    .opt-out-nitti-grotesk-font & {
      padding: 9px 14px 7px;
    }
  }

  &__item:disabled:checked + &__label:hover {
    padding: 7px 14px 7px;

    .opt-out-nitti-grotesk-font & {
      padding: 9px 14px 5px;
    }
  }

  &__item:disabled + &__label:hover {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
    padding: 7px 15px 9px;

    .opt-out-nitti-grotesk-font & {
      padding: 9px 15px 7px;
    }
  }

  &__item:disabled + &__label {
    opacity: 0.5;
    pointer-events: none;
  }

  &__item + &__label {
    border-width: 1px;
    border-style: solid;
    @include border-color($toggleButton_elements_Button_styles_borderColor);
    padding: 7px 15px 9px;
    display: block;

    .opt-out-nitti-grotesk-font & {
      padding: 9px 15px 7px;
    }
  }
}
