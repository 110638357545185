// Left for LEGACY

.c-form-field {

	&__label{
		margin-top: 9px;
	}

	&__element {
		margin-top: 9px;

		&--inline {
			display: inline-block;
			margin-right: 16px;
		}

		& + &--search {
			margin-top: 10px;
			margin-bottom: 1rem;
		}

		&--link {
			line-height: 1.25rem;
		}
	}

	&__icon {
		padding-top: 7px;
		font-size: 20px;
	}

	&--funnel {
		margin-bottom: 1rem;
		.c-form-field__label {
			float: left;
			width: 220px;
			padding-top: 9px;
		}

		.c-form-field__elements {
			margin-left: 250px;
		}

		.c-form-label {
			line-height: 1rem;
		}

		.c-form-field__element {
			line-height: 2rem;
		}
	}
}
