@import '../_helpers/sizes';

.o-layout__container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.75rem;
    max-width: 960px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;

    &--full {
        width: 100% !important;
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    &--page {
        width: $size_xl;
    }
    &--max {
        width: 100%;
        max-width: $size_xxl;
    }
}

html.is-responsive {
    .o-layout__container {

        @media (min-width: $size-xs) {
            & {
                padding-left: 30px;
                padding-right: 30px;
            }
        }

        @media (min-width: $size_xl) {
            & {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

// PRINT VERSION
@media print {
    .o-layout {
        &__container {
            padding: 0!important;
        }
    }
}
