@use "sass:math";
@use "sass:map";

@import "../_helpers/mixins";
@import "../_helpers/color";
@import "../_helpers/sizes";
@import "../_helpers/alias-design-token-maps";
@import "../_helpers/component-design-token-maps";

html {
	font-size: 100%;
}

body {
	font-family: "Nitti Grotesk NN Text", Helvetica, Arial, sans-serif;
	@include font-normal();
	@include font-size(16, 16 * 1.5);
  color: map.get($color_text_default, "light");
  background-color: map.get($backgroundColor_contentContainer_plain_default, "light");

  @media (prefers-color-scheme: dark) {
    &.opt-in-dark-mode {
      color: map.get($color_text_default, "dark");
      background-color: map.get($backgroundColor_contentContainer_plain_default, "dark");
    }
  }

  &.opt-out-nitti-grotesk-font {
    font-family: "NN Dagny", Helvetica, Arial, sans-serif;
  }
}

@mixin heading {
  font-family: "Nitti Grotesk NN Heading", Helvetica, Arial, sans-serif;
	@include font-bold();
	@include header-link;
  @include color($heading_elements_Heading_styles_color);
	margin-top: 0;

  .opt-out-nitti-grotesk-font & {
    font-family: "NN Dagny", Helvetica, Arial, sans-serif;
  }
}

@mixin heading-margin($lineHeight: 16, $totalHeight: 60) {
	margin-bottom: (($totalHeight - $lineHeight) * 0.5) + px;
	margin-bottom: math.div((($totalHeight - $lineHeight) * 0.5), $font-size-base) + rem;
}

h1, .h1 {
	@media(max-width: $size_xs) {
		@include font-size(28);
	}

	@include font-size(35, 35 * 1.1);
	@include heading-margin(37, 80);
	@include heading;

	@include color($heading_elements_Heading_variants_AttentionalXXL_styles_color);

	&--secondary {
		color: $nn_grey_1200;
	}

	&--huge {
		@include font-size(45, 45 * 1.1);
		@include heading-margin(47, 90);
	}
}

h2, .h2 {
	@include font-size(24, 24 * 1.1);
	@include heading-margin(25, 66);
	@include heading;
}

h3, .h3 {
	@include font-size(16, 16 * 1.1);
	@include heading-margin(20);
	@include heading;
}

h4, .h4 {
	@include font-size(16, 16 * 1.1);
	@include heading-margin;
	@include heading;
}

h5, .h5 {
	@include font-size(16, 16 * 1.1);
	@include heading-margin;
	@include heading;
}

h6, .h6 {
	@include font-size(16, 16 * 1.1);
	@include heading-margin;
	@include heading;
}

// DEPRECATED
// Left here for backwards compatibility
.c-title {
	@include heading;

	&-h1 {
		@extend .h1;
	}

	&-h2 {
		@extend .h2;
	}

	&-h3 {
		@extend .h3;
	}

	&-h4 {
		@extend .h4;
	}

	&--secondary {
		color: $nn_extra_dark_grey;
	}
}
