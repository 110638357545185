@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../c-inline-message/c-inline-message.mixin';
@import '../../_helpers/component-design-token-maps';

.c-form-input-group {
	position: relative;
	display: inline-block;
	width: 100%;

  // Prefix + suffix
	&:before, &:after {
    content: none;
		left: 8px;
		top: 50%;
    transform: translateY(-50%);
		position: absolute;
    height: 32px;
    line-height: 32px;
	}

  &[data-prefix]:before {
    content: attr(data-prefix);
		border-right-width: 1px;
		border-right-style: solid;
		@include border-color($input_elements_Input_styles_borderColor);
    padding-right: 8px;
  }

  // Suffix
  &[data-suffix]:after {
		content: attr(data-suffix);
		left: auto;
		border-left-width: 1px;
		border-left-style: solid;
		@include border-color($input_elements_Input_styles_borderColor);
    padding-left: 8px;
		right: 12px;
	}

	&[data-prefix] .c-form-text {
		padding-left: 32px;
	}

	&[data-suffix] .c-form-text {
		padding-right: 56px;
	}

	&__input {
		padding-right: 50px;
	}

	&__button {
		// Ident for :focus-visible outline
    right: 2px;
		top: 2px;
		bottom: 2px;

		position: absolute;
		border-radius: 50%;
    border: none;
    @include color($iconButton_elements_Icon_styles_color);
		font-size: 24px;
		text-decoration: none;
    @include background-color($iconButton_elements_Button_styles_backgroundColor);

    // Place on top of ::after
    z-index: 1;

		// TODO remove me if ie 11 isn't supported any longer
		// or when general button styling is fixed
		.ie11 & {
			padding-top: 5px;
		}

    &:hover {
      @include background-color($iconButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
    }

		&:focus-visible {
      outline-offset: 0;
      outline-style: solid;
      outline-width: 2px;
      @include outline-color($iconButton_elements_Button_interactionStates_FocusVisible_styles_outlineColor);
		}
	}

  ~ .u-color--medium-red,
  ~ .u-color--medium-orange.u-font-bold {
    @include c-inline-message;
  }
}
