/*

Funnel Viewport

Has 2 panels
- Sidebar (left)
- Mainbar (right)

Responsive behavior
Sidebar hides and can be opened by a toggle that is in view. The sidebar becomes a "sort of" overlay.
Also the sidebar get's it's own scroll context

+-------+--------------------------+
|       |                          |
|       |                          |
|       |                          |
|       |                          |
|       |                          |
|       |                          |
|       |                          |
+-------+--------------------------+

*/
$arrow-size: 15px;
.c-funnel-viewport {

	position: relative;


	&__row {

		overflow: auto;

		&:after {
			content: '';
			position: fixed;
			background-color: rgba(0, 0, 0, 0.3);
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s ease-out;
		}

		@media(min-width: $size_m) {
			display: flex;
			flex-wrap: nowrap;
			align-items: stretch;
		}
	}

	&__sidepanel {
		position: fixed;
		top:0;
		left: 0;
		height: 100%;
		width: 80%;
		background-color: $white;
		transform: translateX(-100%);
		z-index: 200;

		@media(min-width: $size_m) {
			position: static;
			top: auto;
			left: auto;
			width: 500px;
			transform: translateX(0);
			flex-grow: 0;
		}

		&__container {
			height: 100%;
		}
	}

	&__mainpanel {
		padding: 10px;
		@media(min-width: $size_m) {
			flex-grow: 1;
			padding: 15px 30px;
		}
	}

	&__toggle {
		position: absolute;
		left: 100%;
		top: 100px;
		background-color: $nn_dark_grey;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		cursor: pointer;

		@media(min-width: $size_m) {
			display: none;
		}

		&__close, &__open {
			color: #fff;
			padding: 5px 10px;
			text-decoration: none;
			display: inline-block;
		}

		&__close {
			display: none;
		}
	}

	&.is-opening &__sidepanel {
		transition: all 0.2s ease-out;
		transform: translateX(0);
	}

	&.is-closing &__sidepanel {
		transition: all 0.2s ease-out;
	}

	&.is-open &__sidepanel {
		transform: translateX(0);
	}

	/* states */
	&.is-open &__row:after {
		@media(max-width: $size_m) {
			opacity: 1;
			visibility: visible;
		}
	}

	&.is-open &__toggle {
		&__open {
			display: none;
		}
		&__close {
			display: inherit;
		}
	}
}

.c-funnel-viewport--25p-75p {
	&__sidepanel {
		width: 75%;
		@media(min-width: $size_m) {
			width: 25%;
		}
	}

	&__mainpanel {
		@media(min-width: $size_m) {
			width: 75%;
		}
	}
}