@import '../../../global/css/colors';

.charactercount {
	margin: 0;
	padding: 0;
	display: inline-block;
	min-width: 100%;
	position: relative;
	line-height:0.8rem;
}

/* PART: Counter text
=============================================================================*/
.charactercount-counter {
	position: relative;
	top: -1px;
	left: 0;
	padding: 4px 6px 3px;
	color: $nn_dark_grey;
	text-align: right;
	display: block;
	border-color: $nn_light_grey;
	border-radius: 0 0 4px 4px;
	border-style: solid;
	border-width: 0 1px 1px 1px;
	font-size: 11px;
}

.charactercount-counter--bottomright {
	position: absolute;
	top: auto;
	right: 0;
	bottom: 0;
	left: auto;
	border-radius: 4px 0 0 0;
	border-width: 1px 0 0 1px;
}