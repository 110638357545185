@import '../../../global/css/breakpoints';

.to-desktop {
	display: none;
}

@media (min-width: $mobile_break_point) {
	.is-responsive {
		.to-desktop {
			display: inherit;
		}
		.to-mobile {
			display: none;
		}
	}
}

@media (max-width: $mobile_break_point) {
	.to-desktop {
		display: none;
	}

	.to-mobile {
		display: inherit;
	}

	.is-responsive {
		.to-desktop {
			display: inherit;
		}

		.to-mobile {
			display: none;
		}
	}
}