.u-flex {
	display: flex;

	&--stretch {
		align-items: stretch;
	}

  &--center {
    align-items: center;
  }

  &--wrap {
		flex-wrap: wrap;
	}

	&--end {
		align-items: flex-end;
	}

	&--row-reverse {
		flex-direction: row-reverse;
	}
}
