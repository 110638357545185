.c-loading-overlay {
	display: none;
	position: fixed;
	z-index: 9100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	background: rgba(255, 255, 255, 0.5);
	transition: all 300ms;

	&.is-active {
		opacity: 1;
	}

	&__spinner {
		//Rotate an svg
		@extend .c-spinner;
  		animation: rotate .8s infinite linear;
  		transform: translateZ(0);
	}

	&__content {
		position: fixed;
		z-index: 9150;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);

			&__dialog {
				//dialog styling
				min-width: 400px;
				background-color: $white;
				@extend .u-border-radius;
				box-shadow: 0px 2px 13px rgba(0, 0, 0, .3);
				padding: 20px 20px;
				border: 1px solid $nn_light_grey;
				max-width: 400px;
				width: auto;

		}
	}


}

