@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../../../../nn-psdl-modules/src/icon/c-icon.variables';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* Notification
=============================================================================*/
%notification {
	@include border-radius(4px);
	position: relative;
  padding: 12px 16px 12px 42px;
	margin-bottom: 20px;

	&:before {
    font-family: 'nn-iconfont';
    font-size: 24px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 8px;
    padding-top: 12px;
    border-left-style: solid;
    border-left-width: 4px;
	}
}

/* PART: Title
=============================================================================*/
.notification-title {
	@include font-size(16);
  margin-bottom: 0.25rem;
}

/* PART: Content
=============================================================================*/
.notification-content {
	@include clearfix;
}

/* PART: Text
=============================================================================*/
.notification-text {
	@include font-size(16);
  margin-bottom: 0.25rem;
}

/* THEME: Success message
=============================================================================*/
.notification--success,
.notification--type2-success {
	@extend %notification;
  @include background-color($attentionBlock_variants_Success_elements_Container_styles_backgroundColor);

  
  &:before {
    content: $fonticon-content--pict-success;

    @include color($attentionBlock_variants_Success_elements_Icon_styles_color);
    @include border-color($attentionBlock_variants_Success_elements_Bar_styles_backgroundColor);
  }
}

/* THEME: Warning message
=============================================================================*/
.notification--warning,
.notification--type2-warning {
	@extend %notification;
  @include background-color($attentionBlock_variants_Warning_elements_Container_styles_backgroundColor);

  &:before {
    content: $fonticon-content--pict-info;

    @include color($attentionBlock_variants_Warning_elements_Icon_styles_color);
    @include border-color($attentionBlock_variants_Warning_elements_Bar_styles_backgroundColor);
  }
}

/* THEME: Info message
=============================================================================*/
.notification--info,
.notification--type2-info {
	@extend %notification;
  @include background-color($attentionBlock_variants_Informative_elements_Container_styles_backgroundColor);

  &:before {
    content: $fonticon-content--pict-info;

    @include color($attentionBlock_variants_Informative_elements_Icon_styles_color);
    @include border-color($attentionBlock_variants_Informative_elements_Bar_styles_backgroundColor);
  }
}

/* THEME: Error message
=============================================================================*/
.notification--error,
.notification--type2-error {
	@extend %notification;
  @include background-color($attentionBlock_variants_Error_elements_Container_styles_backgroundColor);

  &:before {
    content: $fonticon-content--pict-warning;

    @include color($attentionBlock_variants_Error_elements_Icon_styles_color);
    @include border-left-color($attentionBlock_variants_Error_elements_Bar_styles_backgroundColor);
  }
}

/* PART: Close button
=============================================================================*/
.notification-close-button {
	background: url('./notification/img/close-button.png') no-repeat center;
	width: 10px;
	height: 10px;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
}
