@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

@import 'buttons.variables';

/* EXTEND: Sprites
=============================================================================*/
.sprite--previous-next {
  background-image: url('./buttons/img/sprite--previous-next.svg');
  background-repeat: no-repeat;
}

/* EXTEND: Basic button
=============================================================================*/
.button--styling {
  @include font-medium;
  -moz-osx-font-smoothing: grayscale;

  @include font-size(16);
  line-height: 1;

  @include box-sizing-border-box;
  @include border-radius(4px);

  border: 0;
  @include background-color($primaryButton_elements_Button_styles_backgroundColor);
  cursor: pointer;

  @include color($primaryButton_elements_Button_styles_color);
  display: inline-block;

  padding: 9px 16px 13px 16px;

  .opt-out-nitti-grotesk-font & {
    padding: 11px;
  }
}

/* THEME: Disabled state
=============================================================================*/
@mixin button--disabled {
  background-color: $nn_extra_light_grey;
  color: $nn_medium_grey;
  cursor: default;
}

button[disabled],
.button[disabled],
.button.disabled,
a.button.disabled:before {
  @include button--disabled;
}

a.button.disabled:hover {
  color: $nn_medium_grey;
}

/* EXTEND: Remove button styling
=============================================================================*/
@mixin button--no-styling {
  background: none;
  border-color: transparent;
  @include border-radius(0);
}
.button--no-styling {
  @include button--no-styling;
}

/* PART: Button-like links
=============================================================================*/
a.button {
  text-decoration: none;

  &:hover {
    @include color($primaryButton_elements_Button_styles_color);
  }
}

/* PART: Light buttons
=============================================================================*/
.button--light {
  @extend .button--styling;
  @include background-color($secondaryButton_elements_Button_styles_backgroundColor);
  @include color($secondaryButton_elements_Button_styles_color);
  border-width: 1px;
  border-style: solid;
  @include border-color($secondaryButton_elements_Button_styles_borderColor);
  padding: 9px 16px 11px 16px;

  .opt-out-nitti-grotesk-font & {
    padding: 10px;
  }

  &:hover {
    @include border-color($secondaryButton_elements_Button_interactionStates_Hover_styles_borderColor);
    @include color($secondaryButton_elements_Button_styles_color);
    @include background-color($secondaryButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
  }

  &.disabled {
    color: $nn_medium_grey;
    padding: 11px;
    border: none;
    &:hover {
      color: $nn_medium_grey;
      background-color: $nn_extra_light_grey;
    }
  }
}

.button--light-noborder {
  @extend .button--styling;
  @include background-color($plainButton_elements_Button_styles_backgroundColor);
  @include color($plainButton_elements_Button_styles_color);
  border: none;
  text-decoration: none;
  &:hover {
    @include background-color($plainButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
    @include color($plainButton_elements_Button_interactionStates_Hover_styles_color);
  }
}


a.button--light-noborder:hover,
a.button--light:hover {
  color: inherit;
}

/* THEME: Next button
=============================================================================*/
.button--next {
  @extend .button--styling;
  @extend .sprite--previous-next;
  background-position: 100% 0;
  padding-right: 24px;

  .opt-out-nitti-grotesk-font & {
    padding-right: 24px;
  }

  &.disabled {
    background-position: 100% -36px;
  }
}

/* PART: Forward buttons
=============================================================================*/
a.button--forward,
a.button.button--forward {
  @extend .button--no-styling;
  background: none;
  color: $nn_extra_dark_grey;
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 50px;

  &:hover {
    color: $nn_extra_dark_grey;
  }

  &:after {
    @extend .button--styling;
    @extend .sprite--previous-next;
    background-position: 15px 0;
    content: ' ';
    display: inline-block;
    width: $button_height;
    height: $button_height;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.disabled {
    color: $nn_medium_grey;
    &:after {
      @include button--disabled;
      background-position: 15px -36px;
    }
  }
}

/* THEME: Back button
=============================================================================*/
.button--back,
a.button--back:hover {
  @include button--no-styling;
  color: $nn_extra_dark_grey;
}

a.button--back.disabled {
  color: $nn_medium_grey;
}

a.button--back,
.button--back-wrapper {
  display: inline-block;
  position: relative;
}
a.button--back {
  padding-left: 3em;
}

.button--back-wrapper > .button {
  @include button--no-styling;
  color: $nn_extra_dark_grey;
  padding-left: 3em;
  z-index: 3;
}

a.button--back:before,
.button--back-wrapper:before {
  display: inline-block;
  @extend .button--styling;
  @extend .sprite--previous-next;
  background-position: 15px -75px;
  content: ' ';
  width: $button_height;
  height: $button_height;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/*make label and arrow grey*/
.button--back-wrapper.disabled,
a.button--back.disabled {
  color: $nn_medium_grey;

  &:before {
    @include button--disabled;
    background-position: 15px -111px;
  }
}
/*make label grey*/
.button--back-wrapper.disabled > .button {
  color: $nn_medium_grey;
}

/* PART: Standard buttons
=============================================================================*/
//TODO don't style al buttons by default
button,
.button {
  @extend .button--styling;
}

/* PART: Add some distance between adjacent buttons
=============================================================================*/
button + button,
.button + .button,
.button + button,
button + .button {
  margin-left: 5px;
}

/* THEME: Responsive button
=============================================================================*/
@media (max-width: $mobile_break_point) {
  .is-responsive {
    .button--responsive {
      text-align: center;
      width: 100%;
      display: inline-block;
    }
  }
}

/* Button Light Responsive
=============================================================================*/
.button--light-resp,
a.button--light-resp:hover {
  @extend .button--no-styling;
  @include color($plainButton_elements_Button_interactionStates_Hover_styles_color);
}

/* COMPONENT: Video Play Button
=============================================================================*/

.image--video-button {
  position: relative;
  img {
    width: 100%;
    vertical-align: center;
  }
  a {
    display: block;
    position: relative;
    &:before {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('./buttons/img/video-play.svg');
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      height: 100%;
      width: 100%;
    }

    &:hover:before {
      background-image: url('./buttons/img/video-play-hover.svg');
    }
  }
}

.grid--gamma .video-play {
  margin-top: 30%;
}

@media (max-width: $tablet_break_point) {
  .grid--gamma .video-play {
    margin-top: 45%;
  }
}

/* COMPONENT: Service Column Button
=============================================================================*/
.button.button--service-column {
  text-decoration: none;
  @include box-sizing-border-box;
  cursor: pointer;
  display: block;
  padding: 0.3em 0.3em 0.3em 2.7em;
  margin: 0 0 3px;
  min-height: 45px;
  height: auto;
  max-width: 390px;
  position: relative;
  background-image: url('./buttons/img/sprite--service.svg');
  background-position: 8px 0;
  background-repeat: no-repeat;
}

/* Button add/remove
=============================================================================*/
@mixin button-circle-before {
  display: inline-block;
  background-color: $nn_medium_orange;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  color: #fff;
  margin-right: 10px;
}

.button--remove,
.button--minus {
  text-decoration: none;
  &:before {
    content: '';
    @include button-circle-before;
    background-size: 100%;
    margin-bottom: -2px;
  }

  &.disabled {
    cursor: default;
    color: $nn_medium_grey;
  }

  &.disabled:before {
    background-color: $nn_medium_grey;
  }
}

.button--remove {
  &:before {
    background-image: url('./buttons/img/button-icon-remove.svg');
  }
}

.button--add {
  text-decoration: none;

  &:before {
    content: '';
    @include button-circle-before;

    background-image: url('./buttons/img/button-icon-add.svg');
    background-size: 100%;
    margin-bottom: -2px;
  }
}
