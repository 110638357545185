@import 'c-dialog.variables';
@import '../_helpers/color';
@import '../_helpers/sizes';
@import '../_helpers/vars';
@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';

.is-modal-open {
	overflow: hidden;
}

.c-modal {
	position: fixed;

	width: 100vw;
	height: 100vh;

	overflow-x: hidden;
	overflow-y: auto;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	z-index: $modalLayer;

	transition: opacity $o-glass-transition-duration ease-out;
	background-color: rgba(0, 0, 0, 0.7);

	&.is-opening,
	&[data-state="opening"],
	&.is-opened,
	&[data-state="opened"] {
		opacity: 1;
	}

	&.is-closing,
	&[data-state="closing"],
	&.is-closed,
	&[data-state="closed"] {
		opacity: 0;
	}

	&__dialog {
		box-sizing: border-box;
		position: relative;
		width: auto;
		margin: 10px;
		@include box-shadow($modal_elements_Modal_styles_boxShadow);
		border-radius: 4px;
		@include background-color($modal_elements_Modal_styles_backgroundColor);
		transition: transform $o-glass-transition-duration ease-out, top .2s ease-out;

		&--positioned {
			position: absolute;
			margin: 0;
			border-width: 1px;
			border-style: solid;
			@include border-color($modal_elements_Modal_styles_borderColor);
		}
	}

	&__content {
		padding: $c-dialog-padding-size;

    h3 {
      margin-top: 6px;
    }
	}

	&__arrow {
		box-sizing: border-box;
		display: none;
	}

	&[data-arrow-position] &__arrow {
		display: block;
		position: absolute;

		&__part{
			&--two,
			&--three{
				position: absolute;
				border-style: solid;
				border-color: transparent;
				border-width: $c-dialog-arrow-size;
				width: 0;
				height: 0;
			}

			&--two{
				z-index: 2;
				@include color($modal_elements_Modal_styles_borderColor);
			}

			&--three{
				z-index: 3;
				color: $white;
			}
		}
	}

	&[data-arrow-position="left"] &__arrow{
		top: 0;
		left: -$c-dialog-arrow-size;
		padding: 2 * ($c-dialog-padding-size) + $c-dialog-arrow-size 0;

		&__part{
			top: $c-dialog-padding-size * 2;
			border-left-width: 0;
			border-right-color: currentColor;
		}

		&__part--two{
			left: 0;
		}

		&__part--three{
			left: 1px;
		}
	}

	&[data-arrow-position="right"] &__arrow{
		top: 0;
		right: 0;
		padding: 2 * ($c-dialog-padding-size) + $c-dialog-arrow-size 0;

		&__part{
			top: $c-dialog-padding-size * 2;
			border-right-width: 0;
			border-left-color: currentColor;
		}

		&__part--two{
			left: 1px;
		}

		&__part--three{
			left: 0;
		}
	}

	&[data-arrow-position="top"] &__arrow{
		padding: 0 2 * ($c-dialog-padding-size) + $c-dialog-arrow-size;
		top: -$c-dialog-arrow-size;

		&__part{
			left: $c-dialog-padding-size * 2;
			border-top-width: 0;
			border-bottom-color: currentColor;
		}

		&__part--two{
			top: -1px;
		}

		&__part--three{
			top: 0;
		}
	}

	&[data-arrow-position="bottom"] &__arrow{
		padding: 0 2 * ($c-dialog-padding-size) + $c-dialog-arrow-size;
		bottom: 0;

		&--align_left &__part{
			left: 0
		}

		&--align_middle &__part{
			left: calc(50%);
		}

		&--align_right &__part{
			right: 0;
		}

		&__part{
			left: $c-dialog-padding-size * 2;
			border-bottom-width: 0;
			border-top-color: currentColor;
		}

		&__part--two{
			top: 1px;
		}

		&__part--three{
			top: 0;
		}
	}

	&.is-opening &__dialog,
	&[data-state="opening"] &__dialog,
	&.is-opened &__dialog,
	&[data-state="opened"] &__dialog {
		transform: translate(0, 0);
	}

  &__dialog,
	&.is-closing &__dialog,
	&[data-state="closing"] &__dialog,
	&.is-closed &__dialog,
	&[data-state="closed"] &__dialog {
		transform: translate(0, -25%);
	}

	&__close-btn {
		position: relative;
		z-index: 10;
		float: right;
		font-size: 1.5rem;
		padding: 6px;
		margin: $c-dialog-padding-size $c-dialog-padding-size 0 $c-dialog-padding-size;
		text-decoration: none;
		border-radius: 50%;
		@include color($iconButton_elements_Icon_styles_color);

		&:hover {
			@include background-color($iconButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
		}
	}

	// themes

	&--l &__dialog {
		@media (min-width: $size_l) {
			margin: 30px auto;
			width: 800px;
		}
	}

	&--m &__dialog {
		@media (min-width: $size_s) {
			width: 500px;
			margin: 30px auto;
		}
	}

	&--middle &__dialog {
		margin: 0;
		width: 80%;
		position: absolute;
		top: 50%;
		left: 50%;

		@media (min-width: $size_s) {
			width: 500px;
		}
	}

	&--middle.is-opening &__dialog,
	&--middle[data-state="opening"] &__dialog,
	&--middle.is-opened &__dialog,
	&--middle[data-state="opened"] &__dialog{
		transform: translate(-50%, -50%);
	}

  &--middle &__dialog,
	&--middle.is-closing &__dialog,
	&--middle[data-state="closing"] &__dialog,
	&--middle.is-closed &__dialog,
	&--middle[data-state="closed"] &__dialog {
		transform: translate(-50%, -75%);
	}
}
