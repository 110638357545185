@import '../_helpers/mixins';
@import '../_helpers/color';

.c-review {
	border-top: 1px solid $nn_ultra_light_grey;
	border-bottom: 1px solid $nn_ultra_light_grey;
	border-left: 1px solid $nn_ultra_light_grey;
	border-right: 1px solid $nn_ultra_light_grey;
	margin: 0 0 20px 0;
	position: relative;
	background: $nn_ultra_light_grey;
	&__profile {
		background: $nn_ultra_light_grey;
		padding: 20px;
	}

	&__author {
		@include font-bold;
		display: block;
		margin-bottom: 5px;
	}

	&__content {
		padding: 50px 20px 10px 20px;
		position: relative;
		background: $white;
		min-height: 85px;
		.c-rating {
			position: absolute;
			right: 0;
			top: 15px;
		}
	}

	&__date {
		@include font-size(14);
		color: $nn_dark_grey;
	}
}

@media (max-width: $size-s) {

	.c-review {
		&:before {
			display: none;
		}

		&__profile {
			padding: 10px;
		}

		&__content {
			padding: 50px 20px 10px 10px;
		}
	}

}
