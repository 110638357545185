@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';

/* Global Form Styles
=============================================================================*/
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

/* COMPONENT: form fieldset
=============================================================================*/
.form-fieldset {
  width: 100%;
  min-width: 0;
  padding: 0 0 10px 0;
}

@-moz-document url-prefix() {
  .form-fieldset {
    display: table-cell;
  }
}

.mandatory-fields {
  font-style: italic;
  text-align: right;
  width: 100%;
  margin: 0;
  padding: 0;
  color: $nn_medium_grey;
  font-size: 0.6875em;
}

/* @todo: check with Robert */
.form-fieldset--header {
  hr {
    clear: both;
    margin-top: 10px;
  }
}

/* THEME: fieldset responsive
============================================================================= */

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .form-fieldset {
      width: 100%;

      .grid--block-beta {
        margin: 0 0 15px 0;
      }
    }
  }
}

/* COMPONENT: field label
=============================================================================*/

.field-label {
  @include font-medium;
  padding-top: 12px;
}

.data-table-cell {
  .field-label {
    display: inline-block;
    @include font-size(16);
  }
}

div.field-label {
  padding-top: 8px;
}

.label-hint {
  display: inline;
  float: left;
  width: 100%;
  @include font-size(12);
  line-height: 1.2em;
  color: $nn_medium_grey;
  margin: 0;
  padding: 0;
}

label .prefix-unit,
label .postfix-unit {
  display: none;
}

/* CONTEXT: touch-fields
=============================================================================*/
.touch-fields {
  .field-label {
    padding-top: 10px;
  }

  .field-label--radio {
    padding-top: 0;
  }
}

/* remove .mandatory.field-label <- is still being used by Aquima */
.mandatory.field-label:after,
.mandatory > .field-label:after {
  content: '*';
}

/* CONTEXT: grid--block-eta
=============================================================================*/
.grid--block-eta {
  .field-label {
    padding-left: 20px;
  }
  .field-label:first-child {
    padding-left: 0;
  }
}

@media (max-width: $tabmob_break_point) {
  .is-responsive .field-label {
    padding-left: 0;
  }
}

.field--radiobutton {
  position: relative;
}

.field--checkbox {
  position: relative;
}

.field--checkbox-alpha {
  line-height: 37px;

  .checkbox--custom {
    margin-top: 18px;
  }
}

.masthead .field-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

/* THEME: Checkbox
=============================================================================*/
.field-label--checkbox {
  cursor: pointer;
  display: inline-block;
  line-height: 1.5em;
  margin-left: 22px;
  margin-bottom: 6px;
  margin-top: 1px;
}

/* THEME: Radio button label
=============================================================================*/
.field--radiobutton .field-label,
.field-label--radiobutton {
  cursor: pointer;
  display: inline-block;
  line-height: 1.5em;
  margin-left: 22px;
  margin-bottom: 6px;
  padding-top: 0;
}

.field-label--radio {
  cursor: pointer;
  display: inline-block;
  margin-left: 22px;
}

/* THEME: Discount
=============================================================================*/
.field-label--discount {
  span {
    position: absolute;
    left: 24px;
    top: 20px;
  }
}

/* THEME: Licenseplate
=============================================================================*/
.field-label--licenseplate {
  @extend .field-label--checkbox;

  margin-bottom: 10px;
}

/* CONTEXT: Checkbox
=============================================================================*/

/* THEME: responsive
=============================================================================*/
@media (max-width: $mobile_break_point) {
  .is-responsive {
    .field-label {
      float: none;
      padding-left: 0;
    }
  }
}

/* THEME: form actions login
=============================================================================*/

@media (max-width: $desktop_break_point) {
  .is-responsive {
    .form-actions--login {
      padding-left: 150px;
    }
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .form-actions--login {
      padding-left: 150px;
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive .form-actions--login {
    padding-left: 0;
  }
}

.displayNone {
  display: none;
}
/* TODO: create style to add spacing between fields */
/*.controls-row [class*="span"] + [class*="span"] {
margin-left: 20px;
}*/

.field-group {
  .field {
    display: inline-block;
  }

  .field + .field {
    margin: 0 0 0 10px;
  }
}

/* COMPONENT: Buttons container
=============================================================================*/
.buttons {
  position: relative;
  padding-top: 24px;
  padding-bottom: 10px;
  border-top: 2px solid $nn_light_grey;
  width: 100%;

  .submit {
    float: right;

    &:after {
      clear: both;
      content: '';
      display: block;
    }
  }

  .back {
    &:after {
      clear: both;
      content: '';
      display: block;
    }
  }
}

/* THEME: Masthead button styling
=============================================================================*/
.buttons--masthead {
  border: 0;
  padding-top: 0;
}

.form--feedforward .buttons--masthead {
  width: 93%;
}

.buttons--simple {
  border-top: none;
  padding: 0;
}

.block--alert + .buttons--simple {
  padding-top: 0;
}

.buttons--filter {
  padding: 0;
  border: none;
  overflow: inherit;
  height: auto;

  .submit {
    position: absolute;
    bottom: 21px;
    left: 300px;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive .buttons--filter {
    margin-bottom: 10px;

    .submit {
      float: none;
      position: static;
      left: 0;
    }
  }
}
