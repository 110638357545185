/* PART: glass
=============================================================================*/
#glass {
	display: none;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	background: #000;
	width: 100%;
	height: 100%;
	opacity: 0.4;
}