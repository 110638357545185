@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: Footer
=============================================================================*/
.footer-section {
  @include background-color($pageFooter_elements_Container_styles_backgroundColor);
  overflow: hidden;
  margin: 30px auto 0 auto;
  min-width: 990px;
  padding-top: 56px;
  padding-bottom: 30px;
  .is-wide & {
    max-width: 1280px;
  }

  hr {
    margin: 0;
  }

  .link-list {
    li {
      padding: 0;
      margin-bottom: 10px;
      font-size: 0.875rem;
      &:before {
        display: none;
      }
    }

    a {
      @include color($pageFooter_elements_ListItem_styles_color);
      margin: 0;
      padding: 0;
      background: none;
      &:before {
        display: none;
      }
    }
    a:hover {
      @include color($pageFooter_elements_ListItem_interactionStates_Hover_styles_color);
    }
  }

  .link-list--beta {
    position: absolute;
    right: 0;
  }

  .icons--social-v2 {
    float: left;
    margin: 16px 15px 0 0;
    li {
      &:before {
        display: none;
      }
    }
  }

  .icons--trust-builder {
    float: right;
    margin: 16px 0 0 15px;
    li {
      &:before {
        display: none;
      }
    }
  }
}

.footer-link-list-group {
  font-size: 0; /* xc whitespace hack */
  margin: 0 0 16px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;

  > li {
    background-image: none;
    font-size: 14px;
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }

  > li.active {
    .link-list {
      display: block;
    }

    h5:before {
      display: none;
    }
  }

  h5 {
    @include color($heading_elements_Heading_styles_color);
    cursor: pointer;
    margin: 0 0 14px 0;
    padding: 0;
    line-height: 18px;
  }
}

@media all {
  .is-responsive {
    .footer-section {
      min-width: 0;
    }
  }
}
@media (max-width: $tablet_break_point) {
  .is-responsive {
    .footer-section .link-list--beta {
      position: static;
    }

    .footer-link-list-group {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .footer-section {
      padding-top: 2px;

      .icons--trust-builder {
        float: left;
      }
    }

    .footer-section-wrapper {
      padding: 0 16px;
    }

    .footer-link-list-group {
      grid-template-columns: 1fr;
      row-gap: 0;
      // Screen wide over the padding of footer-section-wrapper due to dividers between list items.
      margin: 0 -16px;

      > li {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        @include border-bottom-color($pageFooter_elements_ListItem_styles_borderColor);
        margin: 0;
        padding: 14px 20px;

        &.active {
          padding-bottom: 0;

          h5 {
            margin: 0 0 14px 0;
          }

          .link-list {
            display: block;
          }

          h5:before {
            display: none;
          }
        }

        .link-list {
          display: none;
        }
      }

      h5 {
        position: relative;
        margin: 0;
        padding: 0;

        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 1px;
          height: 16px;
          @include background-color($icon_elements_Icon_styles_color);
          top: 1px;
          right: 7px;
        }

        &:after {
          width: 16px;
          height: 1px;
          top: 9px;
          right: 0px;
        }
      }
    }
  }
}

.footer-section-wrapper {
  margin: 0 auto;
  max-width: $desktop_break_point;
  padding: 0 32px;
  box-sizing: border-box;
  overflow: hidden;

  .is-wide & {
    max-width: 1280px;
  }
}

.footer-section .footer-link-list-group > li {
  @include box-sizing-border-box;
}

.footer-link-list-group--inline {
  position: relative;
  overflow: visible;
  margin: 0;
  padding-top: 12px;
}

.link-list-group--inline {
  a {
    @include font-size(14);
  }
}

// MATTER 2023 version
.footer-component {
  background-color: $white;
  width: 100%;
  min-width: 280px;

  .footer__nav {
    max-width: $desktop_break_point;
    margin: 0 auto;
  }

  .footer__base {
    max-width: $desktop_break_point;
    margin: 0 auto;
    box-sizing: border-box;

    .footer__socials-section {
      padding: 24px 0;
      border-top: 1px solid $nn_sand_200;
      border-bottom: 1px solid $nn_sand_200;
      @include flexbox();
      align-items: center;

      @media (max-width: $mobile_break_point) {
        flex-direction: column;
        align-items: center;
      }

      .footer__logo {
        height: 55px;
        margin-right: 16px;
      }

      .footer__socials-block {

        ul {
          @include flexbox();
          column-gap: 16px;
          margin-bottom: 0;
          @media (max-width: $mobile_break_point) {
            margin-top: 24px;
            margin-left: -16px; // to alight with the logo
          }

          li {
            font-size: 1.25rem;
            position: inherit;
            padding-left: 0px;

            .c-icon {
              color: $nn_grey_800;
            }
          }
        }
      }
    }

    .footer__service-section {
      box-sizing: border-box;
      padding: 20px 0;
      @include flexbox();
      justify-content: space-between;
      @media (max-width: $tabmob_break_point) {
        flex-direction: column;
        align-items: center;
      }

      .service-section__service-options {
        @include flexbox();
        @media (max-width: $mobile_break_point) {
          flex-direction: column;
          align-items: center;
        }

        li {
          position: inherit;
          padding-left: 16px;
          font-size: 0.875rem;
          color: $nn_grey_800;
          
          a {
            text-decoration: none;
          }
        }
      }

      .service-section__copyrights {
        font-size: 0.875rem;
        color: $nn_grey_800;
      }
    }
  }
}
