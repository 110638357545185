@import '../_helpers/color';
@import '../_helpers/mixins';
@import '../icon/c-icon.variables';
@import './c-link.mixin';
@import '../_helpers/component-design-token-maps';

.c-link,
.c-link--bold,
.c-link--highlight,
.c-link-highlight {
  @include c-link;

  // This allows the class to be placed on a container such as a span, rather
  // than on the anchor itself. For instance, this is necessary for the Blueriq
  // thymeleaf templates.
  a:link,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    text-decoration: inherit;
  }
}

.c-link--info {
  @include color($link_elements_Text_variants_Secondary_styles_color);
	text-decoration: none;
	user-select: none;

	&:hover {
    @include color($link_elements_Text_variants_Secondary_styles_color);
	}

	&:focus {
		outline: -webkit-focus-ring-color auto 5px;
	}
}

.c-link--add--icon_right,
.c-link--download,
.c-link--remove--icon_right {
	text-decoration: none;
}

.c-link--download:before {
	width: 30px;
	display: inline-block;
	content: $fonticon-content--pict-download;
	font-family: 'nn-iconfont';
	color: $nn-medium-orange;
	font-size: 20px;
}

.c-link--add--icon_right:after,
.c-link--remove--icon_right:after {
	width: 30px;
	text-align: right;
	display: inline-block;
	font-family: 'nn-iconfont';
	color: $nn-medium-orange;
	font-size: 20px;
}

.c-link--add--icon_right:after {
	content: $fonticon-content--pict-plus
}

.c-link--remove--icon_right:after {
	content: $fonticon-content--pict-minus;
}

.c-link--glossary {
	text-decoration: none;
	border-bottom: 2px dotted;
}