@import '../_helpers/mixins';
@import '../_helpers/color';

.c-testimonial {
	&__title {
		color: $nn_medium_orange;
	}
	&__quote {
		@include font-normal;
		margin: 0 0 8px;
		p {
			margin: 0;
		}
	}

	&__meta {
		display: block;
		color: $nn_medium_grey;
		margin: 0 0 15px ;
		@include font-normal;
	}
}