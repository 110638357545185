@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';

/*
 * COMPONENT: PSDL Single Panel
 * =============================================================================
 */
.single-panel {
  display: block;
  position: relative;
  margin: 0;
  background-color: $nn_ultra_light_grey;
  border-width: 2px 2px 0 2px;
  border-style: solid;
  border-color: $nn_light_grey transparent $nn_light_grey transparent;
  border-top-color: $nn_medium_orange;
  border-bottom: 0 $nn_light_grey;

  &:last-child {
    border-bottom-width: 2px;
  }
}

/*
 * PART: Single panel title
 * =============================================================================
 */
.single-panel-header {
  display: block;
  padding: 1px 5px 0 14px;
  height: 35px;
  line-height: 37px;
}

.single-panel-header .checkbox--custom {
  top: 10px;
  left: 10px;
}

.single-panel-title {
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  @include font-bold;
  font-size: 16px;
  color: $nn_medium_grey;
}

.is-active .single-panel-title {
  color: $nn_medium_orange;
}

/*
 * WORKAROUND: Nasty !important to circumvent old bloated specificity
 * =============================================================================
 */
.single-panel-title {
  margin-left: 25px !important;
}

/*
 * PART: Single panel content
 * =============================================================================
 */
.single-panel-content {
  padding: 12px;
}
.single-panel-content:after {
  content: '';
  display: table;
  clear: both;
}
.single-panel-content-container {
  overflow: hidden;
}

/* PART: Animations
=============================================================================*/
.single-panel--animated {
  -webkit-transition: border-top-color 0.5s;
  -moz-transition: border-top-color 0.5s;
  -o-transition: border-top-color 0.5s;
  -ms-transition: border-top-color 0.5s;
  transition: border-top-color 0.5s;

  .single-panel-content-container {
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -o-transition: height 0.5s;
    -ms-transition: height 0.5s;
    transition: height 0.5s;
  }
}

/*
 * PART: Single panel toggle
 * =============================================================================
 */
.single-panel-toggle {
  display: block;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    height: 2px;
    width: 16px;
    background: black;
  }
  &:before {
    display: none;
  }
}

/*
 * STATUS: Closed
 * =============================================================================
 */
.single-panel--closed {
  background-color: transparent;
  border-top-color: $nn_light_grey;

  .single-panel-toggle {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      height: 2px;
      width: 16px;
      background: black;
    }
    &:before {
      content: '';
      position: absolute;
      left: 7px;
      top: 0;
      width: 2px;
      height: 16px;
      background: black;
      display: block;
    }
  }
}
