@use "sass:math";

@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../../../../nn-psdl-modules/src/_helpers/_mixins.scss';

/* COMPONENT: step-indicator-v2
=============================================================================*/

.step-indicator-v2 {
	margin: 0 0 25px 0;
}

/* PART: labels-top, steps and labels-bottom shared
=============================================================================*/

.step-indicator-v2-labels-top,
.step-indicator-v2-steps,
.step-indicator-v2-labels-bottom {
	@include list-style-none;
	margin: 0;
}

.step-indicator-v2-steps {
	height: 30px;
	overflow: hidden;
}

.step-indicator-v2-labels-top > li,
.step-indicator-v2-steps > li,
.step-indicator-v2-labels-bottom > li {
	@include box-sizing-border-box;
	position: relative;
	display: inline-block;
	width: 115px;
	margin: 0;
	padding: 0;
	border-left: 1px solid transparent;
	vertical-align: top;
	text-align: center;
	/*box-sizing: border-box;*/
	font-size: math.div(14, 16) + 0em;

}

.step-indicator-v2-labels-top > li,
.step-indicator-v2-labels-bottom > li {
	&:before { display:none; }
}

.step-indicator-v2-steps > li {

	&.sub,
	&.last,
	&:last-child {

		&:before {
			display: none;
		}
		&:after {
			display: none;
		}
	}


	&.sub--last:after,
	&.current:after,
	&:after {
		content: " ";
		display: block;
		width: 0;
		height: 0;
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
		border-left: 10px solid $nn_light_grey;
		position: absolute;
		top: 50%;
		margin-top: -20px;
		left: 100%;
		z-index: 2;
	}

	&.sub--last:before,
	&.current:before,
	&:before {
		content: " ";
		display: block;
		width: 0;
		height: 0;
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
		border-left: 10px solid $white;
		position: absolute;
		top: 50%;
		margin-top: -20px;
		margin-left: 2px;
		left: 100%;
		z-index: 1;
	}


}


.step-indicator-v2-steps li.visited,
.step-indicator-v2-steps li.current {
	background-color: $nn_medium_orange;
	color: #fff;

	&:after {
		border-left-color: $nn_medium_orange;
	}
}


/* PART: labels-top
=============================================================================*/

.step-indicator-v2-labels-top {
	margin-bottom: 8px;
}

.step-indicator-v2-labels-top > li {
	font-weight: bold;
	vertical-align: bottom;
}

.step-indicator-v2-labels-top > li.current,
.step-indicator-v2-labels-top > li.visited {
	color: $nn_light_orange;
}

/* PART: steps
=============================================================================*/

.step-indicator-v2-steps > li {
	height: 30px;
	background: #C7C7C7;
	border-color: #fff;
	line-height: 30px;
}

/* Fist step */
.step-indicator-v2-steps > li:first-child {
	border-left: 0;
	border-left-style: none;
/*	@include border-radius-sep(5px, 0, 0, 5px);*/
}

/* Last step */
.step-indicator-v2-steps > li.last {

	@include border-radius-sep(0, 5px, 5px, 0);
}

.step-indicator-v2-steps > li:first-child.last {
	border-left: 0;

	@include border-radius-sep(5px, 5px, 5px, 5px);
}


/* Step active/current state */
.step-indicator-v2-steps > li.current,
.step-indicator-v2-steps > li.visited {
	background-color: $nn_medium_orange;
	color: #fff;
}

.step-indicator-v2-steps > li.current a,
.step-indicator-v2-steps > li.visited a {
	display: block;
	height: 30px;
	color: #fff;
	text-decoration: none;
}

/* Sub step */
.step-indicator-v2-steps > li.sub + li.sub {
	border-left-color: #C7C7C7;
}

/* Sub step active/current state */
.step-indicator-v2-steps > li.sub + li.sub.current,
.step-indicator-v2-steps > li.sub + li.sub.visited {
	border-left-color: $nn_medium_orange;
}

/* PART: labels-bottom
=============================================================================*/

.step-indicator-v2-labels-bottom li {
	padding-top: 10px;
	color: #c7c7c7;
	&:before {
		display:none;
	}
}

.step-indicator-v2-labels-bottom > li.current,
.step-indicator-v2-labels-bottom > li.visited {
	color: $nn_light_orange;
}

.step-indicator-v2-labels-bottom > li.current {

	&:after {
		color: $nn_medium_orange;
		font-family: 'nn-iconfont';
		font-size: 30px;
		content: '\e902';
		position: absolute;
		top: -16px;
		left: 50%;
		margin: 0 0 0 -15px;
	}

}

.step-indicator-v2-labels-bottom > li.visited a {
	color: $nn_light_orange;
	text-decoration: none;
}

@media (max-width: $mobile_break_point) {

	.is-responsive {

		.step-indicator-v2 {
			margin: 0 0 5px 0;
		}

		.step-indicator-v2-labels-bottom li {
			visibility: hidden;
		}
		.step-indicator-v2-labels-bottom .current {
			visibility: visible;
		}

		.step-indicator-v2-labels-bottom .last {
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

	}
}

/* THEME: contains-1
=============================================================================*/

.step-indicator-v2--contains-1 li {
	width: 100%;
}

/* THEME: contains-2
=============================================================================*/

.step-indicator-v2--contains-2 li {
	width: 50%;
}

.step-indicator-v2--contains-2 li.span-2 {
	width: 100%;
}

/* THEME: contains-3
=============================================================================*/

.step-indicator-v2--contains-3 li {
	width: 33.33%;
}

.step-indicator-v2--contains-3 li.span-2 {
	width: 66.66%;
}

.step-indicator-v2--contains-3 li.span-3 {
	width: 100%;
}

/* THEME: contains-4
=============================================================================*/

.step-indicator-v2--contains-4 li {
	width: 25%;
}

.step-indicator-v2--contains-4 li.span-2 {
	width: 50%;
}

.step-indicator-v2--contains-4 li.span-3 {
	width: 75%;
}

.step-indicator-v2--contains-4 li.span-4 {
	width: 100%;
}

/* THEME: contains-5
=============================================================================*/

.step-indicator-v2--contains-5 li {
	width: 20%;
}

.step-indicator-v2--contains-5 li.span-2 {
	width: 40%;
}

.step-indicator-v2--contains-5 li.span-3 {
	width: 60%;
}

.step-indicator-v2--contains-5 li.span-4 {
	width: 80%;
}

.step-indicator-v2--contains-5 li.span-5 {
	width: 100%;
}

/* THEME: contains-6
=============================================================================*/

.step-indicator-v2--contains-6 li {
	width: 16.66%;
}

.step-indicator-v2--contains-6 li.span-2 {
	width: 33.33%;
}

.step-indicator-v2--contains-6 li.span-3 {
	width: 50%;
}

.step-indicator-v2--contains-6 li.span-4 {
	width: 66.66%;
}

.step-indicator-v2--contains-6 li.span-5 {
	width: 83.33%;
}

.step-indicator-v2--contains-6 li.span-6 {
	width: 100%;
}

/* THEME: contains-7
=============================================================================*/

.step-indicator-v2--contains-7 li {
	width: 14.28%;
}

.step-indicator-v2--contains-7 li.span-2 {
	width: 28.57%;
}

.step-indicator-v2--contains-7 li.span-3 {
	width: 42.85%;
}

.step-indicator-v2--contains-7 li.span-4 {
	width: 57.14%;
}

.step-indicator-v2--contains-7 li.span-5 {
	width: 71.42%;
}

.step-indicator-v2--contains-7 li.span-6 {
	width: 85.71%;
}

.step-indicator-v2--contains-7 li.span-7 {
	width: 100%;
}

/* THEME: contains-8
=============================================================================*/

.step-indicator-v2--contains-8 li {
	width: 12.5%;
}

.step-indicator-v2--contains-8 li.span-2 {
	width: 25%;
}

.step-indicator-v2--contains-8 li.span-3 {
	width: 37.5%;
}

.step-indicator-v2--contains-8 li.span-4 {
	width: 50%;
}

.step-indicator-v2--contains-8 li.span-5 {
	width: 62.5%;
}

.step-indicator-v2--contains-8 li.span-6 {
	width: 75%;
}

.step-indicator-v2--contains-8 li.span-7 {
	width: 87.5%;
}

.step-indicator-v2--contains-8 li.span-8 {
	width: 100%;
}

/* THEME: contains-9
=============================================================================*/

.step-indicator-v2--contains-9 li {
	width: 11.11%;
}

.step-indicator-v2--contains-9 li.span-2 {
	width: 22.22%;
}

.step-indicator-v2--contains-9 li.span-3 {
	width: 33.33%;
}

.step-indicator-v2--contains-9 li.span-4 {
	width: 44.44%;
}

.step-indicator-v2--contains-9 li.span-5 {
	width: 55.55%;
}

.step-indicator-v2--contains-9 li.span-6 {
	width: 66.66%;
}

.step-indicator-v2--contains-9 li.span-7 {
	width: 77.77%;
}

.step-indicator-v2--contains-9 li.span-8 {
	width: 88.88%;
}

.step-indicator-v2--contains-9 li.span-9 {
	width: 100%;
}

/* THEME: contains-10
=============================================================================*/

.step-indicator-v2--contains-10 li {
	width: 10%;
}

.step-indicator-v2--contains-10 li.span-2 {
	width: 20%;
}

.step-indicator-v2--contains-10 li.span-3 {
	width: 30%;
}

.step-indicator-v2--contains-10 li.span-4 {
	width: 40%;
}

.step-indicator-v2--contains-10 li.span-5 {
	width: 50%;
}

.step-indicator-v2--contains-10 li.span-6 {
	width: 60%;
}

.step-indicator-v2--contains-10 li.span-7 {
	width: 70%;
}

.step-indicator-v2--contains-10 li.span-8 {
	width: 80%;
}

.step-indicator-v2--contains-10 li.span-9 {
	width: 90%;
}

.step-indicator-v2--contains-10 li.span-10 {
	width: 100%;
}
