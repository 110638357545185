@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../../_helpers/component-design-token-maps';

.c-form-textarea {
	box-sizing: border-box;
	border-width: 1px;
	border-style: solid;
	@include border-color($input_elements_Input_styles_borderColor);
  @include border-radius(4px);
	padding: 8px 16px;
	width: 100%;

	&--error {
    border-width: 2px;
		@include border-color($attentionBlock_variants_Error_elements_Bar_styles_backgroundColor);
	}

	/* PLACEHOLDER */
	&::-webkit-input-placeholder {
	   color: $nn_light_grey;
	}
	&:-ms-input-placeholder {
	   color: $nn_light_grey;
	}
	&::-moz-placeholder {
		color: $nn_light_grey;
		opacity: 1;
	}
	&:-moz-placeholder {
		color: $nn_light_grey;
		opacity: 1;
	}

	&[disabled] {
		background-color: transparent;
	}
}
