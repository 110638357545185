.c-download-link{
	display: inline-block;
	&--spinner{
		display: none;
	}

	&.is-busy &--spinner{
		display: inline-block;
		vertical-align: middle;
	}
}