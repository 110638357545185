@import '../../../global/css/colors';

@import '../../../control/buttons/css/buttons.variables';

/* COMPONENT: pagination
=============================================================================*/

.pagination {
	position: relative;

	a.button--back,
	a.button--forward {
		background: none;
		position: absolute;
		color: $nn_extra_dark_grey;

		&:hover {
			background: none;
		}
	}

	ol {
		@include list-style-none;
		text-align: center;
		line-height: $button_height;
	}
	li {
		color: $nn_dark_grey;
		cursor: pointer;
		display:inline;
		margin: 0 5px;
		a {
			text-decoration: none;
		}
		&.active {
			color: $nn_medium_orange;
			text-decoration: none;
			a {
				color: $nn_medium_orange;
				text-decoration: none;
			}
		}
		&:before {
			display: none;
		}
	}
	li:hover {
		color: $nn_medium_orange;
	}

	&__results-per-page {

		display: inline-block;
		width: 100%;
		text-align: left;

		@media (min-width: $mobile_break_point) {
			text-align: right;
		}

		&__text {
			padding-top: 9px;
			margin: 0;
		}

		&__label {
			display: inline-block;
			width: auto;
		}

		&__element {
			margin: 0;
			display: inline-block;
		}

		&__label + &__element {
			margin-left: 20px;
		}
	}

}

.touch-fields {
	.pagination {
		a.button--back,
		a.button--back.disabled {
			background: none;
			position: absolute;
		}
		/*ol {
			line-height: $button_height;
		}*/
	}
}

@media (max-width: $mobile_break_point) {
	.is-responsive {
		.pagination {
			.button--back,
			.button--forward {
				text-indent: -10000px;
			}
		}
	}
}

/* CONTEXT: Pagination
=============================================================================*/
@media (max-width: $mobile_break_point) {
	.is-responsive {
		.pagination {
			.button--back,
			.button--forward {
				width: $button_height;
				height: $button_height;
				text-indent: 100%;
				overflow: hidden;

				&:before,
				&:after {
					position: absolute;
					text-indent: 0;
				}
			}

			.button--back:before {
				left: 0;
			}

			.button--forward:after {
				right: 0;
			}
		}
	}
}

/* THEME: Disabled back/forward buttons
=============================================================================*/
a.button--back.disabled,
a.button--forward.disabled {
	display: none;
}