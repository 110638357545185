.flex-page-wrapper {
    display: flex;
    flex-direction: column;

    
    /* specific overrides for existing responsive behaviour */
    .flex-header,
    .flex-page,
    .flex-footer {
        width: 100%!important;
        min-height: 0!important;
        min-width: 0!important;
    }

    .flex-header {
        flex: 0 0 auto;
    }

    .flex-page {
        flex-grow: 1;
        flex-shrink: 0;
        height: auto;
    }

    .flex-footer {
        flex: 0 0 auto;
    }
}