// prevent styling which was set on level too high, remove when item is solved!
._ul-reset {
	margin-bottom: 0;
}

._li-reset {
	padding-left: 0;
	background: none; // FIXME: STATIC sets background image to every li

	&:before {
		display: none
	}
}

