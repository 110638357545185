
/* COMPONENT: Logo
=============================================================================*/
/*Note: bounding box must be half the height of logoimage*/

/* EXTEND: Basic logo
=============================================================================*/

.logo--nn-styling {
	border: 0;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	background-color: transparent;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 100%;
	background-image: url('../img/logo-nn.svg');
}




/* PART: Logo sizing
=============================================================================*/
.logo--xsmall {
	@extend .logo--nn-styling;
	width: 118px;
	height: 36px;
	margin: 16px 0;
}

.logo--small {
	@extend .logo--nn-styling;
	width: 157px;
	height: 48px;
	margin: 22px 0;
}

.logo--medium {
	@extend .logo--nn-styling;
	width: 196px;
	height: 60px;
	margin: 30px 0;
}