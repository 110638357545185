
iframe:not([width]) {
	width: 100%;
	margin-bottom: 30px;
}



/* COMPONENT: Iframe banksparen
=============================================================================*/
.iframe--banksparen {
	margin-bottom: 20px;
	height: 840px;
}

/* COMPONENT: Iframe dialog
=============================================================================*/
.iframe--dialog {
	min-height: 20px;
	width: 100%;

	margin: 0 0 0 0;
	padding: 0;
	border: 0;
}