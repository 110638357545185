@import "../_helpers/vars";

.u-greyout {
  position: relative;

  &::before {
    content: '';
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: $elementOverlay;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
