@use "sass:math";

@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';

[class*="grid-wrapper--"] {
    @include clearfix();
    margin-bottom:15px;
    position: relative;


    > [class*="col-"] {
        box-sizing: border-box;
        float:left;
        padding-left:0;

    }
}


@each $gutter-width in 0, 10, 15, 20, 30, 40, 60, 90, 120 {
    @include grid-wrapper($gutter-width){
        margin-left: -#{$gutter-width}px;
        & > [class*="col-"]{
            float: left;
            padding-left: #{$gutter-width}px;
        }
    }
}

.not-responsive {

    .col-one-whole {
        width: 100%;
    }
    .col-one-half{
        width: percentage(1*0.5)
    }
    .col-one-third{
        width: percentage(math.div(1, 3))
    }
    .col-two-thirds{
        width: percentage(math.div(2, 3))
    }
    .col-one-quarter{
        width: percentage(1*0.25)
    }
    .col-three-quarters{
        width: percentage(3*0.25)
    }
    .col-one-fifth{
        width: percentage(1*0.2)
    }
    .col-two-fifths{
        width: percentage(2*0.2)
    }
    .col-three-fifths{
        width: percentage(3*0.2)
    }
    .col-four-fifths{
        width: percentage(4*0.2)
    }
    .col-one-sixth{
        width: percentage(math.div(1, 6))
    }
    .col-two-sixths{
        width: percentage(math.div(2, 6))
    }
    .col-three-sixths{
        width: percentage(math.div(3, 6))
    }
    .col-four-sixths{
        width: percentage(math.div(4, 6))
    }
    .col-five-sixths{
        width: percentage(math.div(5, 6))
    }

    /*@media (max-width: $mobile_break_point) {*/

        .col-m-hidden {
             display:none;
         }
        .col-m-one-whole{
            width: 100%
        }
        .col-m-one-half{
            width: percentage(1*0.5)
        }
        .col-m-one-third{
            width: percentage(math.div(1, 3))
        }
        .col-m-two-thirds{
            width: percentage(math.div(2, 3))
        }
        .col-m-one-quarter{
            width: percentage(1*0.25)
        }
        .col-m-three-quarters{
            width: percentage(3*0.25)
        }
        .col-m-one-fifth{
            width: percentage(1*0.2)
        }
        .col-m-two-fifths{
            width: percentage(2*0.2)
        }
        .col-m-three-fifths{
            width: percentage(3*0.2)
        }
        .col-m-four-fifths{
            width: percentage(4*0.2)
        }
        .col-m-one-sixth{
            width: percentage(math.div(1, 6))
        }
        .col-m-two-sixths{
            width: percentage(math.div(2, 6))
        }
        .col-m-three-sixths{
            width: percentage(math.div(3, 6))
        }
        .col-m-four-sixths{
            width: percentage(math.div(4, 6))
        }
        .col-m-five-sixths{
            width: percentage(math.div(5, 6))
        }

        .col-m-one-eight{
            width: percentage(1*0.125)
        }
        .col-m-two-eights{
            width: percentage(2*0.125)
        }
        .col-m-three-eights{
            width: percentage(3*0.125)
        }
        .col-m-four-eights{
            width: percentage(4*0.125)
        }
        .col-m-five-eights{
            width: percentage(5*0.125)
        }
        .col-m-six-eights{
            width: percentage(6*0.125)
        }
        .col-m-seven-eights{
            width: percentage(7*0.125)
        }
   /* }



    @media (min-width: $tablet_break_point) {*/
        .col-t-hidden {
             display:none;
         }
        .col-t-one-whole{
            width: 100% !important;
        }
        .col-t-one-half{
            width: percentage(1*0.5) !important;
        }
        .col-t-one-third{
            width: percentage(math.div(1, 3)) !important;
        }
        .col-t-two-thirds{
            width: percentage(math.div(2, 3)) !important;
        }
        .col-t-one-quarter{
            width: percentage(1*0.25) !important;
        }
        .col-t-three-quarters{
            width: percentage(3*0.25) !important;
        }
        .col-t-one-fifth{
            width: percentage(1*0.2) !important;
        }
        .col-t-two-fifths{
            width: percentage(2*0.2) !important;
        }
        .col-t-three-fifths{
            width: percentage(3*0.2) !important;
        }
        .col-t-four-fifths{
            width: percentage(4*0.2) !important;
        }
        .col-t-one-sixth{
            width: percentage(math.div(1, 6)) !important;
        }
        .col-t-two-sixths{
            width: percentage(math.div(2, 6)) !important;
        }
        .col-t-three-sixths{
            width: percentage(math.div(3, 6)) !important;
        }
        .col-t-four-sixths{
            width: percentage(math.div(4, 6)) !important;
        }
        .col-t-five-sixths{
            width: percentage(math.div(5, 6)) !important;
        }

        .col-t-one-eight{
            width: percentage(1*0.125) !important;
        }
        .col-t-two-eights{
            width: percentage(2*0.125) !important;
        }
        .col-t-three-eights{
            width: percentage(3*0.125) !important;
        }
        .col-t-four-eights{
            width: percentage(4*0.125) !important;
        }
        .col-t-five-eights{
            width: percentage(5*0.125) !important;
        }
        .col-t-six-eights{
            width: percentage(6*0.125) !important;
        }
        .col-t-seven-eights{
            width: percentage(7*0.125) !important;
        }

        .col-t-one-tenth{
            width: percentage(1*0.1) !important;
        }
        .col-t-two-tenths{
            width: percentage(2*0.1) !important;
        }
        .col-t-three-tenths{
            width: percentage(3*0.1) !important;
        }
        .col-t-four-tenths{
            width: percentage(4*0.1) !important;
        }
        .col-t-five-tenths{
            width: percentage(5*0.1) !important;
        }
        .col-t-six-tenths{
            width: percentage(6*0.1) !important;
        }
        .col-t-seven-tenths{
            width: percentage(7*0.1) !important;
        }
        .col-t-eight-tenths{
            width: percentage(8*0.1) !important;
        }
        .col-t-nine-tenths{
            width: percentage(9*0.1) !important;
        }

        .col-t-one-twelve{
            width: percentage(math.div(1, 12)) !important;
        }
        .col-t-two-twelves{
            width: percentage(math.div(2, 12)) !important;
        }
        .col-t-three-twelves{
            width: percentage(math.div(3, 12)) !important;
        }
        .col-t-four-twelves{
            width: percentage(math.div(4, 12)) !important;
        }
        .col-t-five-twelves{
            width: percentage(math.div(5, 12)) !important;
        }
        .col-t-six-twelves{
            width: percentage(math.div(6, 12)) !important;
        }
        .col-t-seven-twelves{
            width: percentage(math.div(7, 12)) !important;
        }
        .col-t-eight-twelves{
            width: percentage(math.div(8, 12)) !important;
        }
        .col-t-nine-twelves{
            width: percentage(math.div(9, 12)) !important;
        }
        .col-t-ten-twelves{
            width: percentage(math.div(10, 12)) !important;
        }
        .col-t-eleven-twelves{
            width: percentage(math.div(11, 12)) !important;
        }
    /*}*/
}


.is-responsive {

    /* mobile -- */
    .col-m-hidden {
        display:none;
    }

    .col-one-whole {
        width: 100%;
    }
    .col-one-half{
        width: percentage(1*0.5);
    }
    .col-one-third{
        width: percentage(math.div(1, 3));
    }
    .col-two-thirds{
        width: percentage(math.div(2, 3));
    }
    .col-one-quarter{
        width: percentage(1*0.25);
    }
    .col-three-quarters{
        width: percentage(3*0.25);
    }
    .col-one-fifth{
        width: percentage(1*0.2);
    }
    .col-two-fifths{
        width: percentage(2*0.2);
    }
    .col-three-fifths{
        width: percentage(3*0.2);
    }
    .col-four-fifths{
        width: percentage(4*0.2);
    }
    .col-one-sixth{
        width: percentage(math.div(1, 6));
    }
    .col-two-sixths{
        width: percentage(math.div(2, 6));
    }
    .col-three-sixths {
        width: percentage(math.div(3, 6));
    }
    .col-four-sixths{
        width: percentage(math.div(4, 6));
    }
    .col-five-sixths{
        width: percentage(math.div(5, 6));
    }

    /*/ -----------------------------------------------------------------------------
    //
    // Responsive grid allowing columns to be composed for multiple screen sizes
    //
    // -----------------------------------------------------------------------------*/

    @media (min-width: $tablet_break_point) {

        .col-m-hidden {
            display: block;
        }

        .col-t-hidden {
             display:none;
         }
        .col-t-one-whole{
            width: 100% !important;
        }
        .col-t-one-half{
            width: percentage(1*0.5) !important;
        }
        .col-t-one-third{
            width: percentage(math.div(1, 3)) !important;
        }
        .col-t-two-thirds{
            width: percentage(math.div(2, 3)) !important;
        }
        .col-t-one-quarter{
            width: percentage(1*0.25) !important;
        }
        .col-t-two-quarters{
            width: percentage(2*0.25) !important;
        }
        .col-t-three-quarters{
            width: percentage(3*0.25) !important;
        }
        .col-t-one-fifth{
            width: percentage(1*0.2) !important;
        }
        .col-t-two-fifths{
            width: percentage(2*0.2) !important;
        }
        .col-t-three-fifths{
            width: percentage(3*0.2) !important;
        }
        .col-t-four-fifths{
            width: percentage(4*0.2) !important;
        }
        .col-t-one-sixth{
            width: percentage(math.div(1, 6)) !important;
        }
        .col-t-two-sixths{
            width: percentage(math.div(2, 6)) !important;
        }
        .col-t-three-sixths{
            width: percentage(math.div(3, 6)) !important;
        }
        .col-t-four-sixths{
            width: percentage(math.div(4, 6)) !important;
        }
        .col-t-five-sixths{
            width: percentage(math.div(5, 6)) !important;
        }
        /*8*/
        .col-t-one-eight{
            width: percentage(1*0.125) !important;
        }
        .col-t-two-eights{
            width: percentage(2*0.125) !important;
        }
        .col-t-three-eights{
            width: percentage(3*0.125) !important;
        }
        .col-t-four-eights{
            width: percentage(4*0.125) !important;
        }
        .col-t-five-eights{
            width: percentage(5*0.125) !important;
        }
        .col-t-six-eights{
            width: percentage(6*0.125) !important;
        }
        .col-t-seven-eights{
            width: percentage(7*0.125) !important;
        }
        /*10*/
        .col-t-one-tenth{
            width: percentage(1*0.1) !important;
        }
        .col-t-two-tenths{
            width: percentage(2*0.1) !important;
        }
        .col-t-three-tenths{
            width: percentage(3*0.1) !important;
        }
        .col-t-four-tenths{
            width: percentage(4*0.1) !important;
        }
        .col-t-five-tenths{
            width: percentage(5*0.1) !important;
        }
        .col-t-six-tenths{
            width: percentage(6*0.1) !important;
        }
        .col-t-seven-tenths{
            width: percentage(7*0.1) !important;
        }
        .col-t-eight-tenths{
            width: percentage(8*0.1) !important;
        }
        .col-t-nine-tenths{
            width: percentage(9*0.1) !important;
        }
        /*12*/
        .col-t-one-twelve{
            width: percentage(math.div(1, 12)) !important;
        }
        .col-t-two-twelves{
            width: percentage(math.div(2, 12)) !important;
        }
        .col-t-three-twelves{
            width: percentage(math.div(3, 12)) !important;
        }
        .col-t-four-twelves{
            width: percentage(math.div(4, 12)) !important;
        }
        .col-t-five-twelves{
            width: percentage(math.div(5, 12)) !important;
        }
        .col-t-six-twelves{
            width: percentage(math.div(6, 12)) !important;
        }
        .col-t-seven-twelves{
            width: percentage(math.div(7, 12)) !important;
        }
        .col-t-eight-twelves{
            width: percentage(math.div(8, 12)) !important;
        }
        .col-t-nine-twelves{
            width: percentage(math.div(9, 12)) !important;
        }
        .col-t-ten-twelves{
            width: percentage(math.div(10, 12)) !important;
        }
        .col-t-eleven-twelves{
            width: percentage(math.div(11, 12)) !important;
        }

    }

    @media (min-width: $desktop_break_point) {

        .col-d-one-whole{
            width: 100% !important;
        }
        .col-d-one-half{
            width: percentage(1*0.5) !important;
        }
        .col-d-one-third{
            width: percentage(math.div(1, 3)) !important;
        }
        .col-d-two-thirds{
            width: percentage(math.div(2, 3)) !important;
        }
        .col-d-one-quarter{
            width: percentage(1*0.25) !important;
        }
        .col-d-three-quarters{
            width: percentage(3*0.25) !important;
        }
        .col-d-one-fifth{
            width: percentage(1*0.2) !important;
        }
        .col-d-two-fifths{
            width: percentage(2*0.2) !important;
        }
        .col-d-three-fifths{
            width: percentage(3*0.2) !important;
        }
        .col-d-four-fifths{
            width: percentage(4*0.2) !important;
        }
    /*//    6*/
        .col-d-one-sixth{
            width: percentage(math.div(1, 6)) !important;
            display:block;
        }
        .col-d-two-sixths{
            width: percentage(math.div(2, 6)) !important;
        }
        .col-d-three-sixths{
            width: percentage(math.div(3, 6)) !important;
        }
        .col-d-four-sixths{
            width: percentage(math.div(4, 6)) !important;
        }
        .col-d-five-sixths{
            width: percentage(math.div(5, 6)) !important;
        }
    /*//    8*/
        .col-d-one-eighth{
            width: percentage(1*0.125) !important;
            display:block;
        }
        .col-d-two-eighths{
            width: percentage(2*0.125) !important;
            display:block;
        }
        .col-d-three-eighths{
            width: percentage(3*0.125) !important;
        }
        .col-d-four-eighths{
            width: percentage(4*0.125) !important;
        }
        .col-d-five-eighths{
            width: percentage(5*0.125) !important;
        }
        .col-d-six-eighths{
            width: percentage(6*0.125) !important;
        }
        .col-d-seven-eighths{
            width: percentage(7*0.125) !important;
        }

    /*//    10*/
        .col-d-one-tenth{
            width: percentage(1*0.1) !important;
            display:block;
        }
        .col-d-two-tenths{
            width: percentage(2*0.1) !important;
            display:block;
        }
        .col-d-three-tenths{
            width: percentage(3*0.1) !important;
        }
        .col-d-four-tenths{
            width: percentage(4*0.1) !important;
        }
        .col-d-five-tenths{
            width: percentage(5*0.1) !important;
        }
        .col-d-six-tenths{
            width: percentage(6*0.1) !important;
        }
        .col-d-seven-tenths{
            width: percentage(7*0.1) !important;
        }
        .col-d-eight-tenths{
            width: percentage(8*0.1) !important;
        }
        .col-d-nine-tenths{
            width: percentage(9*0.1) !important;
        }
     /*//    12*/
        .col-d-one-twelve{
            width: percentage(math.div(1, 12)) !important;
        }
        .col-d-two-twelves{
            width: percentage(math.div(2, 12)) !important;
        }
        .col-d-three-twelves{
            width: percentage(math.div(3, 12)) !important;
        }
        .col-d-four-twelves{
            width: percentage(math.div(4, 12)) !important;
        }
        .col-d-five-twelves{
            width: percentage(math.div(5, 12)) !important;
        }
        .col-d-six-twelves{
            width: percentage(math.div(6, 12)) !important;
        }
        .col-d-seven-twelves{
            width: percentage(math.div(7, 12)) !important;
        }
        .col-d-eight-twelves{
            width: percentage(math.div(8, 12)) !important;
        }
        .col-d-nine-twelves{
            width: percentage(math.div(9, 12)) !important;
        }
        .col-d-ten-twelves{
            width: percentage(math.div(10, 12)) !important;
        }
        .col-d-eleven-twelves{
            width: percentage(math.div(11, 12)) !important;
        }

    }

}