@import '../../../global/css/colors';

/* COMPONENT: repeater
=============================================================================*/
.repeater {
	margin-bottom: 20px;
}

/* PART: row
=============================================================================*/
.repeater-row {
	border-top: 1px solid $nn_light_grey;
	padding: 15px 0 0 0;
}

.repeater-row.first,
.repeater-row:first-child {
	border: none;
}

.repeater-row-remove {
	margin-bottom: 15px;
	text-align: right;
}

.repeater-content {
	padding: 15px 0 0 0;
}