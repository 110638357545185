@import '../_helpers/color';
@import '../_helpers/sizes';
@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';


.result-item-container {
    padding: 15px 0 15px 0;
    border-bottom: 1px solid #f1edeb
}

.result-item-container .result-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-decoration: none;
    min-height: 80px;
    cursor: pointer
}

.result-item-container .result-item:hover {
    color: inherit
}

.result-item-container .result-item:hover .result-title {
    color: #ea650d
}

.result-item-container .result-item .result-item-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 800px
}

.result-item-container .result-item .result-item-content .result-title {
    margin: 10px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5
}

.result-item-container .result-item .result-item-content .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden
}

.result-item-container .result-item .arrow-container {
    margin-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media(max-width:479px) {
    .result-title {
        font-size: 1.3rem !important
    }
}

.card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    max-width: 800px
}

.card-wrapper .card {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.card-wrapper .card .tile {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 156px;
    padding: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 2px solid #e5ded9;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    color: #ea650d;
    min-width: 280px
}

.card-wrapper .card .tile .tile-icon {
    width: 32px;
    height: 32px
}

.card-wrapper .card .tile .card-arrow-right {
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    margin-left: 8px
}

.card-wrapper .card .tile:hover {
    -webkit-box-shadow: 0 1px 2px hsla(0, 0%, 40%, .1), 0 1px 6px hsla(0, 0%, 50.2%, .1), 0 2px 16px hsla(0, 0%, 44.7%, .08);
    box-shadow: 0 1px 2px hsla(0, 0%, 40%, .1), 0 1px 6px hsla(0, 0%, 50.2%, .1), 0 2px 16px hsla(0, 0%, 44.7%, .08)
}

.card-wrapper .card .tile:hover .card-arrow-right {
    margin-left: 16px
}

.card-wrapper .card .tile:active {
    background: #fff7ee;
    outline: none
}

.card-wrapper .card-header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.card-wrapper .card-header,
.card-wrapper .card-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.card-wrapper .card-link .card-link-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden
}

.card-wrapper .card-header-title {
    font-weight: 700;
    font-size: 1.2em;
    color: #414141;
    height: 50px
}

.card-wrapper .card-text {
    margin: 0 0 10px 0;
    font-weight: 400;
    font-size: 16px;
    color: #666;
    padding-right: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden
}

.auto-complete {
    position: relative;
    z-index: 100
}

.auto-complete .search-magnify-glass-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    padding-right: 5px
}

.auto-complete ol {
    position: absolute;
    width: calc(100% - 2px);
    left: 1px;
    z-index: 100;
    padding: 0;
    margin: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    list-style-type: none !important;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.auto-complete li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    list-style-type: none !important;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.auto-complete input[type=text] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #cac7c7;
    border-radius: 4px;
    padding: 8px;
    padding-right: 132px;
    width: 100%;
    height: 53px;
    padding-left: 46px
}

.auto-complete .clear-button {
    position: absolute;
    right: 95px;
    top: 16px;
    padding: 0;
    background-color: transparent;
    color: #414141;
    cursor: pointer
}

.auto-complete .clear-button span {
    font-size: 20px
}

.auto-complete .search-button {
    position: absolute;
    height: 40px;
    right: 6px;
    top: 6px
}

.auto-complete .autocomplete-result .magnifying-glass-icon {
    margin-right: 10px;
    top: 16px;
    left: 16px;
    padding-right: 5px
}

.auto-complete .loading-spinner {
    position: absolute;
    right: 115px;
    top: 16px;
    padding-right: 5px
}

.auto-complete .autocomplete-result.is-active,
.auto-complete .autocomplete-result:hover {
    background-color: #fff7ee
}

.attention-block {
    max-width: 600px;
    border-radius: 4px;
    background-color: #ccdbf2
}

.attention-block .left-border {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #336fcb;
    width: 4px
}

.attention-block .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    padding: 12px 0
}

.attention-block .content .col-icon {
    margin-left: 12px
}

.attention-block .content .info-icon {
    display: inline-block;
    vertical-align: bottom;
    height: 24px;
    width: 24px;
    font-size: 24px
}

.attention-block .content .text {
    margin: 0 8px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    -ms-flex-item-align: center;
    align-self: center
}

.attention-block .content h5 {
    font-size: 16px;
    line-height: 24px;
    color: #414141;
    margin: 0 0 4px 0
}

.attention-block .content ul {
    margin: 0
}

.attention-block .content li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px
}

.attention-block .content li:before {
    background: #414141;
    width: 4px;
    height: 4px
}

.title {
    font-size: 32px;
    line-height: 40px;
    color: #414141;
    margin-bottom: 0
}

.top-box {
    max-width: 600px;
    min-width: 350px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.top-box .feedback-bar {
    min-height: 24px;
    width: 100%
}

.top-box .feedback-bar .results-count-shimmer {
    width: 400px;
    height: 24px;
    border-radius: 4px
}

.top-box .search-input-wrapper {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px
}

.subtitle {
    color: #999;
    font-size: 1.1rem;
    line-height: 24px
}

.feedback-bar--bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.spinner-wrapper {
    margin-top: 40px
}

.scroll-ref {
    width: 100%;
    height: 40px
}

.results-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 40px
}

.results-list .more-results-button {
    color: #ea650d;
    background-color: #fff7ee
}

.results-list .all-results-button,
.results-list .more-results-button {
    font-size: 16px;
    line-height: 24px;
    margin-top: 34px;
    -ms-flex-item-align: center;
    align-self: center
}

.results-list .all-results-button {
    background: #cac7c7;
    color: #414141
}

.empty-state .attention-block {
    margin-top: 4px
}

.empty-state h2 {
    color: #414141;
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px
}

.empty-state p {
    margin-top: 15px
}

.empty-state .empty-title {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    margin: 24px 0;
    text-align: left;
    color: #414141;
    font-weight: 700
}

@media screen and (max-width:768px) {

    .search-input-wrapper,
    .top-box {
        min-width: 300px;
        width: 100%
    }
}

@media(max-width:1025px) {

    .empty-state,
    .results-count-shimmer {
        min-width: 300px;
        width: 100%
    }

    .subtitle {
        font-size: 1rem
    }
}

@media(max-width:479px) {
    .feedback-bar--bottom {
        display: none
    }
}

html .overlay:before {
    content: "";
    background: rgba(65, 65, 65, .3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10
}

.c-main-navigation ul .text-field-autosuggest-optionlist {
    display: inline-block;
    height: auto;
    padding: 0 !important;
  }

.text-field-autosuggest-viewall {
      text-wrap: wrap;
  }

.text-field-autosuggest-optionlist {
    padding: 5px;
  }

.c-main-navigation__search .text-field-autosuggest-container {
    border-color: #8d8d8d;
}

.c-main-navigation__search .text-field-autosuggest-container:active {
    border-color: #8d8d8d;
}

.c-main-navigation__search .text-field-autosuggest-container:focus {
    border-color: #8d8d8d;
}

.search-field-button-icon {
    height: 40px;
    position: absolute;
    right: 6px;
    top: 6px;
    background: none;
}

.c-main-navigation .text-field-autosuggest-container ul li:first-child {
    padding: 4px 9px !important;
  }

.results-list .all-results-button {
    background: #cac7c7;
    color: #414141
}

.auto-complete .search-input-wrapper + .text-field-autosuggest-container {
    border-color: #8d8d8d;
}

.auto-complete .search-input-wrapper + .text-field-autosuggest-container:focus {
    border-color: #8d8d8d;
}

.auto-complete .search-input-wrapper + .text-field-autosuggest-container:active {
    border-color: #8d8d8d;
}

@media (max-width: 820px) {   
    .c-main-navigation__search .search-input-wrapper { 
        display:none; 
    }
    .c-main-navigation__search .search-input-icon {display:block;}
}

@media (min-width: 821px) {
    .c-main-navigation__search .search-input-wrapper { display:block; }
    .c-main-navigation__search .search-input-icon {display:none;}
}