@import '../_helpers/color';
@import '../_helpers/sizes';
@import '../layout/o-layout-variables';

.c-tile {
  display: flex;
  box-sizing: border-box;
  margin-bottom: $grid-gutter-width;
  border-width: 1px;
  border-style: solid;
  @include border-color($card_variants_Default_styles_borderColor);
  border-radius: 4px;
  @include background-color($card_variants_Default_styles_backgroundColor);
  text-align: center;
  height: 150px;
  width: 100%;
  max-width: 450px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  @include color($link_elements_Text_variants_Primary_styles_color);
}

.c-tile--title {
  padding: 0 5px;
  font-size: 16px;
  font-weight: bold;
  width: 95%;
  margin-top: 5px;
}
.c-tile:hover {
  @include border-color($card_variants_Tinted_styles_borderColor);
  @include color($link_elements_Text_variants_Primary_styles_color);
  @include box-shadow($card_variants_Attentional_styles_boxShadow);
}
