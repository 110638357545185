.c-scrollablecontainer {
  -webkit-overflow-scrolling: touch;

	&--horizontal {
		overflow-x: auto;
	}

	&--vertical {
		overflow-y: auto;	
	}

	&.webkitOverflowScrollingAuto{
		-webkit-overflow-scrolling: auto;	
	}
}

.c-scrollablewrapper {
  position: relative;
}