@import '../../_helpers/color';

.c-form-license-plate{
	box-sizing: border-box;
	display: inline-block;
	width: 128px;
	height: 38px;
	border: 1px solid $nn_extra_dark_grey;
	border-radius: 4px;
	margin: 0;
	padding: 0 0 0 38px;
	background-image: url('../img/euro_nl.svg'), url('../img/euro_stars_yellow.svg'), linear-gradient(to right, rgb(37,103,168) 22px, rgb(242,183,42) 22px, rgb(242,183,42));
	background-position: left 6px bottom 4px, 3px 3px, 0 0;
	background-size: 10px 10px, 16px 16px, auto;
	background-repeat: no-repeat;
	text-transform: uppercase;
	font-weight: bold;
	line-height: 36px;

	&[disabled]{
		background-image: url('../img/euro_nl.svg'), url('../img/euro_stars_white.svg'), linear-gradient(to right, $nn_medium_grey 22px, $nn_ultra_light_grey 22px, $nn_ultra_light_grey);
		border-color: $nn_extra_light_grey;
	}
}
