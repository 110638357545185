@import '../_helpers/mixins';
@import '../_helpers/component-design-token-maps';

.c-step-indicator-bullet {
    $normal-bullet-diameter: 8px;
    $current-bullet-diameter: 8px;
    $bottom-spacing: 22px;

    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    margin: 35px 0 70px 0;
    flex-flow: row nowrap;
    align-items: flex-end;
    overflow: hidden;

    &:has(li:last-child.c-step-indicator-bullet__step--active)::before {
        border: 1px solid #ea650d;
    }

    // Track
    &:before{
        content: '';
        position: absolute;
        bottom: ($current-bullet-diameter) * 0.5;
        height: 0;
        width: 100%;
        display: block;
        border-bottom-width: 2px;
        border-bottom-style: dashed;
        @include border-color($stepIndicatorStep_elements_StepConnectorLine_styles_borderColor);
    }

    &--auto {
      .c-step-indicator-bullet__step {
        /**
         * NOTE: adding this as an option, but can only be used when not hyphenating
         */
        flex: 0 1 auto;
        /****/

        &:first-child {
          padding-left: 0;
        }
      }
    }

    &__step{
        display: inline-block;
        /**
         * NOTE: don't turn flex-basis into '0'! The '%' unit is not superfluous
         * here ಥ﹏ಥ: https://github.com/philipwalton/flexbugs#flexbug-4
         */
        flex: 1 1 0%;
        /****/

        position: relative;
        list-style: none;
        padding: 0 10px;
        text-align: center;

        &:before{
            display: none;
        }

        // Indicator until first step
        &:first-child &__label:before{
            z-index: 1;
            @include background-color($stepIndicatorStep_elements_Indicator_dataStates_Completed_styles_backgroundColor);
        }

        &__label{
            display: inline-block;
            vertical-align: bottom;
            margin-bottom: $bottom-spacing;
            position: relative;
            text-align: center;
            text-decoration: none;
            @include color($label_elements_Label_styles_color);

            // Connector line to indicate complete
            &:before{
                content: '';
                display: none;
                position: absolute;
                bottom: ($current-bullet-diameter) * 0.5 - $bottom-spacing;
                right: calc(50% - 5px);
                height: 2px;
                width: 100vw;
                pointer-events: none;
                @include background-color($stepIndicatorStep_elements_StepConnectorLine_dataStates_Completed_styles_borderColor);
            }

            // Default step bullet
            &:after{
                content: '';
                display: block;
                left: calc(50% - #{$normal-bullet-diameter} / 2);
                position: absolute;
                z-index: 1;
                bottom: -22px;
                width: $normal-bullet-diameter;
                height: $normal-bullet-diameter;
                border-radius: 50%;
                @include background-color($stepIndicatorStep_elements_Indicator_styles_backgroundColor);
                border-width: 2px;
                border-style: solid;
                @include border-color($stepIndicatorStep_elements_Indicator_styles_borderColor);
            }
        }

        &--active &__label{
            &:before{
                display: block;
            }

            // Completed indicator
            &:after{
                @include border-color($stepIndicatorStep_elements_Indicator_dataStates_Completed_styles_borderColor);
                @include background-color($stepIndicatorStep_elements_Indicator_dataStates_Completed_styles_backgroundColor);
            }
        }

        &--current{}

        &--current &__label{
            font-weight: bold;

            // Current step bullet
            &:after{
                left: calc(50% - #{$current-bullet-diameter} / 2);
                bottom: -22px;
                width: $current-bullet-diameter;
                height: $current-bullet-diameter;
                @include background-color($stepIndicatorStep_elements_Indicator_styles_backgroundColor);
                @include border-color($stepIndicatorStep_elements_Indicator_dataStates_Current_styles_borderColor);
            }
        }
    }

    @media (max-width: $size-s) {
        display: block;
        width: auto;

        &__step{
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0;
            max-width: 100%;
            text-align: left;

            @for $i from 1 through 6{
                &:nth-child(#{$i}) &__label:after{
                    left: ($i - 1) * 32px + 15px;
                }
                &:nth-child(#{$i}) &__label:before{
                    left: $normal-bullet-diameter * 0.5 + 15px;
                    right: auto;
                    width: ($i - 1) * 32px;
                }

                &--current:nth-child(#{$i}) &__label__text{
                    padding-left: ($i - 1) * 32px  + 15px;
                }
            }

            &__label{
                text-align: left;

                &:before{
                    bottom: ($current-bullet-diameter) * 0.5 - $bottom-spacing;;
                }

                &__text{
                    display: inline-block; /*required for proper word breaking*/
                    word-break: break-word;
                    position: absolute;
                    clip: rect(0,0,0,0);
                }
            }

            &--current{
                position: relative;
            }

            &--current &__label:before{
                bottom: ($current-bullet-diameter) * 0.5 - $bottom-spacing;;
            }

            &--current &__label__text{
                position: static;
            }
        }
    }
}

// MAX 7 STEPS COMPONENT

.c-step-indicator {
    margin: 0 0 25px 0;
    overflow: hidden;
    position: relative;

    // STEPS
    &__steps {
        height: 30px;
        margin: 0;
        overflow: hidden;

        // single step
        &__item {
            position: relative;
            display: inline-block;
            list-style: none;
            box-sizing: border-box;
            height: 30px;
            line-height: 30px;
            @include background-color($card_variants_Tinted_styles_backgroundColor);
            text-align: center;
            border-right-width: 1px;
            border-right-style: solid;
            @include border-color($card_variants_Default_styles_borderColor);
            font-size: 16px;
            padding: 0 0 0 10px;
            margin: 0;


            // FIXME: generic ol li content is set in before
            &:before {
                display: none;
            }

            // maybe we need this item for later
            span {
                display: block;
                width: 100%;
                height: 100%;
            }


            // ARROW TO THE RIGHT (with before and after)
            &:before {
            	content: " ";
            	display: block!important; // TODO: ADVISOR had shitty declaration messing with us
            	width: 0;
            	height: 0;
            	border-top: 20px solid transparent;
            	border-bottom: 20px solid transparent;
                border-left-width: 10px;
                border-left-style: solid;
            	@include border-left-color($stepIndicatorStep_elements_Indicator_styles_backgroundColor);
            	position: absolute;
                top: 50%;
            	margin-top: -20px;
            	margin-left: 2px;
            	left: 100%;
            	z-index: 1;
            }

            &:after {
            	content: " ";
            	display: block;
            	width: 0;
            	height: 0;
            	border-top: 20px solid transparent;           /* Go big on the size, and let overflow hide */
            	border-bottom: 20px solid transparent;
            	border-left: 10px solid $nn_light_grey;
            	position: absolute;
            	top: 50%;
            	margin-top: -20px;
            	left: 100%;
            	z-index: 2;
            }



            // corner items in our list
            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                padding-left: 3px;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                padding-right: 3px;
                border-right: 0;

                &:before {
                    display: none!important;
                }
                &:after {
                    display: none;
                }

            }

            a,
            a:hover,
            a:visited {
                text-decoration: none!important;
                display: block;
            }
            //just a distinct text-shadow for accesibility
            a:focus {
                outline: none;
                text-shadow: 0 0 2px $white;
            }

            // for now done and current are the same in look and feel
            &--done,
            &--current {
                &,
                a,
                a:hover,
                a:visited {
                    @include background-color($pipelineStep_elements_Indicator_dataStates_Completed_styles_backgroundColor, true);
                    @include color($pipelineStep_elements_IndicatorIcon_dataStates_Completed_styles_color);
                }
                &:after {
                    @include border-left-color($pipelineStep_elements_Card_dataStates_Current_styles_borderColor, true);
                }
            }

            // ACTIVE STATE TO PREVIEW A TAB (hypotheken)
            // No active state for current or done
            &--active:not(&--done),
            &--active.c-step-indicator__steps__item--almost-done--todo
           {
                & ,
                a {
                    @include color($pipelineStep_elements_IndicatorIcon_dataStates_Completed_styles_color);
                    @include background-color($card_variants_Attentional_styles_backgroundColor);
                }

                &:after
                 {
                    border-left-color: $nn_medium_grey;

                }

            }

            // sub step (step inside step)
            &--almost-done {
                background-color: $nn_medium_orange !important;
                border-color: white;

                &:before {
                    display: none!important;
                }
                &:after {
                    display: none;
                }

                a,
                a:hover,
                a:visited {
                    color: $white;
                    display: block;
                }
                a,
                &--current,
                &--current a,
                &--current a:visited,
                &--current a:hover {
                    background-color: $nn_medium_orange !important;
                    color: $white;

                    &:before {
                        display: none!important;
                    }
                    &:after {
                        display: none;
                    }
                }
                //SUB STEPS ARE LIGHTER
                &--todo {
                    background-color: lighten($nn_light_grey,5);
                    &:before {
                        display: none!important;
                    }
                    &:after {
                        display: none;
                    }

                    &--last {
                        background-color: lighten($nn_light_grey,5);

                        &:after {
                            border-left: 10px solid lighten($nn_light_grey,5);
                        }
                    }
                }


            }

        }

    }

    //ADDING HOVERSTATE CLASS

    &--hoverstate &__steps__item {



        html:not(.#{$window-touched}) &:hover,
        html:not(.#{$window-touched}) & a:hover {
            color: $white  !important;
            background-color: $nn_medium_grey;
        }

        html:not(.#{$window-touched}) &:hover:after {
            border-left-color: $nn_medium_grey;
        }

        &--done,
        &--current,
        &--almost-done,
        &--almost-done--current {


            html:not(.#{$window-touched}) &:hover,
        	html:not(.#{$window-touched}) & a:hover {
                color: $nn_extra_dark_grey !important;
                background-color: $nn_medium_orange;
            }

            html:not(.#{$window-touched}) &:hover:after {
                border-left-color: $nn_medium_orange;
            }
        }

    }


    // BIGGER STEPINDICATOR
    &--icons &__steps { height: 40px; }
    &--icons &__steps__item {
        height: 40px;
        line-height: 40px;
        color: $nn_dark_grey;

        a,
        a:visited {
            color: $nn_dark_grey;
        }


        &:before {
            border-top-width: 24px;
            border-bottom-width: 24px;
            border-left-width: 12px;
            margin-top: -24px;
            margin-left: 3px;
        }

        &:after {
            border-top-width: 24px;
            border-bottom-width: 24px;
            border-left-width: 12px;
            margin-top: -24px;
        }

        &--done,
        &--current,
        &--almost-done,
        &--almost-done--current {
            &,
            a,
            a:visited {
                color: $white;
            }

        }


    }

    &--icons &__steps__item-icon { font-size: 24px; line-height: 40px; }
    &--icons &__labels-bottom__item { padding-left: 0px;  }
    &--icons &__labels-bottom__item--current:after { }





    // STEP LABELS, ABOVE OUR STEPS
    &__labels-top {
        margin: 0 0 8px 0;
        font-weight: bold;

        &__item {
            box-sizing: border-box;
            display: inline-block;
            position: relative;
            list-style: none;
            box-sizing: border-box;
            text-align: center;
            font-size: 0.875em;
            padding: 0 0 0 10px;
            margin: 0;
            color: $nn_extra_dark_grey;

            // FIXME: generic ol li content is set in before
            &:before {
                display: none;
            }

            &--current,
            &--done {
                color: $nn_medium_orange;
            }
        }

    }




    // STEP LABELS, BELOW OUR STEPS
    &__labels-bottom {
        margin: 0;

        &__item {
            box-sizing: border-box;
            display: inline-block;
            position: relative;
            list-style: none;
            box-sizing: border-box;
            text-align: center;
            vertical-align: top;
            font-size: 0.875em;
            padding: 10px 0 0 10px;
            margin: 0;
            color: $nn_dark_grey;

            // FIXME: generic ol li content is set in before
            &:before {
                display: none;
            }


            &--done {
                color: $nn_medium_orange;
            }

            &--current {
                color: $nn_medium_orange;


                &:after {
                    color: $nn_medium_orange;
                    font-family: 'nn-iconfont';
                    font-size: 30px;
                    content: '\e902';
                    position: absolute;
                    top: -16px;
                    left: 50%;
                    // TODO: -15 for first and last items
                    margin: 0 0 0 -10px;

                }

            }
            &--active {
                text-decoration: underline;
                // fix for ie underline issue with icon
                &:after {
                    height: 28px;
                    overflow: hidden;
                }

            }

            &:first-child {

                &:after {
                    margin-left: -15px;
                }
            }

        }

    }



    // STEP LABELS, BETWEEN TWO STEPS
    &__labels-remark {
        font-size: 0.875rem;
        color: $nn_medium_blue;
        text-align: center;
        position: relative;
        width: 200px;
        margin: 0 0 28px -100px;

        &__marker {
            background: transparent url('stepindicator/img/c-stepindicator-arrow.svg') center no-repeat;
            color: $nn_medium_blue;
            position: absolute;
            display: block;
            width: 200px;
            height: 20px;
      //    margin: 0 0 0 -100px;
        }
    }

    // OUR SIZES DEPEND ON OUR ITEMS IN OUR LIST, SEE BELOW FOR IMPLEMENTATION
    &__size { }


    // MESSAGE FOR USER, uses c-notification
    &__arrow { }



    // ITEM WIDTH (FALLBACK)
    &__steps__item,
    &__labels-top__item,
    &__labels-bottom__item {
        width: 115px;
    }


    // STEP WIDTH
    // single step
    &--1 &__steps__item,
    &--1 &__labels-top__item,
    &--1 &__labels-bottom__item {
        width: 100%;
    }

    // 2 steps
    &--2 &__steps__item,
    &--2 &__labels-top__item,
    &--2 &__labels-bottom__item {
        width: 50%;
    }
    &--2 &__size--2 { width: 100%; }

    // 3 steps
    &--3 &__steps__item,
    &--3 &__labels-top__item,
    &--3 &__labels-bottom__item {
        width: 33.33%;
    }
    &--3 &__size--2 { width: 66.66%; }
    &--3 &__size--3 { width: 100%; }

    // 4 steps
    &--4 &__steps__item,
    &--4 &__labels-top__item,
    &--4 &__labels-bottom__item {
        width: 25%;
    }
    &--4 &__size--2 { width: 50%; }
    &--4 &__size--3 { width: 75%; }
    &--4 &__size--4 { width: 100%; }

    // 5 items
    &--5 &__steps__item,
    &--5 &__labels-top__item,
    &--5 &__labels-bottom__item {
        width: 20%;
    }
    &--5 &__size--2 { width: 40%; }
    &--5 &__size--3 { width: 60%; }
    &--5 &__size--4 { width: 80%; }
    &--5 &__size--5 { width: 100%; }

    // 6 steps
    &--6 &__steps__item,
    &--6 &__labels-top__item,
    &--6 &__labels-bottom__item {
        width: 16.66%;
    }
    &--6 &__size--2 { width: 33.33%; }
    &--6 &__size--3 { width: 50%; }
    &--6 &__size--4 { width: 66.66%; }
    &--6 &__size--5 { width: 83.33%; }
    &--6 &__size--6 { width: 100%; }

    // 7 steps
    &--7 &__steps__item,
    &--7 &__labels-top__item,
    &--7 &__labels-bottom__item {
        width: 14.28%;
    }

    &--7 &__size--2 { width: 28.57%; }
    &--7 &__size--3 { width: 42.85%; }
    &--7 &__size--4 { width: 57.14%; }
    &--7 &__size--5 { width: 71.42%; }
    &--7 &__size--6 { width: 85.71%; }
    &--7 &__size--7 { width: 100%; }

    // 8 steps
    &--8 &__steps__item,
    &--8 &__labels-top__item,
    &--8 &__labels-bottom__item {
        width: 12.5%;
    }

    &--8 &__size--2 { width: 25%; }
    &--8 &__size--3 { width: 37.5%; }
    &--8 &__size--4 { width: 50%; }
    &--8 &__size--5 { width: 62.5%; }
    &--8 &__size--6 { width: 75%; }
    &--8 &__size--7 { width: 87.5%; }
    &--8 &__size--8 { width: 100%; }






    // MESSAGE ARROW POSITIONING
    &--2 &__arrow {
        &--current-1 { left: 25%; }
        &--current-2 { left: 75.6%; }
    }

    &--3 &__arrow {
        &--current-1 { left: 16.6%; }
        &--current-2 { left: 50.5%; }
        &--current-3 { left: 84%; }
    }

    &--4 &__arrow {
        &--current-1 { left: 12.45%; }
        &--current-2 { left: 37.9%; }
        &--current-3 { left: 63.0%; }
        &--current-4 { left: 88.2%; }
    }

    &--5 &__arrow {
        &--current-1 { left: 9.85%; }
        &--current-2 { left: 30.4%; }
        &--current-3 { left: 50.5%; }
        &--current-4 { left: 70.5%; }
        &--current-5 { left: 90.65%; }
    }

    &--6 &__arrow {
        &--current-1 { left: 8.35%; }
        &--current-2 { left: 25.4%; }
        &--current-3 { left: 42.1%; }
        &--current-4 { left: 58.9%; }
        &--current-5 { left: 75.55%; }
        &--current-6 { left: 92.25%; }
    }

    &--7 &__arrow {
        &--current-1 { left: 7.1%; }
        &--current-2 { left: 21.77%; }
        &--current-3 { left: 36.1%; }
        &--current-4 { left: 50.34%; }
        &--current-5 { left: 64.72%; }
        &--current-6 { left: 79.11%; }
        &--current-7 { left: 93.6%; }
    }

    &--8 &__arrow {
        &--current-1 { left: 6.2%; }
        &--current-2 { left: 19.2%; }
        &--current-3 { left: 31.7%; }
        &--current-4 { left: 44.3%; }
        &--current-5 { left: 56.7%; }
        &--current-6 { left: 69.3%; }
        &--current-7 { left: 81.8%; }
        &--current-8 { left: 94.4%; }
    }

  // MESSAGE TWO STEP ARROW POSITIONING
    &--2 &__labels-remark {
        &--current-1-2 { padding-left: 50%; }
    }

    &--3 &__labels-remark {
        &--current-1-2 { padding-left: 33.3%; }
        &--current-2-3 { padding-left: 66.67%; }
    }

    &--4 &__labels-remark {
        &--current-1-2 { padding-left: 25.05%; }
        &--current-2-3 { padding-left: 50.2%; }
        &--current-3-4 { padding-left: 75.1%; }
    }

    &--5 &__labels-remark {
        &--current-1-2 { padding-left: 20.15%; }
        &--current-2-3 { padding-left: 40.25%; }
        &--current-3-4 { padding-left: 60.3%; }
        &--current-4-5 { padding-left: 80.2%; }
    }

    &--6 &__labels-remark {
        &--current-1-2 { padding-left: 16.67%; }
        &--current-2-3 { padding-left: 33.33%; }
        &--current-3-4 { padding-left: 50%; }
        &--current-4-5 { padding-left: 66.67%; }
        &--current-5-6 { padding-left: 83.2%; }
    }

    &--7 &__labels-remark {
        &--current-1-2 { padding-left: 14.2%; }
        &--current-2-3 { padding-left: 28.4%; }
        &--current-3-4 { padding-left: 42.9%; }
        &--current-4-5 { padding-left: 57.1%; }
        &--current-5-6 { padding-left: 71.5%; }
        &--current-6-7 { padding-left: 85.7%; }
    }

    &--8 &__labels-remark {
        &--current-1-2 { padding-left: 12.5%; }
        &--current-2-3 { padding-left: 25%; }
        &--current-3-4 { padding-left: 37.5%; }
        &--current-4-5 { padding-left: 50%; }
        &--current-5-6 { padding-left: 62.5%; }
        &--current-6-7 { padding-left: 75%; }
        &--current-7-8 { padding-left: 87.5%; }
    }


}


// FOR PHONE WE HIDE SOME LABELS
@media (max-width: $phone) {

    .c-step-indicator {

        // ITEM WIDTH
        &__steps__item,
        &__labels-top__item,
        &__labels-bottom__item {
            width: 75px;
        }

        &__labels-top,
        &__labels-bottom {
            &__item {
                visibility: hidden;

                &-icon {
                    font-size: 30px;
                }

                &--current {
                    visibility: visible;

                }
            }
        }

        &--icons &__steps__item-icon { font-size: 20px; margin-left: 2px; }

        &__labels-remark--current-1-2  {
            text-align: left;
        }


    }

}

@media (max-width: $size-s) {

    .c-step-indicator {

        //first step needs some adjustments in positioning
        &__labels-remark--current-1-2  {
            position: static;
            margin-left: 0;
            padding-left: 0!important;

            .c-step-indicator__labels-remark__marker {
                margin-left: -100px;
            }
        }

        &--3 &__labels-remark--current-1-2 &__labels-remark__marker { left: 33.33%; }
        &--4 &__labels-remark--current-1-2 &__labels-remark__marker { left: 25.05%; }
        &--5 &__labels-remark--current-1-2 &__labels-remark__marker { left: 20.15%; }
        &--6 &__labels-remark--current-1-2 &__labels-remark__marker { left: 16.67%; }
        &--7 &__labels-remark--current-1-2 &__labels-remark__marker { left: 14.2%; }
        &--8 &__labels-remark--current-1-2 &__labels-remark__marker { left: 12.5%; }


        //Last step won't fit in 200px wide
        &--3 &__labels-remark--current-2-3,
        &--4 &__labels-remark--current-3-4,
        &--5 &__labels-remark--current-4-5,
        &--6 &__labels-remark--current-5-6,
        &--7 &__labels-remark--current-6-7,
        &--8 &__labels-remark--current-7-8 {
            width: auto;
         text-align: right;

        }
    }
}
