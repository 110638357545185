.file-field-wrapper{
	position: relative;
	display: inline-flex;
}

.file-field-wrapper .file-field{
	position: absolute;
	opacity: 0;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	border: 0;
	margin: 0;
	padding: 0;
	height: 100%;/*ie10 fix*/
}

.file-field-wrapper__text{
	flex: 0 1 150px;
	margin:0 10px 10px 0;
	max-width: 150px;
}

.file-field-wrapper__button{
	box-sizing: border-box;
	flex: 0 0 144px; // fit at least 'Bestand kiezen'
	margin:0 10px 10px 0;
}
