@import "../_helpers/mixins";
@import "../_helpers/alias-design-token-maps";
@import "../_helpers/component-design-token-maps";

.u-color {

	&--white {
		color: #fff;
	}

  &--dark-orange {
    color: $nn_dark_orange;
  }

  &--medium-orange {
    color: $nn_medium_orange;
  }

	&--light-orange {
		color: $nn_light_orange;
	}

	&--medium-blue {
		color: $nn_medium_blue;
	}

	&--medium-green {
		color: $nn_medium_green;
	}

	&--medium-red {
		color: $nn_medium_red;
	}

	&--medium-purple {
		color: $nn_medium_purple;
	}

	&--light-grey {
		color: $nn_light_grey;
	}

	&--medium-grey {
		color: $nn_medium_grey;
	}

	&--extra-light-grey {
		color: $nn_extra_light_grey;
	}

	&--ultra-light-grey {
		color: $nn_ultra_light_grey;
	}

	&-background {
    &--dark-grey {
      background-color: $nn_dark_grey;
    }
    &--medium-grey {
			@include background-color($checkbox_elements_Box_styles_accentColor);
    }
		&--tinted {
			@include background-color($backgroundColor_contentContainer_tinted);
		}
		&--light-grey {
			background-color: $nn_light_grey;
		}
		&--extra-light-grey {
			background-color: $nn_extra_light_grey;
		}
    &--ultra-light-grey,
    &--extreme-light-grey {
			background-color: $nn_ultra_light_grey;
		}
		&--white {
			@include background-color($backgroundColor_contentContainer_plain_default);
		}

		&--dark-orange {
			background-color: $nn_dark_orange;
		}
		&--medium-orange {
			background-color: $nn_medium_orange;
		}
		&--light-orange {
			background-color: $nn_light_orange;
		}
		&--fifty-light-orange {
			background-color: $nn_fifty_light_orange;
		}

    &--medium-blue {
      background-color: $nn_medium_blue;
    }

	}
}
