@import '../_helpers/color';

.c-chart{
  &--line {
    width: 100%;
  }

  // &--pie {
  //   display: inline-block;
  //   overflow: auto;
  //   max-width: 100%;
  // }

  &--column {
    position: relative;
  }

  &-overlay-reference {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 2px solid $nn_extra_dark_grey;
    color: $nn_extra_dark_grey;
  }

  &-overlay-label {
    float: right;
    padding: 5px 0 5px 5px;
    margin-top: -13px;
    background-color: $white;
    font-size: 12px;
  }
}

/* PART: Line styles
=============================================================================*/
@mixin line-style {
  position: relative;
  display: inline-block;
  width: 30px;
  margin: 0;
}

@mixin line-style-solid($number, $color) {
  .graph-type-#{$number} {
    @include line-style;

    height: 2px;
    background: $color;
    top: -3px;
  }
}

@mixin line-style-dashed($number, $color) {
  .graph-type-#{$number} {
    @include line-style;

    height:2px;
    background: $color;
    top: -3px;
    &:after {
      content:"";
      position:absolute;
      left:13px;
      top:0;
      width:4px;
      height:5px;
      background:white;
    }
  }
}

@mixin line-style-dot($number, $color) {
  .graph-type-#{$number} {
    @include line-style;

    height: 15px;
    width: 15px;
    border-radius: 100%;
    background: $color;
    margin-left: 7px;
    top: 2px;
  }
}

@mixin line-style-square($number, $color) {
  .graph-type-#{$number} {
    @include line-style;

    height: 15px;
    width: 15px;
    border-radius: 3px;
    background: $color;
    margin-left: 7px;
    top: 2px;
  }
}

$colors: ($nn_medium_orange, $nn_medium_blue, $nn_dark_grey, $nn_medium_grey, $nn_medium_purple, $nn_light_orange, $nn_light_blue, $nn_light_grey, $nn_light_green, $nn_light_purple, $nn_dark_orange, $nn_dark_blue, $nn_medium_grey, $nn_dark_green, $nn_dark_purple);
$i:1;

@each $color in $colors {
  @include line-style-solid($i, $color);
  $i: ($i+1);
}
@each $color in $colors {
  @include line-style-dashed($i, $color);
  $i: ($i+1);
}
@each $color in $colors {
  @include line-style-dot($i, $color);
  $i: ($i+1);
}
@each $color in $colors {
  @include line-style-square($i, $color);
  $i: ($i+1);
}

/*missing color watch out for numbering*/
$colors: ($nn_fifty_light_orange, $nn_medium_green);
$i:61;

@each $color in $colors {
  @include line-style-solid($i, $color);
  $i: ($i+1);
}
@each $color in $colors {
  @include line-style-dashed($i, $color);
  $i: ($i+1);
}
@each $color in $colors {
  @include line-style-dot($i, $color);
  $i: ($i+1);
}
@each $color in $colors {
  @include line-style-square($i, $color);
  $i: ($i+1);
}
