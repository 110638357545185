@import '../_helpers/mixins';
@import '../_helpers/color';

$var-premiumoverview-height: 800px;
.c-premium-overview {
  &__innerwrapper {
    @extend .u-color-background--ultra-light-grey;
    //TODO global border-box
    padding: 30px 30px 15px 30px;
    position: relative;
    width: 100%;
    max-width: 1184px;
    border-top: 3px solid $nn_medium_orange;
    max-height: $var-premiumoverview-height;
    overflow: hidden;
    box-sizing: border-box;
    transition: max-height 800ms cubic-bezier(0, 1, 0.5, 1);
    transform-style: preserve-3D;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
  }
  &__content {
    flex: 1 0;
    align-self: auto;
  }
  &__aside {
    flex: 0 0 15em;
    text-align: right;
    align-self: auto;
  }
  &__amount {
    @extend .h1;
    display: inline-block;
    padding-bottom: 5px;
    border-bottom: 1px solid $nn_light_grey;
    margin-bottom: 10px;
  }
  &__detailtoggle {
    display: block;
    &:focus {
      outline: none;
    }
    &__icon {
      transform: rotate(180deg);
    }
    .c-premium-overview.is-closed &__icon {
      transform: rotate(0deg);
      width: 16px;
    }
    .c-premium-overview.is-closed &__open,
    &__closed {
      display: none;
      opacity: 0;
    }
    .c-premium-overview.is-closed &__closed,
    &__open {
      display: inline-block;
      opacity: 1;
    }
  }
  &__title {
    font-size: 24px;
    @include font-bold;
    color: $nn_extra_dark_grey;
    padding: 0;
    margin-bottom: 5px;
  }
  &__subline {
    color: $nn_medium_grey;
    margin-bottom: 15px;
  }
  &.is-closed {
    //   max-heigth: 100%;
  }

  &__details {
    transition:
      max-height 800ms cubic-bezier(0, 1, 0.5, 1),
      opacity 800ms;
    transform: translate3d(0, 0, 0);
    max-height: 30em;
    overflow: hidden;
  }
  &.is-closed &__details {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  &__footer {
    text-align: right;
    color: $nn_medium_grey;
  }
  &__totals {
    text-align: right;
    margin-bottom: 10px;
    &__amount {
      @include font-size(18);
      @include font-bold;
      color: $nn_medium_grey;
      text-decoration: line-through;
    }
    &__discount {
      @include font-size(22);
      @include font-bold;
      margin-left: 1em;
    }
  }
}

// THIS WRAPPER IS NEEDED AS LONG AS WE SUPPORT THIS is-responsive setup...
.is-responsive {
  @media (max-width: $size_s) {
    .c-premium-overview {
      //max-height: 100%;
      &__innerwrapper {
        overflow: visible;
      }
      &__container {
        flex-direction: column;
        flex-wrap: wrap;
      }
      &__content {
        flex: 0 1 auto;
        display: inline-block;
        //ie10 fix for overflowing text
        width: 100%;
      }
      &__aside {
        text-align: left;
        flex: 0 1 auto;
        display: inline-block;
        //ie10 fix for overflowing text
        width: 100%;
      }
    }
  }
}
