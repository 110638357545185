/* PART: page
=============================================================================*/
@import '../../../global/css/breakpoints';
@import '../../../global/css/mixins';
@import '../../../global/css/alias-design-token-maps';

.page {
	position: relative;
	margin: 0 auto;
	max-width: 1184px; // 1120 (content) + 32 (padding left) + 32 (padding right)
  width: auto;
	min-height: 100%;
	height: auto !important;
	height: 100%;
	@include background-color($backgroundColor_contentContainer_plain_default);
}

.is-wide .page {
	width: auto;
	max-width: 1280px;
}

@media (max-width: $mobile_break_point) {

	.is-responsive {
		.page {
			width: auto;
			min-width: $mobile_min_width;
		}
	}

}