@font-face {
  font-family: 'NN Dagny';
  src: url('./font/fonts/NNDagnyDisplayWebPro.eot');
  src: local('☺'), url('./font/fonts/NNDagnyDisplayWebPro.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NN Dagny';
  src: url('./font/fonts/NNDagnyTextWeb.eot');
  src: local('☺'), url('./font/fonts/NNDagnyTextWeb.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nitti Grotesk NN Heading';
  src: url('./font/fonts/NittiGroteskNN/woff2/NittiGroteskNN-Heading-1.2.woff2') format('woff2'),
       url('./font/fonts/NittiGroteskNN/woff/NittiGroteskNN-Heading-1.2.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nitti Grotesk NN Text';
  src: url('./font/fonts/NittiGroteskNN/woff2/NittiGroteskNN-TextBold-1.2.woff2') format('woff2'),
       url('./font/fonts/NittiGroteskNN/woff/NittiGroteskNN-TextBold-1.2.woff') format('woff');
  font-weight: bold; // 700
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nitti Grotesk NN Text';
  src: url('./font/fonts/NittiGroteskNN/woff2/NittiGroteskNN-TextMedium-1.2.woff2') format('woff2'),
  url('./font/fonts/NittiGroteskNN/woff/NittiGroteskNN-TextMedium-1.2.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nitti Grotesk NN Text';
  src: url('./font/fonts/NittiGroteskNN/woff2/NittiGroteskNN-TextRegular-1.2.woff2') format('woff2'),
       url('./font/fonts/NittiGroteskNN/woff/NittiGroteskNN-TextRegular-1.2.woff') format('woff');
  font-weight: normal; // 400
  font-style: normal;
  font-display: swap;
}