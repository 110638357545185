@import '../../_helpers/color';
@import '../../_helpers/mixins';
@import '../../_helpers/component-design-token-maps';

.c-form-button {
	&__icon {
		margin: 0 10px 0 5px;
		color: $nn_dark_grey;
		@include color($addButton_elements_Icon_styles_color);
	}

	&--dashed {
		text-decoration: none;
		display: inline-block;
		font-weight: normal;
		border-radius: 4px;
		@include background-color($addButton_elements_Button_styles_backgroundColor);
		@include color($addButton_elements_Button_styles_color);
		box-sizing: border-box;
		border-width: 1px;
		border-style: dashed;
		@include border-color($addButton_elements_Button_styles_borderColor);
		padding: 14px;

		&:hover,
		&:focus {
			@include border-color($addButton_elements_Button_interactionStates_Hover_styles_borderColor);
			@include background-color($addButton_elements_Button_interactionStates_Hover_styles_backgroundColor);
		}
	}

	&--full-width {
		text-align: left;
		margin: 0;
		width: 100%;
	}
}

