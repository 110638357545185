.c-chartlegend {
	li {
		padding: 0 0 0 40px;
		margin: 0 0 5px;
		&:before {
			display: none;
		}
		div {
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}
