@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';

@import './data-table-legacy';

/* PART: responsive cells
=============================================================================*/

/* DELETE THE ABOVE AFTER THE NEW HELPER CLASSES ARE USED AND THE ABOVE BECOMES OBSOLETE  */
/* END OLD HELPER CLASSES */

/* VARIABLES
=============================================================================*/
$table-font-size-default: 16;
$table-font-size-alt: 16;

/* COMPONENT: Data table
=============================================================================*/

.data-table {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 15px;
  width: 100%;
  li {
    margin-bottom: 0;
  }
  /* PART: Column headers
	=============================================================================*/
  th {
    padding: 5px 5px 3px 0;
    color: #000;
    font-weight: bold;
    text-align: left;
    vertical-align: top;
  }
  thead th {
    border-bottom: 2px solid $nn_medium_orange;
  }

  /* PART: Column actions
	=============================================================================*/
  td {
    padding: 5px 5px 5px 0;
    border-top: 1px solid #d1d1d1;
    vertical-align: top;
    @include font-size($table-font-size-default);
  }

  .data-table-cell--gray-border {
    border-bottom: 1px solid $nn_dark_grey;
  }

  .data-table-dialog {
    padding-right: 30px;
    position: relative;

    .dialog--information {
      position: absolute;
      margin: -1px 0 0 4px;
    }
  }
}
.data-table-row,
.data-table-row--sub,
.data-table-row--alpha,
.data-table-row--header,
.data-table--responsive li {
  &:before {
    display: none;
  }
}

/*/////////////////////////////////////////////
//                                          //
//    exception to the rule for firefox,    //
//    because firefox doesn't support       //
//    position:relative on a td             //
*/
.mozilla .data-table {
  .data-table-dialog {
    display: block;
    margin-top: -1px;
  }
  tbody > tr:first-child > td {
    border-top: 0;
  }
}

/*////////////////////////////////////////////*/

@media (max-width: $tablet_break_point) {
  .is-responsive .data-table {
    width: 100%;
  }
}

/*  NOT READY FOR USE!!

	VARIANT: Data table (fluid); column widths are defined by its content.
=============================================================================*/

.data-table--fluid {
  display: table;
  border-collapse: collapse;

  .data-table-row {
    display: table-row;
  }

  .data-table-cell {
    display: table-cell !important;
    width: auto !important;
    float: none !important;
  }

  .data-table-row--subrow {
    display: table-row-group;
  }
}

/* VARIANT: Data table with some extra padding
=============================================================================*/
.data-table--padding-extra {
  width: 100%;
  td {
    padding: 12px 12px 12px 0;
  }

  td:first-child {
    padding: 12px 12px 12px 12px;
  }
  th {
    padding: 5px 12px 5px 0;

    &:first-child {
      padding: 5px 12px 5px 12px;
    }
  }
}

.data-table-col-actions {
  text-align: right;
}

/* PART: Column expand button
=============================================================================*/

.data-table-col-expand-button {
  width: 16px;
}

/* RESPONSIVE TABLE
=============================================================================*/

.data-table--responsive {
  @include clearfix;

  width: 100%;

  padding: 0;
  margin: 0 0 15px 0;

  li {
    width: 50%;

    float: left;
    background: none;
    padding: 0;
    margin: 0;

    &.first {
      width: 100%;

      .cell-title {
        display: block;
      }

      .cell,
      .cell-header {
        width: 50%;
        display: block;
      }
    }
  }

  .cell-empty,
  .cell-title,
  .cell-header,
  .cell {
    @include box-sizing-border-box;
  }

  .cell-header {
    float: left;
    display: none;
    width: 50%;
    min-height: 24px;
    border-bottom: 2px solid $nn_medium_orange;
    font-weight: bold;
    color: #000;
  }

  .cell-header + .cell-header {
    width: 100%;
    display: block;
  }

  .cell-title {
    float: left;
    display: none;

    width: 50%;
    border-bottom: 1px solid #d1d1d1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px 5px 5px 0;
  }

  .cell {
    float: left;
    width: 100%;
    border-bottom: 1px solid #d1d1d1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px 5px 5px 0;
  }
}

/*h2 heading too big in expandable responsive table; quick fix needed for zpp*/
.data-table--responsive-list [data-role='expand'] {
  fieldset.form-fieldset {
    h2 {
      @include font-size(18);
      span {
        @include font-size(18);
      }
    }
  }
}
.data-table--responsive.data-table--responsive-size2 {
  li {
    width: 33.333333333%;
  }

  li.first {
    width: 66.666666666%;
  }
}

@media (max-width: $tablet_break_point) {
  .data-table--responsive.data-table--responsive-size2 {
    li.first,
    li {
      width: 100%;

      .cell-title {
        display: block;
      }

      .cell-header {
        display: block;
        width: 50%;
      }

      .cell {
        display: block;
        width: 50%;
      }

      .cell-header + .cell-header,
      .cell + .cell {
        width: 50%;
      }

      .cell-title {
        width: 50%;
      }
    }

    li.first + li {
      margin-top: 30px;
    }
  }
}

/* PART: DATA-TABLE-ROW
=============================================================================*/

.data-table-row {
  @include transition(background-color, 0.5s, linear);

  background: none;
  border-top: 1px solid $nn_extra_light_grey;
  padding: 0;
  overflow: hidden;

  &.active {
    background-color: $nn_ultra_light_grey;
    border-color: $nn_medium_orange;
    border-width: 2px 0 0 0;
  }

  .data-table-cell .link-list {
    opacity: 0.5;
    transition: opacity 0.1s ease-in-out;
  }

  &:hover .data-table-cell .link-list {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
  }
}

.data-table-row--borderbottom {
  border-top: none;
  border-bottom: 1px solid $nn_extra_light_grey;
}

.data-table-row--main {
  @include transition(background-color, 0.5s, linear);

  background: none;
  border-bottom: 1px solid $nn_extra_light_grey;
  padding: 0;
  position: relative;
  overflow: visible;
  min-height: 47px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 46px;
    left: 32px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid $nn_light_grey;
    z-index: 10;
    background: transparent;
  }

  &:after {
    top: 45px;
    border-top: 9px solid #fff;
    z-index: 11;
  }

  input[type='text'][disabled='disabled'] {
    height: 34px;
  }
}

.data-table-row--alpha {
  background: none;
  padding: 0;
  overflow: hidden;
  border-top: 1px solid $nn_extra_dark_grey;
}

.data-table-row--sub {
  background: none;
  padding: 0;
  overflow: hidden;
  border-bottom: 1px dotted $nn_light_grey;
  margin-left: 35px;
  clear: both;
}

.data-table-row--header {
  background: none;
  padding: 0;
  border-bottom: 2px solid $nn_grey_800;
  overflow: hidden;
  border-top: 0;

  .data-table-cell {
    float: none;
    background: none;

    @include ellipsis;

    display: inline-block;
    font-weight: bold;
    vertical-align: bottom;
  }
  .dialog--expandable {
    position: absolute;
    right: 5px;
    top: 5px;
    margin-top: 0;
  }
  .dialog--expandable-content {
    float: left;
    background: $nn_ultra_light_grey;
    text-overflow: clip;
    white-space: normal;
    font-weight: normal;
  }

  + .data-table-row {
    border-top: 0;
  }
}

.data-table-row--header-wrap {
  overflow: visible;

  .data-table-cell {
    white-space: normal;
  }
}

.data-table-row--devider {
  @include box-sizing-border-box;
  background: $nn_ultra_light_grey;
  font-weight: bold;
  padding: 5px 0;
}

.pagination-temp {
  @include box-sizing-border-box;
  background: $nn_ultra_light_grey;
  border-bottom: 1px solid $nn_extra_light_grey;
  font-weight: bold;
  padding: 5px 10px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .data-table--responsive-list {
      .data-table-row--devider {
        display: block;
      }
    }
  }
}

.data-table-cell.data-table-control,
.c-table [data-component='Collapser'] {
  padding-left: 5px;
  a {
    display: block;
    height: 15px;
    width: 15px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      height: 2px;
      width: 12px;
      background: black;
      left: 0;
      top: 6px;
    }
    &:after {
      position: absolute;
      content: '';
      height: 12px;
      width: 2px;
      background: black;
      left: 5px;
      top: 1px;
    }
  }

  &.collapse a {
    &:before,
    &:after {
      background: $nn_medium_orange;
    }
    &:after {
      display: none;
    }
  }
}

/* PART: DATA-TABLE-CELL
=============================================================================*/

.data-table-cell {
  @include box-sizing-border-box;
  float: left;
  padding: 7px 5px 8px 0;
  position: relative;
  color: $nn_extra_dark_grey;

  .date-picker-icon {
    display: inline-block;
    position: relative;
    right: -10px;
  }

  .field-label--radio {
    @include font-size($table-font-size-default);
  }

  // Display Tooltips inline
  .dialog--information {
    position: relative;
    margin-left: 3px;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive .data-table-cell .span3 {
    width: 90%;
  }
}

.data-table-cell-title {
  margin-top: 10px;
  display: inline-block;
}

.dialog--information-alpha {
  position: absolute;
  margin-left: 15px;
  bottom: 11px;
}

.data-table-control {
  padding-left: 5px;
  a {
    display: block;
    height: 15px;
    width: 15px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      height: 2px;
      width: 12px;
      background: black;
      left: 0;
      top: 6px;
    }
    &:after {
      position: absolute;
      content: '';
      height: 12px;
      width: 2px;
      background: black;
      left: 5px;
      top: 1px;
    }
  }

  &.collapse a {
    &:before,
    &:after {
      background: $nn_medium_orange;
    }
    &:after {
      display: none;
    }
  }
}

/*
	COMPONENT:	DATA-TABLE
	THEME:		RESPONSIVE-LIST
	CONTEXT:	.
=============================================================================*/

.data-table--responsive-list {
  border: 0;

  .data-table-cell {
    .more {
      font-size: 1em;
    }
  }

  .data-table--responsive-list {
    margin: 0;
    .data-table-row {
      padding: 0;
      border: 0;
    }
  }

  & + .pagination {
    margin: 20px 0;
  }
}

/*
	COMPONENT:	DATA-TABLE
	THEME:		RESPONSIVE-LIST
	CONTEXT:	.is-responsive
=============================================================================*/

@media (max-width: $tabmob_break_point) {
  .is-responsive {
    .data-table--responsive-list {
      &:last-child .data-table-row:last-child {
        border-bottom: none;
      }

      .data-table-control {
        padding-left: 0;
        padding-right: 0;

        a {
          margin-left: 0;
        }
      }

      .data-table--responsive-list {
        .data-table-row {
          padding: 0;
          border: 0;
        }
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .data-table--responsive-list {
      .icon--stock-value-down,
      .icon--stock-value-up {
        background-image: none;
      }
    }
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .data-table-row--header-wrap {
      overflow: visible;

      .data-table-cell {
        white-space: normal;
      }
    }
  }
}

/*
	COMPONENT:	DATA-TABLE
	THEME:		PREMIUMS
	CONTEXT:	.
=============================================================================*/

.data-table--premiums {
  th,
  td,
  .data-table-cell,
  .field-label--radio,
  .text-field-prefix,
  .text-field-suffix,
  .cell,
  .cell-header,
  .cell-title {
    @include font-size($table-font-size-alt);
  }
  .data-table-row {
    &.active {
      background-color: #fff;
    }

    &.data-table-row--devider {
      background: transparent;
      border-color: $nn_light_grey;
      padding-top: 15px;
    }

    &.data-table-row--header {
      padding: 0;
      .data-table-cell {
        color: $nn_medium_orange;
        @include font-size(15);
      }
    }

    &.checked {
      .amount {
        color: $nn_extra_dark_grey;
        font-weight: bold;

        .line-through {
          color: $nn_medium_grey;
        }
      }
    }
  }

  .data-table-row--sub {
    &.checked {
      .amount {
        color: $nn_extra_dark_grey;
        font-weight: bold;
      }
    }
  }

  .data-table-cell {
    &:first-child {
      vertical-align: top;
    }

    &.amount {
      padding-top: 9px;
      line-height: 37px;
      color: $nn_light_grey;
    }
  }

  .data-table-cell--message {
    padding-top: 0;
  }
  .error-message,
  .warning-message,
  .success-message {
    margin: 0 0 5px;
  }

  .dialog--expandable-content {
    display: block;

    h3 {
      font-size: 1.33em; /*  12/16  */
    }
  }

  .premium-discount {
    min-height: 70px;

    .discount-price {
      position: absolute;
      right: 5px;
      top: 27px;
    }
  }

  .data-table-devider {
    color: $nn_medium_orange;
    @include font-size(15);
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .data-table--premiums {
      .data-table-cell.resp-width-100 {
        padding-bottom: 0;
      }
      .resp-width-100 + .resp-width-100 {
        padding-bottom: 7px;
        padding-top: 0;
      }
    }

    .premium-discount .discount-price {
      right: 30px;
    }
  }
}

/* @MEDIA MOBILE: DATA-TABLE
=============================================================================*/

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .block--border .data-table,
    .tabbed-component .data-table,
    .data-table--contained {
      width: auto;
      margin: 0 -15px;

      .data-table-row {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}

.data-table-meta {
  font-size: 0.7em;
  padding: 15px 0;
}

.data-table--responsive-compare {
  border: none;

  > li {
    border: none;
  }

  > li.odd,
  > li:nth-child(odd) {
    background-color: $nn_ultra_light_grey;
  }

  .width-30 + .width-30 + .width-30,
  .data-table-cell-30 + .data-table-cell-30 + .data-table-cell-30 {
    margin-left: 2%;
  }
}

/* PART: Data table wrapper
=============================================================================*/
.data-table-wrapper {
  position: relative;
}

/* THEME: Legacy font sizing
=============================================================================*/
.data-table--new-font-size {
  th,
  td,
  .data-table-cell,
  .field-label--radio,
  .text-field-prefix,
  .text-field-suffix,
  .cell,
  .cell-header,
  .cell-title,
  .field-value {
    @include font-size($table-font-size-alt);
  }
}

.data-table-cell .field-value {
  @include font-size($table-font-size-alt);
}

/*
	COMPONENT:		DATA-TABLE
	THEME:			SCROLLABLE
	CONTEXT:		NONE
	DESCRIPTION: 	SCROLLABLE COLUMS FOR SMALL SCREENS
=============================================================================*/

.data-table--scrollable {
  width: 100%;

  td div {
    position: relative;
  }
}

/*@media (max-width: $tablet_break_point) {*/

.data-table--scrollable-wrapper {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;

  table {
    margin-bottom: 0;
  }

  .scrollable {
    margin-left: 35%;
    overflow: auto;
    overflow-y: hidden;
    border-right: 1px dotted $nn_medium_orange;
    -webkit-overflow-scrolling: touch;

    td,
    th {
      position: relative;
      /*white-space: nowrap; */
      overflow: hidden;
    }
    th:first-child,
    td:first-child {
      display: none;
    }
  }

  .pinned {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 35%;
    overflow: hidden;
    overflow-x: auto;
    z-index: 150;
    border-right: 1px dotted $nn_medium_orange;

    table {
      border-right: none;
      border-left: none;
      width: 100%;

      td:last-child {
        border-bottom: 0;
      }
    }
  }
}
