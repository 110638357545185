@import "u-clear";
@import "u-color";
@import "u-screen-reader";
@import "u-font";
@import "u-margin";
@import "u-padding";
@import "u-border";
@import "u-embed-container";
@import "u-display";
@import "u-flex";
@import "u-greyout";

.u-invisible{
	visibility: hidden;
}

.u-relative {
	position: relative;
}

.u-hidden {
	display: none!important;
}

.u-block {
	display: block;
}

.u-inline {
	display: inline;
}

.u-inline-block {
	display: inline-block;
}

.u-nowrap {
	white-space: nowrap;
}

.u-overflow-hidden {
  overflow: hidden;
}

//TODO add responsive classes for textalignment
.u-textalign-left, .alignleft {
	text-align: left;
}
.u-textalign-right, .alignright {
	text-align: right;
}
.u-textalign-center, .aligncenter {
	text-align: center;
}

.u-valign-top {
	vertical-align: top;
}
.u-valign-middle {
	vertical-align: middle;
}
.u-valign-baseline {
	vertical-align: baseline;
}

.u-float-left {
	float: left;
}

.u-float-right {
	float: right;
}

.u-width-contain {
	max-width: 100%;
}

// NOTE: THIS WRAPPER IS NEEDED AS LONG AS WE SUPPORT THIS is-responsive setup...
.is-responsive {
	@media (max-width: $size-s) {
		.u-visible-s-up {
			display: none!important;
		}
	}
	@media (min-width: $size-s) {
		.u-visible-s-down {
			display: none!important;
		}
	}
	@media (max-width: $size-m) {
		.u-visible-m-up {
			display: none!important;
		}
	}

	@media (min-width: $size-s) {
		.u-hidden-l-up {
			display: none!important;
		}
	}
}


// WIDTHS -- min-widths needed for tables
// SIZES
// TODO: Are we going to do this
// FIXME: Get rid of this

.u-width-50 {
	width: 50px;
	min-width: 50px;
}
.u-width-100 {
	width: 100px;
	min-width: 100px;
}
.u-width-150 {
	width: 150px;
	min-width: 150px;
}
.u-width-200 {
	width: 200px;
	min-width: 200px;
}
.u-width-250 {
	width: 250px;
	min-width: 250px;
}
.u-width-300 {
	width: 300px;
	min-width: 300px;
}
.u-width-350 {
	width: 350px;
	min-width: 350px;
}
.u-width-400 {
	width: 400px;
	min-width: 400px;
}

.u-width-5p {
	width: 5%;
	min-width: 5%;
}
.u-width-10p {
	width: 10%;
	min-width: 10%;
}
.u-width-15p {
	width: 15%;
	min-width: 15%;
}
.u-width-20p {
	width: 20%;
	min-width: 20%;
}
.u-width-25p {
	width: 25%;
	min-width: 25%;
}
.u-width-30p {
	width: 30%;
	min-width: 30%;
}
.u-width-35p {
	width: 35%;
	min-width: 35%;
}
.u-width-40p {
	width: 40%;
	min-width: 40%;
}
.u-width-100p {
	width: 100%;
	min-width: 100%;
}


.u-resize-ver {
	resize: vertical;
}
.u-resize-hor {
	resize: horizontal;
}
