@use "sass:math";

@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';
@import '../../../global/css/breakpoints';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

input, textarea, select {
	@include font-normal();
	@include font-size(16,20);
}

.monospace {
	font-family: "Lucida Console", Monaco, monospace;
	@include font-size(14,16);
}


a, a * {
	cursor: pointer;
}

a {
	@include color($link_elements_Text_variants_Secondary_styles_color);
}

p {
	margin: 0 0 15px 0;
}

p a,
li a {
	text-decoration: underline;
}

address { font-style: normal; }

a:hover,
a.active {
	text-decoration: none;
}

blockquote {
	font-family: Georgia;
	font-style: italic;
	margin: 0 0 0 32px;
}

dl {
	dt {}
	dd {
		&.etactive {
			margin:0;
		}
	}
}

strong {
	@include font-bold();
}

/* CONTEXT: block shadow
=============================================================================*/
.block--shadow {
	h2, .h2 {
		color: $nn_medium_orange;
	}
}

/* CONTEXT: responsive block shadow
=============================================================================*/
@media (max-width: $mobile_break_point) {
	.is-responsive {
		.form-fieldset {
			h2, .h2 {
				padding: 0;
			}
		}
	}
}




/* THEME: H4 More
=============================================================================*/
h4.more {
	font-size: 0.813em;
}

@media (max-width: $mobile_break_point) {

	.is-responsive {
		.heading-accent-resp {
			color: $nn_medium_orange;
		}

		.block--resp-borderless h2 {
			padding: 0 0 8px 0;
		}
	}
}

.heading--submenu {
	color: $nn_light_orange;
	@include font-bold();
	margin: 0 0 12px 0;
	font-weight: normal;
}


/* PART: Helper classes
=============================================================================*/

.indent1 {
	text-indent: 15px;
}

.copy-statement {
	p {
		font-size:34px;
		@include font-bold();
	}
}

.uppercase {
	text-transform: uppercase;
}

.footnote {
	color: $nn_medium_grey;
	@include font-size(12);
}

.accent {
	color: $nn_medium_orange;
	font-weight: bold;
}

.accent--big {
	@extend .accent;
	font-size: 1em;
}

.accent--small {
	@extend .accent;
}

.grid--block-iota {
	.accent {
		font-size: math.div(16, 12)+0em;
	}
}

.etfaqanswer p a {
	@include font-size(16);
}

.client-code {
	letter-spacing: 0.5em;
}

.heading--sub {
	margin-top: -15px;
	color: $nn_extra_dark_grey;
}

.heading-grey {
	color: $nn_extra_dark_grey;
}
.heading-accent {
	color: $nn_medium_orange;
	&:hover {
		color:$nn_dark_orange;
	}
}
