@import "../../../global/css/sass-functions";
@import "../../../global/css/colors";
@import "../../../global/css/breakpoints";
@import '../../../../../../nn-psdl-modules/src/icon/c-icon.variables';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* PART: Blocks
=============================================================================*/

@mixin block-base-styling {
	@include border-radius(5px);
	padding: 15px 15px 0 15px;
	margin-bottom: 30px;
	position: relative;
}

.block {
	@include clearfix();
	margin-bottom: 30px;
	width:100%;
	position: relative;
	-moz-box-sizing: border-box;
 	-webkit-box-sizing: border-box;
 	box-sizing: border-box;
}

.component-equalheights {
	.block {
		.absolute {
			position: absolute;
			bottom:0;
		}
	}
}

/* Block style: left bordered;
=============================================================================*/

.block--bordered-left {
	padding-bottom: 5px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	@include border-bottom-color($card_variants_Default_styles_borderColor);
}

@media (min-width: $tablet_break_point) {
	.block--bordered-left {
		border-left-width: 1px;
		border-left-style: solid;
		@include border-left-color($card_variants_Default_styles_borderColor);
		border-bottom: 0;
		padding: 0 0 0 20px;
	}
}

/* Block style: thick top bordered;
=============================================================================*/

.block--bordered-top {
	padding-top:20px;
	background:white;
	border-top:3px solid $nn_medium_orange;
}
@media (min-width: $tablet_break_point) {
	/*.block--bordered-left {
		border-left-width: 1px;
		border-left-style: solid;
		@include border-left-color($card_variants_Default_styles_borderColor);
		border-bottom: 0;
		padding: 0 0 0 20px;
	}*/
}


/* Block style: left white border
=============================================================================*/
.block--bordered-left-white {
	border-bottom: 1px solid $white;
	padding: 0 0 10px 10px;
}
@media (min-width: $tablet_break_point) {
	.block--bordered-left-white {
		border-bottom:0;
		border-left: 1px solid $white;
		padding: 0 0 0 20px;
	}
}

/* Block Contact
=============================================================================*/

.block--fixed-contact {
	.block-title,
	h2.block-title {
		background-color: $nn_medium_orange;
		color: white;
		@include font-size(18);
		margin: -20px -20px 15px -20px;
		padding: 10px 20px 10px 20px;
		@include border-radius(5px 5px 0  0);
	}
	&.is-sticky {
		position: fixed;
		z-index:1000;
		top:0;
		overflow:hidden;
		h2.block-title {
			@include border-radius(0  0 5px 5px  !important);
		}
	}
	&.block--bordered-left-white {
		border-left: 1px solid $white;
		padding: 0 0 0 10px;
	}
	@media (min-width: $tablet_break_point) {
		&.block--bordered-left-white {
			padding: 0 0 0 20px;
		}
	}
}


.block-sticky-wrapper {
	margin-bottom:20px;
}



@media (max-width: $mobile_break_point) {

	.is-responsive {
		.block--light-gray {
			padding: 14px;
		}
	}
}

.masthead .block {
	@include box-sizing-border-box();
	/*min-height: 240px;*/
	margin:0;
}

/* THEME: Simple
=============================================================================*/
.block--simple {
	padding: 0;
	border: none;
}

@media (max-width: $mobile_break_point) {

	.is-responsive {
		.block--simple {
			padding: 0;
		}
	}

}

.grid--block-kappa .block--simple {
	margin-bottom: 0;
}

/* THEME: Bordered
=============================================================================*/
.block--border {
	@include background-color($card_variants_Default_styles_backgroundColor);
	border-width: 1px;
	border-style: solid;
	@include border-color($card_variants_Default_styles_borderColor, true);
	@include block-base-styling;
}

.block--border-orange {
	@include background-color($card_variants_Default_styles_backgroundColor);
	border-width: 1px;
	border-style: solid;
	@include border-color($card_variants_Default_dataStates_Selected_styles_borderColor, true);
	padding: 10px 10px 0 10px;
}

.block--border-orange-bold {
	@include background-color($card_variants_Default_styles_backgroundColor);
	border-radius: 2px;
	border-width: 2px;
	border-style: solid;
	@include border-color($card_variants_Default_dataStates_Selected_styles_borderColor, true);
	padding: 10px 10px 0 10px;
}

.grid--block-kappa .block--border {
	margin-bottom: 15px;
}

@media (max-width: $mobile_break_point) {
	.is-responsive {
		.grid--block-kappa .block--border {
			margin-bottom: 30px;
		}
		.block--border-orange {
			padding-top: 10px;
		}
		.block--border-orange-bold {
			padding-top: 10px;
		}
	}
}

/* THEME: Shadow
=============================================================================*/
.block--shadow {
	@include border-radius(5px);
	@include drop-shadow(0, 0, 8px, rgba(0,0,0, 0.25));
	padding: 15px 15px 0 15px;
}

/* THEME: Opacity
=============================================================================*/
.block--opacity {
	@extend .block--shadow;
	border: 1px solid $nn_light_grey;
	background-color:rgba(255,255,255,0.97);
	padding: 15px 15px 0 15px;
}

/* THEME: Blue
=============================================================================*/
.block--blue {
  position: relative;
	@include background-color($attentionBlock_variants_Informative_elements_Container_styles_backgroundColor);
  padding: 12px 16px 12px 42px;
  border-radius: 4px;

  &:before {
    display: block;
    font-family: 'nn-iconfont';
    font-size: 24px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 8px;
    padding-top: 12px;
    border-left-style: solid;
    border-left-width: 4px;
    content: $fonticon-content--pict-info;
		@include color($attentionBlock_variants_Informative_elements_Bar_styles_backgroundColor);
		@include border-color($attentionBlock_variants_Informative_elements_Bar_styles_backgroundColor);
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  p {
    margin-bottom: 0.25rem;
  }
}

/* THEME: Light gray
=============================================================================*/
.block--light-gray {
	@include border-radius(5px);
	border: 1px solid $nn_light_grey;
	background-color: $nn_extra_light_grey;
	padding: 15px 15px 0 15px;
}

/* THEME: Gray
=============================================================================*/
.block--ultra-light-gray {
	@include border-radius(4px);
	@include background-color($card_variants_Tinted_styles_backgroundColor);
	background-color: $nn_sand_50;
	padding: 20px 20px 0 20px;
	border-width: 1px;
	border-style: solid;
	@include border-color($card_variants_Tinted_styles_borderColor)
}

.block--bg-ultra-light-gray,
.block--bg-extreme-light-gray {
	background-color: $nn_ultra_light_grey;
}

/* THEME: Rounded corners
=============================================================================*/
.block--rounded-corners {
	@include border-radius(4px);
	.col-one-whole:last-child .block--bordered-left-white {
		border-bottom:0;
		/*padding-bottom:0;*/
	}
}

@media (max-width: $mobile_break_point) {

	.is-responsive {
		.block--image {
			padding: 0;
			margin: 0 0 7px 0;
			height: auto;

			img {
				display: block;
				max-width: 100%;
			}
		}
	}
}


/* THEME: Block responsive border
=============================================================================*/
.block--resp-border {

}

@media (max-width: $mobile_break_point) {

	.is-responsive {
		.block--resp-border {

			border: 1px solid $nn_light_grey;
			@include drop-shadow(0, 0, 0, #fff);
		}
	}

}

/* THEME: Block responsive borderless
=============================================================================*/
.block--resp-borderless {

}

@media (max-width: $mobile_break_point) {

	.is-responsive {
		.block--resp-borderless {
			padding: 0;
			margin: 0 0 5px 0;
			border: none;
		}
	}

}


/* PART: Block header
=============================================================================*/
.block-header {
	border-bottom: 1px solid $nn_light_grey;
	margin: 0 0 15px 0;
}

/* THEME: textarea
=============================================================================*/
.block--textarea,
.block.block--textarea {
	@extend .block--border;
	padding: 0;
	margin-bottom: 20px;
}

@media (max-width: $mobile_break_point) {

	.is-responsive {
		.block--textarea,
		.block.block--textarea {
			padding: 0;
		}
	}

}

.block--fixed-height {
	height: 284px;
}

/* THEME: Result block
=============================================================================*/
.block--result {
	@include box-sizing-border-box();
	background: $nn_extra_light_grey;
	border-top: 2px solid $nn_medium_grey;
	padding: 15px 15px 0 15px;
}

/* THEME: Placeholder
=============================================================================*/
.block--placeholder {
	@include box-sizing-border-box();
	background:$nn_ultra_light_grey;
	padding: 15px;
	width: 100%;
	position: relative;
	display: block;
	margin-bottom: 30px;
	@include border-radius(5px);
	@include drop-shadow(0, 0, 8px, rgba(0,0,0, 0.25));
	span {
		@include font-size(16);
		font-weight: bold;
		margin: 0 auto;
		text-align: center;
		display: block;
	}
	h2 {
		color: $nn_medium_orange;
	}
}

/* Block components
=============================================================================*/

.block--title,
.block-title {
	padding-bottom:8px;
}

.block--subtitle,
.block-subtitle {
	@include font-bold();
	@include font-size(16);
}

.block--image, /* deprecated */
.block-image, /* deprecated */
.image--roundedcorners {
	padding: 0;
	margin:0;
	overflow: hidden;
	border: none;
	height: auto;
	position: relative;
	p {
		margin:0 !important;
	}
	img {
		margin: 0 0 15px 0;
		width: auto;
		max-width: 100%;
		display: block;
		@include border-radius(4px);
	}
  .img-center {
    margin-left: auto;
    margin-right: auto;
  }
}

