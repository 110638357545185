@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* PART: horizontal line
=============================================================================*/
%hr,
hr {
	display: block;
	height: 1px;
	border: 0;

	border-top-width: 1px;
	border-top-style: solid;
	@include border-top-color($divider_elements_Divider_styles_borderColor);

	margin: 5px 0 30px;
	padding: 0;
	clear:both;
}

/* CONTEXT: Fieldset
=============================================================================*/
.form-fieldset {
	hr + .grid {
		margin-top: 15px;
	}
}

/* THEME: fat horizontal line
=============================================================================*/
.divider--fat {
  border-top-width: 2px;
  border-top-style: solid;
	@include border-top-color($divider_elements_Divider_styles_borderColor);
	margin: 5px 0 20px;
}

/* THEME: dotted horizontal line
=============================================================================*/
.devider--dotted {
	border-top: 1px dotted $nn_light_grey;
	@include bg-border-image('../img/devider--dotted.png', 1 0 0 0, repeat);
	margin: 10px 0 15px;
}

/* THEME: dotted horizontal line
=============================================================================*/
.devider--page {
	@extend %hr;
	margin: 20px 0 50px 0;
	/*.grid-wrapper--30 & {
		margin: 40px 0 50px 30px;
	}*/
}

.devider--page-orange {
	@extend %hr;
	border-color: $nn_medium_orange;
	margin: 30px 0 50px 0;
	/*.grid-wrapper--30 & {
		margin: 40px 0 50px 30px;
	}*/
}

.devider--page-orange-minimal {
	@extend %hr;
	border-color: $nn_medium_orange;
	margin: 30px 0 0 0;
	height: 0;
	/*.grid-wrapper--30 & {
		margin-left: 30px;
	}*/
}


/* THEME: devider--container
=============================================================================*/
.devider--container {
	margin: 0 0 10px 0;
}

.devider--container--beta {
	margin: 0 0 15px 0;
}

.devider--container-border-top {
	padding-top: 10px;
	margin: 0 0 15px 0;
	border-top: 1px solid $nn_light_grey;
}

/* THEME: devider--orange
=============================================================================*/
.divider--orange {
	border-top: 2px solid $nn_medium_orange;
	margin: 0 0 15px;
}

.divider--orange--beta {
	border-top: 2px solid $nn_medium_orange;
	margin: 5px 0 5px;
}

.divider--orange-thin {
	border-top: 0;
	border-bottom: 1px solid $nn_medium_orange;
	margin: 0;
}

.divider--orange-thin + .grid--block-gamma {
	margin-top: 20px;
}
