$o-glass-transition-duration: 0.4s;

.o-glass {

	position: fixed;
	background-color: $black;

	width: 100%;
	height: 100%;

	width: 100vw;
	height: 100vh;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	z-index: 500;

	transition: opacity $o-glass-transition-duration 0.1s ease-out;

	&[data-state="closed"] {
		opacity: 0;
	}

	&[data-state="opening"] {
		opacity: 0.5;
	}

	&[data-state="opened"] {
		opacity: 0.5;
	}

	&[data-state="closing"] {
		opacity: 0;
	}

	// themes
	// dark theme uses a white background
	&--dark {
		background-color: $white;
	}

	&--clear {
		background-color: transparent;
	}
}