.c-blogpost{
	padding: 15px;
	margin-bottom: 30px;
	&:before, &:after{
		content: ' ';
		display: table;
	}

	&:after{
		clear: both;
	}
}

.c-blogpost__date{
	box-sizing: border-box;
	float: right;
	position: relative;
	margin: -10px 0 10px 5px;
	padding: 0 5px;
	min-height: 50px;
	min-width: 50px;
	background-color: $nn_ultra_light_grey;

	&:before{
		content: '';
		position: absolute;
		z-index: 2;
		display: block;
		top: -12px;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #f8f8f8;
		box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
	}

	&:after{
		content: '';
		position: absolute;
	    z-index: 1;
	    top: -12px;
	    right: -4px;
	    width: 0px;
	    height: 0px;
	    border-top: 7px solid transparent;
	    border-left: 4px solid rgba(0, 0, 0, 0.5);
	}
}

.c-blogpost__date__day,
.c-blogpost__date__month{
	position: relative;
	z-index: 3;
	display: block;
	text-align: center;
	color: $nn_dark_grey;
}

.c-blogpost__date__day{
	font-size: 26px;
	line-height: 30px;
	font-weight: bold;
}

.c-blogpost__date__month{
	font-size: 14px;
	line-height: 14px;
}
