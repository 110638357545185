@import '../../../global/css/colors';

.text-field-autosuggest-wrapper {
	margin: 0;
	padding: 0;
	position: relative;
}

.text-field-autosuggest-container {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 1100;
	background-color: $white;
	margin-top: 5px;
	border-width: 0 1px 1px 1px;
	border-color: $white;
	border-style: solid;
	display: none;
	border-radius: 0 0 4px 4px;
}

.text-field-autosuggest-container--active {
	display: block;
	border-color: $nn_medium_orange;
}
.text-field-autosuggest-wrapper > .text-field:focus {
	outline-width: 0;
}
.text-field:focus + .text-field-autosuggest-container {
	border-color: $nn_medium_orange;
}

.text-field-autosuggest-optionlist {
	.text-field-autosuggest-option,
	.text-field-autosuggest-viewall {
		padding: 4px 9px;
		background-image: none;
		cursor: pointer;
		word-wrap: break-word;

		&:before {
			content: none;
		}
	}

	.text-field-autosuggest-viewall {
		background-image: none;
		border-top: 1px dotted $nn_light_grey;
		padding-top: 8px;
		padding-bottom: 0;

		&:hover {
			color: $nn_medium_orange;
		}
	}
}

.text-field-autosuggest-option--focus {
	background-color: $nn_light_orange;

	&, & * {
		color: $white;
	}
}