@import '../../../global/css/sass-functions';
@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';

.article-page {
  display: flex;
  justify-content: center;

  .article-page-wrapper {
    width: 100%;
    min-width: 230px;
  }

  .article-introduction__wrapper {
    display: flex;
    flex-direction: column;
  }

  .article-components-margin--top {
    margin-top: 48px;
  }
  .article__title {
    padding-bottom: 24px;
    margin: 0;
    @include font-size(36);
  }
  h2 {
    margin-bottom: 24px;
    color: $black;
  }
  h3 {
    margin-bottom: 8px;
    color: $black;
    @include font-size(20);
  }

  p {
    margin-bottom: 32px;
  }
}
.masthead-blog {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin: 32px 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include border-radius(8px);
  }
}

.article__content {
  .intro-text {
    @include font-size(20);
  }
}

@media (min-width: $desktop-large) {
  .article-page-wrapper {
    background-color: $white;
    max-width: 672px;
    box-sizing: border-box;
  }

  .masthead-blog {
    padding: 0;
    width: 1120px;
    left: 50%;
    margin: 0 0 0 -560px;
    height: 560px;
    order: 1;
    img {
      width: 1120px;
      @include border-radius(0px 0px 8px 8px);
    }
  }

  .breadcrumb-list__article {
    margin-top: -170px;
    margin-bottom: 0px;
    padding: 64px 64px 12px;
    @include border-radius(4px 4px 0px 0px);
    z-index: 1;
    order: 2;
    background-color: $white;
    box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .article__title {
    order: 3;
    z-index: 1;
    padding: 0 64px 24px;
    background-color: $white;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .article__info {
    order: 4;
    z-index: 1;
    padding: 0 64px 32px;
    background-color: $white;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .article__intro {
    order: 5;
    z-index: 1;
    padding: 0 64px;
    background-color: $white;
  }

  .article__content {
    padding: 0 64px;
  }
}

@media (min-width: $tablet_break_point) and (max-width: $desktop-large) {
  .article-page-wrapper {
    max-width: 544px;
  }

  .masthead-blog {
    img {
      height: 305px;
    }
  }

  .breadcrumb-list__article {
    margin-left: calc((50vw - 284px) * -1);
    margin: 16px 0 17px;
  }
}

@media (min-width: $tabmob_break_point) and (max-width: $tablet_break_point) {
  .article-page-wrapper {
    max-width: 520px;
  }

  .masthead-blog {
    img {
      height: 291px;
    }
  }

  .breadcrumb-list__article {
    margin-left: calc((50vw - 272px) * -1);
    margin-bottom: 17px;
    margin-top: 16px;
  }
}

@media (max-width: $tabmob_break_point) {
  .article-page-wrapper {
    position: relative;
    .article__title {
      @include font-size(28);
    }
  }
  .article__content {
    align-items: baseline;
    overflow: hidden;
    table {
      display: block;
      overflow: auto;
    }
    .intro-text {
      @include font-size(20);
    }
  }
  .masthead-blog {
    img {
      min-height: 160px;
    }
  }
}
