@import '../_helpers/color';
@import '../_helpers/mixins';
@import '../_helpers/sizes';

.blog-article-card {
    display: flex;
    height: 120px;
    width: 100%;
    max-width: 544px;
    margin: 16px 0;
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
    @media (min-width: $mobile_break_point) and (max-width: $tablet_break_point) {
        height: 114px;
    }
    @media (max-width: $mobile_break_point) {
        height: 78px;
    }
    .blog-article-card--img {
        position: relative;
        height: 100%;
        min-width: 160px;
        margin-right: 32px;
        @media (min-width: $mobile_break_point) and (max-width: $tablet_break_point) {
            min-width: 152px;  
        }

        @media (max-width: $mobile_break_point) {
            min-width: 78px;
        }
        img {
            border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .blog-article-card--content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        h3 {
            @include font-size(20, 24);
            font-weight: 600;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 0px;

        };
    }
}