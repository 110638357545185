@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';
@import '../../../../../../nn-psdl-modules/src/icon/c-icon.variables';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: Block Alertbox
=============================================================================*/

.block--alert-image {
  background-image: url('./alertbox/img/alertbox-icons-sprite.png');
  background-repeat: no-repeat;
}

.block--alert {
  position: relative;
  padding: 12px 16px 12px 42px;
  border-radius: 4px;
  margin-bottom: 15px;
  @include background-color($inlineMessage_elements_Container_variants_Warning_styles_backgroundColor);

  &:before {
    display: block;
    font-family: 'nn-iconfont';
    font-size: 24px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 8px;
    padding-top: 12px;
    border-left-style: solid;
    border-left-width: 4px;
    content: $fonticon-content--pict-info;
    @include color($inlineMessage_elements_Icon_variants_Warning_styles_color);
    @include border-left-color($inlineMessage_elements_Icon_variants_Warning_styles_color);
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  p {
    margin-bottom: 0.25rem;
  }
}

/* THEME: Error
=============================================================================*/
.block--alert-error {
  position: relative;
  @include background-color($inlineMessage_elements_Container_variants_Error_styles_backgroundColor);
  padding: 12px 16px 12px 42px;
  border-radius: 4px;

  &:before {
    display: block;
    font-family: 'nn-iconfont';
    font-size: 24px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 8px;
    padding-top: 12px;
    border-left-style: solid;
    border-left-width: 4px;
    content: $fonticon-content--pict-warning;
    @include color($inlineMessage_elements_Icon_variants_Error_styles_color);
    @include border-left-color($inlineMessage_elements_Icon_variants_Error_styles_color);
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  p {
    margin-bottom: 0.25rem;
  }
}
