@import "c-icon.content";

// GENERIC ICONFONT
@font-face {
  font-family: 'nn-iconfont';
  src: url('./icon/fonts/nn-iconfont.eot');
  src: url('./icon/fonts/nn-iconfont.eot#iefix') format('embedded-opentype'),
  url('./icon/fonts/nn-iconfont.woff') format('woff'),
  url('./icon/fonts/nn-iconfont.svg#nn-iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.c-icon {
  display: inline-block;
  font-family: 'nn-iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -moz-osx-font-smoothing: grayscale;

  &-fw {
    width: 1.4em;
    text-align: center;
  }

  &-size-l {
    font-size: 64px;
    vertical-align: -5%;
  }

  &-size-m {
    font-size: 42px;
    vertical-align: -10%;
  }

  &-size-s {
    font-size: 26px;
    vertical-align: -10%;
  }

}

@media (max-width: $phone) {
  .c-icon {

    &-size-l {
      font-size: 42px;
    }
  }
}
