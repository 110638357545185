.c-rating {

	margin: 0 0 10px 0;
  display: inline-block;

	&__stars {
		display: inline-block;
		min-height: 20px;
		width: 105px;
		vertical-align: middle;
		margin: -3px 5px 0 0;

		&__amount {
			display: block;
			background-position:  left -30px;
			min-height: 20px;
		}

		&, &__amount {
			// TODO: location of img with inline-image;
			background-image: url('img/c-rating__star.svg');
		}
	}
  
	&__totals {
		display: inline-block;
		margin: 0 20px 0 0;
		color: $nn_medium_orange;
		font-weight: bold;
	}

	&__reviewcount {
		display: inline-block;

		&__label {
			color: $nn_medium_grey;
		}

		&__link {
			color: $nn_medium_orange;
		}
	}
}
