@import '../../_helpers/mixins';
@import '../../_helpers/component-design-token-maps';

.c-form-radio {
	position: absolute;
	opacity: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;

	+ label,
	+ .c-form-label /* left for legacy */ {
    background: url('./c-form-radio/c-form-radio.svg') no-repeat left top;
    background-size: 24px;
    min-height: 24px;
		padding: 0 2px 0 40px;
		margin-bottom: 11px;
		display: inline-block;
		cursor: pointer;
    font-weight: normal;

		&--radio-ideal {
			display: flex;

			&::before {
				content: '';
				min-width: 35px;
				height: 30px;
				display: inline-block;
				margin-top: -10px;
				background: url('../img/c-form-radio-ideal.png') no-repeat left 0;
			}
		}
	}

	&:focus + label,
	&:focus + .c-form-label  /* left for legacy */ {
		outline: -webkit-focus-ring-color auto 5px;
	}

	&:checked + label,
	&:checked + .c-form-label /* left for legacy */ {
    background: url('./c-form-radio/c-form-radio-checked.svg') no-repeat left top;
    background-size: 24px;
	}

	&:disabled:checked + label,
	&:disabled:checked + .c-form-label /* left for legacy */ {
    background: url('./c-form-radio/c-form-radio-disabled-checked.svg') no-repeat left top;
    background-size: 24px;
	}

	&:disabled + label,
	&:disabled + .c-form-label /* left for legacy */ {
    background: url('./c-form-radio/c-form-radio-disabled.svg') no-repeat left top;
    background-size: 24px;
		cursor: default;
	}



	// DEPRECATED; LEFT FOR LEGACY

	+ .c-form-label {

		&--radio-gender {
			display: flex;

			&::before {
				content: '';
				width: 25px;
				height: 40px;
				display: inline-block;
				margin-top: -11px;
				background: url('../img/c-form-radio-gender.png') no-repeat;
			}

			&-female::before {
				background-position: 0 0;
			}

			&-male::before {
				background-position: 0 -60px;
			}
		}
	}

	&:checked + .c-form-label {

		&--radio-gender {
			&-female::before {
				background-position: -159px 0;
			}

			&-male::before {
				background-position: -160px -60px;
			}
		}
	}

	&__label--logo {
		height: 40px;
		margin-top: 10px;
		display: inline-block;
	}

	&__label--logo-img {
		background: url('../img/c-form-radio-assurance-logos.png') no-repeat 0 0;
		height: 40px;
		width: 130px;
		display: inline-block;
		margin-top: -10px;

		&-nn {
			background-position: 0 0;
		}

		&-rvs {
			background-position: 0 -60px;
		}

		&-ing {
			background-position: 0 -125px;
		}

	}
}
