@use "sass:math";

$var-header-menu-height: 37px;
$var-header-switch: 32px;

@import "c-header-dropdown";
@import '../_helpers/mixins';
@import '../_helpers/color';
@import '../_helpers/sizes';
@import "../_helpers/alias-design-token-maps";
@import "../_helpers/component-design-token-maps";

.c-header {
	@extend .u-clear;

	position: relative;
	margin: 0 auto;
	padding: 0 30px;
	border-bottom-width: 1px;
  border-bottom-style: solid;
  @include border-bottom-color($divider_elements_Divider_styles_borderColor);
	box-sizing: border-box;

	width: $desktop-min;

	// currently our header is on non responsive and responsive pages
	// TODO: when this hacky is-responsive solution is phased out, replace above width with max width;
	.is-responsive & {
    	width: auto;
		max-width: $desktop-min;
	}

	.is-wide & {
		width: auto;
		max-width: $desktop-max;
	}

	&__menu,
	&__switch {
		transition: opacity 0.2s ease;
	}

	&--mijnnn {
		min-height: 154px;
	}

	&--sub {

		html.is-responsive & {

			margin-left: (0em - math.div(15, 16));
			margin-right: (0em - math.div(15, 16));

			padding-left: (0em + math.div(15, 16));
			padding-right: (0em + math.div(15, 16));
		}

		&__menu {
			html.is-responsive & {
				display: block;
			}
		}

		.c-header__menu-link {
			outline: 0;
		}
	}


	&__skip {
		margin-bottom: 0;
		position: absolute;
		top: 0;
		left: 0;
	}

	&__logo {
		width: 100%;
		height: 100%;

		&-container {
			display: inline-block;
			margin-top: 34px;
			width: 196px;
			height: 60px;
			position: relative;
			z-index: 3;
		}
	}

	// ING LOGO can be placed on a pace in combination with NN
	&__co-branding {
		position: absolute;
		right: 30px;
		top: 34px;
	}


	// particulier, zakelijk, fondsen switch
	&__switch {
		position: absolute;
		top: 65px;
		left: 293px;

		@extend ._ul-reset;

		&-item {
			@extend ._li-reset;

			display: inline-block;
			padding-right: 20px;

			&:last-child {
				padding-right: 0;
			}
		}

		&-link  {
			color: $nn_medium_grey;
			text-decoration: none;

			@include font-bold;

			&--active {
				color: $nn_medium_orange;
			}
		}

		&--mobile {
			background: $nn_light_grey;
			display: block;
			margin: 10px 0 5px;

			.c-header__switch-item {
				float: left; // no inline-block, else 3px inline bug
    			display: block;
				padding: 0;
				margin-top: -3px;
			}

			.c-header__switch-link {
				border-right: 1px solid $nn_ultra_light_grey;
				border-top: 3px solid $nn_ultra_light_grey;
				color: $nn_dark_grey;
				display: inline-block;
				font-size: 13px;
				padding: 7px 6px;
				position: relative;

				&--active {
					padding: 9px 7px 7px;
					border: 1px solid $white;
					border-bottom: 0;
					color: $nn_medium_orange;
					background: $nn_ultra_light_grey;
				}
			}
		}
	}




	// MAINMENU
	&__menu {
		position: relative;
		padding-top: 18px;
		height: $var-header-menu-height;
		overflow: hidden;

		// OVERFLOW: HIDDEN;
		// This declaration is supposed to cause problems in tosca, but leaving it out
		// also causes problems (FRONTEND-203). For now we'll (re)apply the overflow, but
		// good to keep this in mind.

		@extend ._ul-reset;

		// main menu link
		&-link {
			@include color($color_text_default);
			text-decoration: none;
			white-space: nowrap;

			height: $var-header-menu-height;
			display: inline-block;

			&:hover,
			&:focus {
				text-decoration: underline;
			}

			&:visited {
				color: $nn_extra_dark_grey
			}

			&:hover {
				color: inherit
			}

			&--active,
			&--active:visited,
			&--active:hover {
				@include color($color_text_attentional);
        @include font-medium;
			}
		}

		&-item {
			@extend ._li-reset;
			display: inline-block;
			float: left;
			padding: 0 20px 0 0;
			height: $var-header-menu-height;

			&.is-active .c-header__menu-link {
				text-decoration: underline;
			}

			&--more,
			&:last-child {
				padding-right: 0;
			}

			&--hidden {
				display: none;
			}

		}

		// items for our submenu
		&-sub {
			// display: none; // FIXED: TOSCA CAN NOT FIND HIDDEN ELEMENTS
			opacity: 0;
			position: absolute;
			top: 37px;
			left: 0;
			transform: scale(0);
			height: 0;

			&__heading {
				font-size: 16px;
				@include font-normal;
				font-weight: normal;
				color: $nn_medium_orange;
			}

			&__buttonlist {
				margin-top: 15px;
      }

		}
	}



	// language toggle (for mijn.nn.nl)
	&__languageswitch {
		position: absolute;
		top: 9px;
		right: 30px;

		@extend ._ul-reset;

		&__item {
			@extend ._li-reset;

			display: inline-block;
			padding-right: 20px;

			&:last-child {
				padding-right: 0;
			}
		}

		&__link  {
			color: $nn_medium_grey;
			font-size: 14px;
			text-decoration: none;

			@extend .u-font-bold;

			&--active {
				color: $nn_medium_orange;
			}
		}
	}







	// login component
	&__login {
		@extend ._li-reset;
		position: absolute;
		right: 0;
		top: 18px; // equals margin top on main menu
		height: $var-header-menu-height;
		// when JS is rendering prevent menu items showing through this link
		background-color: $white;
		z-index: 1;

		&-link {
			display: block;
			white-space: nowrap;
			color: $nn_extra_dark_grey;
			height: $var-header-menu-height;
			text-decoration: none;

			@include font-bold;

			&:hover,
			&:focus {
				text-decoration: underline;
				color: inherit;
			}
		}

		&-icon {
			// TODO: no underline for icon! maybe can be moved into c-icon?
			display: inline-block;
			margin-right: 5px;
			color: $nn_medium_orange;
		}

	}

	// FIXME: ADVISEUR really wanted a logged in state which was actually not a link....
	// TODO: THIS WAY OF WORKING 'TOGETHER' DOES ... NOT ... WORK!
	&__no-link {
		display: block;
		white-space: nowrap;
		color: $nn_extra_dark_grey;
		height: $var-header-menu-height;
		text-decoration: none;

		@include font-bold;

	}

	// SEARCH COMPONENT
	&__search {
		position: absolute;
		z-index: 12; // the optional autosuggest should win from our user block!
		top: 56px;
		right: 30px;
		width: 270px;

		&--mobile {
			width: 250px;
			padding: 10px;

			.c-header__search__inputs,
			.c-header__search__input {
				width: 250px;
				float: none;
			}
		}

		&__form {
			// needed to fix alignment on inputbox in XC
			border: 1px solid transparent;
		}

		&--toggle {
			.is-search-open & {
				width: 40%;
			}
		}

		&__inputs {
			width: 100%;
			box-sizing: border-box;
			float: left;

			div.text-field {
				padding: 0;
				border: 0;
				width: 100%;
			}

			// correcting position of search input field;
			.text-field--search {
				margin-top:-4px;
			}

		}

		// TODO: Why is styling for c-form-container found in c-header?
		.c-form-container {
			margin-bottom: 5px;

			.c-form__field {
				margin-left: 0;
			}
		}

		&__toggle {
			color: $nn_medium_orange;
			float: right;
			font-size: 23px;
			text-decoration: none;
			position: absolute;
    	right: 0;
    	top: 0;
      margin-top: 3px;

			&:hover {
				color: $nn_dark_orange;
			}

			&.is-active {
				color: $nn_medium_grey;
				margin: 9px 4px 0 0;
				font-size: 14px;
				&:hover {
					color: $nn_dark_grey;
				}
			}
		}

		// shown in mobile header
		&-button {
			display: none;
		}

	}

	// MODIFIED FOR ADVISOR
	&--advisor {

		.c-form-text {
			padding-top: 7px;
			padding-bottom: 7px;
			-webkit-appearance:none;
		}

		.c-search__submit {
			top: 8px;
		}

		.c-header__search {
			top: 52px;
		}

		&-user .c-header__search {
			top: 20px;
		}

		.c-search__submit {
			// FIXME Important should be removed
			height: 100% !important;
			padding-left: 8px !important;
			padding-right: 8px !important;
			top: 0;
			right: 0;
		}
	}


	// MOBILE CONTAINER
	&__mobile {

		position: fixed; // absolute;

		background-color: $nn_ultra_light_grey;
		z-index: 110; // above our header mask!
		right: -300px;
		top: 0;

		width: 280px;
		height: 100%;

		transition: all 400ms ease-out;

		&-scroll {
			height: 100%;
			overflow-x: hidden;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
		}

		// hamburger menu
		&-button {
			display: none;
		}

		// wrapper around mask and mobile grid
		&__wrapper {
			position: fixed;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    z-index: 2001;
		    visibility: hidden;
		}


		// mask visible when mobile menu is placed, also acts as clickable container to close this mobile menu
		&-mask {
			opacity: 0;
			transition: opacity 400ms ease-in;
			background-color: $black;
			position: absolute;
			z-index: 100;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}


		// main navigation items
		&-menu {
			@extend ._ul-reset;

			&-item {
				@extend ._li-reset;
			}

			&-link {
				@include font-bold;
				display: block;
				text-decoration: none;
				padding: 6px 12px;

				&:focus {
					text-decoration: underline;
				}
			}
		}


		.c-header__user-logout {
			color: $nn_medium_orange;
			@include font-bold;
		}

		&-user {

			margin: 0 0 10px;
			padding: 6px 12px;
			border-bottom: 1px solid $white;


			&__item {
				@extend ._li-reset;

				padding-left: 25px;
				margin-bottom: 6px;

			}

			&__link {
				color: $nn_extra_dark_grey;
				text-decoration: none;
			}

			.c-header__login-icon {
				position: absolute;
				left: 0;
				top: 0;
			}

			.c-header__user-logout .c-icon {
				display: none;
			}

		}


	}


	// user is logged in
	&__user {
		position: absolute;
		z-index: 11; // make sure our __search block stays above it, for their autocomplete, so now it should be 12 there!
		bottom: -28px;
		right: 30px;
		height: 20px;
		border: 1px solid $nn_extra_light_grey;
		border-top-color: $white;
		background-color: $white;
		padding: 3px 10px;

		&-items {
			@extend ._ul-reset;
		}

		&-item {
			@extend ._li-reset;
			float: left;

			&:first-child {
				padding-right: 20px;
			}
		}

		&-account {
			display: inline-block;
			text-decoration: none;
			color: $nn_medium_orange;
			white-space: nowrap;
			max-width: 400px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-logout {
			display: inline-block;
			white-space: nowrap;
			text-decoration: none;

			.c-icon {
				color: $nn_medium_orange;
			}
		}

		// MADE FOR MOBILE MENU ONLY!
		&-button {
			display: none;
		}

	}



	// MOBILE HEADER BUTTONS
	&__buttons {
		display: none;
		@extend ._ul-reset;

		position: absolute;
		right: 11px;
		top: 24px;

		&-item {
			@extend ._li-reset;
			float: left;
			margin-left: 9px;
			padding-left: 8px;
			border-left: 1px solid $nn_extra_light_grey;

			&:first-child {
				margin-left: 0;
				padding-left: 0;
				border: 0;
			}
		}
	}



	// TEMPFIX FOR ADVISOR, THIS LOGGED IN STATUS WILL BE MOVED TO THEIR OWN PAGE
	&__advisor-logged-in {
		position: absolute;
		top: 4px;
		right: 24px;
		color: $nn_dark_grey;
		font-size: 12px;
	}

}



// PRINT DEFINITIONS
@media print {
	.c-header {
		width: 100%;
		border-bottom: 0px;
		padding: 24px 12px 12px 0 !important;

		&__logo-container {
			width: 147px;
			height: 48px;
			margin: 0;
		}

		&__switch,
		&__menu,
		&__user,
		&__search {
			display: none !important;
		}
	}
}



// THIS WRAPPER IS NEEDED AS LONG AS WE SUPPORT THIS is-responsive setup...
.is-responsive {

	@media (max-width: 940px) {

		.c-header {
			&__menu {
				&-item {
					padding-right: 15px;

					&--more,
					&:last-child {
						padding-right: 0;
					}

				}
			}
		}
	}

	@media (max-width: 820px) {

		.c-header {
			padding: 0 12px;

			&__search {
				right: 12px;
			}

			&__user-account {
				max-width: 310px;
			}

			// TEMPFIX FOR ADVISOR, THIS LOGGED IN STATUS WILL BE MOVED TO THEIR OWN PAGE
			&__advisor-logged-in {
				right: 12px;
			}
		}

	}


	@media (max-width: 767px) {
		.c-header {
			&__switch,
			&__languageswitch{
				left: auto;
				top: 8px;
				right: 12px;
			}
		}
	}


	@media (max-width: $phone) {

		.c-header {
			padding: 0 6px;

			&--mijnnn {
				min-height: 0;
			}

			// our header becomes 80px heigh and is set by our image container
			&__logo {
				&-container {
					width: 147px;
					height: 48px;
					margin-top: 17px;
					margin-bottom: 15px;
				}
			}

			&__co-branding {
				top: 17px;
			}

			&__menu,
			&__switch,
			&__user  {
				display: none;
			}

			&__buttons {
				display: block;
			}

			&__search {
				// padding-bottom: 20px; ONLY FOR OPEN STATE!
				position: relative;
				right: 0;
				top: 0;
				width: auto;
				display: none;

				&__inputs {
					float: none;
					width: auto;
					padding-right: 0;
				}

				&__toggle {
					display: none;
				}

				&:target {
					display: block;
				}
			}

			&__login-link {
				position: absolute;
				right: 70px;
			}

			&__user,
			&__search,
			&__mobile {
				&-button {
					display: block;

					width: $var-header-switch;
					height: $var-header-switch;
					line-height: $var-header-switch;

					text-decoration: none;
					font-size: 30px;
					color: $nn_medium_orange;
					text-align: center;
				}
			}

			&.is-search-open .c-header__search__inputs,
			&.is-search-open .c-header__search {
        display: block;
			}

			&.is-search-open .c-header__search {
				padding-bottom: 20px;
				width: 100%;
			}

			// TEMPFIX FOR ADVISOR, THIS LOGGED IN STATUS WILL BE MOVED TO THEIR OWN PAGE
			&__advisor-logged-in {
				right: 12px;
				top: inherit;
				bottom: 0;
			}

		}

	}

}

@media (max-width: 824px) {

	.c-header {
		&__search {
			&--toggle {
				.is-search-open & {
					width: 50%;
				}
			}
		}
	}
}


// MOBILE MENU IS OPENED
@media (max-width: $phone) {

	body.is-menu-open {
		position: fixed;
		height: 100%;
		width: 100%;
		overflow: hidden;
		margin-top: 0;

		.c-header__mobile {
			right: 0;

			&__wrapper {
				visibility: visible;
			}

			&-mask {
				opacity: 0.4;
			}

			.c-header__login-link {
				position: static;
				right: auto;
				height: auto;
			}

			// not logged in icon is darker inside mobile menu
			.c-header__login-icon {
				color: $nn_medium_orange;
			}

			.c-header__search {
	    		display: block;
	    	}
		}
	}
}
