/**
 * Static images
 *
 * This module handles static images using CSS rather than <img> tags.
 * Note that this is a highly undesirable situation. However, it looks like it's
 * unavoidable. The rationale is as follows:
 * - Blueriq applications sometimes need to load static assets (images);
 * - Static assets cannot be served by Blueriq;
 * - XC is unavailable to Blueriq engineers when working on applications in the
 * studio rather than on an actual environment, so we cannot use the cmscontent
 * mechanism to retrieve assets at runtime either.
 * - XC freestyle exports, however, can be used directly by Blueriq engineers
 * when working on local dev, without having to load freestyle contents from XC;
 * - XC freestyle URLs contain UUIDs, e.g.
 * /nn-static/static/design/{$UUID}-fsm/path/to/some/freestyle/asset.jpg";
 * - because of these UUIDs, we can't have fixed paths to freestyle assets;
 * - This leaves us with freestyle CSS where we can use background images with
 * relative paths to reference static assets.
 */

@use "sass:math";

.c-img{
	display: block;
	position: relative;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 0 0;
}

$investment_profiles: (
	liquide
	(''),
	defensief
	(1,2,3,4),
	neutraal
	(1,2,3,4,5,6),
	offensief
	(1,2,3,4,5,6)
);

@each $investment_profile in $investment_profiles {
	$type: nth($investment_profile, 1);
	$suffixes: nth($investment_profile, 2);

	@each $suffix in $suffixes {
		.c-img--#{$type}#{$suffix}{
			padding-top: (math.div(214, 602)*100)+0%;
			background-image: url("img/#{$type}#{$suffix}.png");
		}
	}
}

@for $i from 1 through 28 {
	.c-img--riskprofile#{$i} {
		background-image: url("img/riskprofile#{$i}.png");
    width: 100%;
    padding-bottom: 80%;
	}
}

@for $i from 1 through 3 {
  .c-img--insurance#{$i} {
    background-image: url("img/insurance#{$i}.png");
    width: 100%;
    padding-bottom: 40%;
    margin-bottom: 20px;
  }
}