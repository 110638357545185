.c-checkout-tile{
	padding: 20px 25px 15px 25px;
	border: 0;
	background-color: $nn_ultra_light_grey;
}

.c-checkout-tile--error{
	padding: 16px 21px 11px 21px;
	border: 4px solid #ea4300;
}
