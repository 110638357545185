// PART: Grid
@use 'sass:math';

@import '../../../global/css/breakpoints';
@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';
@import '../../../global/css/mixins';
@import '../../../global/css/component-design-token-maps';

.grid {
  @include clearfix();
  width: 100%;
}

.grid-unit {
  @include box-sizing-border-box();
  float: left;
}

.grid-unit--inner {
  @include box-sizing-border-box();
}

.grid-unit-title {
  font-size: 0.85em;
}

// THEME: alpha

.grid--alpha {
  @include box-sizing-border-box();
  padding-right: 32px;

  > .grid-unit {
    width: 100%;
    padding-left: 32px;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--alpha {
      width: auto;
      padding-right: 0;

      > .grid-unit {
        padding: 0 15px;
      }
    }
  }
}

// THEME: beta

.grid--beta {
  @include box-sizing-border-box();
  padding-right: 32px;

  > .grid-unit {
    width: 75%; /* 720 / 960 */
    padding-left: 3.125%; /* 30 / 960 */
    padding-left: 32px;
  }

  > .grid-unit + .grid-unit {
    width: 25%; /* 240 / 960 */
    margin-bottom: 0;
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .grid-unit--inner {
      float: left;
    }

    .grid--beta {
      width: auto;
      padding: 0;

      > .grid-unit {
        width: 100%;
        padding: 0 32px;
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--beta {
      width: auto;
      padding: 0;

      > .grid-unit {
        width: 100%;
        padding: 0 15px;
      }

      .grid-unit--inner:nth-child(odd),
      .grid-unit--inner:nth-child(even) {
        float: none;
        width: auto;
        padding: 0;
      }
    }
  }
}

// THEME: gamma

.grid--gamma {
  @include box-sizing-border-box();
  background-size: 100% auto;
  min-height: 400px;
  width: 100%;
  padding-right: 30px;

  > .grid-unit {
    width: 58.3333333333%;
    padding-left: 30px;
  }

  > .grid-unit + .grid-unit {
    width: 41.6666666666%;
    margin-bottom: 0;
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .grid--gamma {
      > .grid-unit {
        display: block;
        width: 100%;
      }
    }
  }
}

// THEME: delta

.grid--delta {
  @include box-sizing-border-box();
  border-bottom: 1px solid $nn_light_grey;
  border-top: 1px solid $nn_light_grey;
  display: table;
  padding: 20px 30px 20px 0;
  width: 100%;

  > .grid-unit {
    display: table-cell;
    float: none;
    padding-left: 30px;
    vertical-align: middle;
    width: 50%;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--delta {
      > .grid-unit {
        display: block;
        width: 100%;
      }
    }
  }
}

// THEME: Bank

.grid--bank {
  > .grid-unit {
    margin-top: -10px;
    padding-left: 10px;
    width: 785px;
  }

  > .grid-unit + .grid-unit {
    margin-top: 0;
    width: 175px;
  }
}

.android .grid--bank > .grid-unit + .grid-unit {
  width: 180px;
}

.grid--epsilon {
  @include box-sizing-border-box();
  padding-right: 30px;

  > .grid-unit {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 15px;
    @media (min-width: $mobile_break_point) {
      padding-left: 30px;
    }

    @media (min-width: $tablet_break_point) {
      width: 50%; /* 720 / 960 */
      padding-left: 30px;
      margin-bottom: 20px;
    }
  }
}

// THEME: Grid Block

.grid--block {
  width: 100%;
  margin-bottom: 15px;

  > .grid-unit {
    width: 100%; /* 524/660 */
  }

  > .grid-unit + .grid-unit {
    display: none;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive .grid--block {
    margin-bottom: 0;
  }
}

// THEME: Grid Block Alpha

.grid--block-alpha {
  width: 100%;

  > .grid-unit {
    width: 495px;
    padding-right: 15px;
  }

  > .grid-unit + .grid-unit {
    width: auto;
    width: (math.div(193, 690) * 100)+0%;
  }
}

@media (max-width: $desktop_break_point) {
  .is-responsive .grid--block-alpha > .grid-unit + .grid-unit {
    width: 100%;
  }
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .grid--block-alpha {
      width: auto;

      > .grid-unit {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-alpha {
      width: auto;

      > .grid-unit {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}

// THEME: Block Beta

.grid--block-beta {
  width: 100%;

  > .grid-unit {
    width: 130px;
  }

  > .grid-unit + .grid-unit {
    width: 330px;
    position: relative;
  }
}

// CONTEXT: Form-field Block beta

.form-fieldset {
  .grid--block-beta {
    margin: 0 0 20px 0;
  }

  .grid--block-beta.last {
    margin: 0;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    // CONTEXT: Default Responsive Block beta

    .grid--block-beta {
      width: auto;

      > .grid-unit {
        width: 100%;
      }
    }

    // CONTEXT: Form-field Block beta

    .grid--block-alpha .grid--block-beta {
      > .grid-unit + .grid-unit {
        padding-right: 28px;
      }
    }
  }
}

// THEME: Block Gamma

.grid--block-gamma {
  width: 100%;

  > .grid-unit {
    width: 78%; /* 524/660 */
    padding-right: (math.div(15, 660) * 100) + 0%;
  }

  > .grid-unit + .grid-unit {
    padding-right: 0;
    width: 22%; /* 136/660 */
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-gamma {
      width: auto;

      > .grid-unit {
        width: 100%;
        padding: 0;
      }
    }
  }
}

// THEME: Block Delta

.grid--block-delta {
  width: 100%;
  position: relative;
  > .grid-unit {
    width: 220px;
    margin-right: 30px;
  }

  > .grid-unit + .grid-unit {
    width: 330px;
    position: relative;
    margin-right: 0;
  }
  //Due to changes in width of first column it won't fit anymore to have 3 columns next to eachother
  > .grid-unit + .grid-unit + .grid-unit {
    width: 220px;
    position: relative;
  }
}

.grid--block-delta.grid--alternative {
  > .grid-unit:first-child {
    width: 100%;
  }

  > .grid-unit + .grid-unit {
    margin-left: 250px;
  }
}

@media (max-width: 860px) {
  .grid--block-delta.grid--alternative {
    > .grid-unit + .grid-unit {
      margin-left: 0;
    }
  }
}

// CONTEXT: Form-field Block Delta

.form-fieldset {
  .grid--block-delta {
    margin: 0 0 20px 0;
  }

  .grid--block-delta.last {
    margin: 0;
  }
}

// THEME: Field vertical

.grid--field-vertical {
  margin-bottom: 10px;
  label {
    display: block;
    margin: 0 0 5px 0;
  }
}

// FIX for width issue within TwoColumnsOneWide Blueriq

.is-responsive .grid--TwoColumnsOneWide,
.grid--TwoColumnsOneWide {
  @media (max-width: $tablet_break_point) {
    margin-bottom: 20px;
    & ~ .col-t-one-third .block--bordered-left {
      border: 0;
    }
  }
  .grid--block-delta {
    width: auto;
    margin: 0;
    > .grid-unit {
      margin-right: 0;
      @media (min-width: $tablet_break_point) {
        width: 50%;
      }
    }
    > .grid-unit {
      padding-top: 5px;
    }
    > .grid-unit + .grid-unit {
      @media (min-width: 500px) {
        padding: 5px 32px 0 0;
        width: 50%;
      }
      @media (max-width: $tablet_break_point) {
        margin-left: 0;
        padding: 5px 0 0;
      }
      .dialog--information {
        right: 5px;
        top: 5px;
      }
      .field-value {
        margin: 0;
        padding: 0;
      }
    }
    .field-label {
      @media (max-width: 499px) {
        padding: 10px 0 0;
      }
    }
  }
}

// CONTEXT: Responsive Block Delta

//Form in column grid
@media (min-width: $mobile_break_point) {
  .is-responsive .col-one-half,
  .is-responsive .col-t-one-half,
  .is-responsive .col-d-one-half {
    .grid--block-delta {
      width: auto;

      > .grid-unit {
        width: 100%;
        padding: 0;
        margin-bottom: 5px;
      }

      > .grid-unit + .grid-unit {
        padding-right: 32px;
        margin-left: 0;
        width: 100%;
      }
      > .grid-unit + .grid-unit + .grid-unit {
        padding-right: 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-delta {
      width: auto;

      > .grid-unit {
        width: 100%;
        padding: 0;
        margin-right: 0;
      }

      > .grid-unit + .grid-unit {
        padding-right: 28px;
        margin-left: 0;
      }
      > .grid-unit + .grid-unit + .grid-unit {
        padding-right: 0;
        width: 100%;
      }
    }
  }
}

// THEME: Block Delta message

.grid--block-delta-message {
  width: 100%;
  margin-top: -15px;

  > .grid-unit {
    width: 220px;
    margin-right: 30px;
  }

  > .grid-unit + .grid-unit {
    width: 330px;
    position: relative;
    margin-right: 0;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-delta-message {
      width: auto;

      > .grid-unit {
        width: 100%;
        padding: 0;
      }

      > .grid-unit:first-child {
        display: none;
      }

      > .grid-unit + .grid-unit {
        padding-right: 28px;
        margin-left: 0;
      }
    }
  }
}

// THEME: Responsive Block Epsilon

.grid--block-epsilon {
  width: 100%;

  > .grid-unit {
    width: 50%;
    padding: 0 15px 0 0;
    margin: 0 0 5px 0;
  }

  > .grid-unit + .grid-unit {
    padding: 0 0 0 20px;
  }
}

.block--border .grid--block-epsilon > .grid-unit {
  *width: 330px;
}

// CONTEXT: Responsive Block .grid--block-epsilon

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-epsilon {
      width: auto;

      > .grid-unit {
        width: 100%;
        padding: 0;
      }

      > .grid-unit:first-child {
        margin-bottom: 20px;
      }
    }
  }
}

// THEME: Responsive Block Zeta

.grid--block-zeta {
  width: 100%;

  > .grid-unit {
    width: 42%;
    line-height: 1.5em;
  }

  > .grid-unit + .grid-unit {
    width: auto;
  }
}

.grid--block-zeta.last > .grid-unit {
  margin: 0 0 10px 0;
}

@media (max-width: $tabmob_break_point) {
  .is-responsive {
    .grid--block-zeta {
      > .grid-unit {
        float: none;
      }
    }

    .grid--block-zeta + .grid--block-zeta {
      margin-top: 7px;
    }
  }
}

// THEME: Grid Form Block inline

.grid--block-inline {
  width: 100%;
  margin-bottom: 20px;
  > .grid-unit {
    float: left;
    margin-right: 20px;
    width: 100%;
    @media (min-width: $tabmob_break_point) {
      width: auto;
    }
    > label {
      display: block;
      margin-bottom: 5px;
    }
    .more {
      margin-top: 10px;
    }
    input,
    .select,
    button {
      margin-right: 10px;
    }
    input[type='submit'] {
      margin-top: 10px;
      @media (min-width: $tabmob_break_point) {
        margin-top: 36px;
      }
    }
  }
  /*inline radiobuttons*/
  > p {
    margin-bottom: 0;
  }
  .field--radiobutton {
    margin: 9px 9px 0 0;
  }
}

// CONTEXT: grid--block-inlines

.grid--block-inlines {
  .grid-unit {
    float: left !important;
    width: 100%;
    position: relative;
    vertical-align: top;
    &.field-label {
      padding-top: 0;
    }
    .text-field {
      margin-bottom: 10px;
    }
    @media (min-width: $tabmob_break_point) {
      margin-right: 20px;
      float: none !important;
      display: inline-block;
      width: auto;
      &.field-label {
        padding-top: 10px;
      }
      .date-picker-icon {
        position: relative;
        float: right;
        margin: 11px 0 0 8px;
        right: auto;
        top: auto !important;
      }
      .dialog--information {
        right: -35px;
        top: 9px;
      }
    }
    .dialog--information {
      top: 9px;
    }
  }
  .grid-unit--datefield-picker {
    margin-right: 15px !important;
  }
  .error-message {
    margin-bottom: 0;
  }
  .more {
    margin: 20px 0 0 0;
    @media (min-width: $tabmob_break_point) {
      margin: 10px 0 0 30px;
    }
  }
}

// THEME: Block Eta

.grid--block-eta {
  width: 100%;

  > .grid-unit {
    width: 220px;
    margin-right: 30px;
  }

  > .grid-unit + .grid-unit {
    width: 90px;
    margin-right: 0;
  }

  > .grid-unit + .grid-unit + .grid-unit {
    width: 140px;
    margin-right: 0;
  }

  > .grid-unit + .grid-unit + .grid-unit + .grid-unit {
    width: 90px;
    margin-right: 0;
  }
}

// CONTEXT: Form-field Block eta

.form-fieldset {
  .grid--block-eta {
    margin: 0 0 20px 0;
  }

  .grid--block-eta.last {
    margin: 0;
  }
}

@media (max-width: 860px) {
  .is-responsive {
    .grid--block-eta {
      > .grid-unit + .grid-unit {
        width: 330px;
      }

      > .grid-unit + .grid-unit + .grid-unit {
        width: 250px;
        padding-left: 0;
      }
      > .grid-unit + .grid-unit + .grid-unit + .grid-unit {
        width: 330px;
      }
    }

    .form-fieldset {
      .grid--block-eta > .grid-unit + .grid-unit {
        margin-bottom: 20px;
      }
      .grid--block-eta > .grid-unit + .grid-unit + .grid-unit {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-eta {
      width: auto;

      > .grid-unit,
      > .grid-unit + .grid-unit + .grid-unit + .grid-unit {
        width: 100%;
        float: none;
      }
    }

    .form-fieldset {
      .grid--block-eta > .grid-unit + .grid-unit {
        margin-bottom: 20px;
      }
      .grid--block-eta > .grid-unit + .grid-unit + .grid-unit {
        margin-bottom: 0;
      }
    }
  }
}

// THEME: Block theta

.grid--block-theta {
  width: 100%;

  > .grid-unit {
    width: 100%;
  }
}

.form-fieldset .grid--block-theta {
  margin: 0 0 20px 0;
}

// THEME: Block USP's (4 columns)

.grid--block-wau {
  width: auto;
  margin-left: -30px; /* depends on nesting in a block*/
  margin-bottom: 30px;
  > .grid-unit {
    width: 25%;
    padding-left: 30px;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-wau {
      width: auto;

      > .grid-unit {
        width: 100%;
        float: none;
      }
    }
  }
}

// THEME: grid--block-iota / Small forms

.grid--block-iota {
  position: relative;
  width: auto;
  margin-bottom: 20px;

  > .grid-unit {
    width: 50%;
    position: relative;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-iota {
      margin-bottom: 10px;

      > .grid-unit {
        width: 100%;
        float: none;
      }
    }
  }
}

// THEME: grid--block-masthead / Masthead forms

.grid--block-masthead {
  position: relative;
  width: auto;

  > .grid-unit {
    width: 45%;
    padding-right: 10px;
  }

  > .grid-unit + .grid-unit {
    width: 55%;
    padding: 0;
  }
}

.grid--block-masthead-v2 {
  position: relative;
  width: auto;

  > .grid-unit {
    width: 45%;
    padding-right: 10px;
  }

  > .grid-unit + .grid-unit {
    width: 30%;
  }

  > .grid-unit + .grid-unit + .grid-unit {
    width: 25%;
    padding: 0;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-masthead-v2 {
      > .grid-unit:first-child {
        float: none;
        width: 100%;
      }

      > .grid-unit + .grid-unit {
        width: 60%;
      }
      > .grid-unit + .grid-unit + .grid-unit {
        width: 40%;
      }
    }
  }
}

.form--feedforward .grid--block-masthead,
.form--feedforward .grid--block-masthead-v2 {
  width: 93%;
}

.masthead .grid--block-masthead,
.masthead .grid--block-masthead-v2 {
  margin: 0 0 11px 0;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-masthead {
      > .grid-unit {
        width: 100%;
        float: none;
      }
    }
  }
}

// CONTEXT: Form-field Block Iota

.form-fieldset {
  .grid--block-iota {
    margin: 0 0 20px 0;
  }

  .grid--block-iota.last {
    margin: 0;
  }
}

// THEME: grid--block-kappa

.grid--block-kappa {
  @include border-radius-sep(0, 5px, 5px, 0);
  width: auto;
  margin-left: -30px;
  margin-bottom: 30px;
  padding: 15px 15px 0 30px;
  background: $nn_ultra_light_grey;

  > .grid-unit {
    width: 55%;
    padding: 0 15px 0 0;
  }

  > .grid-unit + .grid-unit {
    width: 45%;
    padding: 0;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-kappa {
      @include border-radius-sep(0, 0, 0, 0);
      margin: 0;
      padding: 0;
      background-color: #fff;

      .grid-unit {
        width: auto;
        display: block;
      }
    }

    .grid--block-kappa--mobilefriendly {
      background: $nn_ultra_light_grey;
      padding: 15px 15px 0 15px;
    }
  }
}

// THEME: grid--block-lambda

.grid--block-lambda {
  width: auto;
  margin-left: -15px;

  > .grid-unit {
    width: 33.333333%;
    padding-left: 15px;
  }
}

@media (max-width: $tabmob_break_point) {
  .is-responsive {
    .grid--block-lambda {
      > .grid-unit {
        width: 50%;
        display: inline;
      }
      > .grid-unit:last-child {
        width: auto;
        display: block;
        float: none;
        clear: both;
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-lambda {
      > .grid-unit {
        width: auto;
        display: block;
        float: none;
      }
    }
  }
}

// THEME: grid--block-mu Specificaly designed for tablet use

.grid--block-mu {
  width: auto;
}

@media (max-width: $tablet_break_point) and (min-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-mu {
      margin-left: -30px;

      > .grid-unit {
        width: 66.6%;
        padding-left: 30px;
      }

      > .grid-unit + .grid-unit {
        width: 33.3%;
      }
    }
  }
}

// THEME: grid--block-nu Specificaly designed for tablet use

.grid--block-nu,
.grid--block-mu + .grid--block-mu {
  width: auto;
}

@media (max-width: $tablet_break_point) and (min-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-nu,
    .grid--block-mu + .grid--block-mu {
      margin-left: -30px;

      > .grid-unit {
        width: 33.3%;
        padding-left: 30px;
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-mu,
    .grid--block-nu {
      .grid-unit {
        width: auto;
        float: none;
      }
    }
  }
}

// THEME: grid--block-xi

.grid--block-xi {
  padding-bottom: 25px;

  &.collapse {
    border-bottom: 1px solid $nn_light_grey;
  }

  > .grid-unit {
    width: 78%; /* 524/660 */
    padding-right: (math.div(15, 660) * 100) + 0%;
  }

  > .grid-unit + .grid-unit {
    padding-top: 8px;
    padding-right: 0;
    width: 22%;
  }

  .grid-unit-title {
    margin: 13px 0;
    display: inline-block;
    padding-left: 10px;
  }
}

.grid--block-xi--container {
  border-top-width: 1px;
  border-top-style: solid;
  @include border-top-color($collapsible_elements_CollapsibleSection_styles_borderColor);

  &:last-child {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @include border-bottom-color($collapsible_elements_CollapsibleSection_styles_borderColor);
  }

  > .grid--block-xi:first-child:hover {
    cursor: pointer;
    @include background-color($collapsible_elements_CollapsibleSectionTrigger_interactionStates_Hover_styles_backgroundColor);    cursor: pointer;
  }

  > .grid--block-xi:first-child .grid-unit:first-child {
    padding-left: 15px;
  }
  > div[data-role='collapser-target'] {
    padding: 0 20px 0 25px;
    /*display:none;*/
  }

  > .grid--block-xi:first-child .grid-unit .grid-unit-title {
    @include font-size(16);
  }

  > .grid--block-xi:first-child .grid-unit:first-child .grid-unit-title {
    padding-top: 0;
    @include font-medium();

    strong {
      @include font-medium();
    }
  }

  > .grid--block-xi:first-child .grid-unit .grid-unit-title:nth-child(3) {
    float: right;
  }
  @media (max-width: $mobile_break_point) {
    h3.heading-grey {
      display: none;
    }
  }

  > .grid--block-xi {
    padding-bottom: 0;
    position: relative;
    > .grid-unit {
      width: 100%;
    }
  }

  > div > .grid--block-xi,
  > div > .grid-wrapper--0 {
    padding-bottom: 25px;
    width: auto;
    .grid--field-row {
      border-bottom: 0;
    }
    > .grid-unit:first-child {
      padding-bottom: 15px;
    }
  }

  &.active {
    border-top-width: 2px;
    border-top-style: solid;
    @include border-top-color($collapsible_elements_CollapsibleSection_styles_borderColor);

    > .grid--block-xi {
      border-bottom: 0;
    }
    .grid-unit .grid-unit-title.hidden-active {
      display: none;
    }
  }

  .grid--field-row {
    padding: 4px 5px 4px 0;
  }
  .collapser-icon {
    position: absolute;
    left: 0;
    top: 15px;
    @media (max-width: $mobile_break_point) {
      top: 9px;
    }
  }

  .link-list {
    a {
      color: $nn_medium_orange;
    }
  }

  .grid--block-xi-halved {
    > .grid-unit {
      width: 50%;
      padding-right: 0;
      padding-top: 0;

      & + .grid-unit {
        text-align: right;
        padding-right: 2.273%;
      }
    }
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-xi {
      margin: 0;
      padding-bottom: 0;
      width: auto;

      > .grid-unit {
        width: 100%;
        padding: 0;
      }
    }

    .grid--block-xi--container {
      /*margin: 0 -10px;*/

      &:last-child .grid--block-xi {
        border: none;
      }

      .grid--field-row {
        margin: 0;
      }

      .link-list--responsive {
        margin: 0;
      }
    }

    .grid--block-xi-halved {
      > .grid-unit {
        width: 100%;
      }
    }
  }
}

.touch {
  .grid-unit-title {
    margin: 14px 0;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid-unit-title {
      margin: 8px 0;
    }
  }
}

// THEME: Field row

.grid--field-row {
  @include box-sizing-border-box();
  width: 100%;
  padding: 7px 5px 7px 5px;
  border-bottom: 1px solid $nn_extra_light_grey;

  > .grid-unit {
    width: 60%;
    position: relative;
  }

  > .grid-unit + .grid-unit {
    width: 40%;
  }

  .grid--field-row {
    background-color: transparent;
  }

  > .grid-unit--single {
    width: 100%;
  }

  &.grid--field-row-no-border {
    border: none;
  }
}

.grid--field-row-header {
  @include box-sizing-border-box();
  background-color: $nn_extra_light_grey;
  border-bottom: 1px solid $nn_extra_light_grey;
  padding: 7px 5px 7px 5px;

  .grid-unit {
    border: 0;
    width: 100%;
  }

  h5 {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--field-row {
      width: auto;
      margin: 0 -10px;
      padding: 3px 10px 4px;

      > .grid-unit {
        float: left;
        width: 60%;
      }

      > .grid-unit + .grid-unit {
        width: 40%;
        padding: 0;
        text-align: right;
      }

      > .grid-unit--single {
        width: 100%;
      }
    }

    .grid--field-row-header {
      width: auto;
      margin: 0 -10px;
      padding: 7px 10px 8px;

      h5 {
        margin: 0;
        padding: 0;
      }
    }

    .grid--field-row:last-child {
      border: none;
    }
  }
}

.field-row-header {
  margin: 10px 0 0 0;
  padding: 0;
}

.field-row-num {
  float: left;
}

.field-row-title {
  display: block;
  margin-left: 20px;
}

.grid--field-row:first-child .field-row-header {
  margin: 0;
}

.grid--field-row .dialog--information {
  margin-left: 4px;
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .collapsible-block {
      margin: 0 0 15px 0;
    }
  }
}

// THEME: FILTER

.grid--block-filter {
  > .grid-unit {
    width: 100px;
    position: relative;
  }

  > .grid-unit + .grid-unit {
    width: 150px;
  }
}

.form-fieldset {
  .grid--block-filter {
    margin-bottom: 20px;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-filter {
      > .grid-unit {
        float: none;
        width: auto;
      }

      > .grid-unit + .grid-unit {
        padding-right: 28px;
        margin-left: 0;
      }

      > .grid-unit + .grid-unit + .grid-unit {
        padding-right: 0;
        width: 100%;
      }
    }
  }
}

.grid--block-omicron {
  margin-left: -30px;
  width: auto;

  > .grid-unit {
    width: (100 * 0.2)+0%;
    padding-left: 30px;
  }
}

.grid--block-pi {
  margin-left: -30px;
  width: auto;

  > .grid-unit {
    width: math.div(100, 3) + 0%;
    padding-left: 30px;
  }
}

.grid--block-rho {
  > .grid-unit {
    width: 60%;
  }

  > .grid-unit + .grid-unit {
    position: relative;
    width: 40%;
  }
  .field-label {
    padding-right: 20px;
  }
}

.form-fieldset {
  .grid--block-rho {
    margin: 0 0 20px 0;
  }

  .grid--block-rho.last {
    margin: 0;
  }
}

@media (max-width: $mobile_break_point) {
  .is-responsive {
    .grid--block-rho {
      .grid-unit {
        width: 100%;
      }
      > .grid-unit + .grid-unit {
        width: 100%;
        padding-right: 28px;
      }
    }
  }
}

// THEME: Main navigation submenu

.grid--submenu {
  max-width: 1184px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin: 0 auto;
  list-style: none;
  overflow: auto;

  .grid-unit {
    margin: 0;
    padding: 0;
    background: none;
    width: (math.div(220, 930) * 100) + 0%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }

  .grid-unit + .grid-unit {
    margin-left: (math.div(math.div(50, 3), 930) * 100) + 0%;
  }
}

.grid--submenu--6 {
  @extend .grid--submenu;

  .grid-unit {
    margin: 0;
    padding: 0;
    background: none;
    width: (math.div(145, 930) * 100) + 0%;
  }

  .grid-unit + .grid-unit {
    margin-left: (math.div((60 * 0.2), 930) * 100) + 0%;
  }
}

.grid--submenu + .grid--submenu--6 {
  margin-top: 15px;
}

@media (max-width: $tablet_break_point) {
  .is-responsive {
    .grid--submenu,
    .grid--submenu--6 {
      padding: 0;

      .grid-unit {
        width: 100%;
      }
    }
  }
}
