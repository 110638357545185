@import '../../../global/css/colors';
@import '../../../global/css/sass-functions';
@import '../../../global/css/mixins';
@import '../../../control/icons/css/icons.mixins';
@import '../../../global/css/component-design-token-maps';

/* COMPONENT: Search text field
=============================================================================*/
.text-field--search {
  position: relative;
  display: inline-block;
  width: 100%;
  /*border: solid 1px $nn_extra_light_grey;*/
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  /* IE */
  .text-field-placeholder,
  .text-field::-webkit-input-placeholder {
    font-style: normal;
  }
  .text-field::-moz-placeholder {
    font-style: normal;
  }
  .text-field:-moz-placeholder {
    font-style: normal;
  }
  .text-field:-ms-input-placeholder {
    font-style: normal;
  }

  input[type='text'],
  .text-field {
    border-width: 1px;
    border-style: solid;
    @include border-color($input_elements_Input_styles_borderColor);
    width: 100% !important;
    font-style: normal;
  }
}
.text-field--search-submit {
  width: 24px;
  height: 24px;
  text-indent: 150%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 16px;
  top: 50%;
  border: none;
  transform: translateY(-50%);
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url('text-field--search/img/icon-search.svg');
}
