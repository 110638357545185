@import '../../../global/css/breakpoints';

/* PART: cell widths
=============================================================================*/

.data-table-cell-5 {
	width: 5%;
}
.data-table-cell-10 {
	width: 10%;
}
.data-table-cell-15 {
	width: 15%;
}
.data-table-cell-20 {
	width: 20%;
	&.data-table-cell-colspan2 {
		width: 40%;
	}
}
.data-table-cell-25 {
	width: 25%;
	&.data-table-cell-colspan2 {
		width: 50%;
	}
}
.data-table-cell-30 {
	width: 30%;

	&.data-table-cell-colspan2 {
		width: 60%;
	}
}
.data-table-cell-35 {
	width: 35%;
	&.data-table-cell-colspan2 {
		width: 70%;
	}
}
.data-table-cell-40 {
	width: 40%;
	&.data-table-cell-colspan2 {
		width: 80%;
	}
}
.data-table-cell-45 {
	width: 45%;
}
.data-table-cell-50 {
	width: 50%;
}
.data-table-cell-55 {
	width: 55%;
}
.data-table-cell-60 {
	width: 60%;
}
.data-table-cell-65 {
	width: 65%;
}
.data-table-cell-70 {
	width: 70%;
}
.data-table-cell-75 {
	width: 75%;
}
.data-table-cell-80 {
	width: 80%;
}
.data-table-cell-85 {
	width: 85%;
}
.data-table-cell-90 {
	width: 90%;
}
.data-table-cell-95 {
	width: 95%;
}
.data-table-cell-100 {
	width: 100%;
}


@media (max-width: $mobile_break_point) {

	.is-responsive {

		.data-table-cell-r5 {
			width: 5%;
		}
		.data-table-cell-r10 {
			width: 10%;
		}
		.data-table-cell-r15 {
			width: 15%;
		}
		.data-table-cell-r20 {
			width: 20%;
		}
		.data-table-cell-r25 {
			width: 25%;
		}
		.data-table-cell-r30 {
			width: 30%;
		}
		.data-table-cell-r35 {
			width: 35%;
		}
		.data-table-cell-r40 {
			width: 40%;
		}
		.data-table-cell-r45 {
			width: 45%;
		}
		.data-table-cell-r50 {
			width: 50%;
		}
		.data-table-cell-r55 {
			width: 55%;
		}
		.data-table-cell-r60 {
			width: 60%;
		}
		.data-table-cell-r65 {
			width: 65%;
		}
		.data-table-cell-r70 {
			width: 70%;
		}
		.data-table-cell-r75 {
			width: 75%;
		}
		.data-table-cell-r80 {
			width: 80%;
		}
		.data-table-cell-r85 {
			width: 85%;
		}
		.data-table-cell-r90 {
			width: 90%;
		}
		.data-table-cell-r95 {
			width: 95%;
		}
		.data-table-cell-r100 {
			width: 100%;
		}

	}
}