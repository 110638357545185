@import 'colors';
@import 'breakpoints';
@import 'sass-functions';

/* Global General Styles
/* Hide from both screenreaders and browsers
=============================================================================*/
.hidden {
  display: none !important;
  visibility: hidden;
}

/* Global Responsive Helper Styles
=============================================================================*/

.visible-mobile {
  display: none !important;
}

.visible-mobile-special {
  display: none;
}

.visible-tabmob {
  display: none !important;
}

.visible-tablet {
  display: none !important;
}

@media (min-width: $mobile_break_point) {
  .hidden-desktop {
    display: none !important;
  }
}
@media (min-width: $tablet_break_point) {
  .visible-desktop {
    display: inherit !important;
  }
}

@media (max-width: $tabmob_break_point) {
  .hidden-tabmob {
    display: none !important;
  }

  .visible-tabmob {
    display: inherit !important;
  }
}

@media (max-width: $mobile_break_point) {
  .hidden-mobile {
    display: none !important;
  }

  .visible-mobile {
    display: inherit !important;
  }

  .visible-mobile-special {
    display: inherit;
  }
}

@media (max-width: $tablet_break_point) {
  .hidden-tablet {
    display: none !important;
  }

  .visible-tablet {
    display: inherit !important;
  }
}

/* Global Layout Styles
=============================================================================*/

html,
body {
  height: 100%;
}

@media (max-width: $tablet_break_point) {
  html.is-responsive.navigation-open {
    overflow: hidden;
    overflow-y: scroll;
  }

  body.is-responsive.touch.navigation-open {
    overflow-x: hidden;
    overflow-y: scroll;
    /* enables smooth scrolling, but gives horizontal scrollbar: */
    /*-webkit-overflow-scrolling: touch;*/
  }

  body.is-responsive.touch.ie10 {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

ul {
  margin-bottom: 15px;
}

li {
  padding-bottom: 7px;
}

li p {
  font-size: 1em;
}
