@import '../../../global/css/colors';
@import '../../../global/css/breakpoints';
@import '../../../global/css/sass-functions';

.receipt {
  .form-fieldset {
    h2 {
      font-size: 1rem;
      padding: 0;
    }
  }
}

.receipt-info {
  &:last-child {
    padding-bottom: 15px;
  }
}

/* COMPONENT: Receipt
=============================================================================*/
.receipt {
  position: relative;
  border-radius: 5px;
  @include font-size(13);
  background-color: $nn_ultra_light_grey;
  padding: 20px 20px 1px;
}

.block.receipt {
  border: 0;
  background: transparent;
}

/* PART: Heading
=============================================================================*/
.receipt-heading,
.is-responsive .receipt-heading {
  line-height: 2em;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include font-size(20);
}
.receipt-heading:before {
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent $nn_ultra_light_grey transparent;
  position: absolute;
  left: 20px;
  top: -20px;
  @media (min-width: $tablet_break_point) {
    left: -17px;
    top: 10px;
    border-width: 20px 20px 20px 0;
    border-color: transparent $nn_ultra_light_grey transparent transparent;
  }
}

/* PART: Content
=============================================================================*/
.receipt-content {
  margin: 1em 0;
}

/* HACK: Collapser
=============================================================================
Since the collapser is was not set up as a separate entity in the CSS,
some hackery is required. This screams refactoring.
*/
.receipt .expand {
  display: inline-block;
  margin: 0 0 10px 0;
}

.receipt .textwrap {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

/* PART: Receipt product list
=============================================================================*/
.receipt-productlist {
  margin: 0 0 5px 0;
  border: none;
  width: 100%;
}
.receipt-productlist td,
.receipt-productlist th {
  border: none;
  margin: 0;
  vertical-align: top;
}

.receipt-productlist td,
.receipt-productlist th {
  padding: 0.2em 0.5em;
  max-width: 70px;
  position: relative;
}

.receipt-productlist td:first-child,
.receipt-productlist th:first-child {
  padding-left: 0;
}

.receipt-productlist td:last-child,
.receipt-productlist th:last-child {
  padding-right: 0;
  text-align: right;
  .amount-currency {
    float: left;
  }
}
.receipt-foot {
  .receipt-productlist th,
  .receipt-productlist td {
    color: $nn_medium_grey;
  }
}

.receipt-productlist-col-item {
  min-width: 6em;
}
.receipt-productlist-col-amount {
  width: 7em;
}
.receipt-productlist-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* PART: Receipt divider
=============================================================================*/
.receipt-divider {
  margin: 0 0 0.5em 0;
}

/* FIX: Fix assumptions of old style
=============================================================================*/
.receipt p.amount {
  border: none;
  margin: 0;
}

/* PART: Totalsum
=============================================================================*/
.receipt-totalsum {
  color: $nn_medium_orange;
  font-size: 1.5em;
  @include font-bold;
}

.receipt .aligner--right {
  text-align: right;
}
