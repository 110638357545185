.videoDiv { 
    position: relative; 
    width: 100%; 
    height: 0; 
    padding-top: 60%; 
    border: 0px; 
} 
.videoDiv > iframe { 
    position: absolute; 
    top: 0; 
    left: 0; 
    bottom: 0; 
    right: 0; 
    width: 100%; 
    border: 0px; 
    height: 100%; 
}
